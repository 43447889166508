import { Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import { ModuleType } from '@module/settings';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Switch, useLocation } from 'react-router-dom';
import Select from 'react-select';
import {
  getPermissionMatrix,
  getPermissionMatrixColumns,
  getPermissionMatrixRows,
  setPermissionValue,
} from '../../modules/settings';
import {
  selectGetPermissionMatrix,
  selectGetPermissionMatrixColumns,
  selectGetPermissionMatrixRows,
} from '../../modules/settings/selectors';
import MultidimensionalGrid from '../../shared/multidimensional-grid';

type CellWithSelectProps = {
  value: any;
  moduleId: string;
  roleId: string;
  moduleType: string;
  isSuperAdmin: boolean;
  isDisabled: boolean;
};

const CellWithSelect = ({
  value,
  moduleId,
  roleId,
  moduleType,
  isSuperAdmin,
  isDisabled,
}: CellWithSelectProps) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectRef = useRef<any>();

  const cellClassName = `${isDisabled ? 'pe-none opacity-50' : ''} w-100 h-100 d-flex align-items-center`;

  const onChange = ({ value }) => {
    setLoading(true);
    (dispatch(setPermissionValue({ value, moduleId, roleId, moduleType, isSuperAdmin })) as any)
      .unwrap()
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (clicked) {
      selectRef.current.focus();
      selectRef.current.onMenuOpen();
    }
  }, [clicked]);

  const options = useMemo(
    () =>
      moduleType !== 'admin'
        ? [
            { value: 'NO', label: 'NO' },
            { value: 'YES', label: 'YES' },
            { value: 'ASSIGN', label: 'ASSIGNED' },
          ]
        : [
            { value: 'NO', label: 'NO' },
            { value: 'YES', label: 'YES' },
          ],
    [moduleType]
  );

  const getLabelByValue = useCallback(
    (value: string) => options.find((o) => o.value === value)?.label || value,
    [options]
  );

  if (clicked)
    return (
      <Select
        ref={selectRef}
        menuPortalTarget={document.body}
        className='w-100'
        onChange={onChange}
        onMenuClose={() => setClicked(false)}
        value={{ value: value, label: getLabelByValue(value) }}
        options={options}
      />
    );

  return (
    <div className={cellClassName} onClick={() => setClicked(true)}>
      {loading ? 'Loading ...' : <a>{getLabelByValue(value) ?? '<Empty>'}</a>}
    </div>
  );
};

const PermissionsPage = ({ isSuperAdmin = false }: { isSuperAdmin?: boolean }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const pathPrefix = isSuperAdmin ? '/admin/default-role-permissions/' : '/settings/permissions/';

  const moduleType = location.pathname.replace(pathPrefix, '') as ModuleType;

  const { data: userSettings } = useSelector(selectGetPermissionMatrixRows);
  const { data: modules } = useSelector(selectGetPermissionMatrixColumns);
  const { data: matrix } = useSelector(selectGetPermissionMatrix);

  const manageAdminsModuleId = modules.find((module) => module.moduleCode === 'P-MANAGE-ADMINS')?._id;
  const disabledModules = [manageAdminsModuleId].filter((val) => val); // filter to remove trash

  useEffect(() => {
    dispatch(getPermissionMatrixRows({ isSuperAdmin }));
    dispatch(getPermissionMatrixColumns({ moduleType }));
    dispatch(getPermissionMatrix({ moduleType, isSuperAdmin }));
  }, [moduleType]);

  return (
    <Switch>
      <TabContext value={location.pathname}>
        <TabList
          variant='scrollable'
          TabIndicatorProps={{ style: { backgroundColor: '#000' } }}
          aria-label='User permissions and roles tabs'
        >
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label={'Status Change'}
            value={`${pathPrefix}status-change`}
            to={`${pathPrefix}status-change`}
          />
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label='Report Access'
            value={`${pathPrefix}report-access`}
            to={`${pathPrefix}report-access`}
          />
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label='Actions'
            value={`${pathPrefix}actions`}
            to={`${pathPrefix}actions`}
          />
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label='Alerts'
            value={`${pathPrefix}alerts`}
            to={`${pathPrefix}alerts`}
          />
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label='Widgets'
            value={`${pathPrefix}user-interaction`}
            to={`${pathPrefix}user-interaction`}
          />
          <Tab
            disableRipple
            component={Link}
            className='flex-grow mw-30 text-reset text-decoration-none'
            label='Admin'
            value={`${pathPrefix}admin`}
            to={`${pathPrefix}admin`}
          />
        </TabList>

        <MultidimensionalGrid
          columnDimension={modules}
          rowDimension={userSettings}
          matrixData={matrix}
          rowNameHeader='User Type'
          // limit rendered data
          //maxHeight={500}
          useControlledState={(state) => ({ ...state, moduleType, isSuperAdmin, disabledModules })}
          Cell={({ value, row, column, state }) => {
            const { moduleType, isSuperAdmin, disabledModules } = state;
            const roleCode = row.original._row.originalRoleCode;
            const isAdminPermCell = moduleType === 'admin' && roleCode === 'ADMIN';
            const isDisabled = isAdminPermCell || disabledModules.includes(column.id);

            return (
              <CellWithSelect
                value={value}
                moduleId={column.id}
                roleId={row.original._rowIndex}
                moduleType={moduleType}
                isSuperAdmin={isSuperAdmin}
                isDisabled={isDisabled}
                />
            );
          }}
        />
      </TabContext>
    </Switch>
  );
};

export default PermissionsPage;
