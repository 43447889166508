import { useEffect, useCallback, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CurrencyInput } from '../../../../../shared/form-controls/form-input/currency-input';
import FormInput from '../../../../../shared/form-controls/form-input';
import FormSelect from '../../../../../shared/form-controls/form-select/form-select';
import { currencyToNumber, formatMoneyAuto, formatNumber } from '../../../../../utils/number-formatters';
import styles from '../style.module.scss';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { EditSettingModal } from '../../../../../pages/settings/line-items-page/edit-setting-modal';
import _ from 'lodash';

type ScopeEstimateProps = {
  options: Array<any>;
  allOptions: Array<any>;
  id: string;
  itemIndex: number;
  isLocked: boolean;
  showProfitAsPers: boolean;
  onAddFirstLevelItem: (index: number) => void;
  onRemoveFirstLevelItem: (index: number) => void;
  isTotalLocked?: boolean;
  lineItemOptions?: Array<any>;
  detailedMode?: boolean;
  visibleColumns?: {
    serviceDate: boolean;
    description: boolean;
    qty: boolean;
    unitCost: boolean;
    cost: boolean;
    markup: boolean;
    unitPrice: boolean;
    total: boolean;
    taxable: boolean;
    billable: boolean;
  };
  textAreaEnabled?: boolean;
  onDragEndNested: (result: any) => void;
  dragHandleProps: any;
  onAddNewLineItem: (lineItem: any) => void;
};

export const ScopeEstimateModule = ({
  options,
  allOptions,
  id,
  itemIndex,
  isLocked,
  showProfitAsPers,
  onAddFirstLevelItem,
  onRemoveFirstLevelItem,
  isTotalLocked = false,
  detailedMode = false,
  lineItemOptions = [],
  visibleColumns = {
    serviceDate: false,
    description: true,
    qty: true,
    unitCost: true,
    cost: true,
    markup: true,
    unitPrice: true,
    total: true,
    taxable: true,
    billable: false,
  },
  textAreaEnabled = true,
  onDragEndNested,
  dragHandleProps,
  onAddNewLineItem,
}: ScopeEstimateProps) => {
  const fieldName = 'estData';
  const { watch, setValue, control, getValues } = useFormContext();
  const estData = watch(fieldName);
  const curLineItemId = estData[itemIndex]?.lineItemId;
  const scopeEst = estData[itemIndex];
  const fieldPrefix = `${fieldName}.${itemIndex}`;
  const setValueOptions = { shouldValidate: true };

  const [showEditModal, setShowEditModal] = useState(false);
  const [newLineItemName, setNewLineItemName] = useState('');
  const [lastNewLineItemName, setLastNewLineItemName] = useState('');
  const [lineItemLabel, setLineItemLabel] = useState('');

  // Format money consistently
  const formatMoneyLocal = useCallback((value: any) => {
    return formatNumber( value, { prefix: '', autoDecimal: true, decimalCount: 2 });
  }, []);

  // Initialize scope estimate calculations if needed
  useEffect(() => {
    const { subTotalProfit, subTotalCost, subTotalCtr, qty, unitProfit } = scopeEst;
    const subTotalCtrNumber = currencyToNumber(subTotalCtr);
    const subTotalProfitNumber = currencyToNumber(subTotalProfit);
    const subTotalCostNumber = currencyToNumber(subTotalCost);
    const profitPers =
      subTotalCostNumber === 0 ? 0 : (subTotalProfitNumber / subTotalCostNumber) * 100;

    if (!unitProfit || !qty || qty?.length == 0 || unitProfit?.length == 0) {
      // For legacy scope that needs updating, do this only once
      const updates = {
        [`${fieldPrefix}.qty`]: '1',
        [`${fieldPrefix}.unitCost`]: formatMoneyLocal(subTotalCostNumber),
        [`${fieldPrefix}.unitPrice`]: formatMoneyLocal(subTotalCtrNumber),
        [`${fieldPrefix}.unitProfit`]: formatMoneyLocal(subTotalProfitNumber),
        [`${fieldPrefix}.subTotalCtr`]: formatMoneyLocal(subTotalCtrNumber),
        [`${fieldPrefix}.subTotalCost`]: formatMoneyLocal(subTotalCostNumber),
        [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(subTotalProfitNumber),
        [`${fieldPrefix}.serviceDate`]: null,
        [`${fieldPrefix}.taxable`]: false,
        [`${fieldPrefix}.billable`]: true
      };
      
      // Batch update values
      Object.entries(updates).forEach(([key, value]) => {
        setValue(key, value, setValueOptions);
      });
    }

    setValue(`${fieldPrefix}.subTotalProfitPers`, profitPers.toFixed(1), setValueOptions);
  }, []);

  // Memoize style classes to avoid recalculation
  const formClasses = useMemo(() => {
    return {
      formInputClass: `px-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`,
      formInputClassShort: `px-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailedShort : styles.inputWidth}`,
      formInputClassExtraShort: `px-1 ms-1 mt-1 me-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailedExtraShort : styles.inputWidth}`,
      formInputClassLong: `px-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailedLong : styles.inputWidth}`,
      itemLabelStyle: `${detailedMode ? 'col-md-2' : 'col-md-4'}`
    };
  }, [detailedMode, styles]);

  // Memoize line item options
  const lineItemSelectOptions = useMemo(() => {
    const allOptions = lineItemOptions.map((option) => ({
      value: option._id,
      label: option.name,
      definition: option.definition,
      category: option.qbCategory,
      type: option.type,
      scopeId: option?.jobScopeId,
      itemGroupDetails: option.type === 'Group' ? option.itemGroupDetails : null,
      allowLineItems: option.type === 'Group',
    }));

    return {
      all: allOptions,
      standard: allOptions.filter(option => option.type !== 'Group')
    };
  }, [lineItemOptions]);

  // Check if the current scope is a group item
  const isGroupItem = useMemo(() => {
    return lineItemOptions.find((option) => option._id === scopeEst.lineItemId)?.type === 'Group';
  }, [lineItemOptions, scopeEst.lineItemId]);

  // Check if we have line items
  const hasLineItems = useMemo(() => {
    return scopeEst.items && scopeEst.items.length > 0;
  }, [scopeEst.items]);
  
  // Effect to clear items if not a group item
  useEffect(() => {
    if (scopeEst.items && scopeEst.items.length > 0 && !isGroupItem) {
      setValue(`${fieldPrefix}.items`, [], setValueOptions);
    }
  }, [isGroupItem, scopeEst.items]);

  // Effect to update line item label when item changes
  useEffect(() => {
    const lineItem = lineItemOptions.find((option) => option._id === curLineItemId);
    setLineItemLabel(lineItem?.name ?? '');
  }, [curLineItemId, lineItemOptions]);

  // Function to check if an option is disabled
  const isOptionDisabled = useCallback(
    (option: any) => estData.find((estData: any) => estData.scopeId === option.value),
    [estData]
  );

  // Recalculate all totals with optimized batching
  const recalculateTotals = useCallback((itemIndex?: number) => {
    let estTotalCost = 0;
    let estTotalProfit = 0;
    let estTotalContract = 0;
  
    const currentEstData = getValues(fieldName);
    const updates = {};
  
    currentEstData.forEach((scopeEst: any, index: number) => {
      // Ensure items is always an array
      if (!scopeEst.items) {
        scopeEst.items = [];
      }
      
      const { qty, lineItemId } = scopeEst;
      const items = scopeEst.items;
  
      const isAllowedLineItems = lineItemId ? 
        lineItemSelectOptions.all.find((option) => option.value === lineItemId)?.allowLineItems : 
        false;
  
      if (items.length === 0 && !isAllowedLineItems) {
        if (!qty) {
          return;
        }
  
        const { subTotalCost, subTotalProfit, subTotalCtr } = scopeEst;
        const subTotalCostNumber = currencyToNumber(subTotalCost);
        const subTotalProfitNumber = currencyToNumber(subTotalProfit);
        const subTotalCtrNumber = currencyToNumber(subTotalCtr);
  
        estTotalCost += subTotalCostNumber;
        estTotalProfit += subTotalProfitNumber;
        estTotalContract += subTotalCtrNumber;
  
        return;
      }
  
      const curQty = currencyToNumber(qty);
      const unitCost =
        items.reduce((acc: number, curr: any) => acc + currencyToNumber(curr.cost), 0) / curQty;
      const subTotalCost = unitCost * curQty;
  
      const unitPrice =
        items.reduce((acc: number, curr: any) => acc + currencyToNumber(curr.total), 0) / curQty;
      const subTotalCtr = unitPrice * curQty;
  
      const unitProfit = unitCost === 0 ? 0 : unitPrice - unitCost;
      const subTotalProfit = subTotalCtr - subTotalCost;
      const unitProfitPers = unitCost === 0 ? 0 : (unitProfit / unitCost) * 100;
  
      if (!qty) {
        return;
      }
  
      estTotalCost += subTotalCost;
      estTotalProfit += subTotalProfit;
      estTotalContract += subTotalCtr;
  
      if (itemIndex !== undefined && itemIndex !== index) {
        return;
      }
  
      const valsToSet = [
        { key: 'unitCost', value: unitCost },
        { key: 'unitPrice', value: unitPrice },
        { key: 'unitProfit', value: unitProfit },
        { key: 'subTotalProfitPers', value: unitProfitPers },
        { key: 'subTotalCost', value: subTotalCost },
        { key: 'subTotalProfit', value: subTotalProfit },
        { key: 'subTotalCtr', value: subTotalCtr },
      ];
  
      valsToSet.forEach((val) => {
        const newVal = val.key === 'subTotalProfitPers' ? val.value.toFixed(1) : formatMoneyLocal(val.value);
        
        // Only update if value has changed
        if (scopeEst[val.key] !== newVal) {
          updates[`${fieldName}.${index}.${val.key}`] = newVal;
        }
      });
    });
  
    // Batch updates for better performance
    updates['estTotalCost'] = estTotalCost;
    updates['estTotalProfit'] = estTotalProfit;
    updates['estTotalContract'] = estTotalContract;
    
    Object.entries(updates).forEach(([key, value]) => {
      setValue(key, value, setValueOptions);
    });
  }, [getValues, setValue, fieldName, formatMoneyLocal, lineItemSelectOptions]);

  // Update all sub-fields in a batch for better performance
  const updateEstDataSubFields = useCallback(
    (items) => {
      const currentItems = items ?? getValues(`${fieldPrefix}.items`);
      
      // Prepare batch updates
      currentItems.forEach((subItem, subIndex) => {
        const path = `${fieldPrefix}.items.${subIndex}`;
        const updates = {
          [`${path}.lineItemId`]: subItem.lineItemId,
          [`${path}.qbItemId`]: subItem.qbItemId,
          [`${path}.scopeId`]: subItem.scopeId,
          [`${path}.qty`]: subItem.qty ?? 0,
          [`${path}.unitCost`]: subItem.unitCost ?? 0,
          [`${path}.unitPrice`]: subItem.unitPrice ?? 0,
          [`${path}.cost`]: subItem.cost,
          [`${path}.profit`]: subItem.profit,
          [`${path}.total`]: subItem.total,
          [`${path}.profitPers`]: subItem.profitPers,
          [`${path}.description`]: subItem.description ?? '',
          [`${path}.serviceDate`]: subItem.serviceDate,
          [`${path}.taxable`]: subItem.taxable ?? false,
          [`${path}.billable`]: subItem.billable ?? true
        };
        
        // Batch set values
        Object.entries(updates).forEach(([key, value]) => {
          setValue(key, value, setValueOptions);
        });
      });
    },
    [setValue, getValues, fieldPrefix]
  );

  // Handler for removing a line item with proper index handling
  const onRemoveLineItem = useCallback((fieldPrefix: string, lineItemIndex: number) => {
    const currentItems = _.cloneDeep(scopeEst.items);
    const updatedItems = currentItems.filter((_, index) => index !== lineItemIndex);
    
    setValue(`${fieldPrefix}.items`, updatedItems, setValueOptions);
    updateEstDataSubFields(updatedItems);
    recalculateTotals(itemIndex);
  }, [scopeEst.items, setValue, updateEstDataSubFields, recalculateTotals, itemIndex]);

  // Handler for adding a line item at a specific position
  const onAddLineItem = useCallback((at_index = 0) => {
    const newLineItem = {
      lineItemId: '',
      description: '',
      qty: 1,
      unitCost: 0,
      cost: 0,
      profit: 0,
      profitPers: 0,
      unitPrice: 0,
      total: 0,
      qbItemId: '',
      taxable: false,
      billable: true,
      serviceDate: null,
      scopeId: '',
    };

    const currentItems = scopeEst.items ?? [];
    let updatedItems;

    if (at_index < 0 || at_index > currentItems.length) {
      updatedItems = [...currentItems, newLineItem];
    } else {
      updatedItems = _.cloneDeep(currentItems);
      updatedItems.splice(at_index, 0, newLineItem);
    }

    setValue(`${fieldPrefix}.items`, updatedItems, setValueOptions);
    updateEstDataSubFields(updatedItems);
    recalculateTotals(itemIndex);
  }, [scopeEst.items, setValue, updateEstDataSubFields, recalculateTotals, itemIndex, fieldPrefix]);

  // Function to render a line item label with consistent styling
  const renderLineItemLabel = useCallback((option, menuIsOpen) => {
    if (menuIsOpen) {
      return (
        <div>
          <div className='row d-flex flex-nowrap'>
            <div className='col'>{option.label}</div>
            <div className='col text-muted small'>{option.definition}</div>
          </div>
        </div>
      );
    } else {
      return option.label;
    }
  }, []);

  // Render function for line items with memoization
  const renderLineItem = useCallback((lineItem: any, index: number) => {
    return (
      <Draggable
        key={`${fieldPrefix}.items.${index}.` + lineItem.lineItemId}
        draggableId={'level2:' + itemIndex + ':' + index + ':' + lineItem.lineItemId}
        index={index}
        isDragDisabled={isLocked}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className='row flex-nowrap col-md-12'
            data-testId='bidManagementModalScope'
          >
            <div {...provided.dragHandleProps} className={`px-1 ${styles.dragHandle} ms-2`}>
              <FontAwesomeIcon icon={faGripVertical} />
            </div>

            <div
              className={`px-1 pe-0 ${formClasses.itemLabelStyle} ${isLocked ? styles.disabledSelect : ''}`}
              style={
                detailedMode ? { width: 'calc((100%/6) - 0.5em' } : { width: 'calc(100%/12*4)' }
              }
            >
              <FormSelect
                label=''
                control={control}
                className={`ms-0 text-start px-1 ${styles.lineItemSelect}`}
                options={[
                  ...lineItemSelectOptions.standard,
                  {
                    value: 'add_new',
                    label: `Add "${newLineItemName}"`,
                    isDisabled: !newLineItemName,
                  },
                ]}
                isClearable={true}
                inputClassName='lineItemSelect'
                key={`${fieldPrefix}.items.${index}.lineItemId`}
                name={`${fieldPrefix}.items.${index}.lineItemId`}
                isOptionDisabled={isOptionDisabled}
                disabled={isLocked}
                formatOptionLabel={(option, menuIsOpen) => {
                  if (option.value === 'add_new' && newLineItemName.length > 0) {
                    return <span style={{ fontStyle: 'italic' }}>{option.label}</span>;
                  } else if (option.value === 'add_new' && newLineItemName.length == 0) {
                    return <span style={{ fontStyle: 'italic' }}>{lastNewLineItemName}</span>;
                  }

                  return renderLineItemLabel(option, menuIsOpen);
                }}
                onInputChange={(inputValue) => {
                  inputValue = inputValue.trim();
                  setNewLineItemName(inputValue);
                  if (inputValue.length > 0) {
                    setLastNewLineItemName(inputValue);
                  }
                }}
                onChange={(e) => {
                  if (e?.value === 'add_new') {
                    setShowEditModal(true);
                  } else if (e) {
                    const lineItem = lineItemOptions.find((option) => option._id === e.value);
                    if (lineItem) {
                      const updates = {
                        [`${fieldPrefix}.items.${index}.description`]: lineItem.definition,
                        [`${fieldPrefix}.items.${index}.unitCost`]: lineItem.unitCost,
                        [`${fieldPrefix}.items.${index}.unitPrice`]: lineItem.unitPrice,
                        [`${fieldPrefix}.items.${index}.qbItemId`]: lineItem.qbItemId,
                        [`${fieldPrefix}.items.${index}.taxable`]: lineItem.taxable,
                        [`${fieldPrefix}.items.${index}.billable`]: lineItem.billable,
                        [`${fieldPrefix}.items.${index}.serviceDate`]: null
                      };

                      // Batch update values
                      Object.entries(updates).forEach(([key, value]) => {
                        setValue(key, value, setValueOptions);
                      });

                      let currentQty = currencyToNumber(scopeEst.items[index].qty);
                      if (currentQty === 0) {
                        currentQty = currencyToNumber(lineItem.defaultQuantity ?? '1');
                        setValue(
                          `${fieldPrefix}.items.${index}.qty`,
                          currentQty.toString(),
                          setValueOptions
                        );
                      }

                      const unitCost = currencyToNumber(lineItem.unitCost);
                      const unitPrice = currencyToNumber(lineItem.unitPrice);

                      const totalCost = currentQty * unitCost;
                      const total = currentQty * unitPrice;
                      const markup = unitPrice - unitCost;
                      const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                      const calculatedValues = {
                        [`${fieldPrefix}.items.${index}.cost`]: formatMoneyAuto(totalCost),
                        [`${fieldPrefix}.items.${index}.total`]: formatMoneyLocal(total),
                        [`${fieldPrefix}.items.${index}.profit`]: formatMoneyLocal(markup),
                        [`${fieldPrefix}.items.${index}.profitPers`]: markupPercent.toFixed(1)
                      };

                      // Batch update calculated values
                      Object.entries(calculatedValues).forEach(([key, value]) => {
                        setValue(key, value, setValueOptions);
                      });

                      recalculateTotals(itemIndex);
                    }
                  }
                }}
              />
              {isLocked && (
                <div className='ms-2'>
                  {
                    lineItemSelectOptions.all.find(
                      (option) => option.value === lineItem.lineItemId
                    )?.label
                  }
                </div>
              )}
            </div>

            {/* Description field */}
            {detailedMode && visibleColumns.description && (
              <FormInput
                type='text'
                inputClassName={`form-control text-start`}
                className={formClasses.formInputClassLong}
                control={control}
                key={`${fieldPrefix}.items.${index}.description`}
                name={`${fieldPrefix}.items.${index}.description`}
                placeholder='Description'
                disabled={isLocked}
                textArea={textAreaEnabled}
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
            )}

            {/* Service Date field */}
            {detailedMode && visibleColumns.serviceDate && (
              <FormInput
                type='date'
                inputClassName={`form-control text-start`}
                className={formClasses.formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.serviceDate`}
                name={`${fieldPrefix}.items.${index}.serviceDate`}
                placeholder='Service Date'
                disabled={isLocked}
              />
            )}

            {/* Quantity field */}
            {detailedMode && visibleColumns.qty && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end`}
                className={formClasses.formInputClassShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.qty`}
                name={`${fieldPrefix}.items.${index}.qty`}
                placeholder='Qty'
                disabled={isLocked}
                onChange={(e) => {
                  const qty = currencyToNumber(e);
                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                  const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);

                  const totalCost = qty * unitCost;
                  const total = qty * unitPrice;

                  const updates = {
                    [`${fieldPrefix}.items.${index}.cost`]: formatMoneyLocal(totalCost),
                    [`${fieldPrefix}.items.${index}.total`]: formatMoneyLocal(total)
                  };

                  // Batch update values
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }}
              />
            )}

            {/* Unit Cost field */}
            {detailedMode && visibleColumns.unitCost && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end`}
                className={formClasses.formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.unitCost`}
                name={`${fieldPrefix}.items.${index}.unitCost`}
                placeholder='Unit Cost'
                disabled={isLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const unitCost = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const updates = {};

                  if (isTotalLocked) {
                    const total = currencyToNumber(scopeEst.items[index].total);
                    const unitPrice = total / qty;
                    const markup = unitPrice - unitCost;
                    const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                    const totalCost = qty * unitCost;

                    updates[`${fieldPrefix}.items.${index}.cost`] = formatMoneyLocal(totalCost);
                    updates[`${fieldPrefix}.items.${index}.profit`] = formatMoneyLocal(markup);
                    updates[`${fieldPrefix}.items.${index}.profitPers`] = markupPercent.toFixed(1);
                    updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                  } else {
                    const markup = showProfitAsPers
                      ? (currencyToNumber(scopeEst.items[index].profitPers) / 100) * unitCost
                      : currencyToNumber(scopeEst.items[index].profit);

                    const unitPrice = unitCost + markup;
                    const totalCost = qty * unitCost;
                    const total = qty * unitPrice;

                    updates[`${fieldPrefix}.items.${index}.cost`] = formatMoneyLocal(totalCost);
                    updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                    updates[`${fieldPrefix}.items.${index}.total`] = formatMoneyLocal(total);
                  }

                  // Batch update values
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }}
              />
            )}

            {/* Cost field */}
            {detailedMode && visibleColumns.cost && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end`}
                className={formClasses.formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.cost`}
                name={`${fieldPrefix}.items.${index}.cost`}
                placeholder='Cost'
                disabled={isLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const cost = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const unitCost = cost / qty;
                  const updates = {};

                  if (isTotalLocked) {
                    const total = currencyToNumber(scopeEst.items[index].total);
                    const unitPrice = total / qty;
                    const markup = unitPrice - unitCost;
                    const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                    updates[`${fieldPrefix}.items.${index}.unitCost`] = formatMoneyLocal(unitCost);
                    updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                    updates[`${fieldPrefix}.items.${index}.profit`] = formatMoneyLocal(markup);
                    updates[`${fieldPrefix}.items.${index}.profitPers`] = markupPercent.toFixed(1);
                  } else {
                    const markup = showProfitAsPers
                      ? (currencyToNumber(scopeEst.items[index].profitPers) / 100) * unitCost
                      : currencyToNumber(scopeEst.items[index].profit);

                    const unitPrice = unitCost + markup;
                    const total = qty * unitPrice;

                    updates[`${fieldPrefix}.items.${index}.unitCost`] = formatMoneyLocal(unitCost);
                    updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                    updates[`${fieldPrefix}.items.${index}.total`] = formatMoneyLocal(total);
                  }

                  // Batch update values
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }}
              />
            )}

            {/* Markup field */}
            {detailedMode && visibleColumns.markup && (
              showProfitAsPers ? (
                <FormInput
                  type='number'
                  inputClassName={`form-control text-end`}
                  className={`${formClasses.formInputClass} ${styles.percentage}`}
                  control={control}
                  key={`${fieldPrefix}.items.${index}.profitPers`}
                  name={`${fieldPrefix}.items.${index}.profitPers`}
                  placeholder='%'
                  disabled={isLocked}
                  onChange={(e) => {
                    const profitPers = currencyToNumber(e);
                    const qty = currencyToNumber(scopeEst.items[index].qty);
                    const updates = {};

                    if (isTotalLocked) {
                      const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);
                      const unitCost = unitPrice / (1 + profitPers / 100);
                      const totalCost = qty * unitCost;

                      updates[`${fieldPrefix}.items.${index}.unitCost`] = formatMoneyLocal(unitCost);
                      updates[`${fieldPrefix}.items.${index}.cost`] = formatMoneyLocal(totalCost);
                    } else {
                      const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                      const markup = (profitPers / 100) * unitCost;
                      const unitPrice = unitCost + markup;
                      const total = qty * unitPrice;

                      updates[`${fieldPrefix}.items.${index}.profit`] = formatMoneyLocal(markup);
                      updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                      updates[`${fieldPrefix}.items.${index}.total`] = formatMoneyLocal(total);
                    }

                    // Batch update values
                    Object.entries(updates).forEach(([key, value]) => {
                      setValue(key, value, setValueOptions);
                    });

                    recalculateTotals(itemIndex);
                  }}
                />
              ) : (
                <FormInput
                  type='number'
                  inputClassName={`form-control text-end`}
                  className={formClasses.formInputClass}
                  control={control}
                  key={`${fieldPrefix}.items.${index}.profit`}
                  name={`${fieldPrefix}.items.${index}.profit`}
                  placeholder='Profit ($)'
                  disabled={isLocked}
                  CustomInput={CurrencyInput}
                  onChange={(e) => {
                    const profit = currencyToNumber(e);
                    const qty = currencyToNumber(scopeEst.items[index].qty);
                    const updates = {};

                    if (isTotalLocked) {
                      const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);
                      const unitCost = unitPrice - profit;
                      const profitPers = unitCost === 0 ? 0 : (profit / unitCost) * 100;
                      const totalCost = qty * unitCost;

                      updates[`${fieldPrefix}.items.${index}.profitPers`] = profitPers.toFixed(1);
                      updates[`${fieldPrefix}.items.${index}.unitCost`] = formatMoneyLocal(unitCost);
                      updates[`${fieldPrefix}.items.${index}.cost`] = formatMoneyLocal(totalCost);
                    } else {
                      const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                      const unitPrice = unitCost + profit;
                      const total = qty * unitPrice;

                      updates[`${fieldPrefix}.items.${index}.unitPrice`] = formatMoneyLocal(unitPrice);
                      updates[`${fieldPrefix}.items.${index}.total`] = formatMoneyLocal(total);
                    }

                    // Batch update values
                    Object.entries(updates).forEach(([key, value]) => {
                      setValue(key, value, setValueOptions);
                    });

                    recalculateTotals(itemIndex);
                  }}
                />
              )
            )}

            {/* Unit Price field */}
            {detailedMode && visibleColumns.unitPrice && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end`}
                className={formClasses.formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.unitPrice`}
                name={`${fieldPrefix}.items.${index}.unitPrice`}
                placeholder='Unit Price'
                disabled={isLocked || isTotalLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const unitPrice = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                  const markup = unitPrice - unitCost;
                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                  const total = qty * unitPrice;

                  const updates = {
                    [`${fieldPrefix}.items.${index}.profitPers`]: markupPercent.toFixed(1),
                    [`${fieldPrefix}.items.${index}.profit`]: formatMoneyLocal(markup),
                    [`${fieldPrefix}.items.${index}.total`]: formatMoneyLocal(total)
                  };

                  // Batch update values
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }}
              />
            )}

            {/* Total field */}
            {(!detailedMode || visibleColumns.total) && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end`}
                className={formClasses.formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.total`}
                name={`${fieldPrefix}.items.${index}.total`}
                placeholder='Total'
                disabled={isLocked || isTotalLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const total = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                  const profit = total - qty * unitCost;
                  const unitPrice = total / qty;
                  const markupPercent = unitCost === 0 ? 0 : ((total - qty * unitCost) / (qty * unitCost)) * 100;

                  const updates = {
                    [`${fieldPrefix}.items.${index}.profit`]: formatMoneyLocal(profit),
                    [`${fieldPrefix}.items.${index}.unitPrice`]: formatMoneyLocal(unitPrice),
                    [`${fieldPrefix}.items.${index}.profitPers`]: markupPercent.toFixed(1)
                  };

                  // Batch update values
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }}
              />
            )}

            {/* Taxable checkbox */}
            {detailedMode && visibleColumns.taxable && (
              <FormInput
                type='checkbox'
                inputClassName={`form-check-input ` + styles.checkbox}
                className={formClasses.formInputClassExtraShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.taxable`}
                name={`${fieldPrefix}.items.${index}.taxable`}
                disabled={isLocked}
              />
            )}

            {/* Billable checkbox */}
            {detailedMode && visibleColumns.billable && (
              <FormInput
                type='checkbox'
                inputClassName={`form-check-input ` + styles.checkbox}
                className={formClasses.formInputClassExtraShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.billable`}
                name={`${fieldPrefix}.items.${index}.billable`}
                disabled={isLocked}
              />
            )}

            {/* Control buttons */}
            {!isLocked && (
              <div className={`px-1 ${styles.controlBtn} me-2`}>
                {!isLocked && detailedMode && isGroupItem ? (
                  <div className=''>
                    <a className='ms-1' onClick={() => onAddLineItem(index + 1)}>
                      <i
                        className={`fa-solid fa-plus-circle ${styles.addButton}`}
                        aria-hidden='true'
                        title='Add line item'
                      ></i>
                    </a>
                  </div>
                ) : (
                  <div className={`ms-4`}></div>
                )}
                <a onClick={() => onRemoveLineItem(fieldPrefix, index)}>
                  <i
                    className={`fa-solid fa-circle-xmark ${styles.deleteButton}`}
                    aria-hidden='true'
                    title='Remove line item'
                  ></i>
                </a>
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  }, [
    fieldPrefix, itemIndex, isLocked, formClasses, control, styles, isOptionDisabled, 
    lineItemSelectOptions, newLineItemName, lastNewLineItemName, scopeEst.items, isTotalLocked,
    showProfitAsPers, recalculateTotals, renderLineItemLabel, isGroupItem, onAddLineItem, onRemoveLineItem
  ]);

  return (
    <>
      {isGroupItem && <br></br>}
      <div className={'row flex-nowrap col-md-12'} data-testId='bidManagementModalScope'>
        {/* Edit modal for adding new line items */}
        {showEditModal && (
          <EditSettingModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            settingId={null}
            onSubmit={(setting: any) => {
              setShowEditModal(false);

              if (setting.name.length > 0) {
                onAddNewLineItem({
                  name: setting.name,
                  definition: setting.definition,
                  code: setting.code,
                });
              }
            }}
            initialValues={{ name: lastNewLineItemName, jobScopeId: scopeEst.scopeId }}
            jobScopes={options.map((option) => ({ _id: option.value, jobScopeName: option.label }))}
          />
        )}

        {/* Drag handle for detailed mode */}
        {detailedMode && !isLocked && (
          <div {...dragHandleProps} className={`px-1 ${styles.dragHandle} ms-0 mb-0`}>
            <FontAwesomeIcon icon={faGripVertical} />
          </div>
        )}

        {/* Main item selection area */}
        <div
          className={`px-1 ${formClasses.itemLabelStyle} ${
            scopeEst.allowChangeScope || isLocked ? styles.disabledSelect : ''
          }`}
        >
          {lineItemOptions?.length > 0 ? (
            !isLocked ? (
              <div>
                <div className='ms-2'>
                  <FormSelect
                    label={''}
                    control={control}
                    options={[
                      ...lineItemSelectOptions.all,
                      {
                        value: 'add_new',
                        label: `Add "${newLineItemName}"`,
                        isDisabled: !newLineItemName,
                        scopeId: scopeEst.scopeId,
                      },
                    ]}
                    isClearable={true}
                    inputClassName='lineItemSelect'
                    key={`${fieldPrefix}.lineItemId`}
                    name={`${fieldPrefix}.lineItemId`}
                    isOptionDisabled={isOptionDisabled}
                    disabled={isLocked}
                    formatOptionLabel={(option, menuIsOpen) => {
                      if (option.value === 'add_new' && newLineItemName.length > 0) {
                        return <span style={{ fontStyle: 'italic' }}>{option.label}</span>;
                      } else if (option.value === 'add_new' && newLineItemName.length == 0) {
                        return <span style={{ fontStyle: 'italic' }}>{lastNewLineItemName}</span>;
                      }

                      return renderLineItemLabel(option, menuIsOpen);
                    }}
                    onInputChange={(inputValue) => {
                      inputValue = inputValue.trim();
                      setNewLineItemName(inputValue);
                      if (inputValue.length > 0) {
                        setLastNewLineItemName(inputValue);
                      }
                    }}
                    onChange={(e) => {
                      if (!e) {
                        setValue(`${fieldPrefix}.description`, '', setValueOptions);
                        setValue(`${fieldPrefix}.scopeId`, '', setValueOptions);
                        return;
                      }
                      
                      if (e.value === 'add_new') {
                        setShowEditModal(true);
                      } else {
                        const lineItem = lineItemOptions.find((option) => option._id === e.value);
                        if (lineItem) {
                          // Prepare all updates in one batch
                          const updates = {
                            [`${fieldPrefix}.description`]: lineItem.definition,
                            [`${fieldPrefix}.unitCost`]: lineItem.unitCost,
                            [`${fieldPrefix}.unitPrice`]: lineItem.unitPrice,
                            [`${fieldPrefix}.qbItemId`]: lineItem.qbItemId,
                            [`${fieldPrefix}.taxable`]: lineItem.taxable,
                            [`${fieldPrefix}.billable`]: lineItem.billable,
                            [`${fieldPrefix}.serviceDate`]: null,
                            [`${fieldPrefix}.scopeId`]: lineItem.jobScopeId
                          };
                          
                          // Apply all updates
                          Object.entries(updates).forEach(([key, value]) => {
                            setValue(key, value, setValueOptions);
                          });

                          let currentQty = currencyToNumber(scopeEst.qty);
                          if (currentQty === 0) {
                            currentQty = currencyToNumber(lineItem.defaultQuantity ?? '1');
                            setValue(`${fieldPrefix}.qty`, currentQty.toString(), setValueOptions);
                          }

                          const itemGroupDetails = lineItem.itemGroupDetails;

                          if (itemGroupDetails && lineItem.type === 'Group') {
                            const newSubItems = [] as any[];

                            itemGroupDetails.forEach((subItem) => {
                              const subLineItem = lineItemOptions.find(
                                (option) => option.qbItemId === subItem.qbId
                              );
                              const subLineItemQty = currencyToNumber(subItem.qty);

                              if (subLineItem) {
                                newSubItems.push({
                                  lineItemId: subLineItem._id,
                                  description: subLineItem.definition,
                                  qty: subLineItemQty.toString(),
                                  unitCost: subLineItem.unitCost,
                                  cost: formatMoneyLocal(subLineItem.unitCost * subLineItemQty),
                                  unitPrice: subLineItem.unitPrice,
                                  total: formatMoneyLocal(subLineItem.unitPrice * subLineItemQty),
                                  profit: formatMoneyLocal(
                                    subLineItem.unitPrice * subLineItemQty -
                                      subLineItem.unitCost * subLineItemQty
                                  ),
                                  profitPers: (
                                    ((subLineItem.unitPrice * subLineItemQty -
                                      subLineItem.unitCost * subLineItemQty) /
                                      (subLineItem.unitCost * subLineItemQty)) *
                                    100
                                  ).toFixed(1),
                                  subTotalCtr: formatMoneyLocal(
                                    subLineItem.unitPrice * subLineItemQty
                                  ),
                                  qbItemId: subLineItem.qbItemId,
                                  taxable: subLineItem.taxable,
                                  billable: subLineItem.billable,
                                  serviceDate: null,
                                });
                              }
                            });
                            setValue(`${fieldPrefix}.items`, newSubItems, setValueOptions);
                          }

                          const unitCost = currencyToNumber(lineItem.unitCost);
                          const unitPrice = currencyToNumber(lineItem.unitPrice);

                          const totalCost = currentQty * unitCost;
                          const total = currentQty * unitPrice;
                          const markup = unitPrice - unitCost;

                          // Calculate and set financial values
                          const financialUpdates = {
                            [`${fieldPrefix}.subTotalCtr`]: formatMoneyLocal(total),
                            [`${fieldPrefix}.subTotalCost`]: formatMoneyLocal(totalCost),
                            [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(markup),
                            [`${fieldPrefix}.unitPrice`]: formatMoneyLocal(unitPrice),
                            [`${fieldPrefix}.unitCost`]: formatMoneyLocal(unitCost)
                          };
                          
                          // Apply financial updates
                          Object.entries(financialUpdates).forEach(([key, value]) => {
                            setValue(key, value, setValueOptions);
                          });

                          recalculateTotals(itemIndex);
                        }
                      }
                    }}
                  />
                </div>
                {isLocked && (
                  <div className='ms-0 mt-2'>
                    {lineItemLabel}
                  </div>
                )}
              </div>
            ) : (
              <div className='ms-2 mt-2'>
                {lineItemLabel}
              </div>
            )
          ) : !isLocked ? (
            // Legacy job scope selection
            <FormSelect
              label='JOBSCOPEMODE'
              control={control}
              options={options}
              key={`${fieldPrefix}.scopeId`}
              name={`${fieldPrefix}.scopeId`}
              isOptionDisabled={isOptionDisabled}
              disabled={scopeEst.allowChangeScope || isLocked}
              onChange={(e) => {
                const scope = allOptions.find((option) => option.value === e.value);
                if (scope) {
                  const updates = {
                    [`${fieldPrefix}.lineItemId`]: scope.lineItemId,
                    [`${fieldPrefix}.description`]: scope.label,
                    [`${fieldPrefix}.unitCost`]: scope.unitCost,
                    [`${fieldPrefix}.unitPrice`]: scope.unitPrice,
                    [`${fieldPrefix}.qbItemId`]: scope.qbItemId,
                    [`${fieldPrefix}.taxable`]: scope.taxable,
                    [`${fieldPrefix}.billable`]: scope.billable,
                    [`${fieldPrefix}.serviceDate`]: null
                  };
                  
                  // Apply all updates
                  Object.entries(updates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  let currentQty = currencyToNumber(scopeEst.qty);
                  if (currentQty === 0) {
                    currentQty = currencyToNumber(scope.defaultQuantity ?? '1');
                    setValue(`${fieldPrefix}.qty`, currentQty.toString(), setValueOptions);
                  }

                  const unitCost = currencyToNumber(scope.unitCost);
                  const unitPrice = currencyToNumber(scope.unitPrice);

                  const totalCost = currentQty * unitCost;
                  const total = currentQty * unitPrice;
                  const markup = unitPrice - unitCost;
                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                  // Calculate and set financial values
                  const financialUpdates = {
                    [`${fieldPrefix}.subTotalCost`]: formatMoneyLocal(totalCost),
                    [`${fieldPrefix}.subTotalCtr`]: formatMoneyLocal(total),
                    [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(markup),
                    [`${fieldPrefix}.unitPrice`]: formatMoneyLocal(unitPrice),
                    [`${fieldPrefix}.unitCost`]: formatMoneyLocal(unitCost),
                    [`${fieldPrefix}.subTotalProfitPers`]: markupPercent.toFixed(1)
                  };
                  
                  // Apply financial updates
                  Object.entries(financialUpdates).forEach(([key, value]) => {
                    setValue(key, value, setValueOptions);
                  });

                  recalculateTotals(itemIndex);
                }
              }}
            />
          ) : (
            <div className='ms-2'>
              {allOptions.find((option) => option.value === estData[itemIndex].lineItemId)?.label}
            </div>
          )}
        </div>

        {/* Description field */}
        {detailedMode && visibleColumns.description && (
          <FormInput
            type='text'
            inputClassName={`form-control text-start`}
            className={formClasses.formInputClassLong}
            control={control}
            key={`${fieldPrefix}.description`}
            name={`${fieldPrefix}.description`}
            placeholder='Description'
            disabled={isLocked}
            textArea={textAreaEnabled}
            autoSize={{ minRows: 1, maxRows: 8 }}
          />
        )}

        {/* Service Date field */}
        {detailedMode && visibleColumns.serviceDate && (!isGroupItem ? (
          <FormInput
            type='date'
            inputClassName={`form-control text-start`}
            className={formClasses.formInputClass}
            control={control}
            key={`${fieldPrefix}.serviceDate`}
            name={`${fieldPrefix}.serviceDate`}
            placeholder='Service Date'
            disabled={isLocked}
          />
        ) : (
          <div className={formClasses.formInputClass}></div>
        ))}

        {/* Quantity field */}
        {detailedMode && visibleColumns.qty && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end`}
            className={formClasses.formInputClassShort}
            control={control}
            key={`${fieldPrefix}.qty`}
            name={`${fieldPrefix}.qty`}
            placeholder='Qty'
            disabled={isLocked}
            onChange={(e) => {
              const qty = currencyToNumber(e);

              if (hasLineItems) {
                if (qty === 0) return;
                
                const subTotalCost = currencyToNumber(scopeEst.subTotalCost);
                const unitCost = currencyToNumber(scopeEst.unitCost);
                const subTotalCtr = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = currencyToNumber(scopeEst.unitPrice);

                const formerQty = unitCost > 0 ? subTotalCost / unitCost : subTotalCtr / unitPrice;
                const changeInQty = qty / formerQty;

                // Batch update all sub-items
                const newItems = scopeEst.items.map((item: any) => {
                  const newQty = currencyToNumber(item.qty) * changeInQty;
                  const newCost = currencyToNumber(item.cost) * changeInQty;
                  const newTotal = currencyToNumber(item.total) * changeInQty;

                  return {
                    ...item,
                    qty: newQty.toString(),
                    cost: formatMoneyLocal(newCost),
                    total: formatMoneyLocal(newTotal),
                  };
                });

                // Update all sub-items in batch
                newItems.forEach((item: any, index: number) => {
                  setValue(`${fieldPrefix}.items.${index}.qty`, item.qty, setValueOptions);
                  setValue(`${fieldPrefix}.items.${index}.cost`, item.cost, setValueOptions);
                  setValue(`${fieldPrefix}.items.${index}.total`, item.total, setValueOptions);
                });
              } else {
                const unitCost = currencyToNumber(scopeEst.unitCost);
                const unitPrice = currencyToNumber(scopeEst.unitPrice);

                const totalCost = qty * unitCost;
                const total = qty * unitPrice;
                const profit = total - totalCost;

                const updates = {
                  [`${fieldPrefix}.subTotalCost`]: formatMoneyLocal(totalCost),
                  [`${fieldPrefix}.subTotalCtr`]: formatMoneyLocal(total),
                  [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(profit)
                };
                
                // Apply all updates
                Object.entries(updates).forEach(([key, value]) => {
                  setValue(key, value, setValueOptions);
                });
              }

              recalculateTotals(itemIndex);
            }}
          />
        )}

        {/* Unit Cost field */}
        {detailedMode && visibleColumns.unitCost && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end`}
            className={formClasses.formInputClass}
            control={control}
            key={`${fieldPrefix}.unitCost`}
            name={`${fieldPrefix}.unitCost`}
            placeholder='Unit Cost'
            disabled={isLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const unitCost = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const updates = {};

              if (isTotalLocked) {
                const total = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = total / qty;
                const markup = unitPrice - unitCost;
                const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                const totalCost = qty * unitCost;
                const totalProfit = qty * markup;

                updates[`${fieldPrefix}.subTotalCost`] = formatMoneyLocal(totalCost);
                updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                updates[`${fieldPrefix}.subTotalProfitPers`] = markupPercent.toFixed(1);
                updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(markup);
              } else {
                const markup = showProfitAsPers
                  ? (currencyToNumber(scopeEst.subTotalProfitPers) / 100) * unitCost
                  : currencyToNumber(scopeEst.subTotalProfit) / qty;
                const unitPrice = unitCost + markup;
                const total = qty * unitPrice;
                const totalCost = qty * unitCost;
                const totalProfit = qty * markup;

                updates[`${fieldPrefix}.subTotalCost`] = formatMoneyLocal(totalCost);
                updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                updates[`${fieldPrefix}.subTotalCtr`] = formatMoneyLocal(total);
                updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(markup);
              }

              // Apply all updates
              Object.entries(updates).forEach(([key, value]) => {
                setValue(key, value, setValueOptions);
              });

              recalculateTotals(itemIndex);
            }}
          />
        )}

        {/* Total Cost field */}
        {(!detailedMode || visibleColumns.cost) && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end`}
            className={formClasses.formInputClass}
            control={control}
            key={`${fieldPrefix}.subTotalCost`}
            name={`${fieldPrefix}.subTotalCost`}
            placeholder='Cost'
            disabled={isLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const cost = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = cost / qty;
              const updates = {};

              if (isTotalLocked) {
                const total = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = total / qty;
                const markup = unitPrice - unitCost;
                const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                const totalProfit = qty * markup;

                updates[`${fieldPrefix}.unitCost`] = formatMoneyLocal(unitCost);
                updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(markup);
                updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                updates[`${fieldPrefix}.subTotalProfitPers`] = markupPercent.toFixed(1);
              } else {
                const markup = showProfitAsPers
                  ? (currencyToNumber(scopeEst.subTotalProfitPers) / 100) * unitCost
                  : currencyToNumber(scopeEst.subTotalProfit) / qty;
                const unitPrice = unitCost + markup;
                const total = qty * unitPrice;
                const totalProfit = qty * markup;

                updates[`${fieldPrefix}.unitCost`] = formatMoneyLocal(unitCost);
                updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                updates[`${fieldPrefix}.subTotalCtr`] = formatMoneyLocal(total);
                
                if (showProfitAsPers) {
                  updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                  updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(markup);
                } else {
                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                  updates[`${fieldPrefix}.subTotalProfitPers`] = markupPercent.toFixed(1);
                }
              }

              // Apply all updates
              Object.entries(updates).forEach(([key, value]) => {
                setValue(key, value, setValueOptions);
              });

              recalculateTotals(itemIndex);
            }}
          />
        )}

        {/* Markup field (percentage or dollar amount) */}
        {(!detailedMode || visibleColumns.markup) && (
          showProfitAsPers ? (
            <FormInput
              type='number'
              inputClassName={`form-control text-end`}
              className={`${formClasses.formInputClass} ${styles.percentage}`}
              control={control}
              key={`${fieldPrefix}.subTotalProfitPers`}
              name={`${fieldPrefix}.subTotalProfitPers`}
              placeholder=''
              disabled={isLocked || hasLineItems || isGroupItem}
              onChange={(e) => {
                const profitPers = currencyToNumber(e);
                const qty = currencyToNumber(scopeEst.qty);
                const updates = {};

                if (isTotalLocked) {
                  const unitPrice = currencyToNumber(scopeEst.unitPrice);
                  const unitCost = unitPrice / (1 + profitPers / 100);
                  const profit = unitPrice - unitCost;
                  const totalCost = qty * unitCost;
                  const totalProfit = qty * profit;

                  updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                  updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(profit);
                  updates[`${fieldPrefix}.unitCost`] = formatMoneyLocal(unitCost);
                  updates[`${fieldPrefix}.subTotalCost`] = formatMoneyLocal(totalCost);
                } else {
                  const unitCost = currencyToNumber(scopeEst.unitCost);
                  const markup = (profitPers / 100) * unitCost;
                  const unitPrice = unitCost + markup;
                  const total = qty * unitPrice;
                  const totalProfit = qty * markup;

                  updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                  updates[`${fieldPrefix}.unitProfit`] = formatMoneyLocal(markup);
                  updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                  updates[`${fieldPrefix}.subTotalCtr`] = formatMoneyLocal(total);
                }

                // Apply all updates
                Object.entries(updates).forEach(([key, value]) => {
                  setValue(key, value, setValueOptions);
                });

                recalculateTotals(itemIndex);
              }}
            />
          ) : (
            <FormInput
              type='number'
              inputClassName={`form-control text-end`}
              className={formClasses.formInputClass}
              control={control}
              key={`${fieldPrefix}.unitProfit`}
              name={`${fieldPrefix}.unitProfit`}
              placeholder='Markup ($)'
              disabled={isLocked || hasLineItems || isGroupItem}
              CustomInput={CurrencyInput}
              onChange={(e) => {
                const unitProfit = currencyToNumber(e);
                const qty = currencyToNumber(scopeEst.qty);
                const updates = {};

                if (isTotalLocked) {
                  const unitPrice = currencyToNumber(scopeEst.unitPrice);
                  const unitCost = unitPrice - unitProfit;
                  const profitPers = unitCost === 0 ? 0 : (unitProfit / unitCost) * 100;
                  const totalCost = qty * unitCost;
                  const totalProfit = qty * unitProfit;

                  updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                  updates[`${fieldPrefix}.subTotalProfitPers`] = profitPers.toFixed(1);
                  updates[`${fieldPrefix}.unitCost`] = formatMoneyLocal(unitCost);
                  updates[`${fieldPrefix}.subTotalCost`] = formatMoneyLocal(totalCost);
                } else {
                  const unitCost = currencyToNumber(scopeEst.unitCost);
                  const unitPrice = unitCost + unitProfit;
                  const total = qty * unitPrice;
                  const profitPers = unitCost === 0 ? 0 : (unitProfit / unitCost) * 100;
                  const totalProfit = qty * unitProfit;

                  updates[`${fieldPrefix}.subTotalProfitPers`] = profitPers.toFixed(1);
                  updates[`${fieldPrefix}.subTotalProfit`] = formatMoneyLocal(totalProfit);
                  updates[`${fieldPrefix}.unitPrice`] = formatMoneyLocal(unitPrice);
                  updates[`${fieldPrefix}.subTotalCtr`] = formatMoneyLocal(total);
                }

                // Apply all updates
                Object.entries(updates).forEach(([key, value]) => {
                  setValue(key, value, setValueOptions);
                });

                recalculateTotals(itemIndex);
              }}
            />
          )
        )}

        {/* Unit Price field */}
        {detailedMode && visibleColumns.unitPrice && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end`}
            className={formClasses.formInputClass}
            control={control}
            key={`${fieldPrefix}.unitPrice`}
            name={`${fieldPrefix}.unitPrice`}
            placeholder='Unit Price'
            disabled={isLocked || isTotalLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const unitPrice = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = currencyToNumber(scopeEst.unitCost);
              const markup = unitPrice - unitCost;
              const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
              const total = qty * unitPrice;
              const totalProfit = qty * markup;

              const updates = {
                [`${fieldPrefix}.unitProfit`]: formatMoneyLocal(markup),
                [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(totalProfit),
                [`${fieldPrefix}.subTotalProfitPers`]: markupPercent.toFixed(1),
                [`${fieldPrefix}.subTotalCtr`]: formatMoneyLocal(total)
              };

              // Apply all updates
              Object.entries(updates).forEach(([key, value]) => {
                setValue(key, value, setValueOptions);
              });

              recalculateTotals(itemIndex);
            }}
          />
        )}

        {/* Total Price field */}
        {(!detailedMode || visibleColumns.total) && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end`}
            className={formClasses.formInputClass}
            control={control}
            key={`${fieldPrefix}.subTotalCtr`}
            name={`${fieldPrefix}.subTotalCtr`}
            placeholder='Total'
            disabled={isLocked || isTotalLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const total = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = currencyToNumber(scopeEst.unitCost);
              const unitPrice = total / qty;
              const markup = unitPrice - unitCost;
              const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
              const totalProfit = qty * markup;

              const updates = {
                [`${fieldPrefix}.unitPrice`]: formatMoneyLocal(unitPrice),
                [`${fieldPrefix}.unitProfit`]: formatMoneyLocal(markup),
                [`${fieldPrefix}.subTotalProfit`]: formatMoneyLocal(totalProfit),
                [`${fieldPrefix}.subTotalProfitPers`]: markupPercent.toFixed(1)
              };

              // Apply all updates
              Object.entries(updates).forEach(([key, value]) => {
                setValue(key, value, setValueOptions);
              });

              recalculateTotals(itemIndex);
            }}
          />
        )}

        {/* Taxable checkbox */}
        {detailedMode && visibleColumns.taxable && (!isGroupItem ? (
          <FormInput
            type='checkbox'
            inputClassName={`form-check-input ` + styles.checkbox}
            className={formClasses.formInputClassExtraShort}
            control={control}
            key={`${fieldPrefix}.taxable`}
            name={`${fieldPrefix}.taxable`}
            disabled={isLocked}
          />
        ) : (
          <div className={formClasses.formInputClassExtraShort}></div>
        ))}

        {/* Billable checkbox */}
        {detailedMode && visibleColumns.billable && (!isGroupItem ? (
          <FormInput
            type='checkbox'
            inputClassName={`form-check-input ` + styles.checkbox}
            className={formClasses.formInputClassExtraShort}
            control={control}
            key={`${fieldPrefix}.billable`}
            name={`${fieldPrefix}.billable`}
            disabled={isLocked}
          />
        ) : (
          <div className={formClasses.formInputClassExtraShort}></div>
        ))}

        {/* Control buttons */}
        {!isLocked && (
          <div className={`px-1 ${styles.controlBtn} ms-auto me-2 ms-2`}>
            {!isLocked && detailedMode && isGroupItem ? (
              <>
                <div className='ms-4'>&nbsp;</div>
                <div>
                  <a className={``} onClick={() => onAddLineItem()}>
                    <i
                      className={`fa-solid fa-plus-circle ${styles.addButton}`}
                      aria-hidden='true'
                      title='Add line item'
                    ></i>
                  </a>
                </div>
              </>
            ) : detailedMode ? (
              <>
                <div className='ms-4'>&nbsp;</div>
                <div>
                  <a onClick={() => onAddFirstLevelItem(itemIndex)}>
                    <i
                      className={`fa-solid fa-plus-circle ${styles.addButton}`}
                      aria-hidden='true'
                      title='Add line item'
                    ></i>
                  </a>
                </div>
              </>
            ) : (
              <div className={`ms-0`}></div>
            )}
            <a onClick={() => onRemoveFirstLevelItem(itemIndex)}>
              <i
                className={`fa-solid fa-circle-xmark ${styles.deleteButton}`}
                aria-hidden='true'
                title='Remove'
              ></i>
            </a>
          </div>
        )}
      </div>

      {/* Nested line items with drag and drop */}
      {detailedMode && (
        <DragDropContext onDragEnd={onDragEndNested}>
          <Droppable droppableId={`scope-${id}`}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {scopeEst.items?.map(renderLineItem)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      
      {detailedMode && scopeEst.items?.length > 0 && <br />}
    </>
  );
};