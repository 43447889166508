import React, { useEffect, useMemo, useState, Suspense } from 'react';

import { Link } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllProjectAction } from '../../../actions/project';

import ReactLoading from 'react-loading';
import { CustomTable, customSortByKey } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { formatMoney } from '../../../utils/number-formatters';
import usePagination from '../../../shared/data-grid/use-pagination';
import RemotePagination from '../../../shared/data-grid/remote-pagination/remote-pagination.jsx';
import useSorting, { headerWithSort } from '../../../shared/data-grid/use-sorting/use-sorting.jsx';
import store from '../../../modules/store';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { isAllowed, moduleConstants } from '../../../_constants';


import { formatDateWithTime, formatDateObject, formatDate } from '../../../utils/date-formatters';

const ProjectTable = ({ list, total, isLoading, prjProps }) => {
  const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
  //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

  const data = useSelector((state) => state.list);
  const loading = useSelector((state) => state.isLoading);
  const { fetchRequest } = useSelector((state) => state);

  const pagination = usePagination({ total });

  const { limit, offset, setPage } = pagination;

  const sortingState = useSorting();

  const { sortingField, sortingOrder } = sortingState;

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    prjProps.onSortChange(sortingField, sortingOrder);
    store.dispatch(
      getAllProjectAction({
        displayLimit: limit,
        page: offset / limit,
        sortKey: prjProps.state.sortForm.sortKey,
        sortType: prjProps.state.sortForm.sortType,
        searchValue,
        type: 'project',
        jobStatusCodes:
          prjProps.state.searchSelectBox?.jobStatusCodes?.value !== 'All'
            ? prjProps.state.searchSelectBox.jobStatusCodes.value
            : '',
        accountExec:
          prjProps.state.searchSelectBox?.accountExec?.value !== 'All'
            ? prjProps.state.searchSelectBox.accountExec.value
            : '',
        clientId:
          prjProps.state.searchSelectBox?.client?.value !== 'All'
            ? prjProps.state.searchSelectBox.client.value
            : '',
      })
    );
  }, [limit, offset, sortingField, sortingOrder, searchValue]);

  useEffect(() => {
    setPage(1);
  }, [searchValue, limit]);

  const columns = useMemo(
    () => [
      {
        Header: headerWithSort('Job #', 'jobNumFormatted'),
        accessor: 'jobNum',
        width: 50,
        disableSortBy: true,
        Cell: (props) => prjProps.onGetJobNum(props.column.id, props.row.original, props.value),
      },
      {
        Header: headerWithSort('Project Name', 'jobName'),
        accessor: 'jobName',
        disableSortBy: true,
        width: 165,
        Cell: (props) => prjProps.onCellButton(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Status',
        accessor: 'projectStatus',
        width: 50,
        disableSortBy: true,
        Cell: (props) => prjProps.onGetStatusCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: headerWithSort('Bid Date', 'bidDue'),
        accessor: 'bidDue',
        width: 80,
        disableSortBy: true,
        Cell: (props) =>
          prjProps.onGetBidDateCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: headerWithSort('Start Date', 'startDate'),
        accessor: 'startDate',
        width: 80,
        disableSortBy: true,
        Cell: (props) =>
          prjProps.onGetStartDateCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: appConstants.accountExecLabelNameShort,
        accessor: 'accountExecArr',
        sortType: customSortByKey,
        sortKey: appConstants.roleList.accountExecArrLabel,
        filter: SelectColumnFilter,
        width: 60,
        disableSortBy: true,
        Cell: (props) => (
          <>
            {prjProps.props.contactRedirect.showAccountExecStatus(
              props.column.id,
              props.row.original,
              props.value
            )}
          </>
        ),
      },
      hasContractValPermissions && {
        Header: headerWithSort('Contract Amount', 'currentContractAmount'),
        id: 'currentContractAmount',
        width: 100,
        disableSortBy: true,
        Cell: (props) => prjProps.onContractVal(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Client',
        id: appConstants.roleList.clientName,
        width: 100,
        Cell: (props) => (
          <>
            {prjProps.props.contactRedirect.showClient(
              props.column.id,
              props.row.original,
              props.value
            )}
          </>
        ),
      },
    ],
    [sortingState]
  );

  return (
    <>
      <CustomTable
        columns={columns.filter(Boolean)}
        list={list}
        inlineEdit={false}
        canShowExport={false}
        canShowPagination={false}
        canShowGlobalFilter={false}
        canShowColumnSettings={false}
        canShowDateFilter={false}
        canShowRowSelection={false}
        isLoading={fetchRequest}
        useControlledState={(state) => ({ ...state, ...sortingState })}
      />
      <RemotePagination {...pagination} />
    </>
  );
};

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      row: [],
      key: 1,
      openPopUpState: '',
      list: [],
      sizePerPage: 15,
      currentPage: 1,
      createSuccess: false,
      projectListStatus: false,
      filterData: false,
      searchSelectBox: {
        jobStatusCodes: { value: 'All', label: 'All' },
        accountExec: { value: 'All', label: 'All' },
        client: { value: 'All', label: 'All' },
      },
      //sizePerPage: appConstants.TableSizePerPageList[2].value,
      sortForm: { sortType: '', sortKey: '' },
      fetchRequest: true,
      trigger: false,
      totalCount: 0,
    };

    this.getProjectList = this.getProjectList.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    //this.onSizePerPageList = this.onSizePerPageList.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //this.setState({...this.state});
    if (nextProps.filterData && this.state.filterStatus !== nextProps.filterStatus) {
      this.setState({
        filterStatus: nextProps.filterStatus,
        searchSelectBox: nextProps.filterData,
      });
      this.getProjectList(0);
    }

    if (!this.props.createSuccess && nextProps.createSuccess) {
      this.getProjectList(this.state.key);
    }

    if (this.state.projectListStatus !== nextProps.projectListStatus) {
      //console.log("nextProps.searchSelectBox", nextProps.searchSelectBox);
      this.setState({
        projectListStatus: !this.state.projectListStatus,
        searchSelectBox: nextProps.searchSelectBox,
      });
      //this.getProjectList(0);
    }

    let list = [];
    if (nextProps.allProjects && nextProps.allProjects.length > 0) {
      list = nextProps.allProjects.filter((project) => {
        return project;
      });
      this.setState({
        totalCount: nextProps.allProjectsCount,
        fetchRequest: nextProps.fetchRequest,
      });
    } else {
      this.setState({ totalCount: 0 });
      this.setState({ fetchRequest: true });
    }
    this.setState({ list: list });
  }

  getProjectList(key) {
    this.props.onClickFun();
    this.setState({ currentPage: 1 });

    let allProject = {};
    const { searchSelectBox } = this.state;
    //console.log("searchSelectBox", searchSelectBox);
    if (searchSelectBox.jobStatusCodes.value !== 'All') {
      //console.log("searchSelectBox.jobStatusCodes.value", searchSelectBox.jobStatusCodes.value);
      allProject['jobStatusCodes'] = searchSelectBox.jobStatusCodes.value;
    }
    if (searchSelectBox.accountExec.value !== 'All') {
      allProject['accountExec'] = searchSelectBox.accountExec.value;
    }
    if (searchSelectBox.client.value !== 'All') {
      allProject['clientId'] = searchSelectBox.client.value;
    }

    allProject['type'] = 'project';
    allProject['accountId'] = this.state.accountId;
    allProject['userId'] = this.state.userId;
    allProject['page'] = 0;
    allProject['displayLimit'] = this.state.sizePerPage;

    this.props.getAllProjectAction(allProject);
  }

  handleChangeSearchState(name, value) {
    let searchSelectBox = this.state.searchSelectBox;
    searchSelectBox[name] = value;
    this.setState({
      searchSelectBox: searchSelectBox,
    });
  }

  handleDeleteButtonClick = (rowKeys) => {
    let data = {};
    let tifOptions = [];
    tifOptions = Object.keys(rowKeys).map(function (key) {
      return { objectId: rowKeys[key] };
    });
    data['className'] = 'jobStatusCodes';
    data['dataArr'] = tifOptions;
    this.props.deleteJobStatusAction(data);
  };

  deleteButton = (onBtnClick) => {
    //Delete button
    return "<div><btn className='btn btn-danger' onClick={e => this.handleDeleteButtonClick(onBtnClick)}>Delete Account</btn></div>";
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  showAmountProject(cell, row, enumObject, rowIndex) {
    // if (row.originalContractAmount && row.originalContractAmount !== '') {
    //     let originalContractAmount = Math.round(row.originalContractAmount);
    //     return (<p>{row.originalContractAmount && formatMoney(originalContractAmount)}</p>)
    // }
    if (row.currentContractAmount && row.currentContractAmount !== '') {
      let originalContractAmount = Math.round(row.currentContractAmount);
      return (
        <p>{row.currentContractAmount && formatMoney(originalContractAmount)}</p>
      );
    }
  }

  onSortChange = (sortName, sortOrder) => {
    let sortForm = this.state.sortForm;

    if (sortOrder === 'asc' || sortOrder === 'ASC') {
      sortOrder = 'ASC';
    } else {
      sortOrder = 'DESC';
    }

    sortForm['sortType'] = sortOrder;
    sortForm['sortKey'] = sortName;

    this.setState({ sortForm });
  };

  internalData(cell, row, enumObject, rowIndex) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  onExternalData(name, row, cell, rowIndex) {
    if (row['accountExecArr']?.length > 0) {
      return formatExternalContactData(row['accountExecArr'], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  onGetJobNum(name, row, enumObject, rowIndex) {
    if (row) {
      const { jobNumPrefix = '', jobNum = '', jobNumFormatted='' } = row;
      const cellValue = jobNumFormatted.replace('undefined','');
      return (
        <>
          <div>{cellValue}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onGetStartDateCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.startDate && row.startDate.iso ? formatDate(row.startDate.iso) : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onContractVal(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.currentContractAmount ? '$' + formatMoney(row.currentContractAmount) : '-'}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onCellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
          {row && row.jobName ? row.jobName : '-'}
        </Link>
      </div>
    );
  }

  onGetStatusCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.projectStatus ? row.projectStatus : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onGetBidDateCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.bidDue && row.bidDue.iso ? formatDate(row.bidDue.iso) : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  getProjectSortList = () => {
    this.props.onClickFun();
    let sortForm = this.state.sortForm;
    let allProject = {};
    allProject['page'] = this.state.currentPage - 1;
    allProject['displayLimit'] = this.state.sizePerPage;

    let searchSelectBox = this.state.searchSelectBox;
    if (searchSelectBox.jobStatusCodes.value !== 'All') {
      allProject['jobStatusCodes'] = searchSelectBox.jobStatusCodes.value;
    }
    if (searchSelectBox.accountExec.value !== 'All') {
      allProject['accountExec'] = searchSelectBox.accountExec.value;
    }
    if (searchSelectBox.client.value !== 'All') {
      allProject['clientId'] = searchSelectBox.client.value;
    }
    allProject['type'] = 'project';
    allProject['accountId'] = this.state.accountId;
    allProject['userId'] = this.state.userId;
    allProject['sortType'] = sortForm.sortType;
    allProject['sortKey'] = sortForm.sortKey;
  };

  render() {
    return (
      <>
        <ProjectTable
          list={this.state.list}
          total={this.state.totalCount}
          isLoading={this.state.fetchRequest}
          prjProps={this}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjects: state.project.allProjects,
    isStatus: state.project.isStatus,
    allProjectsCount: state.project.allProjectsCount,
    fetchRequest: state.project.fetchRequest,
    createSuccess: state.project.createSuccess,
    message: state.project.message,
    filterData: state.project.filterData,
    filterStatus: state.project.filterStatus,
    fetchSuccess: state.project.fetchSuccess,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectAction,
  })(ProjectList)
);
