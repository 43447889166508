import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import { ResponsiveFunnel } from '@nivo/funnel';
import { useTheme } from '@nivo/core';
import { appConstants } from '../../../_constants';
import { formatMoney, formatMoneyAuto, formatNumber } from '../../../utils/number-formatters';
import { GetProjectFunnelReportAction } from '../../../actions/reports';
import { withRouter } from 'react-router-dom';
import './ProjectFunnelChartWidget.scss';

// Simplified Label component for funnel parts - no animations
const PartLabel = ({ part }) => {
    const theme = useTheme();
    
    return (
      <g transform={`translate(${part.x}, ${part.y})`}>
        {/* Main label in the center */}
        <text
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            ...theme.labels.text,
            fill: part.labelColor,
            pointerEvents: 'none',
            textShadow: '0px 0px 3px rgba(0,0,0,0.5)',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {part.data.label}
        </text>
      </g>
    );
  };
  
  // Custom component for external dollar amount labels
  const ExternalLabels = ({ parts, valueType }) => {
    const widths = parts.map(part => part.width);
    const heights = parts.map(part => part.height);

    const isVertical = Math.max(...widths) < Math.max(...heights);

    return parts.map(part => {
      //const isVertical = part.height > part.width;
      const labelOffsetX = 100;
      const labelOffsetY = 50;
      
      // Get YOY percentage difference and format it
      const yoyDiffPercent = part.data.yoyDiffPercent || 0;
      const yoyColor = yoyDiffPercent >= 0 ? 'green' : 'red';
      const yoyPrefix = yoyDiffPercent >= 0 ? '+' : '';
      const yoyText = ` (${yoyPrefix}${yoyDiffPercent.toFixed(1)}% YOY)`;
      
      return (
        <g key={part.data.id + '-external'} transform={`translate(${part.x}, ${part.y})`}>
          <text
            textAnchor="start"
            dominantBaseline="central"
            x={isVertical ? -part.width/3 : (part.width/2 + labelOffsetX)}
            y={isVertical ? (part.height/2 + labelOffsetY) : 0}
            style={{
              fontSize: '13px',
              fontWeight: 'bold',
              fill: '#001000',
            }}
          >
            {part.data.formattedValue}
            <tspan
              style={{
                fontSize: '12px',
                fontWeight: 'normal',
                fill: yoyColor,
              }}
            >
              {yoyText}
            </tspan>
          </text>
        </g>
      );
    });
  };
  
  // Labels component to map all part labels
  function Labels(props) {
    return (
      <>
        {props.parts.map((part) => <PartLabel key={part.data.id} part={part} />)}
        <ExternalLabels parts={props.parts} valueType={props.valueType} />
      </>
    );
  }

const ProjectFunnelChartWidget = ({ funnelData, loading, GetProjectFunnelReportAction }) => {
  // State variables
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [showFixedFunnel, setShowFixedFunnel] = useState(true);
  const [isRotated, setIsRotated] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [valueType, setValueType] = useState('contract'); // 'contract', 'profit', or 'count'

  const [visualizationData, setVisualizationData] = useState([]);

  // Request parameters
  const requestData = {
    analyzeBy: 'PM',
    timePeriodType: 'next12months',
    projectStatus: 'realizedProject',
    period: 'last12months',
    valueType: 'contract',
  };

  // Fetch data on component mount and when valueType changes
  useEffect(() => {
    GetProjectFunnelReportAction({
      userId,
      accountId,
      ...requestData,
    });
  }, [userId, accountId]);

  useEffect(() => {
    if (funnelData && funnelData.length > 0) {
        const preparedData = prepareData(funnelData, valueType);
        setVisualizationData(preparedData);
    }
    }, [funnelData, valueType,isRotated, showFixedFunnel]);
    
  // Prepare data for visualization
  const prepareData = (data, valueType, isRotated) => {
    if (!data || data.length === 0) return [];

    // Sort by stage order to ensure proper funnel flow
    const sortedData = [...data].sort((a, b) => a.order - b.order);

    if (showFixedFunnel) {
      // Create a fixed-size funnel where each stage is slightly smaller than the previous
      return sortedData.map((item, index) => {
        const baseValue = 1000;
        const reductionFactor = 0.9; // Reduced wave effect by changing from 0.85 to 0.9
        const fixedValue = baseValue * Math.pow(reductionFactor, index);
        
        let formattedValue, rawValue, yoyDiffPercent;
        
        if (valueType === 'contract') {
          formattedValue = formatMoneyAuto(item.totalContract / 1000, { decimalCount: 0, prefix: '$' }) + 'k';
          rawValue = item.totalContract;
          yoyDiffPercent = item.totalContract_yoy_diff_percent;
        } else if (valueType === 'profit') {
          formattedValue = formatMoneyAuto(item.totalProfit / 1000, { decimalCount: 0, prefix: '$' }) + 'k';
          rawValue = item.totalProfit;
          yoyDiffPercent = item.totalProfit_yoy_diff_percent;
        } else { // count
          formattedValue = item.totalProjects;
          rawValue = item.totalProjects;
          yoyDiffPercent = item.totalProjects_yoy_diff_percent;
        }

        return {
          id: item.label,
          value: fixedValue,
          label: `${item.label}`,
          rawValue: rawValue,
          formattedValue: formattedValue,
          count: item.totalProjects,
          profit: item.totalProfit,
          yoyDiffPercent: yoyDiffPercent || 0
        };
      });
    } else {
      // Use real values
      return sortedData.map((item) => {
        let formattedValue, value, rawValue, yoyDiffPercent;
        
        if (valueType === 'contract') {
          formattedValue = formatMoneyAuto(item.totalContract / 1000, { decimalCount: 0, prefix: '$' }) + 'k';
          value = Number.parseFloat(item.totalContract) || 0;
          rawValue = item.totalContract;
          yoyDiffPercent = item.totalContract_yoy_diff_percent;
        } else if (valueType === 'profit') {
          formattedValue = formatMoneyAuto(item.totalProfit / 1000, { decimalCount: 0, prefix: '$' }) + 'k';
          value = Number.parseFloat(item.totalProfit) || 0;
          rawValue = item.totalProfit;
          yoyDiffPercent = item.totalProfit_yoy_diff_percent;
        } else { // count
          formattedValue = item.totalProjects;
          value = Number.parseFloat(item.totalProjects) || 0;
          rawValue = item.totalProjects;
          yoyDiffPercent = item.totalProjects_yoy_diff_percent;
        }
        
        return {
          id: item.label,
          value: value,
          label: item.label,
          rawValue: rawValue,
          formattedValue: formattedValue,
          count: item.totalProjects,
          profit: item.totalProfit,
          yoyDiffPercent: yoyDiffPercent || 0
        };
      });
    }
  };

  // Toggle between fixed and actual funnel sizes
  const toggleFunnelMode = () => {
    setShowFixedFunnel(!showFixedFunnel);
  };

  // Toggle rotation
  const toggleRotation = () => {
    setIsRotated(!isRotated);
  };

  // Toggle controls visibility
  const toggleControls = () => {
    setShowControls(!showControls);
  };

  // Toggle value type - now cycles through contract, profit, and count
  const toggleValueType = () => {
    if (valueType === 'contract') {
      setValueType('profit');
    } else if (valueType === 'profit') {
      setValueType('count');
    } else {
      setValueType('contract');
    }
  };

  // Get chart header text based on valueType
  const getChartHeaderText = () => {
    if (valueType === 'contract') {
      return 'The total of all contract value by status';
    } else if (valueType === 'profit') {
      return 'The total of all contract profit by status';
    } else {
      return 'The total of all projects by status';
    }
  };

  // Get icon for value type toggle button
  const getValueTypeIcon = () => {
    if (valueType === 'contract') {
      return 'dollar';
    } else if (valueType === 'profit') {
      return 'money';
    } else {
      return 'hashtag';
    }
  };

  // Get a better color scheme for readability
  const getCustomColorScheme = () => {
    //return ['#4361ee', '#3a86ff', '#4cc9f0', '#4895ef', '#5e60ce', '#7400b8'].reverse();
    return ['#5CCD91'];
  };

  return (
    <div className='funnel-chart-widget'>
      <div
        className='funnel-chart-container'
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        {/* Chart header */}
        <div className='chart-header' style={{ textAlign: 'center', margin: '5px 0', fontWeight: 'bold' }}>
          {getChartHeaderText()}
        </div>
        
        <div className={`plot-controls ${showControls ? 'show' : ''}`}>
          <button
            className='control-button view-toggle'
            onClick={toggleControls}
            title='Toggle Controls'
          >
            <i className={`fa fa-eye${showControls ? '-slash' : ''}`}></i>
          </button>

          {showControls && (
            <>
              <button
                className='control-button rotation-toggle'
                onClick={toggleRotation}
                title={isRotated ? 'Horizontal View' : 'Vertical View'}
              >
                <i className={`fa fa-${isRotated ? 'arrows-h' : 'arrows-v'}`}></i>
              </button>

              <button
                className='control-button funnel-mode-toggle'
                onClick={toggleFunnelMode}
                title={showFixedFunnel ? 'Show Actual Size' : 'Show Fixed Funnel'}
              >
                <i className={`fa fa-${showFixedFunnel ? 'balance-scale' : 'chart-bar'}`}></i>
              </button>

              <button
                className='control-button value-type-toggle'
                onClick={toggleValueType}
                title={
                  valueType === 'contract' 
                    ? 'Show Profit Value' 
                    : valueType === 'profit' 
                      ? 'Show Project Count' 
                      : 'Show Contract Value'
                }
              >
                <i className={`fa fa-${getValueTypeIcon()}`}></i>
              </button>
            </>
          )}
        </div>

        {/* Fixed height chart container */}
        <div className='chart-wrapper'>
          {visualizationData.length > 0 && (
            <div className='chart-container'>
              <ResponsiveFunnel
                data={visualizationData}
                layers={['separators', 'parts', 
                  (props) => <Labels {...props} valueType={valueType} />, 
                'annotations']}
                margin={{ top: 5, right: 100 + (isRotated? 200:0), bottom: (isRotated? 50:100), left: 20 }}
                valueFormat='>-.4s'
                colors={getCustomColorScheme()}
                borderWidth={2}
                labelColor={'white'}
                spacing={15}
                shapeBlending= { showFixedFunnel ? 0 : 0.5}
                currentPartSizeExtension={5} // Reduced from 10
                currentBorderWidth={2} // Reduced from 3
                direction={isRotated ? 'vertical' : 'horizontal'}
                // Disabled animations by using a "stiff" motion config with immediate=true
                animate={false}
                interpolation={showFixedFunnel ? 'linear' : 'smooth'}
                motionConfig='stiff'
                tooltip={({ part }) => (
                  <div className='funnel-tooltip'>
                    <strong>{part.data.label}</strong>
                    <div>
                      {valueType === 'contract'
                        ? 'Contract Value: ' + formatMoney(part.data.rawValue)
                        : valueType === 'profit'
                          ? 'Profit Value: ' + formatMoney(part.data.profit)
                          : 'Projects: ' + part.data.count}
                    </div>
                    <div>
                      {valueType === 'contract'
                        ? 'Projects: ' + part.data.count
                        : valueType === 'profit'
                          ? 'Projects: ' + part.data.count
                          : 'Avg Value: ' + formatMoney(part.data.rawValue / part.data.count)}
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {loading && (
            <ReactLoading
              className='table-loader'
              type={appConstants.LOADER_TYPE}
              color={appConstants.LOADER_COLOR}
              height={appConstants.LOADER_HEIGHT}
              width={appConstants.LOADER_WIDTH}
            />
          )}

          {!loading && visualizationData.length === 0 && (
            <div className='msgBox1 msgBoxEF'>
              <span>No data available!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  funnelData: state.reports.projectFunnelList?.result?.data ?? [],
  loading: state.reports.loading || !(state.reports.projectFunnelList?.result),
  error: state.reports.error,
});

export default withRouter(
  connect(mapStateToProps, { GetProjectFunnelReportAction })(ProjectFunnelChartWidget)
);