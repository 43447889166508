import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import { connect } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { GetProfitProjectionReportAction } from '../../../actions/reports';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactLoading from 'react-loading';
import {
  getAcronymCode,
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { yearPickerValue } from '../../../utils/date-formatters';
import { formatMoneyParens } from '../../../utils/number-formatters';

var dataArray = [];
var tableData = [];
var tableHeader = [];
let monthIdxArray = [];

let monthHeaderArray = [];
var netContract = [];
var overHead = [];
let customDate = false;

class ProfitProjectionWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      reportList: [],
      monthlyExArr: [],
      fetchRequest: false,
      showProcess: true,
      projectList: {
        analyzeBy: { value: 'PM', label: 'Project Manager' },
        timePeriodType: { label: 'Next 12 Months', value: 'next12months' },
        projectStatus: 'realizedProject',
      },
      requestData: {
        analyzeBy: 'PM',
        timePeriodType: 'next6months',
        projectStatus: 'realizedProject',
      },
    };

    //  console.log('ppr', this.props.componentData)
  }

  componentDidMount() {
    //  console.log("this.props.componentData.pprListData.data",this.props.componentData.pprListData)
    
    let requestData = this.state.requestData;
    requestData['analyzeBy'] = this.state.projectList.analyzeBy.value;
    requestData['timePeriodType'] = this.state.projectList.timePeriodType.value;
    requestData['projectStatus'] = this.state.projectList.projectStatus;
    this.setState({ requestData: requestData });


    this.props.GetProfitProjectionReportAction(requestData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportData && nextProps.reportData.success === 1) {
      dataArray = [];
      this.setState({
        reportList: nextProps.reportData.data,
        contactData: nextProps.reportData.contactData,
        monthlyExArr: nextProps.reportData.monthlyOverheadFixedArr,
        showProcess: false,
        fetchRequest: false,
        totalCount: nextProps.count,
      });
    }

    if (!(nextProps?.reportData?.data)||nextProps.reportData.data?.length === 0) {
      this.setState({
        showProcess: false,
        fetchRequest: true,
      });
    }
  
  }

  dataFilter() {
    if (!dataArray||dataArray?.length === 0) {
      tableData = [];
      tableHeader = [];
      monthHeaderArray = [];
      monthIdxArray = []
      netContract = [];
      overHead = [];

      let processedProjects = [];

      dataArray = this.state.reportList;

      if (dataArray?.length > 0) {
        
        const contactData = this.state.contactData;

        let groupCount = 0;
        for (let x in dataArray) {
          let projectCount = 0;
          const analyzeByGroup = dataArray[x];
          const groupProjects = analyzeByGroup.projectData;

          for (let y in groupProjects) {
            let project = groupProjects[y];

            const useForGlobal = processedProjects.length==0||!processedProjects.includes(project.objectId);
            if(useForGlobal){
              processedProjects.push(project.objectId);
            }
            dataArray[x].projectData[y].useForGlobal = useForGlobal;
           
            for (let m in project.monthsArr) {

              
              const curMonthIdx = project.monthsArr[m].name;
if (groupCount === 0 && projectCount ==0) { // initialize values for all groups
                let monthStartDate = new Date(project.monthsArr[m].start_date.iso);
                let monthNum = monthStartDate.getUTCMonth();
                let yearNum = monthStartDate.getUTCFullYear();
                let newDate = new Date(yearNum,monthNum,1);
  
                const monthNameShort = newDate.toLocaleString([], { month: 'short' }) + '-' + (String(newDate.getFullYear())).substring(2, 4);
                monthHeaderArray.push(monthNameShort);

                monthIdxArray.push(curMonthIdx);

                netContract[curMonthIdx] = 0;
                overHead[curMonthIdx] = 0;

                overHead[curMonthIdx + 'once'] = 0;
              }

              if (projectCount == 0) {
                dataArray[x][curMonthIdx] = 0; // initialize profit to 0 for group month
                dataArray[x][curMonthIdx + 'once'] = 0;

                
                project[curMonthIdx] = 0;
              }
              const monthlyProjectProfit = Math.round(project.monthsArr[m].profit);
              const monthlyProjectContract = Math.round(project.monthsArr[m].contractAmount);
              const monthlyProjectVariableOverhead = Math.round(project.monthsArr[m].monthlyOverheadVariable);

              project[curMonthIdx] =monthlyProjectProfit;
              project[curMonthIdx+'once'] =useForGlobal?monthlyProjectProfit:0;
              

              overHead[curMonthIdx] =
                parseInt(overHead[curMonthIdx], 10) +
                (monthlyProjectContract *
                  monthlyProjectVariableOverhead) /
                100;

                // add cumulative overhead
              overHead[curMonthIdx+'once'] =
                parseInt(overHead[curMonthIdx+'once'], 10) +(useForGlobal?(
                (monthlyProjectContract *
                  monthlyProjectVariableOverhead) /
                100):0);
              
              // add cumulative profit
              dataArray[x][curMonthIdx] = Math.round(
                dataArray[x][curMonthIdx] +monthlyProjectProfit);

              dataArray[x][curMonthIdx+'once'] = Math.round(
                dataArray[x][curMonthIdx+'once'] + (useForGlobal?(
                  monthlyProjectProfit):0));

                  
            }
            projectCount++;
            groupCount++;
          }
        }

        tableHeader = [];

        for (let s in monthHeaderArray) {
          let thead = <th className="textRight">{monthHeaderArray[s]}</th>;
          tableHeader.push(thead);
        }

        tableData = [];
        let tdGroupBy = '';
        for (let e in dataArray) {
          tdGroupBy = '';
          if (dataArray[e].groupById === "Unassigned") {
             tdGroupBy = <td colSpan="2">Unassigned</td>;
          }
          else if (this.state.projectList.analyzeBy.value === 'jobStatusCodesName') {
             tdGroupBy = <td colSpan="2">Status Code: {dataArray[e].groupByName}</td>;
          }
          else if (dataArray[e].groupById != null && !dataArray[e].groupByName) {
            tdGroupBy = <td colSpan="2">Deleted User: {dataArray[e].groupById}</td>;
          } 
          else {
            tdGroupBy = (
              <td colSpan="5">
                {formatExternalContactData([dataArray[e].groupById],[dataArray[e].groupByItem],false,true)}
              </td>
            );
          }

          var Subtotals = (
            <td colSpan="1" className="textRight">
              Subtotals
            </td>
          );

          let groupProjects = dataArray[e].projectData;
          for (let s in groupProjects) {
            let row = {};
            const curProject = dataArray[e].projectData[s];
            row.objectId = curProject.objectId;

            const projectSupervisors = curProject.projectAssignments.filter(assign=>assign.projectRoleCode=='SI').map(contact=>contactData[contact.contactAffiliationId]);
            const projectManagers = curProject.projectAssignments.filter(assign=>assign.projectRoleCode=='PM').map(contact=>contactData[contact.contactAffiliationId]);

            var tdJobNum;
            var tdAltJobNum;
            var tdJobName;
            var tdProjMg = '';
            var tdProjSr = '';
            var tdJobStatus;
            tdJobNum = (
              <td>
                {curProject.jobNumFormatted ? curProject.jobNumFormatted : curProject.jobNum}
              </td>
            );
            tdAltJobNum = (
              <td>
                {curProject.altJobNum ? curProject.altJobNum : '-'}

              </td>
            );
            tdJobName = (
              <td className="jobname">
                <Link to={{ pathname: `/project-details/` + curProject.objectId }}>
                  {curProject.jobName}
                </Link>
              </td>
            );


            let projMg = projectManagers.map(ps=>formatInternalContactData([ps.objectId],contactData));
            let projSv = projectSupervisors.map(ps=>formatInternalContactData([ps.objectId],contactData));


            if(projMg.length>0){
              tdProjMg = <td>{projMg}</td>;
            } else {
              tdProjMg = <td>-</td>;
            }

            if(projSv.length>0){
              tdProjSr = <td>{projSv}</td>;
            } else {
              tdProjSr = <td>-</td>;
            }
          
             

            tdJobStatus = <td>{curProject.jobStatusCodesName}</td>;
            var jkhn = [];
            var jshn = [];
            for (let l in monthIdxArray) {
              if (Math.round(curProject[monthIdxArray[l]]) === 0) {
                curProject[monthIdxArray[l]] = "";
              } else {
                curProject[monthIdxArray[l]] = curProject[monthIdxArray[l]] = (formatMoneyParens(
                  Math.round(curProject[monthIdxArray[l]])
                ))+(!curProject.useForGlobal?'*':'');
              }
              

              var dataArrayE = formatMoneyParens(dataArray[e][monthIdxArray[l]]);
              var monthHeadArr;
              var dataArrHead;

              monthHeadArr = (
                <td className="textRight" key={monthIdxArray[l]}>
                  {curProject[monthIdxArray[l]]}
                </td>
              );
              dataArrHead = <td className="textRight">{dataArrayE}</td>;
              jkhn.push(monthHeadArr);
              jshn.push(dataArrHead);
            }
            var pm = (
              <tr>
                {tdJobNum}
                {tdAltJobNum}
                {tdJobName}
                {tdProjMg}
                {tdProjSr}
                {tdJobStatus}
                {jkhn}
              </tr>
            );
            tableData.push(pm);
          }
          var sm = (
            <tr className="border border2 subtotal">
              {tdGroupBy}
              {Subtotals}
              {jshn}
            </tr>
          );
          tableData.push(sm);
        }


        // calculate final group totals
        let finalSum = null;
        for (let k in monthIdxArray) {
          if(!finalSum){
            finalSum={};
          }

          finalSum[monthIdxArray[k]]=0;

          for (let g in dataArray) {
          
            
            finalSum[monthIdxArray[k]] =
              finalSum[monthIdxArray[k]] +dataArray[g][monthIdxArray[k]+'once'];
            
            
          }
        }

        if (finalSum) {
          let monthlyExArr = this.state.monthlyExArr;
          let totalSub = [];
          let netSub = [];
          let overSub = [];
          let cumulativeAmtSub = [];
          let cumulativeAmount = [];
          let indirectSub = [];

          for (let d in monthIdxArray) {
            let fsm;
            let n;
            let tdComsub;
            let oh;
            let insert;

            for (let t in monthlyExArr) {
              if (monthlyExArr[t].monthYear === monthIdxArray[d]) {
                netContract[monthIdxArray[d]] = Number(
                  (
                    finalSum[monthIdxArray[d]] -
                    (overHead[monthIdxArray[d]+'once'] + monthlyExArr[t].monthlyOverheadFixed)
                  ).toFixed(2)
                );
                if (
                  overHead[monthIdxArray[d]+'once'] + monthlyExArr[t].monthlyOverheadFixed >
                  Number(netContract[monthIdxArray[d]])
                ) {
                  netContract[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                }
                cumulativeAmount[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                if (cumulativeAmount[monthIdxArray[d - 1]]) {
                  if (netContract[monthIdxArray[d]] >= 0) {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) +
                      Number(netContract[monthIdxArray[d]])
                    ).toFixed(2);
                  } else {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) -
                      Number(Math.abs(netContract[monthIdxArray[d]]))
                    ).toFixed(2);
                  }
                }

                //console.log('monthlyExArr[t].monthlyOverheadFixed', monthlyExArr[t].monthlyOverheadFixed)

                insert = (
                  <td className="textRight">
                    {formatMoneyParens(monthlyExArr[t].monthlyOverheadFixed)}
                  </td>
                );
                indirectSub.push(insert);
              }
            }
            n = (
              <td className="textRight">
                {formatMoneyParens(netContract[monthIdxArray[d]])}
              </td>
            );

            d = parseInt(d, 10);

            tdComsub = (
              <td className="textRight">
                {formatMoneyParens(cumulativeAmount[monthIdxArray[d]])}
              </td>
            );
            netSub.push(n);
            cumulativeAmtSub.push(tdComsub);

            fsm = (
              <td className="textRight">
                {formatMoneyParens(finalSum[monthIdxArray[d]])}
              </td>
            );
            oh = (
              <td className="textRight">{formatMoneyParens(overHead[monthIdxArray[d]+'once'])}</td>
            );
            totalSub.push(fsm);
            overSub.push(oh);
          }

          var emc = <td colSpan="6">Total Estimated Marginal Contribution</td>;
          var iex = <td colSpan="6">Less Estimated Variable Overhead</td>;
          var oex = <td colSpan="6">Less Estimated Fixed Overhead</td>;
          var net = <td colSpan="6">Equals Estimated Net Monthly Profit</td>;
          var cumulative = <td colSpan="6">Equals Estimated Cumulative Monthly Profit</td>;
          var ovex = (
            <tr className="border border2">
              {iex}
              {overSub}
            </tr>
          );
          var inEex = (
            <tr className="border border2">
              {oex}
              {indirectSub}
            </tr>
          );
          var tem = (
            <tr className="border border2 total">
              {emc}
              {totalSub}
            </tr>
          );
          var netv = (
            <tr className="border border2 total">
              {net}
              {netSub}
            </tr>
          );
          var comeu = (
            <tr className="border border2 total">
              {cumulative}
              {cumulativeAmtSub}
            </tr>
          );
          tableData.push(tem, inEex, ovex, netv, comeu);
        }
      }
    }
  }

  handleChangeSearchState(name, value) {
    let requestData = this.state.requestData;
    let projectList = this.state.projectList;

    if (
      value.value === 'lastQuarter' ||
      value.value === 'nextQuarter' ||
      value.value === 'thisQuarter'
    ) {
     
      requestData['timePeriodType'] = value.value;
    } else {
      requestData['timePeriodType'] = value.value;
    }
    if (value.value === 'custom' || name === 'custom') {
      requestData['timePeriodType'] = 'custom';
      requestData['customYear'] = value.value;
      customDate = true;
    } else {
      customDate = false;
    }

    projectList[name] = value;
    this.setState({ requestData: requestData, projectList: projectList });
    if (value.value !== 'custom') {
      tableData = [];
      this.setState({
        showProcess: true,
      });
      this.props.GetProfitProjectionReportAction(this.state.requestData);
    }
  }

  render() {
    let yearPickerValues = [];
    let optionYears = yearPickerValue(yearPickerValues);
    this.dataFilter();

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-2">
              <label>Select</label>
              <Select
                name="status"
                components={makeAnimated()}
                value={this.state.projectList.timePeriodType}
                onChange={this.handleChangeSearchState.bind(this, 'timePeriodType')}
                options={appConstants.optionDateFilterPPr}
              />
            </div>
            {customDate && (
              <div className="col-md-2">
                <label>Select Year</label>
                <Select
                  name="status"
                  components={makeAnimated()}
                  value={this.state.projectList.custom}
                  onChange={this.handleChangeSearchState.bind(this, 'custom')}
                  options={optionYears}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="profit-projection reportTableSection bigTable bigTable1">
            <div className="table-responsive">
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={500}>
                <div>
                  <table width="100%" className="rsrTable ppr">
                    <tbody>
                      <tr>
                        <th>Project#</th>
                        <th>Alt Job#</th>
                        <th>Project Name</th>
                        <th>Proj.Mgr</th>
                        <th>Supt</th>
                        <th>Status</th>
                        {tableHeader}
                      </tr>
                      {tableData}
                    </tbody>
                  </table>
                  <div>
                    {this.state.showProcess && (
                      <ReactLoading
                        className="table-loader"
                        type={appConstants.LOADER_TYPE}
                        color={appConstants.LOADER_COLOR}
                        height={appConstants.LOADER_HEIGHT}
                        width={appConstants.LOADER_WIDTH}
                      />
                    )}
                  </div>
                </div>
                {this.state.fetchRequest && (
                  <div className="msgBox">
                    <span>No data available!</span>
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportData:
      state.reports?.profitProjectList?.result,
  };
}

export default withRouter(
  connect(mapStateToProps, { GetProfitProjectionReportAction })(ProfitProjectionWidget)
);
