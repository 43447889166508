import React from 'react';
import { SwatchesPicker } from 'react-color';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import reactCSS from 'reactcss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  createJobStatusAction,
  deleteJobStatusAction,
  getAllJobStatusAction,
  updateJobStatusAction
} from '../../../actions/Admin/jobStatus.actions.js';
import { appConstants } from '../../../_constants';
import AdminSidebar from '../../Admin/Inc/AdminSideBar.jsx';
import CustomTable from '../../Common/CustomTable';
import TitleComponent from '../../Common/TitleComponent';
import { callAlertMessage } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  jobStatusCodesCode: 'required',
  jobStatusCodesName: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
};
let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class JobStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // for use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      modal: false,
      saveType: 'POST',
      formData: {},
      fetchRequest: false,
      isCreated: false,
      successRedirect: false,
      selectedRows: [],
      isplayColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidMount() {
    this.list();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allJobStatus) {
      this.setState({ fetchRequest: true });
    }

    if (nextProps.allJobStatus && nextProps.allJobStatus.length > 0) {
      let listArr = [];
      nextProps.allJobStatus.map((v, i) => {
        if (typeof v !== 'undefined') {
          listArr.push(v);
        }
        return true;
      });

      this.setState({ list: listArr, fetchRequest: true });

      //this.setState({ list :nextProps.allJobStatus,fetchRequest: true})
    }

    if (nextProps.isCreated !== this.state.isCreated && nextProps.isCreated === true) {
      this.setState({
        isCreated: true,
      });
      let _this = this;
      if (nextProps.success === 1 && this.state.successRedirect && nextProps.message !== '') {
        callAlertMessage('success', nextProps.message);
        if (_this.state.modal) {
          _this.setState({ modal: false });
        }
        this.list();
      }
      if (nextProps.success === 0 && nextProps.message !== '') {
        callAlertMessage('error', nextProps.message);
        if (_this.state.modal) _this.setState({ modal: true });
      }
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeSelect = (name, state) => {
    
    if (name) {
      let formData = this.state.formData;
      formData[name] = state.value;
      
      this.setState({
        formData
      });
    }
    };

  handleChangeColor = (color) => {
    this.setState({ color: color.rgb });
    this.setState({ displayColorPicker: false });
  };

  list() {
    let data = {};
    data['className'] = 'jobStatusCodes';
    data['type'] = 'SUPERADMIN';
    data['accountId'] = this.state.accountId;
    this.props.getAllJobStatusAction(data);
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    if (this.state.formData.nonActiveStatus != undefined) {
      this.state.formData.nonActiveStatus = this.state.formData.nonActiveStatus.toString();
    }
    if (this.state.formData.onDueDateReport != undefined) {
      this.state.formData.onDueDateReport = this.state.formData.onDueDateReport.toString();
    }
    if (this.state.formData.trash != undefined) {
      this.state.formData.trash = this.state.formData.trash.toString();
    }
    if (this.state.formData.jobColorCode) {
      this.setState({ color: this.state.formData.jobColorCode });
    } else {
      let tempColor = [];
      tempColor.r = 0.5;
      tempColor.g = 0;
      tempColor.b = 0.5;
      tempColor.a = 1;
      this.setState({ color: tempColor });
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  cancelFun(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, formArr: [] });
    this.list();
  }

  selectedRow1 = (row) => {
    let _this = this;
    if (row) {
      this.setState({ formData: row });
      setTimeout(function () {
        _this.toggle('PUT');
      }, 100); // Select row to open edit modal
    }
  };

  addJobStatus(event) {
    event.preventDefault();
    let formData = this.state.formData;
    formData['className'] = 'jobStatusCodes';
    formData['userId'] = this.state.userId;
    formData['accountId'] = this.state.accountId;
    formData['isSystemMaster'] = true;
    formData['jobColorCode'] = this.state.color;
    formData['type'] = 'SUPERADMIN';

    formData['trash'] = formData['trash']==='true';
    formData['onDueDateReport'] = formData['onDueDateReport']==='true';

    formData['nonActiveStatus'] = formData['nonActiveStatus']==='true';

    if (this.allValidate(false)) {
      if (this.state.saveType === 'POST') {
        this.props.createJobStatusAction(formData);
      } else {
        this.props.updateJobStatusAction(formData);
      }
      this.setState({ successRedirect: true, isCreated: false });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
   e.preventDefault();
     let formData = this.state.formData;
    formData[e.target.name] = e.target.value;

    if (e.target.name === 'jobStatusCodesCode') {
      formData[e.target.name] = (e.target.value).trim();
    }
    if (e.target.name === 'jobStatusCodesDefinition') {
      formData[e.target.name] = (e.target.value);
    }

    if (e.target.name === 'jobStatusCodesName') {
      formData[e.target.name] = (e.target.value);
    }

    if (e.target.name === 'jobStatusSortIndex') {
      formData[e.target.name] = Number(e.target.value);
    }

    formData['type'] = 'SUPERADMIN';

    this.setState({ formData });
  };

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    const keysToDelete = list
      .filter((f, index) => selectedRows.includes(index.toString()))
      .map((k) => ({
        objectId: k.objectId,
      }));
    data['className'] = 'jobStatusCodes';
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm('Are you sure to delete?');
    if (confirm) {
      this.props.deleteJobStatusAction(data);
      this.setState({ successRedirect: true, isCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  displayColors(cell, row, enumObject, rowIndex) {
    if (row && row.jobColorCode) {
      let colorRGB =
        'rgba' +
        '(' +
        row.jobColorCode.r +
        ',' +
        row.jobColorCode.g +
        ',' +
        row.jobColorCode.b +
        ',' +
        row.jobColorCode.a +
        ')';
      return (
        <div
          className='color-box'
          style={{ backgroundColor: colorRGB, minHeight: '20px', borderRadius: '10px' }}
        ></div>
      );
    } else {
      return null;
    }
  }

  showTrueFalse(row,name) {
    return (
      <div style={{ textAlign: 'center' }}>
        {row[name] === true ? (
          <i className='fa fa-check fa-lg' style={{ color: 'green' }} aria-hidden='true'></i>
        ) : '-'}
      </div>
    );
  }
  showTrueFalseTrash(row) {
    return (
      <div style={{ textAlign: 'center' }}>
        {row[name] === true ? (
          '-'
        ) :<i className='fa fa-trash fa-lg' style={{ color: 'red' }} aria-hidden='true'></i>}
      </div>
    );
  }

  render() {
    const styles = reactCSS({
      default: {
        colorCurrent: {
          width: '36px',
          height: '14px',
          borderRadius: '5px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },

        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = { mode: 'checkbox' };

    const TrueFalseOpt = [{label:'true',value:'true'},{label:'false',value:'false'}];

    const PermGroupOptions =     [  { label: 'L', value: 'L' },
    { label: 'B', value: 'B' },
    { label: 'PC', value: 'PC' },
    { label: 'P1', value: 'P1' },
    { label: 'P2', value: 'P2' },
    { label: 'P3', value: 'P3' },
    { label: 'A', value: 'A' },
    { label: 'NC', value: 'NC' },
    { label: 'OH', value: 'OH' },
    { label: 'D', value: 'D' },
    { label: 'W', value: 'W' },
    { label: 'C', value: 'C' },
    { label: 'SUSP', value: 'SUSP' },
    
  ];

    const statusCategoryOptions= [
      { label: 'LeadsAndBids', value: 'LeadsAndBids' },
      { label: 'PreConstruction', value: 'PreConstruction' },
      { label: 'Construction', value: 'Construction' },
      { label: 'Operational', value: 'Operational' },
      { label: 'Other', value: 'Other' }
    ];

    const dueDateVal={label:this.state.formData.onDueDateReport,value:this.state.formData.onDueDateReport};

    const nonActiveVal={label:this.state.formData.nonActiveStatus,value:this.state.formData.nonActiveStatus};
    const deletedVal={label:this.state.formData.trash,value:this.state.formData.trash};
    const jobStatusCategoryVal={label:this.state.formData.jobStatusCodesCategory,value:this.state.formData.jobStatusCodesCategory};
    
    const permGroupVal={label:this.state.formData.permissionsGroupName,value:this.state.formData.permissionsGroupName};
    //show option, delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: 'Code',
        accessor: 'jobStatusCodesCode',
        width: 70,
      },
      {
        Header: 'Name',
        accessor: 'jobStatusCodesName',
        width: 180,
      },
      {
        Header: 'Definition',
        accessor: 'jobStatusCodesDefinition',
        width: 200,
      },
      {
        Header: 'Category',
        accessor: 'jobStatusCodesCategory',
        width: 200,
      },
      {
        Header: 'RBC Group',
        accessor: 'permissionsGroupName',
        width: 50,
      },
      {
        Header: 'Display Color',
        accessor: 'jobColorCode',
        disableSortBy: true,
        width: 140,
        Cell: (props) => this.displayColors(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Bids Due',
        accessor: 'onDueDateReport',
        width: 75,
        Cell: (props) => this.showTrueFalse(props.row.original, 'onDueDateReport'),
      },
      {
        Header: 'Inactive',
        accessor: 'nonActiveStatus',
        width: 75,
        Cell: (props) => this.showTrueFalse(props.row.original, 'nonActiveStatus'),
      },
      {
        Header: 'Sorting Index',
        accessor: 'jobStatusSortIndex',
        width: 100,
      },
      {
        Header: 'Deleted',
        accessor: 'trash',
        width: 100,
        Cell: (props) => this.showTrueFalse(props.row.original,'trash'),
      },
    ];

    return (
      <div>
        <TitleComponent data={{ title: `Administration - Default Job Status Code Settings` }} />
        <div className='headingbox'>
          <h2 className='heading2'>Job Status Codes</h2>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <button className='btn btnRight' onClick={() => this.toggle('POST')}>
              Add
            </button>
            <button
              className='btn btn-primary'
              style={{ cursor: 'pointer', padding: '5px' }}
              disabled={this.state.selectedRows?.length < 1}
              onClick={() => this.handleDeleteButtonClick()}
            >
              <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Delete
            </button>
            <CustomTable
              columns={columns}
              list={this.state.list}
              canShowColumnSettings={false}
              canShowDateFilter={false}
              canShowExport={false}
              canShowPagination={false}
              onSavePreferences={() => { }}
              isLoading={!this.state.fetchRequest}
              canShowRowSelection={true}
              onChangeRowSelection={this.handleRowSelection}
              onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
            />
          </div>
          </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.formData.objectId ? 'Edit Job Status' : 'Add Job Status'}
          </ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='jobStatusCodesName'>
                      Name<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='jobStatusCodesName'
                      id='jobStatusCodesName'
                      placeholder='Name'
                      value={this.state.formData.jobStatusCodesName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesName &&
                        validation.errors.first('jobStatusCodesName')}
                    </span>
                  </div>{' '}
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='jobStatusCodesCode'>
                      Code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='jobStatusCodesCode'
                      id='jobStatusCodesCode'
                      placeholder='Code'
                      value={this.state.formData.jobStatusCodesCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesCode &&
                        validation.errors.first('jobStatusCodesCode')}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='jobStatusSortIndex'>
                      Sorting Index<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='jobStatusSortIndex'
                      id='jobStatusSortIndex'
                      placeholder='999'
                      value={this.state.formData.jobStatusSortIndex}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusSortIndex &&
                        validation.errors.first('jobStatusSortIndex')}
                    </span>
                  </div>
                </div>
                {/* */}
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='jobStatusCodesCategory'>Category</label>
                    <Select
                      name="jobStatusCodesCategory"
                     
                      value={jobStatusCategoryVal}
                      options={statusCategoryOptions}
                      onChange={this.handleChangeSelect.bind(this, 'jobStatusCodesCategory')}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesCategory &&
                        validation.errors.first('jobStatusCodesCategory')}
                    </span>
                  </div>
                </div>
          
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='permissionsGroupName'>RBC Permissions Group</label>
                    <Select
                      name="permissionsGroupName"
                     
                      value={permGroupVal}
                      options={PermGroupOptions}
                      onChange={this.handleChangeSelect.bind(this, 'permissionsGroupName')}
                    />
                    <span style={styleData}>
                      {this.state.formArr.permissionsGroupName &&
                        validation.errors.first('permissionsGroupName')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <div className='check'>
                      <label htmlFor='trash'>Deleted</label>
                      <Select
                      name="trash"
                     
                      value={deletedVal}
                      options={TrueFalseOpt}
                      onChange={this.handleChangeSelect.bind(this, 'trash')}
                    />
                      <span style={styleData}>
                        {this.state.formArr.trash && validation.errors.first('trash')}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='form-group'>
                    <div className='check'>
                      <label htmlFor='nonActiveStatus'>Nonactive Status</label>
                      <Select
                      name="nonActiveStatus"
                     
                      value={nonActiveVal}
                      options={TrueFalseOpt}
                      onChange={this.handleChangeSelect.bind(this, 'nonActiveStatus')}
                    />
                      <span style={styleData}>
                        {this.state.formArr.nonActiveStatus &&
                          validation.errors.first('nonActiveStatus')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <div className='check'>
                      <label htmlFor='onDueDateReport'>On Bids Due Report</label>
                      <Select
                      name="onDueDateReport"
                     
                      value={dueDateVal}
                      options={TrueFalseOpt}
                      onChange={this.handleChangeSelect.bind(this, 'onDueDateReport')}
                    />
                      <span style={styleData}>
                        {this.state.formArr.onDueDateReport &&
                          validation.errors.first('onDueDateReport')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='jobStatusCodesDefinition'>Definition</label>
                    <input
                      type='text'
                      className='form-control'
                      name='jobStatusCodesDefinition'
                      id='jobStatusCodesDefinition'
                      placeholder='Definition'
                      value={this.state.formData.jobStatusCodesDefinition}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesDefinition &&
                        validation.errors.first('jobStatusCodesDefinition')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='ml-3' style={{ display: '' }}>
                <div className='d-inline-flex'>
                  Choose Color:
                  <div className='ml-2' style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.colorCurrent} />

                    {/*<div style={ {height:'10px',width:'36px',borderRadius: '2px',background:currentSelectedColor}} />*/}
                  </div>
                  {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={this.handleClose} />
                      <SwatchesPicker
                        color={this.state.formData.jobColorCode}
                        onChangeComplete={this.handleChangeColor}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <GithubPicker color={'#EB9694'} /> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={(e) => this.cancelFun(e)}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.addJobStatus(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allJobStatus: state.jobStatus.allJobStatus,
    isCreated: state.jobStatus.isCreated,
    message: state.jobStatus.message,
    success: state.jobStatus.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createJobStatusAction,
    getAllJobStatusAction,
    updateJobStatusAction,
    deleteJobStatusAction,
  })(JobStatus)
);
