import { ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';
import { formatDateObjectOrNull } from '../../../utils/date-formatters';
import moment from 'moment';

type FormDurationPickerProps = {
  name: string;
  label: string;
  startDateFieldName: string;
  endDateFieldName: string;
  required?: boolean;
  disabled?: boolean;
  updateEndOnStartChange?: boolean;
  Layout?: (props: LayoutProps) => ReactElement;
  inlineStyle?: boolean;
};

const FormDurationPicker = ({
  name,
  label,
  startDateFieldName,
  endDateFieldName,
  required = false,
  disabled = false,
  updateEndOnStartChange = true,
  Layout = DefaultLayout,
  inlineStyle = false,
}: FormDurationPickerProps) => {
  const maxDaysDuration = 10 * 365; // Equivalent to 10 years in days
  const form = useFormContext();

  if (!form) {
    throw new Error('FormDurationPicker must be used within a FormContext');
  }

  const watchStartDate = form.watch(startDateFieldName);
  const watchEndDate = form.watch(endDateFieldName);

  // Calculate total days between dates
  const calculateDays = (start, end) => {
    if (!start || !end) return 0;
    return moment(end).diff(moment(start), 'days');
  };

  const initialDays = calculateDays(watchStartDate, watchEndDate);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const daysRef = useRef<number>(initialDays);

  useEffect(() => { setDurationValue() }, []);
  useEffect(() => { 
    setDurationValue();
    // validate start and end dates
    form.trigger([startDateFieldName, endDateFieldName]);
  }, [watchEndDate]);
  
  useEffect(() => { 
    if(updateEndOnStartChange) {
      // add duration to start date and set end date
      const currentEndDate = formatDateObjectOrNull(watchEndDate);
      const currentStartDate = formatDateObjectOrNull(watchStartDate);
      
      if (currentStartDate) {
        let newEndDate = moment(currentStartDate).add(daysRef.current, 'days');

        const daysDuration = calculateDays(watchStartDate, newEndDate);

        if (daysDuration > maxDaysDuration) {
          newEndDate = moment(watchStartDate).add(maxDaysDuration, 'days');
        }

        if(newEndDate){
          if(newEndDate.isSame(currentEndDate, 'day')) return;
          form.setValue(endDateFieldName, newEndDate);
        }
      }
    }
    setDurationValue(); 
  }, [watchStartDate]);

  const setDurationValue = () => {
    if(!watchStartDate || !watchEndDate) return;

    const newDays = calculateDays(watchStartDate, watchEndDate);

    if(newDays === daysRef.current) return;

    daysRef.current = newDays;
    forceUpdate();
  };

  const onDaysChange = (days: number) => {
    const currentEndDate = formatDateObjectOrNull(watchEndDate);
    const currentStartDate = formatDateObjectOrNull(watchStartDate);
    
    // Don't allow more than max days
    const actualDays = Math.min(days, maxDaysDuration);
    
    if (updateEndOnStartChange && currentStartDate) {
      const newEndDate = moment(currentStartDate).add(actualDays, 'days');

      if(newEndDate){
        if(newEndDate.isSame(currentEndDate, 'day')) return;
        form.setValue(endDateFieldName, newEndDate);
      }
    }
    else if (!updateEndOnStartChange && currentEndDate){
      const newStartDate = moment(watchEndDate).subtract(actualDays, 'days');

      if (newStartDate) {
        if(newStartDate.isSame(currentStartDate, 'day')) return;
        form.setValue(startDateFieldName, newStartDate);
      }
    }

    // trigger form validation for start and end dates
    form.trigger([startDateFieldName, endDateFieldName]);
  };

  interface DaysInputProps {
    value: number;
    onChange: (days: number) => void;
    disabled: boolean;
    inlineStyle?: boolean;
  }

  const DaysInput = ({ value, onChange, disabled, inlineStyle }: DaysInputProps) => {
    // Create local state to track the input value during editing
    const [localInputValue, setLocalInputValue] = useState(value.toString());
    
    // Update local value when external value changes
    useEffect(() => {
      setLocalInputValue(value.toString());
    }, [value]);

    // Handle typing without losing cursor position
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalInputValue(e.target.value);
    };

    // Apply changes only when focus leaves the input
    const handleBlur = () => {
      const parsedValue = parseInt(localInputValue) || 0;
      if (parsedValue !== value) {
        onChange(parsedValue);
      }
    };

    // Also apply changes on Enter key
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const parsedValue = parseInt(localInputValue) || 0;
        if (parsedValue !== value) {
          onChange(parsedValue);
        }
      }
    };

    return (
      <div className={inlineStyle ? "d-flex align-items-center" : "d-flex align-items-center"}>
        <input
          type="text"
          className={inlineStyle ? "form-control form-control" : "form-control"}
          value={localInputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          style={inlineStyle ? { width: '80px' } : {}}
        />
        <span className="ms-2">day(s)</span>
      </div>
    );
  };

  if (inlineStyle) {
    return (
      <Controller
        rules={{ required: required && `${label} is required` }}
        name={name}
        control={form.control}
        render={({ field, fieldState }) => (
          <DaysInput
            value={daysRef.current}
            onChange={onDaysChange}
            disabled={disabled}
            inlineStyle={true}
          />
        )}
      />
    );
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={form.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            label={label}
            required={required}
            error={fieldState.error?.message}
            input={
              <DaysInput
                value={daysRef.current}
                onChange={onDaysChange}
                disabled={disabled}
              />
            }
          />
        );
      }}
    />
  );
};

export default FormDurationPicker;