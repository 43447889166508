import React, { Suspense } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../../components/Layout/Header.js';
import Footer from '../../../components/Layout/Footer.js';
import { appConstants } from '../../../_constants';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { GetProfitProjectionReportAction } from '../../../actions/reports';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ReactLoading from 'react-loading';
import ExportExcelComponent from './../exportExcelComponent';
import TitleComponent from '../../Common/TitleComponent';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { yearPickerValue } from '../../../utils/date-formatters';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { formatMoneyParens } from '../../../utils/number-formatters';
import { Checkbox, Space } from 'antd';

var dataArray = [];
var tableData = [];
var tableHeader = [];
let monthIdxArray = [];

let monthHeaderArray = [];
var netContract = [];
var overHead = [];

class ProfitProjectionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      reportList: [],
      projectList: {
        analyzeBy: { value: 'PM', label: 'Project Manager' },
        analyzeType: 'profit',
        timePeriodType: { label: 'Next 12 Months', value: 'next12months' },
        projectStatus: ['active', 'delivered', 'suspended', 'closedOut'],
      },
      monthlyExArr: [],
      monthlyExArrExcel: [],
      fetchRequest: false,
      showProcess: true,
      comLogo: '',
      formData: {
        analyzeBy: 'PM',
        analyzeType: 'profit',
        timePeriodType: 'next12months',
        projectStatus: ['A','D','W','C','SUSP'],
      },
      currentPage: 1,
      sizePerPage: 10,
      modal: false,
      listExcel: [],
      dataForExcel: false,
      customDate: false,
      searchText: '',
      projectRoleTypes: [],
    };

    this.toggle1 = this.toggle1.bind(this);
    this.searchData = this.searchData.bind(this);
    this.getReportData = this.getReportData.bind(this);
    this.selectReportRealized = this.selectReportRealized.bind(this);
    this.openFilter = this.openFilter.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
  }

  makeDataForExportExcel = (reportList) => {
    let dataArraySheet = [];
    let tableDataSheet = [];
    let tableHeaderSheet = [];

    if (dataArraySheet.length === 0) {
      const rolesLoaded = this.state.projectRoleTypes.length > 0;
      //const analyzeByOptions = rolesLoaded && this.state.OptionStatus;

      const superRoleNameShort =
        (rolesLoaded && this.state.projectRoleTypes.find((role) => role.code == 'SI')?.shortName) ??
        'Supt.';
      const projMgrNameShort =
        (rolesLoaded && this.state.projectRoleTypes.find((role) => role.code == 'PM')?.shortName) ??
        'Proj.Mgr';

      tableDataSheet = [];
      tableHeaderSheet = [
        'Project#',
        'Alt Job#',
        'Project Name',
        projMgrNameShort,
        superRoleNameShort,
        'Status',
      ];
      let monthIdxArray = [];
      let netContract = [];
      let overHead = [];

      if (reportList.length > 0) {
        const contactData = this.state.contactData;

        let processedProjects = [];

        dataArraySheet = reportList;

        let groupCount = 0;

        const profitMode = this.state.projectList.analyzeType === 'profit';
        for (let x in dataArraySheet) {
          let projectCount = 0;

          const analyzeByGroup = dataArraySheet[x];
          const groupProjects = analyzeByGroup.projectData;

          for (let y in groupProjects) {
            let project = groupProjects[y];

            const useForGlobal =
              processedProjects.length == 0 || !processedProjects.includes(project.objectId);
            if (useForGlobal) {
              processedProjects.push(project.objectId);
              dataArraySheet[x].projectData[y].useForGlobal = true;
            }

            for (let m in project.monthsArr) {
              const curMonthIdx = project.monthsArr[m].name;

              if (groupCount === 0 && projectCount == 0) {
                // initialize values for all groups
                let monthStartDate = new Date(project.monthsArr[m].start_date.iso);
                let monthNum = monthStartDate.getUTCMonth();
                let yearNum = monthStartDate.getUTCFullYear();
                let newDate = new Date(yearNum, monthNum, 1);

                const monthNameShort =
                  newDate.toLocaleString([], { month: 'short' }) +
                  '-' +
                  String(newDate.getFullYear()).substring(2, 4);
                monthHeaderArray.push(monthNameShort);

                monthIdxArray.push(curMonthIdx);

                netContract[curMonthIdx] = 0;
                overHead[curMonthIdx] = 0;

                overHead[curMonthIdx + 'once'] = 0;
              }

              if (projectCount == 0) {
                dataArraySheet[x][curMonthIdx] = 0; // initialize profit to 0 for group month
                dataArraySheet[x][curMonthIdx + 'once'] = 0;

                project[curMonthIdx] = 0;
              }

              const monthlyProjectProfit = Math.round(project.monthsArr[m].profit)??0;
              const monthlyProjectContract = Math.round(project.monthsArr[m].contractAmount)??0;
              const monthlyProjectVariableOverhead = Math.round(
                project.monthsArr[m].monthlyOverheadVariable
              )??0;

              project[curMonthIdx] = profitMode?monthlyProjectProfit:monthlyProjectContract;
              project[curMonthIdx + 'once'] = useForGlobal ? (profitMode?monthlyProjectProfit:monthlyProjectContract) : 0;

              overHead[curMonthIdx] =
                parseInt(overHead[curMonthIdx], 10) +
                (monthlyProjectContract * monthlyProjectVariableOverhead) / 100;

              // add cumulative overhead
              overHead[curMonthIdx + 'once'] =
                parseInt(overHead[curMonthIdx + 'once'], 10) +
                (useForGlobal
                  ? (monthlyProjectContract * monthlyProjectVariableOverhead) / 100
                  : 0);

              // add cumulative profit
              dataArraySheet[x][curMonthIdx] = Math.round(
                dataArraySheet[x][curMonthIdx] + (profitMode?monthlyProjectProfit:monthlyProjectContract)
              );

              dataArraySheet[x][curMonthIdx + 'once'] = Math.round(
                dataArraySheet[x][curMonthIdx + 'once'] + (useForGlobal ? (profitMode?monthlyProjectProfit:monthlyProjectContract) : 0)
              );
            }
            projectCount++;
          }
          groupCount++;
        }

        for (let s in monthIdxArray) {
          let thead;
          thead = monthIdxArray[s];
          tableHeaderSheet.push(thead);
        }

        for (let e in dataArraySheet) {
          let tdGroupBy = '';
          if (dataArraySheet[e].groupById === 'Unassigned') {
            tdGroupBy = 'Unassigned';
          } else if (
            [
              'jobStatusCodes',
              'occupancyType',
              'scopeArr',
              'buildingType',
              'negotiatingMethod',
            ].includes(this.state.projectList.analyzeBy.value)
          ) {
            tdGroupBy =
              this.state.projectList.analyzeBy.label + ': ' + dataArraySheet[e].groupByName;
          } else if (dataArraySheet[e].groupById != null && !dataArraySheet[e].groupByName) {
            tdGroupBy = 'Deleted User: ' + dataArraySheet[e].groupById;
          } else {
            tdGroupBy = dataArraySheet[e].groupByName;
          }
          const Subtotals = 'Subtotals';

          ///

          let groupProjects = dataArraySheet[e].projectData;

          for (let s in groupProjects) {
            let row = {};
            const curProject = groupProjects[s];
            row.objectId = curProject.objectId;

            const projectSupervisors = curProject.projectAssignments
              .filter((assign) => assign.projectRoleCode == 'SI')
              .map((contact) => contactData[contact.contactAffiliationId]?.displayName);
            const projectManagers = curProject.projectAssignments
              .filter((assign) => assign.projectRoleCode == 'PM')
              .map((contact) => contactData[contact.contactAffiliationId]?.displayName);
            var dataRowArr = [];
            var dataRowArrSub = [];

            row.objectId = curProject.objectId;
            //console.log("curProject", curProject);
            var tdJobNum;
            var tdAltJobNum;
            var tdJobName;
            var tdProjMg = '';
            var tdProjSr = '';
            var tdJobStatus;
            tdJobNum = curProject.jobNumFormatted ? curProject.jobNumFormatted : curProject.jobNum;
            tdAltJobNum = curProject.altJobNum ? curProject.altJobNum : '-';
            //console.log("tdJobNum", tdJobNum);
            tdJobName = curProject.jobName;

            if (projectManagers.length > 0) {
              tdProjMg = projectManagers.join(', ');
            } else {
              tdProjMg = '-';
            }

            if (projectSupervisors.length > 0) {
              tdProjSr = projectSupervisors.join(', ');
            } else {
              tdProjSr = '-';
            }

            tdJobStatus = curProject.jobStatusCodesName;
            var jkhn = [];
            var jshn = [];
            dataRowArr.push(tdJobNum, tdAltJobNum, tdJobName, tdProjMg, tdProjSr, tdJobStatus);

            dataRowArrSub.push(tdGroupBy, '', '', '', '', Subtotals);
            for (let l in monthIdxArray) {
              curProject[monthIdxArray[l]] = curProject[monthIdxArray[l]] =
                formatMoneyParens(curProject[monthIdxArray[l]]) +
                (!curProject.useForGlobal ? '*' : '');
              var dataArraySheetMoney = formatMoneyParens(
               dataArraySheet[e][monthIdxArray[l]]
              );
              var monthHeadArr;
              var dataArrHead;

              monthHeadArr = curProject[monthIdxArray[l]];
              dataArrHead = dataArraySheetMoney;
              jkhn.push(monthHeadArr);
              jshn.push(dataArrHead);
              dataRowArr.push(monthHeadArr);

              dataRowArrSub.push(dataArraySheetMoney);
            }
            tableDataSheet.push(dataRowArr);
            /*var pm = <tr>{tdJobNum}{tdJobName}{tdProjMg}{tdProjSr}{tdJobStatus}{jkhn}</tr>*/
          }
          tableDataSheet.push(dataRowArrSub);
          /*var sm = <tr className="border border2 subtotal">{tdProjectManager}{Subtotals}{jshn}</tr>*/
        }

        // calculate final group totals
        let finalSum = null;
        for (let k in monthIdxArray) {
          if (!finalSum) {
            finalSum = {};
          }

          finalSum[monthIdxArray[k]] = 0;

          for (let g in dataArraySheet) {
            finalSum[monthIdxArray[k]] =
              finalSum[monthIdxArray[k]] + ((dataArraySheet[g][monthIdxArray[k] + 'once'])??0);
          }
        }

        if (finalSum) {
          let monthlyExArr = this.state.monthlyExArrExcel;
          let totalSub = [(profitMode?'Total Estimated Marginal Contribution':'Total Estimated Monthly Revenue'), '', '', '', '', ''];
          let netSub = [(profitMode?'Estimated Net Monthly Profit':'Estimated Net Monthly Revenue'), '', '', '', '', ''];
          let overSub = ['Less Estimated Variable Overhead', '', '', '', '', ''];
          let cumulativeAmtSub = [(profitMode?'Estimated Cumulative Monthly Profit':'Estimated Cumulative Monthly Revenue'), '', '', '', '', ''];
          let cumulativeAmount = [];
          let indirectSub = ['Less Estimated Fixed Overhead', '', '', '', '', ''];

          for (let d in monthIdxArray) {
            let fsm;
            let n;
            let tdComsub;
            let oh;
            let insert;

            for (let t in monthlyExArr) {
              if (monthlyExArr[t].monthYear === monthIdxArray[d]) {
                netContract[monthIdxArray[d]] = Number(
                  (
                    finalSum[monthIdxArray[d]] -(profitMode?
                    (overHead[monthIdxArray[d] + 'once'] + monthlyExArr[t].monthlyOverheadFixed):0)
                  ).toFixed(2)
                );
                if (
                  overHead[monthIdxArray[d] + 'once'] + monthlyExArr[t].monthlyOverheadFixed >
                  Number(netContract[monthIdxArray[d]])
                ) {
                  netContract[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                }
                cumulativeAmount[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                if (cumulativeAmount[monthIdxArray[d - 1]]) {
                  if (netContract[monthIdxArray[d]] >= 0) {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) +
                      Number(netContract[monthIdxArray[d]])
                    ).toFixed(2);
                  } else {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) -
                      Number(Math.abs(netContract[monthIdxArray[d]]))
                    ).toFixed(2);
                  }
                }

                insert = formatMoneyParens(monthlyExArr[t].monthlyOverheadFixed);
                indirectSub.push(insert);
              }
            }
            n = formatMoneyParens(netContract[monthIdxArray[d]]);

            d = parseInt(d, 10);

            tdComsub = formatMoneyParens(cumulativeAmount[monthIdxArray[d]]);
            netSub.push(n);
            cumulativeAmtSub.push(tdComsub);
            fsm = formatMoneyParens(finalSum[monthIdxArray[d]]);
            oh = formatMoneyParens(overHead[monthIdxArray[d] + 'once']);
            totalSub.push(fsm);
            overSub.push(oh);
          }

          if(profitMode){
          tableDataSheet.push(totalSub, indirectSub, overSub, netSub, cumulativeAmtSub);
          }else{
            tableDataSheet.push(totalSub, cumulativeAmtSub);
          
          }
        }
      }
    }

    tableDataSheet.unshift(tableHeaderSheet);

    this.setState({ multiDataSet: tableDataSheet });
  };

  componentDidMount() {
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    let _this = this;
    this.toggle1();
    setTimeout(function () {
      _this.getReportData();
    }, 1000);
    let data = {};
    data['accountId'] = this.state.accountId;
    data['analyzeBy'] = 'PM';
    data['timePeriodType'] = 'next12months';
    data['projectStatus'] = 'realized';
    data['page'] = 0;
    data['displayLimit'] = 10;
    this.props.GetProfitProjectionReportAction(data);

    this.setState({ showProcess: false, modal: false, reportList: [] });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    /*setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);*/
  }

  excelExport() {
    this.setState({
      dataForExcel: true,
    });
    this.makeDataForExportExcel(this.state.reportList);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes?.length > 0) {
      const allRoles = nextProps.projectRoles.allProjectRoleTypes;

      const OptionBidRecapAStatus = [
        { label: 'Building Type', value: 'buildingType' },
        { label: 'Occupancy Type', value: 'occupancyType' },
        { label: 'Negotiation Method ', value: 'negotiatingMethod' },
        { label: 'Job Scope', value: 'scopeArr' },
        { label: 'Job Status Code', value: 'jobStatusCodes' },
      ];

      const roleOptions = allRoles.map((role) => ({ label: role.name, value: role.code }));

      const optionStatus = roleOptions.concat(OptionBidRecapAStatus);

      let formData = this.state.formData;
      formData.analyzeBy = optionStatus[0].value;

      this.setState({ projectRoleTypes: allRoles, OptionStatus: optionStatus, formData: formData });
    }

    if (
      nextProps.reportData &&
      nextProps.reportData?.success === 1
    ) {
      dataArray = [];
      if (nextProps.reportData && nextProps.reportData.data) {
        this.setState({
          monthlyExArrExcel: nextProps.reportData.monthlyOverheadFixedArr,
          reportList: nextProps.reportData.data,
          contactData: nextProps.reportData.contactData,
          monthlyExArr: nextProps.reportData.monthlyOverheadFixedArr,
          fetchRequest: false,
          showProcess: false,
          totalCount: nextProps.count,
        });
      }
    }

    if (nextProps.reportData && nextProps.reportData.success === 1 && this.state.dataForExcel) {
      if (nextProps.reportData && nextProps.reportData.data) {
        this.setState({
          monthlyExArrExcel: nextProps.reportData.monthlyOverheadFixedArr,
          dataForExcel: false,
        });
        this.makeDataForExportExcel(nextProps.reportData.data);
      }
    }

    if (
      (nextProps.reportData && !nextProps.reportData?.data) ||
      nextProps.reportData?.data?.length === 0
    ) {
      this.setState({ showProcess: false, fetchRequest: true });
    }
  }

  toggle1() {
    this.setState({ modal: false });
    this.setState({ showProcess: false });
  }

  openFilter() {
    this.setState({ modal: true });
    this.setState({ showProcess: false });
    let projectStatus = this.state.projectList.projectStatus;

    setTimeout(function () {
      if (document.getElementById(projectStatus))
        document.getElementById(projectStatus).checked = true;
    }, 500);
  }

  getReportData() {
    /*if(document.getElementById('realized')){
    document.getElementById('realized').checked = true;
  }*/
  }

  handleChangeInput(e) {
    e.preventDefault();
    let selectYear = this.state.selectYear;
    selectYear = e.target.value;
    //selectYear = selectYear.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
    this.setState({ selectYear: selectYear });
    // modelproj=this.modelHtml(projectList[s],s);
  }

  getStatusCodesFromOptions(options) {
    const codes = [];

    if (options.includes('active')) {
      codes.push('A');
    }

    if (options.includes('delivered')) {
      codes.push('D');
    }

    if (options.includes('preconstruction')) {
      codes.push('P1', 'P2', 'P3', 'P1P', 'P2P', 'P3P', 'SNS');
    }

    if (options.includes('bidsPending')) {
      codes.push('BP');
    }

    if (options.includes('suspended')) {
      codes.push('SUSP');

      if (options.includes('preconstruction')) {
        codes.push('OH', 'OHP', 'PC');
      }

      if (options.includes('bidsPending')) {
        codes.push('OHP');
      }
    }

    if (options.includes('closedOut')) {
      codes.push('W', 'C');
    }

    return codes;
  }

  handleChangeRadio(e) {
    let formData = this.state.formData;
    let projectList = this.state.projectList;
    formData['analyzeType'] = e.target.value;
    projectList['analyzeType'] = e.target.value;
    this.setState({ formData: formData, projectList: projectList });
  }

  selectReportRealized(a, b, c) {
    const statusCodes = this.getStatusCodesFromOptions(a);
    const checkedOptions = a;

    let formData = this.state.formData;
    let projectList = this.state.projectList;
    formData['projectStatus'] = statusCodes;
    projectList['projectStatus'] = checkedOptions;
    this.setState({
      formData: formData,
      projectList: projectList,
    });
  }

  searchData() {
    let formData = this.state.formData;
    formData['accountId'] = this.state.accountId;
    formData['moduleName'] = appConstants.reportModuleName['20'];

    if (formData['timePeriodType'] === undefined) {
      formData['timePeriodType'] = 'thisYear';
    }
    if (formData['timePeriodType'] === 'custom') {
      formData['timePeriodType'] = 'custom';
      formData['customYear'] = this.state.formData.customYear;
    }
    formData['page'] = 0;
    formData['displayLimit'] = 10;
    formData['analyzeBy'] = this.state.projectList.analyzeBy.value;

    this.props.GetProfitProjectionReportAction(formData);
    this.setState({ showProcess: false, modal: false, reportList: [] });
  }

  handleChangeSearchState(name, value) {
    let formData = this.state.formData;
    let projectList = this.state.projectList;

    if (name === 'timePeriodType') {
      formData['timePeriodType'] = value.value;

      this.setState({ customDate: value.value === 'custom' });

      projectList['timePeriodType'] = { value: value.value, label: value.label };
      this.setState({ formData: formData, projectList: projectList });
    }

    if (name == 'analyzeBy') {
      formData['analyzeBy'] = value.value;

      projectList['analyzeBy'] = { value: value.value, label: value.label };
      this.setState({ formData: formData, projectList: projectList });
    }

    if (name === 'custom') {
      formData['customYear'] = value.value;

      projectList['selectYear'] = { value: value.value, label: value.label };
      this.setState({ formData: formData, projectList: projectList });
    }
  }

  /*event for date change and save handling*/

  dataFilter() {
    if (dataArray.length === 0) {
      tableData = [];
      tableHeader = [];
      monthHeaderArray = [];
      monthIdxArray = [];
      netContract = [];
      overHead = [];

      let processedProjects = [];

      dataArray = this.state.reportList;

      const profitMode = this.state.projectList.analyzeType === 'profit';

      if (dataArray.length > 0) {
        const contactData = this.state.contactData;

        let groupCount = 0;
        for (let x in dataArray) {
          let projectCount = 0;
          const analyzeByGroup = dataArray[x];
          const groupProjects = analyzeByGroup.projectData;

          for (let y in groupProjects) {
            let project = groupProjects[y];

            const useForGlobal =
              processedProjects.length == 0 || !processedProjects.includes(project.objectId);
            if (useForGlobal) {
              processedProjects.push(project.objectId);
              dataArray[x].projectData[y].useForGlobal = true;
            }

            for (let m in project.monthsArr) {
              const curMonthIdx = project.monthsArr[m].name;

              if (groupCount === 0) {
                // initialize values for all groups
                let monthStartDate = new Date(project.monthsArr[m].start_date.iso);
                let monthNum = monthStartDate.getUTCMonth();
                let yearNum = monthStartDate.getUTCFullYear();
                let newDate = new Date(yearNum, monthNum, 1);

                const monthNameShort =
                  newDate.toLocaleString([], { month: 'short' }) +
                  '-' +
                  String(newDate.getFullYear()).substring(2, 4);
                monthHeaderArray.push(monthNameShort);

                monthIdxArray.push(curMonthIdx);

                netContract[curMonthIdx] = 0;
                overHead[curMonthIdx] = 0;
                project[curMonthIdx] = 0;

                overHead[curMonthIdx + 'once'] = 0;
              }

              if (projectCount == 0) {
                dataArray[x][curMonthIdx] = 0; // initialize profit to 0 for group month
                dataArray[x][curMonthIdx + 'once'] = 0;
              }

              const monthlyProjectProfit = Math.round(project.monthsArr[m].profit)??0;
              const monthlyProjectContract = Math.round(project.monthsArr[m].contractAmount)??0;
              const monthlyProjectVariableOverhead = Math.round(
                project.monthsArr[m].monthlyOverheadVariable
              )??0;

              project[curMonthIdx] = profitMode?monthlyProjectProfit:monthlyProjectContract;
              project[curMonthIdx + 'once'] = useForGlobal ? (profitMode?monthlyProjectProfit:monthlyProjectContract) : 0;

              overHead[curMonthIdx] =
                parseInt(overHead[curMonthIdx], 10) +
                (monthlyProjectContract * monthlyProjectVariableOverhead) / 100;

              // add cumulative overhead
              overHead[curMonthIdx + 'once'] =
                parseInt(overHead[curMonthIdx + 'once'], 10) +
                (useForGlobal
                  ? (monthlyProjectContract * monthlyProjectVariableOverhead) / 100
                  : 0);

              // add cumulative profit
              dataArray[x][curMonthIdx] = Math.round(
                dataArray[x][curMonthIdx] + (profitMode?monthlyProjectProfit:monthlyProjectContract)
              );

              dataArray[x][curMonthIdx + 'once'] = Math.round(
                dataArray[x][curMonthIdx + 'once'] + (useForGlobal ? (profitMode?monthlyProjectProfit:monthlyProjectContract) : 0)
              );
            }
            projectCount++;
            groupCount++;
          }
        }

        tableHeader = [];

        for (let s in monthHeaderArray) {
          let thead = <th className='textRight'>{monthHeaderArray[s]}</th>;
          tableHeader.push(thead);
        }

        tableData = [];
        let tdGroupBy = '';
        for (let e in dataArray) {
          tdGroupBy = '';
          if (dataArray[e].groupById === 'Unassigned') {
            tdGroupBy = <td colSpan='5'>Unassigned</td>;
          } else if (
            [
              'jobStatusCodes',
              'occupancyType',
              'scopeArr',
              'buildingType',
              'negotiatingMethod',
            ].includes(this.state.projectList.analyzeBy.value)
          ) {
            tdGroupBy = (
              <td colSpan='5'>
                {this.state.projectList.analyzeBy.label}: {dataArray[e].groupByName}
              </td>
            );
          } else if (dataArray[e].groupById != null && !dataArray[e].groupByName) {
            tdGroupBy = <td colSpan='2'>Deleted User: {dataArray[e].groupById}</td>;
          } else {
            tdGroupBy = (
              <td colSpan='5'>
                {formatExternalContactData(
                  [dataArray[e].groupById],
                  [dataArray[e].groupByItem],
                  false,
                  true
                )}
              </td>
            );
          }

          var Subtotals = (
            <td colSpan='1' className='textRight'>
              Subtotals
            </td>
          );

          let groupProjects = dataArray[e].projectData;
          for (let s in groupProjects) {
            let row = {};
            const curProject = dataArray[e].projectData[s];
            row.objectId = curProject.objectId;

            const projectSupervisors = curProject.projectAssignments
              .filter((assign) => assign.projectRoleCode == 'SI')
              .map((contact) => contactData[contact.contactAffiliationId]);
            const projectManagers = curProject.projectAssignments
              .filter((assign) => assign.projectRoleCode == 'PM')
              .map((contact) => contactData[contact.contactAffiliationId]);

            var tdJobNum;
            var tdAltJobNum;
            var tdJobName;
            var tdProjMg = '';
            var tdProjSr = '';
            var tdJobStatus;
            tdJobNum = (
              <td>{curProject.jobNumFormatted ? curProject.jobNumFormatted : curProject.jobNum}</td>
            );
            tdAltJobNum = <td>{curProject.altJobNum ? curProject.altJobNum : '-'}</td>;
            tdJobName = (
              <td className='jobname'>
                <Link to={{ pathname: `/project-details/` + curProject.objectId }}>
                  {curProject.jobName}
                </Link>
              </td>
            );

            let projMg = projectManagers.map((ps) =>
              formatInternalContactData([ps.objectId], contactData)
            );
            let projSv = projectSupervisors.map((ps) =>
              formatInternalContactData([ps.objectId], contactData)
            );

            if (projMg.length > 0) {
              tdProjMg = <td>{projMg}</td>;
            } else {
              tdProjMg = <td>-</td>;
            }

            if (projSv.length > 0) {
              tdProjSr = <td>{projSv}</td>;
            } else {
              tdProjSr = <td>-</td>;
            }

            tdJobStatus = <td>{curProject.jobStatusCodesName}</td>;
            var jkhn = [];
            var jshn = [];
            for (let l in monthIdxArray) {
              if (Math.round(curProject[monthIdxArray[l]]) === 0) {
                curProject[monthIdxArray[l]] = '';
              } else {
                curProject[monthIdxArray[l]] =
                  formatMoneyParens(curProject[monthIdxArray[l]]) +
                  (!curProject.useForGlobal ? '*' : '');
              }

              var dataArrayE = formatMoneyParens(dataArray[e][monthIdxArray[l]]);
              var monthHeadArr;
              var dataArrHead;

              monthHeadArr = (
                <td className='textRight' key={monthIdxArray[l]}>
                  {curProject[monthIdxArray[l]]}
                </td>
              );
              dataArrHead = <td className='textRight'>{dataArrayE}</td>;
              jkhn.push(monthHeadArr);
              jshn.push(dataArrHead);
            }
            var pm = (
              <tr>
                {tdJobNum}
                {tdAltJobNum}
                {tdJobName}
                {tdProjMg}
                {tdProjSr}
                {tdJobStatus}
                {jkhn}
              </tr>
            );
            tableData.push(pm);
          }
          var sm = (
            <tr className='border border2 subtotal'>
              {tdGroupBy}
              {Subtotals}
              {jshn}
            </tr>
          );
          tableData.push(sm);
        }

        // calculate final group totals
        let finalSum = null;
        for (let k in monthIdxArray) {
          if (!finalSum) {
            finalSum = {};
          }

          finalSum[monthIdxArray[k]] = 0;

          for (let g in dataArray) {
            finalSum[monthIdxArray[k]] =
              finalSum[monthIdxArray[k]] + ((dataArray[g][monthIdxArray[k] + 'once'])??0);
          }
        }

        if (finalSum) {
          let monthlyExArr = this.state.monthlyExArr;
          let totalSub = [];
          let netSub = [];
          let overSub = [];
          let cumulativeAmtSub = [];
          let cumulativeAmount = [];
          let indirectSub = [];

          for (let d in monthIdxArray) {
            let fsm;
            let n;
            let tdComsub;
            let oh;
            let insert;

            for (let t in monthlyExArr) {
              if (monthlyExArr[t].monthYear === monthIdxArray[d]) {
                netContract[monthIdxArray[d]] = Number(
                  (
                    finalSum[monthIdxArray[d]] -(profitMode?
                    (overHead[monthIdxArray[d] + 'once'] + monthlyExArr[t].monthlyOverheadFixed):0)
                  ).toFixed(2)
                );
                if (
                  overHead[monthIdxArray[d] + 'once'] + monthlyExArr[t].monthlyOverheadFixed >
                  Number(netContract[monthIdxArray[d]])
                ) {
                  netContract[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                }
                cumulativeAmount[monthIdxArray[d]] = Number(netContract[monthIdxArray[d]]);
                if (cumulativeAmount[monthIdxArray[d - 1]]) {
                  if (netContract[monthIdxArray[d]] >= 0) {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) +
                      Number(netContract[monthIdxArray[d]])
                    ).toFixed(2);
                  } else {
                    cumulativeAmount[monthIdxArray[d]] = (
                      Number(cumulativeAmount[monthIdxArray[d - 1]]) -
                      Number(Math.abs(netContract[monthIdxArray[d]]))
                    ).toFixed(2);
                  }
                }

                //console.log('monthlyExArr[t].monthlyOverheadFixed', monthlyExArr[t].monthlyOverheadFixed)

                insert = (
                  <td className='textRight'>
                    {formatMoneyParens(monthlyExArr[t].monthlyOverheadFixed)}
                  </td>
                );
                indirectSub.push(insert);
              }
            }
            n = <td className='textRight'>{formatMoneyParens(netContract[monthIdxArray[d]])}</td>;

            d = parseInt(d, 10);

            tdComsub = (
              <td className='textRight'>{formatMoneyParens(cumulativeAmount[monthIdxArray[d]])}</td>
            );
            netSub.push(n);
            cumulativeAmtSub.push(tdComsub);

            fsm = <td className='textRight'>{formatMoneyParens(finalSum[monthIdxArray[d]])}</td>;
            oh = <td className='textRight'>{formatMoneyParens(overHead[monthIdxArray[d] + 'once'])}</td>;
            totalSub.push(fsm);
            overSub.push(oh);
          }

          var emc = <td colSpan='6'>{(profitMode?'Total Estimated Marginal Contribution':'Total Monthly Revenue')}</td>;
          var iex = <td colSpan='6'>Less Estimated Variable Overhead</td>;
          var oex = <td colSpan='6'>Less Estimated Fixed Overhead</td>;
          var net = <td colSpan='6'>{(profitMode?'Estimated Net Monthly Profit':'Estimated Marginal Revenue')}</td>;
          var cumulative = <td colSpan='6'>{(profitMode?'Estimated Cumulative Monthly Profit':'Estimated Cumulative Monthly Revenue')}</td>;
          var ovex = (
            <tr className='border border2'>
              {iex}
              {overSub}
            </tr>
          );
          var inEex = (
            <tr className='border border2'>
              {oex}
              {indirectSub}
            </tr>
          );
          var tem = (
            <tr className='border border2 total'>
              {emc}
              {totalSub}
            </tr>
          );
          var netv = (
            <tr className='border border2 total'>
              {net}
              {netSub}
            </tr>
          );
          var comeu = (
            <tr className='border border2 total'>
              {cumulative}
              {cumulativeAmtSub}
            </tr>
          );
          if(profitMode){
            tableData.push(tem, inEex, ovex, netv, comeu);
          }else{
            tableData.push(tem, comeu);
          }
        }
      }
    }
  }



  render() {

    const statusOptions = [
      {
        value: 'active',
        label: 'Active (A)',
        defaultChecked: true,
      },
      {
        value: 'delivered',
        label: 'Delivered (D)',
        defaultChecked: true,
      },
      {
        value: 'preconstruction',
        label: 'Preconstruction / Not Started (P1,P2,P3,SNS)',
      },
      {
        value: 'bidsPending',
        label: 'Bids Pending (BP)',
      },
      {
        value: 'suspended',
        label: 'Include Suspended / On Hold (SUSP,OH)',
        defaultChecked: true,
      },
      {
        value: 'closedOut',
        label: 'Closed Out (W,C)',
        defaultChecked: true,
      }];

    const rolesLoaded = this.state.projectRoleTypes.length > 0;
    const analyzeByOptions = rolesLoaded && this.state.OptionStatus;

    const superRoleNameShort =
      (rolesLoaded && this.state.projectRoleTypes.find((role) => role.code == 'SI')?.shortName) ??
      'Supt.';
    const projMgrNameShort =
      (rolesLoaded && this.state.projectRoleTypes.find((role) => role.code == 'PM')?.shortName) ??
      'Proj.Mgr';

    let yearPickerValues = [];
    let optionYears = yearPickerValue(yearPickerValues);

    this.dataFilter();

    let { multiDataSet } = this.state;

    const profitMode = this.state.projectList.analyzeType === 'profit';

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Work In Progress Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          {/*<div className="noteProcessing"  style={{display: this.state.showProcessing ? 'block' : 'none' }}>
                 <img src={loaderImage} alt="Loading" />
                </div>*/}
          <div className='contactBox'>
            <div className='ms-2 container me-4'>
              <div className='row'>
                <div className='reportHead no-print'>
                  <h2>Work In Progress Report</h2>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;Analyzed {profitMode?'Profit':'Revenue'} by: {this.state.projectList.analyzeBy.label}
                  
                  <ExportExcelComponent
                    onClick={this.excelExport.bind(this)}
                    data={multiDataSet}
                    reportName='Profit_Projection_Report'
                  />
                  <ReactToPrint
                    trigger={() => (
                      <a className='btn rightalignmentBtn'>
                        <i className='fa fa-print' aria-hidden='true'></i> Print PDF
                      </a>
                    )}
                    content={() => this.componentRef}
                  />
                  <button className='btn rightalignmentBtn' onClick={(e) => this.openFilter(e)}>
                    <i className='fa fa-filter'></i> Apply Filters
                  </button>
                </div>
              </div>
              <br />

              <div className='row overflow-auto'>
                <div className='col-md-12'>
                  <div className='profit-projection reportTableSection bigTable bigTable1'>
                    <div className='table-responsive'>
                      <div ref={(el) => (this.componentRef = el)}>
                        <div className='pdfLogoBox'>
                          <div className='reportHead'>
                            <div className='logo'>
                              <img src={this.state.comLogo} alt='Upload Logo' />
                            </div>
                            <h2>Profit Projection Report</h2>
                          </div>
                          &nbsp;&nbsp;&nbsp;&nbsp;Analyzed by:{' '}
                          {this.state.projectList.analyzeBy.label}
                        </div>

                        <table width='100%' className='rsrTable ppr'>
                          <thead>
                            <tr>
                              <th>Project#</th>
                              <th>Alt Job#</th>
                              <th>Project Name</th>
                              <th width='60'>{projMgrNameShort}</th>
                              <th width='60'>{superRoleNameShort}</th>
                              <th>Status</th>
                              {tableHeader}
                            </tr>
                          </thead>
                          <tbody>{tableData}</tbody>
                        </table>
                      </div>

                      <div>
                        {this.state.showProcess && (
                          <ReactLoading
                            className='table-loader'
                            type={appConstants.LOADER_TYPE}
                            color={appConstants.LOADER_COLOR}
                            height={appConstants.LOADER_HEIGHT}
                            width={appConstants.LOADER_WIDTH}
                          />
                        )}
                      </div>

                      {this.state.fetchRequest && (
                        <div className='msgBox'>
                          <div className='textCenter'>No data found.</div>
                        </div>
                      )}
                      {/*
                                 {this.state.fetchRequest&&
                                 <span className=" text-center"><h6>No data found..</h6></span>
                                 }*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle1}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle1}>Select</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label>Group By</label>
                    <Select
                      name='analyzeBySelect'
                      closeMenuOnSelect={true}
                      components={makeAnimated()}
                      value={this.state.projectList.analyzeBy}
                      onChange={this.handleChangeSearchState.bind(this, 'analyzeBy')}
                      options={analyzeByOptions}
                    />
                  </div>
                  <div className='col-md-6'>
                    <label>Period</label>
                    <Select
                      name='status'
                      components={makeAnimated()}
                      value={this.state.projectList.timePeriodType}
                      onChange={this.handleChangeSearchState.bind(this, 'timePeriodType')}
                      options={appConstants.optionDateFilterPPr}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                {this.state.customDate && (
                  <div className='col-md-6'>
                    <label>Select Year</label>
                    <Select
                      name='status'
                      components={makeAnimated()}
                      value={this.state.projectList.selectYear}
                      onChange={this.handleChangeSearchState.bind(this, 'custom')}
                      options={optionYears}
                    />
                  </div>
                )}
              </div>


              <fieldset className='row scheduler-border'>
              <legend className='scheduler-border mt-2'>Analyze:</legend>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                     
                      <label>
                        <input
                          type="radio"
                          value="revenue"
                          checked={this.state.formData.analyzeType === 'revenue'}
                          onChange={this.handleChangeRadio}
                        />{' '}
                        Revenue ($)&nbsp;&nbsp;
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="profit"
                          checked={this.state.formData.analyzeType === 'profit'}
                          onChange={this.handleChangeRadio}
                        />{' '}
                        Profit ($)
                      </label>
                    </div>
                  </div>
                </div>
                <legend className='scheduler-border'>Include:</legend>
                <div className='col-md-12'>
                  <div className='row'>
                    <Checkbox.Group value={this.state.projectList.projectStatus} onChange={this.selectReportRealized.bind(this)}>
                      <Space direction='vertical' className='ms-3'>
                        {statusOptions.map((option) => (
                          <Checkbox
                            key={option.value}
                            value={option.value}
                            defaultChecked={option.defaultChecked}
                          //defaultChecked={option.defaultChecked}
                          >
                            {option.label}
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
              </fieldset>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle1}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={this.searchData}>
              Filter
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alert: state.contact.alert,
    reportData: state.reports.profitProjectList?.result,
    projectRoles: state.projectRoleType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetProfitProjectionReportAction,
    getAllProjectRoleAction,
  })(ProfitProjectionReport)
);
