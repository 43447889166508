import React, { useCallback, forwardRef, ForwardRefRenderFunction } from 'react';
import { Rifm } from 'rifm';
import { formatMoney, currencyToNumber } from '../../../utils/number-formatters';
import {ForwardRefExoticComponent} from 'react';

interface CurrencyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: number) => void;
}

const CurrencyInputComponent: ForwardRefRenderFunction<HTMLInputElement, CurrencyInputProps> = (
  { onValueChange, onChange, value, disabled = false, className = '', placeholder = '', ...props },
  ref
) => {
  const formatCurrency = (str: string) => {
    const isNegative = str.startsWith('-');
    const digits = str.replace(/[^\d.]/g, '');
    const numVal = parseFloat(digits);
    const isDot = str.endsWith('.');

    if(str === '-') return '-';
    if (isNaN(numVal)) return '';
    const formattedValue = formatMoney(numVal);
    return (isNegative ? `-${formattedValue}` : formattedValue) + (isDot ? '.' : '');
  };

  const handleChange = useCallback((formattedValue: string) => {
    const numericValue = currencyToNumber(formattedValue);
    if (onValueChange) {
      onValueChange(numericValue);
    }
    if (onChange) {
      const event = { target: { value: formattedValue } } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  }, [onValueChange, onChange]);

  const renderInput = useCallback(({ value, onChange }) => {
    return (
      <input
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        type="text"
        inputMode="decimal"
        value={value}
        onChange={onChange}
        {...props}
      />
    );
  }, [disabled, placeholder, className, props, ref]);

  const rifmProps = {
    accept: /[\d.]/g,
    format: formatCurrency,
    value: typeof value === 'string' ? value : formatMoney(value as number),
    onChange: handleChange,
  };

  return <Rifm {...rifmProps}>{renderInput}</Rifm>;
};

export const CurrencyInput = forwardRef(CurrencyInputComponent) as 
  ForwardRefExoticComponent<CurrencyInputProps & React.RefAttributes<HTMLInputElement>> & 
  ((props: CurrencyInputProps) => JSX.Element);

CurrencyInput.displayName = 'CurrencyInput';