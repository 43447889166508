import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { getUserSettings,getReasonCodesByType,getJobStatusCodesSettings } from '../../../modules/settings';
import { updateProjectStatusAction } from '../../../actions/projectStatus';
import { selectGeneralInfo } from '../../../selectors/accountSettings';
import styles from './update-project-status-modal.module.scss'
import { isAllowed } from '../../../_constants';
import moment from 'moment';
import FormDatePicker from '../../../shared/form-controls/form-date-picker';
import DatePickerWrapper from '../../../shared/datepicker-wrapper/datepicker-wrapper';

export const renderStatusHeader = (projectData) => {
  const hasJobNumber = projectData?.jobNumFormatted?.length > 0;
  const hasAltJobNumber = projectData?.altJobNum?.length > 0;

  return (
    <div className='row'>
      <div className='col'>
        <div className={`form-group mb-3 ${styles.jobName}`}>
          <label>Job name:</label>
          <input
            type='text'
            className={`form-control ${styles.jobBlockInfo}`}
            value={projectData?.jobName ?? ''}
            disabled
          />
        </div>
      </div>
      {hasJobNumber && (
        <div className='col'>
          <div className={`form-group mb-3 ${styles.jobName}`}>
            <label>Job number:</label>
            <input
              type='text'
              className={`form-control ${styles.jobBlockInfo}`}
              value={projectData.jobNumFormatted}
              disabled
            />
          </div>
        </div>
      )}
      {hasAltJobNumber && (
        <div className='col'>
          <div className={`form-group mb-3 ${styles.jobName}`}>
            <label>Alt. Job number:</label>
            <input
              type='text'
              className={`form-control ${styles.jobBlockInfo}`}
              value={projectData.altJobNum}
              disabled
            />
          </div>
        </div>
      )}
    </div>
  );
};

const UpdateProjectStatusModal = ({
  isOpen,
  toggle,
  projectId,
  projectData,
  currentStatus,
  onAfterUpdate,
  actionType = null,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch } = useForm();

  const [loading, setLoading] = useState(true);
  const [errorMsg, setError] = useState(null);
  const [nextStatusOptions, setNextStatusOptions] = useState([]);
  const [allStatusChangeReason, setAllStatusChangeReason] = useState([]);
  const [currentStatusChangeReason, setCurrentStatusChangeReason] = useState([]);

  
  const accountInfo = useSelector(selectGeneralInfo);
  const requireReasonCodes = accountInfo?.requireReasonCodes;

  const selectedAction = watch('action');
  const nextStatus = watch('nextStatus');

  const [allActions, setAllActions] = useState([]);
  const [allStatusCodes, setAllStatusCodes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const [actionsResult, statusCodesResult] = await Promise.all([
          dispatch(getUserSettings(['projectActions'])),
          dispatch(getJobStatusCodesSettings()),
          dispatch(getReasonCodesByType('StatusChange'))
          .unwrap()
          .then((data) => {
            setAllStatusChangeReason(
              data.map((reason) => ({ label: reason.reasonName, value: reason._id, jobStatusCodesCode : reason.jobStatusCodesCode}))
            );
          })
        ]);

        const actions = actionsResult.payload.filter(
          (action) => !action.trash && action.initialStatus.includes(currentStatus)
        );
        const statusCodes = statusCodesResult.payload
          .filter((code) => !code.trash && !code.isSystemMaster)
          .map((code) => ({
            _id: code._id,
            jobStatusCodesName: code.jobStatusCodesName,
            jobStatusCodesCode: code.jobStatusCodesCode,
            permissionsGroupName: code.permissionsGroupName,
            orderId: code.orderId
          }));

        setAllStatusCodes(statusCodes);

          

        if (actionType) {
          const action = actions.find((a) => a.code === actionType);
          if (action) {
            setValue('action', { value: action.code, label: action.name, ...action });
            updateNextStatusOptions(action, statusCodes);
            setAllActions([action]);
          } else {
            setError('No valid action found');
          }
        } else if (actions.length === 1) {
          const action = actions[0];
          setValue('action', { value: action.code, label: action.name, ...action });
          updateNextStatusOptions(action, statusCodes);

          setAllActions(actions);
        } else {
          setNextStatusOptions([]);
          setAllActions(actions);
          
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [dispatch, currentStatus, actionType, isOpen]);

  const updateNextStatusOptions = (action, statusCodes) => {
    const validStatusOptions = action.nextStatus.map((status) =>
      statusCodes.find((code) => code.jobStatusCodesCode === status)
    );
    const nextStatusOptions = validStatusOptions
      .filter(Boolean)
      .map((code) => ({ value: code._id, label: code.jobStatusCodesName, code: code.jobStatusCodesCode,orderId:code.orderId }));

    setNextStatusOptions(nextStatusOptions);
    if (nextStatusOptions.length === 1) {
      setValue('nextStatus', nextStatusOptions[0]);
    }else if(nextStatusOptions.length>0 && action.defaultNextStatus){
      setValue('nextStatus', nextStatusOptions.find((status) => status.code === action.defaultNextStatus));
    }else if(nextStatusOptions.length>0 && !(action.defaultNextStatus?.length>0)){
      const currentProjectStatusOrder = allStatusCodes.find((code) => code.jobStatusCodesCode === currentStatus)?.orderId;
      const nextStatusOrder = nextStatusOptions.map((status) => status.orderId);

      if(action.backwardsStatus){
        const nextStatus = nextStatusOptions.find((status) => status.orderId === Math.max(...nextStatusOrder.filter((order) => order < currentProjectStatusOrder)));
        setValue('nextStatus', nextStatus);
        return;
      }
      const nextStatus = nextStatusOptions.find((status) => status.orderId === Math.min(...nextStatusOrder.filter((order) => order > currentProjectStatusOrder)));
      setValue('nextStatus', nextStatus);
    }
  };

  useEffect(() => {
    if(!nextStatusOptions.length|| !nextStatus){
      if(currentStatusChangeReason?.length>0){
        setCurrentStatusChangeReason([]);
        setValue('reason', null);
      }
      return;
    }
    const validReasons = allStatusChangeReason.filter(
      (reason) => reason.jobStatusCodesCode === nextStatus.code
    );
    setCurrentStatusChangeReason(validReasons);

    if(validReasons.length === 1){
      setValue('reason', validReasons[0]);
    }else if(validReasons.length === 0){
      setValue('reason', null);
    }


  }, [nextStatusOptions,nextStatus,allStatusChangeReason]);

  useEffect(() => {
    if (selectedAction) {
      //const action = allActions.find((a) => a.code === selectedAction.value);
      if (selectedAction) {
        updateNextStatusOptions(selectedAction,allStatusCodes.filter((code) => selectedAction.nextStatus.includes(code.jobStatusCodesCode)));

        if (selectedAction.actionFields) {
          selectedAction.actionFields.forEach((field) => {
            setValue(field.parseField, field.defaultValue==='today'?moment().format('YYYY-MM-DD'):null);
          });
        }
      }
    }
  }, [selectedAction, allActions, projectData?.statusCodes]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await dispatch(
        updateProjectStatusAction({
          ...(selectedAction.actionFields || []).map((field) => ({ [field.parseField]: data[field.parseField] })).reduce((a, b) => ({ ...a, ...b }), {}), // merge all actionFields
          projectId: projectId,
          nextStatus: data.nextStatus?.value,
          updateType: selectedAction.updateType,
          statusReasonCode: data.reason?.label??null,
          comment: data.comment,
        })
      );
      onAfterUpdate();
      toggle();
    } catch (err) {
      setError('Failed to update status');
    } finally {
      setLoading(false);
    }
  };

  const modalTitle = selectedAction
    ? selectedAction.title?.replace('$NEXTSTATUSNAME', nextStatus?.label || '')
    : 'Update project status';

  const hasPermission = selectedAction && isAllowed(
    selectedAction.actionPermissionModule,
    'assigned',
    projectData?.userAssignData
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} >
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {loading ? (
          <p>Loading...</p>
        ) : errorMsg ? (
          <p className='text-danger'>{errorMsg}</p>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderStatusHeader(projectData)}

            {!actionType && allActions?.length>0 && (
              <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                  Action:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='action'
                    control={control}
                    rules={{ required: 'Action is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={allActions.map((status) => ({
                          value: status.value,
                          label: status.label,
                          code: status.jobStatusCodesCode,
                        }))}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {actionType && (
              <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                  Next Status:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='nextStatus'
                    control={control}
                    rules={{ required: 'Next status is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={nextStatusOptions.map((status) => ({
                          value: status.value,
                          label: status.label,
                          code: status.jobStatusCodesCode,
                        }))}
                      />
                    )}
                  />
                </div>
              </div>
            )}  

            {currentStatusChangeReason.length>0 && (
              
                    <div className="form-group">
                      <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                        Reason:{' '}
                      </label>
                      <div className='col-sm-9'>
                        <Controller
                          name='reason'
                          control={control}
                          rules={{ ...requireReasonCodes && { required: 'Reason is required' } }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={currentStatusChangeReason}
                              isClearable={!requireReasonCodes}
                              required={requireReasonCodes}
                            />
                          )}
                        />

                      </div>
                    </div>
                 
              )}

              {(selectedAction.actionFields || []).map((field) => (
                // list all actionField dates
                <div className='form-group mb-3 row ' key={field.parseField}>
                  <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                    {field.fieldName}{field.required && (<span className='text-danger'>*</span>)}:{' '}
                  </label>
                  <div className='col-sm-9'>
                    <DatePickerWrapper
                      //control={control}
                      selected={watch(field.parseField)}
                      name={field.parseField}
                      field={field.parseField}
                      rules={field.required && { required: 'Date is required' }}
                      disabled={!hasPermission}
                      clearable={!field.required}
                      required={field.required}
                      className='form-control'
                      onChange={(date) => setValue(field.parseField, date)}
                      dateFormat={field.showTime ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy'}
                      
                    />
                      
                  </div>
                </div>
              ))}

            {selectedAction?.allowComments && (
              <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                  Comment:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='comment'
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} className='form-control' rows={3} />
                    )}
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary' onClick={toggle}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleSubmit(onSubmit)}
          disabled={loading || !nextStatus || errorMsg?.length > 0}
        >
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateProjectStatusModal;
