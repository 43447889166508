import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GetClientListReportAction } from '../../../actions/reports';
import { formatDate, formatDateObject, startDateFormatWithoutTimeUTCF } from '../../../utils/date-formatters';
import { appConstants } from '../../../_constants';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import CustomTable, { customSortByKey } from '../../Common/CustomTable';
import { formatMoney, roundFormatMoney } from '../../../utils/number-formatters';
import { ResponsivePie } from '@nivo/pie';
import  DateRangeSelector from '../../../shared/report-date-range-selector/DateRangeSelector';
import moment from 'moment';

const ClientListReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    accountId: localStorage.getItem('accountId'),
    userId: localStorage.getItem('userId'),
    list: [],
    netTotal: {},
    fetchRequest: true,
    comLogo: '',
    modal: false,
    dateRange: {
      mode: 'priorDays',
      value: '365',
      startDate: formatDateObject().subtract(365, 'days'),
      endDate: formatDateObject(),
    },
    dataForExcel: false,
  });

  const [columns, setColumns] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [timePeriodLabel, setTimePeriodLabel] = useState('');

  const clientListData = useSelector((state) => state.reports.clientList);
  
  useEffect(() => {
    if (localStorage.getItem('companyLogo') !== 'undefined' && localStorage.getItem('companyLogo') !== null) {
      setState(prevState => ({ ...prevState, comLogo: localStorage.getItem('companyLogo') }));
    } else {
      setState(prevState => ({ ...prevState, comLogo: appConstants.CONTENT_CPOST_LOGO_URL }));
    }

    // Load saved date range from localStorage if available
    const savedDateRange = localStorage.getItem('clientListReportDateRange');
    if (savedDateRange) {
      const parsedDateRange = JSON.parse(savedDateRange);
      setState(prevState => ({ ...prevState, dateRange: JSON.parse(savedDateRange) }));
      if(parsedDateRange.mode === 'priorDays') {
        parsedDateRange.startDate = moment().subtract(parseInt(parsedDateRange.value), 'days').startOf('day').toDate();
        parsedDateRange.endDate = moment().endOf('day').toDate();
      }
      submitReportRequest({ ...state, startDate: parsedDateRange.startDate, endDate: parsedDateRange.endDate });
    }else {
      // Default to last year if no saved date range
      const lastYear = {
        mode: 'year',
        value: (new Date().getFullYear() - 1).toString(),
        startDate: moment().subtract(1, 'year').startOf('year').toDate(),
        endDate: moment().subtract(1, 'year').endOf('year').toDate()
      };
      setState(prevState => ({ ...prevState, dateRange: lastYear }));
      localStorage.setItem('clientListReportDateRange', JSON.stringify(lastYear));
      submitReportRequest({ ...state, startDate: lastYear.startDate, endDate: lastYear.endDate });
    }

    
  }, []);

  const [reportTotals, setReportTotals] = useState({
    contractAmount: 0,
  });

  useEffect(() => {
    if (clientListData && clientListData.length) {
      const newReportData = [];
      const newReportTotals = { contractAmount: 0 };
      const newGraphData = [];

      clientListData.forEach((client) => {
        client.projectData.forEach((project) => {
          project.clientName = client.contactAllInformation?.displayNameWithCompany || 'Unassigned';
          project.clientId = client.contactAllInformation?.objectId;
          newReportData.push(project);
          newReportTotals.contractAmount += project.currentContractAmount || 0;
        });

        newGraphData.push({
          id: client.contactAllInformation?.displayNameWithCompany || 'Unassigned',
          label: client.contactAllInformation?.displayNameWithCompany || 'Unassigned',
          value: client.currentContractSubtotal || 0,
        });
      });

      const minPercentage = 5;
      const minValue = newReportTotals.contractAmount * (minPercentage / 100);

      const otherData = newGraphData.filter((item) => item.value <= minValue);
      setGraphData(otherData.length > 0 
        ? [{ id: 'Other', label: 'Other', value: otherData.reduce((acc, item) => acc + item.value, 0) }, ...newGraphData.filter((item) => item.value > minValue)]
        : newGraphData);

      setReportTotals(newReportTotals);
      setReportData(newReportData);
      setState(prevState => ({
        ...prevState,
        netTotal: newReportTotals,
        fetchRequest: false,
      }));
      buildColumns();
    }
  }, [clientListData]);

  const toggleFilter = (shouldClose = false) => {
    if (shouldClose) {
      if (!state.modal) return;
      setState(prevState => ({ ...prevState, modal: false }));
      return;
    }
    setState(prevState => ({ ...prevState, modal: !prevState.modal }));
  };

  const handleDateRangeChange = (newDateRange) => {
    setState(prevState => ({
      ...prevState,
      dateRange: newDateRange,
    }));
    // Save date range to localStorage
    localStorage.setItem('clientListReportDateRange', JSON.stringify(newDateRange));
  };

  const submitReportRequest = (data = {
    accountId: state.accountId,
    moduleName: appConstants.reportModuleName['CLR'],
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    page: 0,
    displayLimit: 10000, // Adjust as needed
  },e) => {
    setState(prevState => ({ ...prevState, fetchRequest: true }));

    data.startDate =startDateFormatWithoutTimeUTCF(data.startDate);
    data.endDate =startDateFormatWithoutTimeUTCF(data.endDate);

    setTimePeriodLabel(`Jobs logged between ${formatDate(moment(data.startDate).add(
      8, 'hours'
    ))} and ${formatDate(data.endDate)}`);

    toggleFilter(true);
    dispatch(GetClientListReportAction(data));
  };

  const buildColumns = () => {
    const columns = [
      {
        Header: 'Client',
        accessor: 'clientName',
        canGroupBy: true,
        defaultGrouped: true,
        minWidth: 100,
        groupedColSpan: 4,
        hideMenu: true,
        hideGroupByExpand: true,
        Cell: ({ cell }) => {
          return <b>{cell.row.values.clientName}</b>;
        },
        aggregate: 'count',
        Aggregated: ({ cell }) => {
          return <div><b>{cell.row.values.clientName} ({cell.value})</b></div>;
        },
        Footer: () => {
          return <b>Company Totals:</b>;
        },
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 195,
        groupedColSpan: 1,
        hideMenu: true,
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/project-details/${props.row.original?.objectId}`,
                state: { row: props.row.original },
              }}
            >
              {props.value}
            </Link>
          );
        },
      },
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        width: 40,
        groupedColSpan: 0,
        hideMenu: true,
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/project-details/${props.row.original?.objectId}`,
                state: { row: props.row.original },
              }}
            >
              {props.value}
            </Link>
          );
        },
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 40,
        hideMenu: true,
        groupedColSpan: 0,
      },
      {
        Header: 'Sched. End Date',
        accessor: 'endDate.iso',
        groupedColSpan: 1,
        hideMenu: true,
        Cell: ({ value }) => (value ? formatDate(value) : '-'),
        sortType: customSortByKey,
      },
      {
        Header: 'Building Type',
        accessor: 'buildingTypesData.buildingTypeCode',
        Cell: ({ value }) => value || '-',
      },
      {
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        Cell: ({ value }) => <div className="text-end">{value ? roundFormatMoney(value) : '-'}</div>,
        aggregate: 'sum',
        Aggregated: ({ value }) => <div className="text-end"><b>{value ? roundFormatMoney(value) : '-'}</b></div>,
        Footer: () => <div className="text-end"><b>{reportTotals.contractAmount ? roundFormatMoney(reportTotals.contractAmount) : '-'}</b></div>,
      },
      {
        Header: 'Status',
        accessor: 'jobStatusCodesCode',
        Cell: ({ value }) => value || '-',
      },
    ];

    setColumns(columns);
  };

  const [graphData, setGraphData] = useState([]);

  const printContainer = useRef(null);

  return (
    <div className="ClientListReport">
      <TitleComponent data={{ title: `Client List Report` }} />
      <Header props={history} />
      
      <div className="contactBox">
        <div className="container">
          <div className="reportHead no-print">
            <h2>Client List Report</h2>
            
            <button className="btn rightalignmentBtn" onClick={() => toggleFilter()}>
              <i className="fa fa-filter"></i> Apply Filters
            </button>
          </div>

          <div ref={printContainer}>
            <div className="row">
              <div className="col-md-12">
                <div className="pdfLogoBox">
                  <div className="reportHead">
                    <div className="logo">
                      <img src={state.comLogo} alt="Upload Logo" />
                    </div>
                    <h2>Client List Report</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="row searchbar">
              <div className="col-md-12">
                <div>{timePeriodLabel}</div>
              </div>
            </div>

            {reportData?.length > 0 && (
              <div className='row' style={{ minHeight: 300, fill: 'white' }}>
                <div className='col svgGraph'>
                  <ResponsivePie
                    data={graphData}
                    margin={{ top: 30, right: 80, bottom: 40, left: 0 }}
                    innerRadius={0.5}
                    cornerRadius={3}
                    colors={{ scheme: 'tableau10' }}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    padAngle={2}
                    arcLabelsTextColor={'#ffffff'}
                    arcLabelsSkipAngle={15}
                    valueFormat={(value) => roundFormatMoney(value, { prefix: '$' })}
                    arcLabel={(e) => roundFormatMoney((e.value / 1000), { prefix: '$' }) + 'k'}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="profit-projection reportTableSection bigTable bigTable1">
                  <CustomTable
                    columns={columns}
                    data={reportData}
                    loading={state.fetchRequest}
                    noDataText="No data found."
                    canShowFooter={true}
                    componentRef={printContainer}
                    groupBy={['clientName']}
                    groupUp={true}
                    canShowColumnSettings={false}
                    pageSizeArray={[200, 500]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Modal
        backdrop="static"
        keyboard={true}
        isOpen={state.modal}
        toggle={() => toggleFilter(true)}
        className={history.className}
      >
        <ModalHeader toggle={() => toggleFilter(true)}>Select date range</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span>
                  The report will contain all jobs whose end date falls within the selected date range.
                </span>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <DateRangeSelector
                  onChange={handleDateRangeChange}
                  initialMode={state.dateRange.mode}
                  initialValue={state.dateRange.value}
                  initialStart={state.dateRange.startDate}
                  initialEnd={state.dateRange.endDate}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={() => toggleFilter(true)}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => submitReportRequest( { ...state, startDate: state.dateRange.startDate, endDate: state.dateRange.endDate })}>
            Next
          </button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default ClientListReport;