import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AdminSidebar from '../../components/Admin/Inc/AdminSideBar.jsx';

const handleAuthentication = () => {
  let user_info = JSON.parse(localStorage.getItem('userInfo'));

  return (
    localStorage.getItem('baseUserRole') === 'SUPERADMIN' &&
    user_info &&
    (user_info.username === 'superadmin@commandtech.io' ||
      user_info.username === 'superadmin+staging@commandtech.io')
  );
};

const AdminLayout = ({ children }) => {
  const history = useHistory();
  
  useEffect(() => {
    if (!handleAuthentication()) {
      history.replace('/admin/access-denied');
    }
  }, [history]);

  return (
    <div className="wrapper">
      <div className="d-flex">
        <AdminSidebar location={history.location} />
        <main className="flex-grow-1 " style={{ width: 'calc(100% - 260px)' }}>
          <div className="p-4">
            <div className="bg-white rounded shadow-sm">
              <div className="p-4">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
