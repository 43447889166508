export const SELECT_NAV_LINK_ACTION = 'SELECT_NAV_LINK_ACTION';

export const GET_ALL_COMPANY_REQUEST = 'GET_ALL_COMPANY_REQUEST';
export const GET_ALL_COMPANY_SUCCESS = 'GET_ALL_COMPANY_SUCCESS';
export const GET_ALL_COMPANY_ERROR = 'GET_ALL_COMPANY_ERROR';

export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST';
export const SIGNIN_USER_ERROR = 'SIGNIN_USER_ERROR';

export const GET_SIGNIN_USER_SUCCESS = 'GET_SIGNIN_USER_SUCCESS';
export const GET_SIGNIN_USER_REQUEST = 'GET_SIGNIN_USER_REQUEST';
export const GET_SIGNIN_USER_ERROR = 'GET_SIGNIN_USER_ERROR';

export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

export const GET_USER_EMAIL_BY_ID_REQUEST = 'GET_USER_EMAIL_BY_ID_REQUEST';
export const GET_USER_EMAIL_BY_ID_SUCCESS = 'GET_USER_EMAIL_BY_ID_SUCCESS';
export const GET_USER_EMAIL_BY_ID_ERROR = 'GET_USER_EMAIL_BY_ID_ERROR';

export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR';

export const UPDATE_CONTACT_STATUS_REQUEST = 'UPDATE_CONTACT_STATUS_REQUEST';
export const UPDATE_CONTACT_STATUS_SUCCESS = 'UPDATE_CONTACT_STATUS_SUCCESS';
export const UPDATE_CONTACT_STATUS_ERROR = 'UPDATE_CONTACT_STATUS_ERROR';

export const GET_ALL_PROJECT_ROLE_REQUEST = 'GET_ALL_PROJECT_ROLE_REQUEST';
export const GET_ALL_PROJECT_ROLE_SUCCESS = 'GET_ALL_PROJECT_ROLE_SUCCESS';
export const GET_ALL_PROJECT_ROLE_ERROR = 'GET_ALL_PROJECT_ROLE_ERROR';

export const GET_ALL_PROJECT_FIELDS_REQUEST = 'GET_ALL_PROJECT_FIELDS_REQUEST';
export const GET_ALL_PROJECT_FIELDS_SUCCESS = 'GET_ALL_PROJECT_FIELDS_SUCCESS';
export const GET_ALL_PROJECT_FIELDS_ERROR = 'GET_ALL_PROJECT_FIELDS_ERROR';

export const GET_ALL_CONTACT_FIELDS_REQUEST = 'GET_ALL_CONTACT_FIELDS_REQUEST';
export const GET_ALL_CONTACT_FIELDS_SUCCESS = 'GET_ALL_CONTACT_FIELDS_SUCCESS';
export const GET_ALL_CONTACT_FIELDS_ERROR = 'GET_ALL_CONTACT_FIELDS_ERROR';

export const SET_NOTIFICATION_PREFERENCE_REQUEST = 'SET_NOTIFICATION_PREFERENCE_REQUEST';
export const SET_NOTIFICATION_PREFERENCE_SUCCESS = 'SET_NOTIFICATION_PREFERENCE_SUCCESS';
export const SET_NOTIFICATION_PREFERENCE_ERROR = 'SET_NOTIFICATION_PREFERENCE_ERROR';

export const GET_ALL_NOTIFICATION_PREFERENCES_REQUEST = 'GET_ALL_NOTIFICATION_PREFERENCES_REQUEST';
export const GET_ALL_NOTIFICATION_PREFERENCES_SUCCESS = 'GET_ALL_NOTIFICATION_PREFERENCES_SUCCESS';
export const GET_ALL_NOTIFICATION_PREFERENCES_ERROR = 'GET_ALL_NOTIFICATION_PREFERENCES_ERROR';

export const UPDATE_WIDGET_PREFERENCE_REQUEST = 'UPDATE_WIDGET_PREFERENCE_REQUEST';
export const UPDATE_WIDGET_PREFERENCE_SUCCESS = 'UPDATE_WIDGET_PREFERENCE_SUCCESS';
export const UPDATE_WIDGET_PREFERENCE_ERROR = 'UPDATE_WIDGET_PREFERENCE_ERROR';

export const SET_WIDGET_ORDER_REQUEST = 'SET_WIDGET_ORDER_REQUEST';
export const SET_WIDGET_ORDER_SUCCESS = 'SET_WIDGET_ORDER_SUCCESS';
export const SET_WIDGET_ORDER_ERROR = 'SET_WIDGET_ORDER_ERROR';

export const GET_ALL_WIDGET_PREFERENCES_REQUEST = 'GET_ALL_WIDGET_PREFERENCES_REQUEST';
export const GET_ALL_WIDGET_PREFERENCES_SUCCESS = 'GET_ALL_WIDGET_PREFERENCES_SUCCESS';
export const GET_ALL_WIDGET_PREFERENCES_ERROR = 'GET_ALL_WIDGET_PREFERENCES_ERROR';

export const CREATE_CONTRACT_TYPE_REQUEST = 'CREATE_CONTRACT_TYPE_REQUEST';
export const CREATE_CONTRACT_TYPE_SUCCESS = 'CREATE_CONTRACT_TYPE_SUCCESS';
export const CREATE_CONTRACT_TYPE_ERROR = 'CREATE_CONTRACT_TYPE_ERROR';

export const GET_ALL_CONTRACT_TYPE_REQUEST = 'GET_ALL_CONTRACT_TYPE_REQUEST';
export const GET_ALL_CONTRACT_TYPE_SUCCESS = 'GET_ALL_CONTRACT_TYPE_SUCCESS';
export const GET_ALL_CONTRACT_TYPE_ERROR = 'GET_ALL_CONTRACT_TYPE_ERROR';

export const UPDATE_CONTRACT_TYPE_REQUEST = 'UPDATE_CONTRACT_TYPE_REQUEST';
export const UPDATE_CONTRACT_TYPE_SUCCESS = 'UPDATE_CONTRACT_TYPE_SUCCESS';
export const UPDATE_CONTRACT_TYPE_ERROR = 'UPDATE_CONTRACT_TYPE_ERROR';


export const DELETE_CONTRACT_TYPE_REQUEST = 'DELETE_CONTRACT_TYPE_REQUEST';
export const DELETE_CONTRACT_TYPE_SUCCESS = 'DELETE_CONTRACT_TYPE_SUCCESS';
export const DELETE_CONTRACT_TYPE_ERROR = 'DELETE_CONTRACT_TYPE_ERROR';

export const CREATE_CONTACT_ROLE_REQUEST = 'CREATE_CONTACT_ROLE_REQUEST';
export const CREATE_CONTACT_ROLE_SUCCESS = 'CREATE_CONTACT_ROLE_SUCCESS';
export const CREATE_CONTACT_ROLE_ERROR = 'CREATE_CONTACT_ROLE_ERROR';

export const GET_ALL_CONTACT_ROLE_REQUEST = 'GET_ALL_CONTACT_ROLE_REQUEST';
export const GET_ALL_CONTACT_ROLE_SUCCESS = 'GET_ALL_CONTACT_ROLE_SUCCESS';
export const GET_ALL_CONTACT_ROLE_ERROR = 'GET_ALL_CONTACT_ROLE_ERROR';

export const UPDATE_CONTACT_ROLE_REQUEST = 'UPDATE_CONTACT_ROLE_REQUEST';
export const UPDATE_CONTACT_ROLE_SUCCESS = 'UPDATE_CONTACT_ROLE_SUCCESS';
export const UPDATE_CONTACT_ROLE_ERROR = 'UPDATE_CONTACT_ROLE_ERROR';


export const DELETE_CONTACT_ROLE_REQUEST = 'DELETE_CONTACT_ROLE_REQUEST';
export const DELETE_CONTACT_ROLE_SUCCESS = 'DELETE_CONTACT_ROLE_SUCCESS';
export const DELETE_CONTACT_ROLE_ERROR = 'DELETE_CONTACT_ROLE_ERROR';

export const REGISTER_COMPANY_REQUEST = 'REGISTER_COMPANY_REQUEST';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_ERROR = 'REGISTER_COMPANY_ERROR';

export const CREATE_OCCUPANCY_TYPE_REQUEST = 'CREATE_OCCUPANCY_TYPE_REQUEST';
export const CREATE_OCCUPANCY_TYPE_SUCCESS = 'CREATE_OCCUPANCY_TYPE_SUCCESS';
export const CREATE_OCCUPANCY_TYPE_ERROR = 'CREATE_OCCUPANCY_TYPE_ERROR';

export const UPDATE_OCCUPANCY_TYPE_REQUEST = 'UPDATE_OCCUPANCY_TYPE_REQUEST';
export const UPDATE_OCCUPANCY_TYPE_SUCCESS = 'UPDATE_OCCUPANCY_TYPE_SUCCESS';
export const UPDATE_OCCUPANCY_TYPE_ERROR = 'UPDATE_OCCUPANCY_TYPE_ERROR';

export const GET_ALL_OCCUPANCY_TYPE_REQUEST = 'GET_ALL_OCCUPANCY_TYPE_REQUEST';
export const GET_ALL_OCCUPANCY_TYPE_SUCCESS = 'GET_ALL_OCCUPANCY_TYPE_SUCCESS';
export const GET_ALL_OCCUPANCY_TYPE_ERROR = 'GET_ALL_OCCUPANCY_TYPE_ERROR';

export const DELETE_OCCUPANCY_TYPE_REQUEST = 'DELETE_OCCUPANCY_TYPE_REQUEST';
export const DELETE_OCCUPANCY_TYPE_SUCCESS = 'DELETE_OCCUPANCY_TYPE_SUCCESS';
export const DELETE_OCCUPANCY_TYPE_ERROR = 'DELETE_OCCUPANCY_TYPE_ERROR';

export const CREATE_BUILDING_TYPE_REQUEST = 'CREATE_BUILDING_TYPE_REQUEST';
export const CREATE_BUILDING_TYPE_SUCCESS = 'CREATE_BUILDING_TYPE_SUCCESS';
export const CREATE_BUILDING_TYPE_ERROR = 'CREATE_BUILDING_TYPE_ERROR';

export const UPDATE_BUILDING_TYPE_REQUEST = 'UPDATE_BUILDING_TYPE_REQUEST';
export const UPDATE_BUILDING_TYPE_SUCCESS = 'UPDATE_BUILDING_TYPE_SUCCESS';
export const UPDATE_BUILDING_TYPE_ERROR = 'UPDATE_BUILDING_TYPE_ERROR';

export const GET_ALL_BUILDING_TYPE_REQUEST = 'GET_ALL_BUILDING_TYPE_REQUEST';
export const GET_ALL_BUILDING_TYPE_SUCCESS = 'GET_ALL_BUILDING_TYPE_SUCCESS';
export const GET_ALL_BUILDING_TYPE_ERROR = 'GET_ALL_BUILDING_TYPE_ERROR';

export const DELETE_BUILDING_TYPE_REQUEST = 'DELETE_BUILDING_TYPE_REQUEST';
export const DELETE_BUILDING_TYPE_SUCCESS = 'DELETE_BUILDING_TYPE_SUCCESS';
export const DELETE_BUILDING_TYPE_ERROR = 'DELETE_BUILDING_TYPE_ERROR';

export const CREATE_JOB_SCOPE_REQUEST = 'CREATE_JOB_SCOPE_REQUEST';
export const CREATE_JOB_SCOPE_SUCCESS = 'CREATE_JOB_SCOPE_SUCCESS';
export const CREATE_JOB_SCOPE_ERROR = 'CREATE_JOB_SCOPE_ERROR';

export const UPDATE_JOB_SCOPE_REQUEST = 'UPDATE_JOB_SCOPE_REQUEST';
export const UPDATE_JOB_SCOPE_SUCCESS = 'UPDATE_JOB_SCOPE_SUCCESS';
export const UPDATE_JOB_SCOPE_ERROR = 'UPDATE_JOB_SCOPE_ERROR';

export const GET_ALL_JOB_SCOPE_REQUEST = 'GET_ALL_JOB_SCOPE_REQUEST';
export const GET_ALL_JOB_SCOPE_SUCCESS = 'GET_ALL_JOB_SCOPE_SUCCESS';
export const GET_ALL_JOB_SCOPE_ERROR = 'GET_ALL_JOB_SCOPE_ERROR';

export const DELETE_JOB_SCOPE_REQUEST = 'DELETE_JOB_SCOPE_REQUEST';
export const DELETE_JOB_SCOPE_SUCCESS = 'DELETE_JOB_SCOPE_SUCCESS';
export const DELETE_JOB_SCOPE_ERROR = 'DELETE_JOB_SCOPE_ERROR';

export const CREATE_REASON_CODE_REQUEST = 'CREATE_REASON_CODE_REQUEST';
export const CREATE_REASON_CODE_SUCCESS = 'CREATE_REASON_CODE_SUCCESS';
export const CREATE_REASON_CODE_ERROR = 'CREATE_REASON_CODE_ERROR';

export const UPDATE_REASON_CODE_REQUEST = 'UPDATE_REASON_CODE_REQUEST';
export const UPDATE_REASON_CODE_SUCCESS = 'UPDATE_REASON_CODE_SUCCESS';
export const UPDATE_REASON_CODE_ERROR = 'UPDATE_REASON_CODE_ERROR';

export const GET_ALL_REASON_CODE_REQUEST = 'GET_ALL_REASON_CODE_REQUEST';
export const GET_ALL_REASON_CODE_SUCCESS = 'GET_ALL_REASON_CODE_SUCCESS';
export const GET_ALL_REASON_CODE_ERROR = 'GET_ALL_REASON_CODE_ERROR';

export const DELETE_REASON_CODE_REQUEST = 'DELETE_REASON_CODE_REQUEST';
export const DELETE_REASON_CODE_SUCCESS = 'DELETE_REASON_CODE_SUCCESS';
export const DELETE_REASON_CODE_ERROR = 'DELETE_REASON_CODE_ERROR';

export const CREATE_NEGOTIATING_METHOD_REQUEST = 'CREATE_NEGOTIATING_METHOD_REQUEST';
export const CREATE_NEGOTIATING_METHOD_SUCCESS = 'CREATE_NEGOTIATING_METHOD_SUCCESS';
export const CREATE_NEGOTIATING_METHOD_ERROR = 'CREATE_NEGOTIATING_METHOD_ERROR';

export const UPDATE_NEGOTIATING_METHOD_REQUEST = 'UPDATE_NEGOTIATING_METHOD_REQUEST';
export const UPDATE_NEGOTIATING_METHOD_SUCCESS = 'UPDATE_NEGOTIATING_METHOD_SUCCESS';
export const UPDATE_NEGOTIATING_METHOD_ERROR = 'UPDATE_NEGOTIATING_METHOD_ERROR';

export const GET_ALL_NEGOTIATING_METHOD_REQUEST = 'GET_ALL_NEGOTIATING_METHOD_REQUEST';
export const GET_ALL_NEGOTIATING_METHOD_SUCCESS = 'GET_ALL_NEGOTIATING_METHOD_SUCCESS';
export const GET_ALL_NEGOTIATING_METHOD_ERROR = 'GET_ALL_NEGOTIATING_METHOD_ERROR';

export const DELETE_NEGOTIATING_METHOD_REQUEST = 'DELETE_NEGOTIATING_METHOD_REQUEST';
export const DELETE_NEGOTIATING_METHOD_SUCCESS = 'DELETE_NEGOTIATING_METHOD_SUCCESS';
export const DELETE_NEGOTIATING_METHOD_ERROR = 'DELETE_NEGOTIATING_METHOD_ERROR';

export const CREATE_COMPANY_TYPES_REQUEST = 'CREATE_COMPANY_TYPES_REQUEST';
export const CREATE_COMPANY_TYPES_SUCCESS = 'CREATE_COMPANY_TYPES_SUCCESS';
export const CREATE_COMPANY_TYPES_ERROR = 'CREATE_COMPANY_TYPES_ERROR';

export const UPDATE_COMPANY_TYPES_REQUEST = 'UPDATE_COMPANY_TYPES_REQUEST';
export const UPDATE_COMPANY_TYPES_SUCCESS = 'UPDATE_COMPANY_TYPES_SUCCESS';
export const UPDATE_COMPANY_TYPES_ERROR = 'UPDATE_COMPANY_TYPES_ERROR';

export const GET_ALL_COMPANY_TYPES_REQUEST = 'GET_ALL_COMPANY_TYPES_REQUEST';
export const GET_ALL_COMPANY_TYPES_SUCCESS = 'GET_ALL_COMPANY_TYPES_SUCCESS';
export const GET_ALL_COMPANY_TYPES_ERROR = 'GET_ALL_COMPANY_TYPES_ERROR';

export const DELETE_COMPANY_TYPES_REQUEST = 'DELETE_COMPANY_TYPES_REQUEST';
export const DELETE_COMPANY_TYPES_SUCCESS = 'DELETE_COMPANY_TYPES_SUCCESS';
export const DELETE_COMPANY_TYPES_ERROR = 'DELETE_COMPANY_TYPES_ERROR';

export const DELETE_OVER_HEADS_REQUEST = 'DELETE_OVER_HEADS_REQUEST';
export const DELETE_OVER_HEADS_SUCCESS = 'DELETE_OVER_HEADS_SUCCESS';
export const DELETE_OVER_HEADS_ERROR = 'DELETE_OVER_HEADS_ERROR';

export const CREATE_JOB_STATUS_REQUEST = 'CREATE_JOB_STATUS_REQUEST';
export const CREATE_JOB_STATUS_SUCCESS = 'CREATE_JOB_STATUS_SUCCESS';
export const CREATE_JOB_STATUS_ERROR = 'CREATE_JOB_STATUS_ERROR';

export const UPDATE_JOB_STATUS_REQUEST = 'UPDATE_JOB_STATUS_REQUEST';
export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_ERROR = 'UPDATE_JOB_STATUS_ERROR';

export const GET_ALL_JOB_STATUS_REQUEST = 'GET_ALL_JOB_STATUS_REQUEST';
export const GET_ALL_JOB_STATUS_SUCCESS = 'GET_ALL_JOB_STATUS_SUCCESS';
export const GET_ALL_JOB_STATUS_ERROR = 'GET_ALL_JOB_STATUS_ERROR';

export const LIST_IMPORTABLE_JOB_STATUS_REQUEST = 'LIST_IMPORTABLE_JOB_STATUS_REQUEST';
export const LIST_IMPORTABLE_JOB_STATUS_SUCCESS = 'LIST_IMPORTABLE_JOB_STATUS_SUCCESS';
export const LIST_IMPORTABLE_JOB_STATUS_ERROR = 'LIST_IMPORTABLE_JOB_STATUS_ERROR';

export const DELETE_JOB_STATUS_REQUEST = 'DELETE_JOB_STATUS_REQUEST';
export const DELETE_JOB_STATUS_SUCCESS = 'DELETE_JOB_STATUS_SUCCESS';
export const DELETE_JOB_STATUS_ERROR = 'DELETE_JOB_STATUS_ERROR';

export const CREATE_JOB_TYPES_REQUEST = 'CREATE_JOB_TYPES_REQUEST';
export const CREATE_JOB_TYPES_SUCCESS = 'CREATE_JOB_TYPES_SUCCESS';
export const CREATE_JOB_TYPES_ERROR = 'CREATE_JOB_TYPES_ERROR';

export const UPDATE_JOB_TYPES_REQUEST = 'UPDATE_JOB_TYPES_REQUEST';
export const UPDATE_JOB_TYPES_SUCCESS = 'UPDATE_JOB_TYPES_SUCCESS';
export const UPDATE_JOB_TYPES_ERROR = 'UPDATE_JOB_TYPES_ERROR';

export const GET_ALL_JOB_TYPES_REQUEST = 'GET_ALL_JOB_TYPES_REQUEST';
export const GET_ALL_JOB_TYPES_SUCCESS = 'GET_ALL_JOB_TYPES_SUCCESS';
export const GET_ALL_JOB_TYPES_ERROR = 'GET_ALL_JOB_TYPES_ERROR';

export const DELETE_JOB_TYPES_REQUEST = 'DELETE_JOB_TYPES_REQUEST';
export const DELETE_JOB_TYPES_SUCCESS = 'DELETE_JOB_TYPES_SUCCESS';
export const DELETE_JOB_TYPES_ERROR = 'DELETE_JOB_TYPES_ERROR';

export const GET_EXISTING_CONTACT_REQUEST = 'GET_EXISTING_CONTACT_REQUEST';
export const GET_EXISTING_CONTACT_SUCCESS = 'GET_EXISTING_CONTACT_SUCCESS';
export const GET_EXISTING_CONTACT_ERROR = 'GET_EXISTING_CONTACT_ERROR';

export const GET_ALL_CONTACT_REQUEST = 'GET_ALL_CONTACT_REQUEST';
export const GET_ALL_CONTACT_SUCCESS = 'GET_ALL_CONTACT_SUCCESS';
export const GET_ALL_INTERNAL_CONTACT_SUCCESS = 'GET_ALL_INTERNAL_CONTACT_SUCCESS';
export const GET_ALL_CONTACT_SUCCESS_NOT_UPDATED = 'GET_ALL_CONTACT_SUCCESS_NOT_UPDATED';
export const GET_ALL_CONTACT_ERROR = 'GET_ALL_CONTACT_ERROR';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const RESEND_INVITE_REQUEST = 'RESEND_INVITE_REQUEST';
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_ERROR = 'RESEND_INVITE_ERROR';

export const CANCEL_INVITE_REQUEST = 'CANCEL_INVITE_REQUEST';
export const CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS';
export const CANCEL_INVITE_ERROR = 'CANCEL_INVITE_ERROR';

export const CREATE_MULTIPLE_CONTACT_REQUEST = 'CREATE_MULTIPLE_CONTACT_REQUEST';
export const CREATE_MULTIPLE_CONTACT_SUCCESS = 'CREATE_MULTIPLE_CONTACT_SUCCESS';
export const CREATE_MULTIPLE_CONTACT_ERROR = 'CREATE_MULTIPLE_CONTACT_ERROR';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

export const GET_CONTACT_DETAILS_REQUEST = 'GET_CONTACT_DETAILS_REQUEST';
export const GET_CONTACT_DETAILS_SUCCESS = 'GET_CONTACT_DETAILS_SUCCESS';
export const GET_CONTACT_DETAILS_ERROR = 'GET_CONTACT_DETAILS_ERROR';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const GET_EXISTING_PROJECT_REQUEST = 'GET_EXISTING_PROJECT_REQUEST';
export const GET_EXISTING_PROJECT_SUCCESS = 'GET_EXISTING_PROJECT_SUCCESS';
export const GET_EXISTING_PROJECT_ERROR = 'GET_EXISTING_PROJECT_ERROR';

export const GET_ALL_PROJECT_REQUEST = 'GET_ALL_PROJECT_REQUEST';
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_ERROR = 'GET_ALL_PROJECT_ERROR';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const GET_ALL_COMPANY_USER_REQUEST = 'GET_ALL_COMPANY_USER_REQUEST';
export const GET_ALL_COMPANY_USER_SUCCESS = 'GET_ALL_COMPANY_USER_SUCCESS';
export const GET_ALL_COMPANY_USER_ERROR = 'GET_ALL_COMPANY_USER_ERROR';

export const GET_ALL_PROJECT_TASKS_REQUEST = 'GET_ALL_PROJECT_TASKS_REQUEST';
export const GET_ALL_PROJECT_TASKS_SUCCESS = 'GET_ALL_PROJECT_TASKS_SUCCESS';
export const GET_ALL_PROJECT_TASKS_ERROR = 'GET_ALL_PROJECT_TASKS_ERROR';

export const CREATE_PROJECT_TASKS_REQUEST = 'CREATE_PROJECT_TASKS_REQUEST';
export const CREATE_PROJECT_TASKS_SUCCESS = 'CREATE_PROJECT_TASKS_SUCCESS';
export const CREATE_PROJECT_TASKS_ERROR = 'CREATE_PROJECT_TASKS_ERROR';

export const UPDATE_PROJECT_TASKS_REQUEST = 'UPDATE_PROJECT_TASKS_REQUEST';
export const UPDATE_PROJECT_TASKS_SUCCESS = 'UPDATE_PROJECT_TASKS_SUCCESS';
export const UPDATE_PROJECT_TASKS_ERROR = 'UPDATE_PROJECT_TASKS_ERROR';

export const DELETE_PROJECT_TASKS_REQUEST = 'DELETE_PROJECT_TASKS_REQUEST';
export const DELETE_PROJECT_TASKS_SUCCESS = 'DELETE_PROJECT_TASKS_SUCCESS';
export const DELETE_PROJECT_TASKS_ERROR = 'DELETE_PROJECT_TASKS_ERROR';

export const GET_ALL_PROJECT_LISTS_REQUEST = 'GET_ALL_PROJECT_LISTS_REQUEST';
export const GET_ALL_PROJECT_LISTS_SUCCESS = 'GET_ALL_PROJECT_LISTS_SUCCESS';
export const GET_ALL_PROJECT_LISTS_ERROR = 'GET_ALL_PROJECT_LISTS_ERROR';

export const GET_ALL_PROJECT_STATUS_REQUEST = 'GET_ALL_PROJECT_STATUS_REQUEST';
export const GET_ALL_PROJECT_STATUS_SUCCESS = 'GET_ALL_PROJECT_STATUS_SUCCESS';
export const GET_ALL_PROJECT_STATUS_ERROR = 'GET_ALL_PROJECT_STATUS_ERROR';

export const UPDATE_PROJECT_STATUS_REQUEST = 'UPDATE_PROJECT_STATUS_REQUEST';
export const UPDATE_PROJECT_STATUS_SUCCESS = 'UPDATE_PROJECT_STATUS_SUCCESS';
export const UPDATE_PROJECT_STATUS_ERROR = 'UPDATE_PROJECT_STATUS_ERROR';

export const GET_ALL_MODULE_TYPE_REQUEST = 'GET_ALL_MODULE_TYPE_REQUEST';
export const GET_ALL_MODULE_TYPE_SUCCESS = 'GET_ALL_MODULE_TYPE_SUCCESS';
export const GET_ALL_MODULE_TYPE_ERROR = 'GET_ALL_MODULE_TYPE_ERROR';

export const CREATE_MODULE_TYPE_REQUEST = 'CREATE_MODULE_TYPE_REQUEST';
export const CREATE_MODULE_TYPE_SUCCESS = 'CREATE_MODULE_TYPE_SUCCESS';
export const CREATE_MODULE_TYPE_ERROR = 'CREATE_MODULE_TYPE_ERROR';

export const UPDATE_MODULE_TYPE_REQUEST = 'UPDATE_MODULE_TYPE_REQUEST';
export const UPDATE_MODULE_TYPE_SUCCESS = 'UPDATE_MODULE_TYPE_SUCCESS';
export const UPDATE_MODULE_TYPE_ERROR = 'UPDATE_MODULE_TYPE_ERROR';

export const DELETE_MODULE_TYPE_REQUEST = 'DELETE_MODULE_TYPE_REQUEST';
export const DELETE_MODULE_TYPE_SUCCESS = 'DELETE_MODULE_TYPE_SUCCESS';
export const DELETE_MODULE_TYPE_ERROR = 'DELETE_MODULE_TYPE_ERROR';

export const GET_ALL_NOTES_REQUEST = 'GET_ALL_NOTES_REQUEST';
export const GET_ALL_NOTES_SUCCESS = 'GET_ALL_NOTES_SUCCESS';
export const GET_ALL_NOTES_ERROR = 'GET_ALL_NOTES_ERROR';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const PIN_NOTE_REQUEST = 'PIN_NOTE_REQUEST';
export const PIN_NOTE_SUCCESS = 'PIN_NOTE_SUCCESS';
export const PIN_NOTE_ERROR = 'PIN_NOTE_ERROR';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_ERROR = 'DELETE_NOTES_ERROR';

export const GET_ALL_ASSIGN_MODULE_REQUEST = 'GET_ALL_ASSIGN_MODULE_REQUEST';
export const GET_ALL_ASSIGN_MODULE_SUCCESS = 'GET_ALL_ASSIGN_MODULE_SUCCESS';
export const GET_ALL_ASSIGN_MODULE_ERROR = 'GET_ALL_ASSIGN_MODULE_ERROR';

export const CREATE_ASSIGN_MODULE_REQUEST = 'CREATE_ASSIGN_MODULE_REQUEST';
export const CREATE_ASSIGN_MODULE_SUCCESS = 'CREATE_ASSIGN_MODULE_SUCCESS';
export const CREATE_ASSIGN_MODULE_ERROR = 'CREATE_ASSIGN_MODULE_ERROR';

export const UPDATE_ASSIGN_MODULE_REQUEST = 'UPDATE_ASSIGN_MODULE_REQUEST';
export const UPDATE_ASSIGN_MODULE_SUCCESS = 'UPDATE_ASSIGN_MODULE_SUCCESS';
export const UPDATE_ASSIGN_MODULE_ERROR = 'UPDATE_ASSIGN_MODULE_ERROR';

export const DELETE_ASSIGN_MODULE_REQUEST = 'DELETE_ASSIGN_MODULE_REQUEST';
export const DELETE_ASSIGN_MODULE_SUCCESS = 'DELETE_ASSIGN_MODULE_SUCCESS';
export const DELETE_ASSIGN_MODULE_ERROR = 'DELETE_ASSIGN_MODULE_ERROR';

export const GET_PROJECT_HISTORY_QUICK_REQUEST = 'GET_PROJECT_HISTORY_QUICK_REQUEST';
export const GET_PROJECT_HISTORY_QUICK_SUCCESS = 'GET_PROJECT_HISTORY_QUICK_SUCCESS';
export const GET_PROJECT_HISTORY_QUICK_ERROR = 'GET_PROJECT_HISTORY_QUICK_ERROR';

export const UPDATE_CHANGE_ORDER_REQUEST = 'UPDATE_CHANGE_ORDER_REQUEST';
export const UPDATE_CHANGE_ORDER_SUCCESS = 'UPDATE_CHANGE_ORDER_SUCCESS';
export const UPDATE_CHANGE_ORDER_ERROR = 'UPDATE_CHANGE_ORDER_ERROR';

export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_REQUEST =
  'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_SUCCESS =
  'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const CREATE_ASSIGN_PROJECT_TO_COMPANY_REQUEST = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const CREATE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const CREATE_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const DELETE_ASSIGN_PROJECT_TO_COMPANY_REQUEST = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const DELETE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const DELETE_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const DELETE_CHANGE_PROJECT_TO_PEOPLE_REQUEST = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_REQUEST';
export const DELETE_CHANGE_PROJECT_TO_PEOPLE_SUCCESS = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_SUCCESS';
export const DELETE_CHANGE_PROJECT_TO_PEOPLE_ERROR = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_ERROR';

export const GET_MODULE_PERMISSION_REQUEST = 'GET_MODULE_PERMISSION_REQUEST';
export const GET_MODULE_PERMISSION_SUCCESS = 'GET_MODULE_PERMISSION_SUCCESS';
export const GET_MODULE_PERMISSION_ERROR = 'GET_MODULE_PERMISSION_ERROR';

export const MOVE_CONTACT_TO_EMP_REQUEST = 'MOVE_CONTACT_TO_EMP_REQUEST';
export const MOVE_CONTACT_TO_EMP_SUCCESS = 'MOVE_CONTACT_TO_EMP_SUCCESS';
export const MOVE_CONTACT_TO_EMP_ERROR = 'MOVE_CONTACT_TO_EMP_ERROR';

export const GET_ALL_CPOST_ROLE_REQUEST = 'GET_ALL_CPOST_ROLE_REQUEST';
export const GET_ALL_CPOST_ROLE_SUCCESS = 'GET_ALL_CPOST_ROLE_SUCCESS';
export const GET_ALL_CPOST_ROLE_ERROR = 'GET_ALL_CPOST_ROLE_ERROR';

export const GET_USER_CPOST_ROLE_REQUEST = 'GET_USER_CPOST_ROLE_REQUEST';
export const GET_USER_CPOST_ROLE_SUCCESS = 'GET_USER_CPOST_ROLE_SUCCESS';
export const GET_USER_CPOST_ROLE_ERROR = 'GET_USER_CPOST_ROLE_ERROR';

export const CREATE_CPOST_ROLE_REQUEST = 'CREATE_CPOST_ROLE_REQUEST';
export const CREATE_CPOST_ROLE_SUCCESS = 'CREATE_CPOST_ROLE_SUCCESS';
export const CREATE_CPOST_ROLE_ERROR = 'CREATE_CPOST_ROLE_ERROR';

export const UPDATE_CPOST_ROLE_REQUEST = 'UPDATE_CPOST_ROLE_REQUEST';
export const UPDATE_CPOST_ROLE_SUCCESS = 'UPDATE_CPOST_ROLE_SUCCESS';
export const UPDATE_CPOST_ROLE_ERROR = 'UPDATE_CPOST_ROLE_ERROR';

export const GET_PERMISSIONS_BY_ROLE_REQUEST = 'GET_PERMISSIONS_BY_ROLE_REQUEST';
export const GET_PERMISSIONS_BY_ROLE_SUCCESS = 'GET_PERMISSIONS_BY_ROLE_SUCCESS';
export const GET_PERMISSIONS_BY_ROLE_ERROR = 'GET_PERMISSIONS_BY_ROLE_ERROR';

export const GET_BID_HISTORY_REPORT_REQUEST = 'GET_BID_HISTORY_REPORT_REQUEST';
export const GET_BID_HISTORY_REPORT_SUCCESS = 'GET_BID_HISTORY_REPORT_SUCCESS';
export const GET_BID_HISTORY_REPORT_ERROR = 'GET_BID_HISTORY_REPORT_ERROR';

export const GET_BID_HISTORY_PREF_REQUEST = 'GET_BID_HISTORY_PREF_REQUEST';
export const GET_BID_HISTORY_PREF_SUCCESS = 'GET_BID_HISTORY_PREF_SUCCESS';
export const GET_BID_HISTORY_PREF_ERROR = 'GET_BID_HISTORY_PREF_ERROR';

export const GET_BID_DUE_REPORT_REQUEST = 'GET_BID_DUE_REPORT_REQUEST';
export const GET_BID_DUE_REPORT_SUCCESS = 'GET_BID_DUE_REPORT_SUCCESS';
export const GET_BID_DUE_REPORT_ERROR = 'GET_BID_DUE_REPORT_ERROR';

export const GET_BID_DUE_PREF_REQUEST = 'GET_BID_DUE_PREF_REQUEST';
export const GET_BID_DUE_PREF_SUCCESS = 'GET_BID_DUE_PREF_SUCCESS';
export const GET_BID_DUE_PREF_ERROR = 'GET_BID_DUE_PREF_ERROR';

export const GET_LEAD_SOURCE_REPORT_REQUEST = 'GET_LEAD_SOURCE_REPORT_REQUEST';
export const GET_LEAD_SOURCE_REPORT_SUCCESS = 'GET_LEAD_SOURCE_REPORT_SUCCESS';
export const GET_LEAD_SOURCE_REPORT_ERROR = 'GET_LEAD_SOURCE_REPORT_ERROR';

export const GET_LEAD_SOURCE_PREF_REQUEST = 'GET_LEAD_SOURCE_PREF_REQUEST';
export const GET_LEAD_SOURCE_PREF_SUCCESS = 'GET_LEAD_SOURCE_PREF_SUCCESS';
export const GET_LEAD_SOURCE_PREF_ERROR = 'GET_LEAD_SOURCE_PREF_ERROR';

export const GET_PROJECT_LEAD_REPORT_REQUEST = 'GET_PROJECT_LEAD_REPORT_REQUEST';
export const GET_PROJECT_LEAD_REPORT_SUCCESS = 'GET_PROJECT_LEAD_REPORT_SUCCESS';
export const GET_PROJECT_LEAD_REPORT_ERROR = 'GET_PROJECT_LEAD_REPORT_ERROR';

export const GET_PROJECT_LEAD_PREF_REQUEST = 'GET_PROJECT_LEAD_PREF_REQUEST';
export const GET_PROJECT_LEAD_PREF_SUCCESS = 'GET_PROJECT_LEAD_PREF_SUCCESS';
export const GET_PROJECT_LEAD_PREF_ERROR = 'GET_PROJECT_LEAD_PREF_ERROR';

export const GET_PROFIT_PER_WEEK_REPORT_REQUEST = 'GET_PROFIT_PER_WEEK_REPORT_REQUEST';
export const GET_PROFIT_PER_WEEK__REPORT_SUCCESS = 'GET_PROFIT_PER_WEEK__REPORT_SUCCESS';
export const GET_PROFIT_PER_WEEK__REPORT_ERROR = 'GET_PROFIT_PER_WEEK__REPORT_ERROR';

export const GET_ALL_HOME_REQUEST = 'GET_ALL_HOME_REQUEST';
export const GET_ALL_HOME_SUCCESS = 'GET_ALL_HOME_SUCCESS';
export const GET_ALL_HOME_ERROR = 'GET_ALL_HOME_ERROR';

export const GET_ALL_USER_LIST_REQUEST = 'GET_ALL_USER_LIST_REQUEST';
export const GET_ALL_USER_LIST_SUCCESS = 'GET_ALL_USER_LIST_SUCCESS';
export const GET_ALL_USER_LIST_ERROR = 'GET_ALL_USER_LIST_ERROR';

export const GET_EXISTING_PROFILE_REQUEST = 'GET_EXISTING_PROFILE_REQUEST';
export const GET_EXISTING_PROFILE_SUCCESS = 'GET_EXISTING_PROFILE_SUCCESS';
export const GET_EXISTING_PROFILE_ERROR = 'GET_EXISTING_PROFILE_ERROR';

export const GET_EXISTING_ACCOUNT_SETTINGS_REQUEST = 'GET_EXISTING_ACCOUNT_SETTINGS_REQUEST';
export const GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS = 'GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS';
export const GET_EXISTING_ACCOUNT_SETTINGS_ERROR = 'GET_EXISTING_ACCOUNT_SETTINGS_ERROR';

export const UPDATE_ACCOUNT_SETTINGS_REQUEST = 'UPDATE_ACCOUNT_SETTINGS_REQUEST';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_ERROR = 'UPDATE_ACCOUNT_SETTINGS_ERROR';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const GET_COMPANY_ROLE_MATRIX_REQUEST = 'GET_COMPANY_ROLE_MATRIX_REQUEST';
export const GET_COMPANY_ROLE_MATRIX_SUCCESS =
  'GET_COMPANY_ROLE_MATRIX_SUCCESS';
export const GET_COMPANY_ROLE_MATRIX_ERROR = 'GET_COMPANY_ROLE_MATRIX_ERROR';

export const CREATE_CHANGE_ORDER_STATUS_REQUEST = 'UPDATE_CHANGE_ORDER_STATUS_REQUEST';
export const CREATE_CHANGE_ORDER_STATUS_SUCCESS = 'UPDATE_CHANGE_ORDER_STATUS_SUCCESS';
export const CREATE_CHANGE_ORDER_STATUS_ERROR = 'UPDATE_CHANGE_ORDER_STATUS_ERROR';

export const ADD_CONTACT_COMPANY_REQUEST = 'ADD_CONTACT_COMPANY_REQUEST';
export const ADD_CONTACT_COMPANY_SUCCESS = 'ADD_CONTACT_COMPANY_SUCCESS';
export const ADD_CONTACT_COMPANY_ERROR = 'ADD_CONTACT_COMPANY_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const GET_ALL_EMAIL_REQUEST = 'GET_ALL_EMAIL_REQUEST';
export const GET_ALL_EMAIL_SUCCESS = 'GET_ALL_EMAIL_SUCCESS';
export const GET_ALL_EMAIL_ERROR = 'GET_ALL_EMAIL_ERROR';

export const CREATE_EMAIL_REQUEST = 'CREATE_EMAIL_REQUEST';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_ERROR = 'CREATE_EMAIL_ERROR';

export const GET_ALL_SCHEDULE_REQUEST = 'GET_ALL_SCHEDULE_REQUEST';
export const GET_ALL_SCHEDULE_SUCCESS = 'GET_ALL_SCHEDULE_SUCCESS';
export const GET_ALL_SCHEDULE_ERROR = 'GET_ALL_SCHEDULE_ERROR';

export const CREATE_SCHEDULE_REQUEST = 'CREATE_SCHEDULE_REQUEST';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_ERROR = 'CREATE_SCHEDULE_ERROR';

export const UPDATE_LEAD_SECTION_REQUEST = 'UPDATE_LEAD_SECTION_REQUEST';
export const UPDATE_LEAD_SECTION_SUCCESS = 'UPDATE_LEAD_SECTION_SUCCESS';
export const UPDATE_LEAD_SECTION_ERROR = 'UPDATE_LEAD_SECTION_ERROR';

export const GET_ALL_CUSTOM_FIELD_REQUEST = 'GET_ALL_CUSTOM_FIELD_REQUEST';
export const GET_ALL_CUSTOM_FIELD_SUCCESS = 'GET_ALL_CUSTOM_FIELD_SUCCESS';
export const GET_ALL_CUSTOM_FIELD_ERROR = 'GET_ALL_CUSTOM_FIELD_ERROR';

export const CREATE_CUSTOM_FIELD_REQUEST = 'CREATE_CUSTOM_FIELD_REQUEST';
export const CREATE_CUSTOM_FIELD_SUCCESS = 'CREATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_CUSTOM_FIELD_ERROR = 'CREATE_CUSTOM_FIELD_ERROR';

export const UPDATE_CUSTOM_FIELD_REQUEST = 'UPDATE_CUSTOM_FIELD_REQUEST';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS';
export const UPDATE_CUSTOM_FIELD_ERROR = 'UPDATE_CUSTOM_FIELD_ERROR';

export const DELETE_CUSTOM_FIELD_REQUEST = 'DELETE_CUSTOM_FIELD_REQUEST';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_ERROR = 'DELETE_CUSTOM_FIELD_ERROR';

export const GET_LAST_JOB_NUM_REQUEST = 'GET_LAST_JOB_NUM_REQUEST';
export const GET_LAST_JOB_NUM_SUCCESS = 'GET_LAST_JOB_NUM_SUCCESS';
export const GET_LAST_JOB_NUM_ERROR = 'GET_LAST_JOB_NUM_ERROR';

export const GET_BID_PENDING_REPORT_REQUEST = 'GET_BID_PENDING_REPORT_REQUEST';
export const GET_BID_PENDING_REPORT_SUCCESS = 'GET_BID_PENDING_REPORT_SUCCESS';
export const GET_BID_PENDING_REPORT_ERROR = 'GET_BID_PENDING_REPORT_ERROR';

export const GET_BID_PENDING_PREF_REQUEST = 'GET_BID_PENDING_PREF_REQUEST';
export const GET_BID_PENDING_PREF_SUCCESS = 'GET_BID_PENDING_PREF_SUCCESS';
export const GET_BID_PENDING_PREF_ERROR = 'GET_BID_PENDING_PREF_ERROR';

export const GET_EMP_ROSTER_REPORT_REQUEST = 'GET_EMP_ROSTER_REPORT_REQUEST';
export const GET_EMP_ROSTER_REPORT_SUCCESS = 'GET_EMP_ROSTER_REPORT_SUCCESS';
export const GET_EMP_ROSTER_REPORT_ERROR = 'GET_EMP_ROSTER_REPORT_ERROR';

export const GET_EMP_ROSTER_PREF_REQUEST = 'GET_EMP_ROSTER_PREF_REQUEST';
export const GET_EMP_ROSTER_PREF_SUCCESS = 'GET_EMP_ROSTER_PREF_SUCCESS';
export const GET_EMP_ROSTER_PREF_ERROR = 'GET_EMP_ROSTER_PREF_ERROR';

export const GET_ACTIVE_PROJECT_REPORT_REQUEST = 'GET_ACTIVE_PROJECT_REPORT_REQUEST';
export const GET_ACTIVE_PROJECT_REPORT_SUCCESS = 'GET_ACTIVE_PROJECT_REPORT_SUCCESS';
export const GET_ACTIVE_PROJECT_REPORT_ERROR = 'GET_ACTIVE_PROJECT_REPORT_ERROR';

export const GET_ACTIVE_PROJECT_PREF_REQUEST = 'GET_ACTIVE_PROJECT_PREF_REQUEST';
export const GET_ACTIVE_PROJECT_PREF_SUCCESS = 'GET_ACTIVE_PROJECT_PREF_SUCCESS';
export const GET_ACTIVE_PROJECT_PREF_ERROR = 'GET_ACTIVE_PROJECT_PREF_ERROR';

export const GET_ADDRESS_LABEL_REPORT_REQUEST = 'GET_ADDRESS_LABEL_REPORT_REQUEST';
export const GET_ADDRESS_LABEL_REPORT_SUCCESS = 'GET_ADDRESS_LABEL_REPORT_SUCCESS';
export const GET_ADDRESS_LABEL_REPORT_ERROR = 'GET_ADDRESS_LABEL_REPORT_ERROR';

export const GET_ADDRESS_LABEL_PREF_REQUEST = 'GET_ADDRESS_LABEL_PREF_REQUEST';
export const GET_ADDRESS_LABEL_PREF_SUCCESS = 'GET_ADDRESS_LABEL_PREF_SUCCESS';
export const GET_ADDRESS_LABEL_PREF_ERROR = 'GET_ADDRESS_LABEL_PREF_ERROR';

export const GET_TIME_LINE_REQUEST = 'GET_TIME_LINE_REQUEST';
export const GET_TIME_LINE_SUCCESS = 'GET_TIME_LINE_SUCCESS';
export const GET_TIME_LINE_ERROR = 'GET_TIME_LINE_ERROR';

export const GET_CONTACT_LIST_REPORT_REQUEST = 'GET_CONTACT_LIST_REPORT_REQUEST';
export const GET_CONTACT_LIST_REPORT_SUCCESS = 'GET_CONTACT_LIST_REPORT_SUCCESS';
export const GET_CONTACT_LIST_REPORT_ERROR = 'GET_CONTACT_LIST_REPORT_ERROR';

export const GET_CONTACT_LIST_PREF_REQUEST = 'GET_CONTACT_LIST_PREF_REQUEST';
export const GET_CONTACT_LIST_PREF_SUCCESS = 'GET_CONTACT_LIST_PREF_SUCCESS';
export const GET_CONTACT_LIST_PREF_ERROR = 'GET_CONTACT_LIST_PREF_ERROR';

export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST =
  'GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST';
export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS =
  'GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS';
export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR = 'GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR';

export const GET_CUSTOMIZE_PROJECT_LIST_PREF_REQUEST =
  'GET_CUSTOMIZE_PROJECT_LIST_PREF_REQUEST';
export const GET_CUSTOMIZE_PROJECT_LIST_PREF_SUCCESS =
  'GET_CUSTOMIZE_PROJECT_LIST_PREF_SUCCESS';
export const GET_CUSTOMIZE_PROJECT_LIST_PREF_ERROR = 'GET_CUSTOMIZE_PROJECT_LIST_PREF_ERROR';

export const DELETE_EMAIL_REQUEST = 'DELETE_EMAIL_REQUEST';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';

export const PROMOTE_LEAD_REQUEST = 'PROMOTE_LEAD_REQUEST';
export const PROMOTE_LEAD_SUCCESS = 'PROMOTE_LEAD_SUCCESS';
export const PROMOTE_LEAD_ERROR = 'PROMOTE_LEAD_ERROR';

export const UPDATE_LEAD_STATUS_REQUEST = 'UPDATE_LEAD_STATUS_REQUEST';
export const UPDATE_LEAD_STATUS_SUCCESS = 'UPDATE_LEAD_STATUS_SUCCESS';
export const UPDATE_LEAD_STATUS_ERROR = 'UPDATE_LEAD_STATUS_ERROR';

export const GET_CLIENT_LIST_REPORT_REQUEST = 'GET_CLIENT_LIST_REPORT_REQUEST';
export const GET_CLIENT_LIST_REPORT_SUCCESS = 'GET_CLIENT_LIST_REPORT_SUCCESS';
export const GET_CLIENT_LIST_REPORT_ERROR = 'GET_CLIENT_LIST_REPORT_ERROR';

export const GET_COMPANY_LIST_REPORT_REQUEST = 'GET_COMPANY_LIST_REPORT_REQUEST';
export const GET_COMPANY_LIST_REPORT_SUCCESS = 'GET_COMPANY_LIST_REPORT_SUCCESS';
export const GET_COMPANY_LIST_REPORT_ERROR = 'GET_COMPANY_LIST_REPORT_ERROR';

export const GET_COMPANY_LIST_VIEW_PREF_REQUEST = 'GET_COMPANY_LIST_VIEW_PREF_REQUEST';
export const GET_COMPANY_LIST_VIEW_PREF_SUCCESS = 'GET_COMPANY_LIST_VIEW_PREF_SUCCESS';
export const GET_COMPANY_LIST_VIEW_PREF_ERROR = 'GET_COMPANY_LIST_VIEW_PREF_ERROR';

export const GET_COMPANY_JOB_LIST_REPORT_REQUEST = 'GET_COMPANY_JOB_LIST_REPORT_REQUEST';
export const GET_COMPANY_JOB_LIST_REPORT_SUCCESS = 'GET_COMPANY_JOB_LIST_REPORT_SUCCESS';
export const GET_COMPANY_JOB_LIST_REPORT_ERROR = 'GET_COMPANY_JOB_LIST_REPORT_ERROR';

export const GET_COMPANY_JOB_LIST_PREF_REQUEST = 'GET_COMPANY_JOB_LIST_PREF_REQUEST';
export const GET_COMPANY_JOB_LIST_PREF_SUCCESS = 'GET_COMPANY_JOB_LIST_PREF_SUCCESS';
export const GET_COMPANY_JOB_LIST_PREF_ERROR = 'GET_COMPANY_JOB_LIST_PREF_ERROR';

export const GET_CONTACT_JOB_LIST_REPORT_REQUEST = 'GET_CONTACT_JOB_LIST_REPORT_REQUEST';
export const GET_CONTACT_JOB_LIST_REPORT_SUCCESS = 'GET_CONTACT_JOB_LIST_REPORT_SUCCESS';
export const GET_CONTACT_JOB_LIST_REPORT_ERROR = 'GET_CONTACT_JOB_LIST_REPORT_ERROR';

export const GET_CONTACT_JOB_LIST_PREF_REQUEST = 'GET_CONTACT_JOB_LIST_PREF_REQUEST';
export const GET_CONTACT_JOB_LIST_PREF_SUCCESS = 'GET_CONTACT_JOB_LIST_PREF_SUCCESS';
export const GET_CONTACT_JOB_LIST_PREF_ERROR = 'GET_CONTACT_JOB_LIST_PREF_ERROR';

export const GET_NO_BID_REPORT_REQUEST = 'GET_NO_BID_REPORT_REQUEST';
export const GET_NO_BID_REPORT_SUCCESS = 'GET_NO_BID_REPORT_SUCCESS';
export const GET_NO_BID_REPORT_ERROR = 'GET_NO_BID_REPORT_ERROR';

export const GET_NO_BID_PREF_REQUEST = 'GET_NO_BID_PREF_REQUEST';
export const GET_NO_BID_PREF_SUCCESS = 'GET_NO_BID_PREF_SUCCESS';
export const GET_NO_BID_PREF_ERROR = 'GET_NO_BID_PREF_ERROR';

export const GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST =
  'GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST';
export const GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS =
  'GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS';
export const GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR = 'GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR';

export const GET_DELIVERED_REPORT_REQUEST = 'GET_DELIVERED_REPORT_REQUEST';
export const GET_DELIVERED_REPORT_SUCCESS = 'GET_DELIVERED_REPORT_SUCCESS';
export const GET_DELIVERED_REPORT_ERROR = 'GET_DELIVERED_REPORT_ERROR';

export const GET_DELIVERED_PREF_REQUEST = 'GET_DELIVERED_PREF_REQUEST';
export const GET_DELIVERED_PREF_SUCCESS = 'GET_DELIVERED_PREF_SUCCESS';
export const GET_DELIVERED_PREF_ERROR = 'GET_DELIVERED_PREF_ERROR';

export const GET_LIEN_PERIOD_REPORT_REQUEST = 'GET_LIEN_PERIOD_REPORT_REQUEST';
export const GET_LIEN_PERIOD_REPORT_SUCCESS = 'GET_LIEN_PERIOD_REPORT_SUCCESS';
export const GET_LIEN_PERIOD_REPORT_ERROR = 'GET_LIEN_PERIOD_REPORT_ERROR';

export const GET_LIEN_PERIOD_PREF_REQUEST = 'GET_LIEN_PERIOD_PREF_REQUEST';
export const GET_LIEN_PERIOD_PREF_SUCCESS = 'GET_LIEN_PERIOD_PREF_SUCCESS';
export const GET_LIEN_PERIOD_PREF_ERROR = 'GET_LIEN_PERIOD_PREF_ERROR';

export const GET_WARRANTY_STATUS_REPORT_REQUEST = 'GET_WARRANTY_STATUS_REPORT_REQUEST';
export const GET_WARRANTY_STATUS_REPORT_SUCCESS = 'GET_WARRANTY_STATUS_REPORT_SUCCESS';
export const GET_WARRANTY_STATUS_REPORT_ERROR = 'GET_WARRANTY_STATUS_REPORT_ERROR';

export const GET_WARRANTY_STATUS_PREF_REQUEST = 'GET_WARRANTY_STATUS_PREF_REQUEST';
export const GET_WARRANTY_STATUS_PREF_SUCCESS = 'GET_WARRANTY_STATUS_PREF_SUCCESS';
export const GET_WARRANTY_STATUS_PREF_ERROR = 'GET_WARRANTY_STATUS_PREF_ERROR';

export const SET_IMAGE_MASTER_REQUEST = 'SET_IMAGE_MASTER_REQUEST';
export const SET_IMAGE_MASTER_SUCCESS = 'SET_IMAGE_MASTER_SUCCESS';
export const SET_IMAGE_MASTER_ERROR = 'SET_IMAGE_MASTER_ERROR';

export const GET_FOLLOW_UP_PROJECT_REPORT_REQUEST = 'GET_FOLLOW_UP_PROJECT_REPORT_REQUEST';
export const GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS = 'GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS';
export const GET_FOLLOW_UP_PROJECT_REPORT_ERROR = 'GET_FOLLOW_UP_PROJECT_REPORT_ERROR';

export const GET_FOLLOW_UP_PROJECT_PREF_REQUEST = 'GET_FOLLOW_UP_PROJECT_PREF_REQUEST';
export const GET_FOLLOW_UP_PROJECT_PREF_SUCCESS = 'GET_FOLLOW_UP_PROJECT_PREF_SUCCESS';
export const GET_FOLLOW_UP_PROJECT_PREF_ERROR = 'GET_FOLLOW_UP_PROJECT_PREF_ERROR';

export const GET_LOST_JOB_REPORT_REQUEST = 'GET_LOST_JOB_REPORT_REQUEST';
export const GET_LOST_JOB_REPORT_SUCCESS = 'GET_LOST_JOB_REPORT_SUCCESS';
export const GET_LOST_JOB_REPORT_ERROR = 'GET_LOST_JOB_REPORT_ERROR';

export const GET_LOST_JOB_PREF_REQUEST = 'GET_LOST_JOB_PREF_REQUEST';
export const GET_LOST_JOB_PREF_SUCCESS = 'GET_LOST_JOB_PREF_SUCCESS';
export const GET_LOST_JOB_PREF_ERROR = 'GET_LOST_JOB_PREF_ERROR';

export const GET_PROFIT_PROJECTION_REPORT_REQUEST = 'GET_PROFIT_PROJECTION_REPORT_REQUEST';

export const GET_PROFIT_PROJECTION_REPORT_SUCCESS = 'GET_PROFIT_PROJECTION_REPORT_SUCCESS';

export const GET_PROFIT_PROJECTION_REPORT_ERROR = 'GET_PROFIT_PROJECTION_REPORT_ERROR';

export const GET_PROFIT_PROJECTION_FORECAST_REPORT_REQUEST =
  'GET_PROFIT_PROJECTION_FORECAST_REPORT_REQUEST';
export const GET_PROFIT_PROJECTION_REPORT_FORECAST_SUCCESS = 'GET_PROFIT_PROJECTION_REPORT_FORECAST_SUCCESS';

export const GET_PROFIT_PROJECTION_REPORT_FORECAST_ERROR = 'GET_PROFIT_PROJECTION_REPORT_FORECAST_ERROR';

export const GET_PROFIT_PROJECTION_PREF_REQUEST = 'GET_PROFIT_PROJECTION_PREF_REQUEST';
export const GET_PROFIT_PROJECTION_PREF_SUCCESS = 'GET_PROFIT_PROJECTION_PREF_SUCCESS';
export const GET_PROFIT_PROJECTION_PREF_ERROR = 'GET_PROFIT_PROJECTION_PREF_ERROR';

export const GET_WIP_PROJECT_LIST_REPORT_REQUEST = 'GET_WIP_PROJECT_LIST_REPORT_REQUEST';
export const GET_WIP_PROJECT_LIST_REPORT_SUCCESS = 'GET_WIP_PROJECT_LIST_REPORT_SUCCESS';
export const GET_WIP_PROJECT_LIST_REPORT_ERROR = 'GET_WIP_PROJECT_LIST_REPORT_ERROR';

export const UPDATE_WIP_PROJECT_LIST_REPORT_REQUEST = 'UPDATE_WIP_PROJECT_LIST_REPORT_REQUEST';
export const UPDATE_WIP_PROJECT_LIST_REPORT_SUCCESS = 'UPDATE_WIP_PROJECT_LIST_REPORT_SUCCESS';
export const UPDATE_WIP_PROJECT_LIST_REPORT_ERROR = 'UPDATE_WIP_PROJECT_LIST_REPORT_ERROR';

export const GET_CUSTOM_FIELD_REQUEST = 'GET_CUSTOM_FIELD_REQUEST';
export const GET_CUSTOM_FIELD_SUCCESS = 'GET_CUSTOM_FIELD_SUCCESS';
export const GET_CUSTOM_FIELD_ERROR = 'GET_CUSTOM_FIELD_ERROR';

export const IMPORT_MULTIPLE_CONTACT_REQUEST = 'IMPORT_MULTIPLE_CONTACT_REQUEST';
export const IMPORT_MULTIPLE_CONTACT_SUCCESS = 'IMPORT_MULTIPLE_CONTACT_SUCCESS';
export const IMPORT_MULTIPLE_CONTACT_ERROR = 'IMPORT_MULTIPLE_CONTACT_ERROR';

export const IMPORT_MULTIPLE_COMPANY_REQUEST = 'IMPORT_MULTIPLE_COMPANY_REQUEST';
export const IMPORT_MULTIPLE_COMPANY_SUCCESS = 'IMPORT_MULTIPLE_COMPANY_SUCCESS';
export const IMPORT_MULTIPLE_COMPANY_ERROR = 'IMPORT_MULTIPLE_COMPANY_ERROR';

export const UPLOAD_SHEET_DATA_REQUEST = 'UPLOAD_SHEET_DATA_REQUEST';
export const UPLOAD_SHEET_DATA_SUCCESS = 'UPLOAD_SHEET_DATA_SUCCESS';
export const UPLOAD_SHEET_DATA_ERROR = 'UPLOAD_SHEET_DATA_ERROR';

export const UPDATE_ONBOARDING_STATUS_REQUEST = 'UPDATE_ONBOARDING_STATUS_REQUEST';
export const UPDATE_ONBOARDING_STATUS_SUCCESS = 'UPDATE_ONBOARDING_STATUS_SUCCESS';
export const UPDATE_ONBOARDING_STATUS_ERROR = 'UPDATE_ONBOARDING_STATUS_ERROR';

export const SAVE_CUSTOM_FIELD_VALUE_REQUEST = 'SAVE_CUSTOM_FIELD_VALUE_REQUEST';
export const SAVE_CUSTOM_FIELD_VALUE_SUCCESS = 'SAVE_CUSTOM_FIELD_VALUE_SUCCESS';
export const SAVE_CUSTOM_FIELD_VALUE_ERROR = 'SAVE_CUSTOM_FIELD_VALUE_ERROR';

export const GET_HELP_REQUEST = 'GET_HELP_REQUEST';
export const GET_HELP_SUCCESS = 'GET_HELP_SUCCESS';
export const GET_HELP_ERROR = 'GET_HELP_ERROR';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export const ADD_HELP_REQUEST = 'ADD_HELP_REQUEST';
export const ADD_HELP_SUCCESS = 'ADD_HELP_SUCCESS';
export const ADD_HELP_ERROR = 'ADD_HELP_ERROR';

export const ADD_HELP_BY_FILE_UPLOAD_REQUEST = 'ADD_HELP_BY_FILE_UPLOAD_REQUEST';
export const ADD_HELP_BY_FILE_UPLOAD_SUCCESS = 'ADD_HELP_BY_FILE_UPLOAD_SUCCESS';
export const ADD_HELP_BY_FILE_UPLOAD_ERROR = 'ADD_HELP_BY_FILE_UPLOAD_ERROR';

export const UPDATE_HELP_REQUEST = 'UPDATE_HELP_REQUEST';
export const UPDATE_HELP_SUCCESS = 'UPDATE_HELP_SUCCESS';
export const UPDATE_HELP_ERROR = 'UPDATE_HELP_ERROR';

export const GET_PROJECT_HISTORY_DD_REQUEST = 'GET_PROJECT_HISTORY_DD_REQUEST';
export const GET_PROJECT_HISTORY_DD_SUCCESS = 'GET_PROJECT_HISTORY_DD_SUCCESS';
export const GET_PROJECT_HISTORY_DD_ERROR = 'GET_PROJECT_HISTORY_DD_ERROR';

export const GET_PROJECT_HISTORY_REQUEST = 'GET_PROJECT_HISTORY_REQUEST';
export const GET_PROJECT_HISTORY_SUCCESS = 'GET_PROJECT_HISTORY_SUCCESS';
export const GET_PROJECT_HISTORY_ERROR = 'GET_PROJECT_HISTORY_ERROR';

export const GET_PROJECT_HISTORY_PREF_REQUEST = 'GET_PROJECT_HISTORY_PREF_REQUEST';
export const GET_PROJECT_HISTORY_PREF_SUCCESS = 'GET_PROJECT_HISTORY_PREF_SUCCESS';
export const GET_PROJECT_HISTORY_PREF_ERROR = 'GET_PROJECT_HISTORY_PREF_ERROR';

export const GET_TIMESHEET_REQUEST = 'GET_TIMESHEET_REQUEST';
export const GET_TIMESHEET_SUCCESS = 'GET_TIMESHEET_SUCCESS';
export const GET_TIMESHEET_ERROR = 'GET_TIMESHEET_ERROR';

export const GET_MASTER_CONSTRUCTION_REQUEST = 'GET_MASTER_CONSTRUCTION_REQUEST';
export const GET_MASTER_CONSTRUCTION_SUCCESS = 'GET_MASTER_CONSTRUCTION_SUCCESS';
export const GET_MASTER_CONSTRUCTION_ERROR = 'GET_MASTER_CONSTRUCTION_ERROR';

export const GET_MASTER_CONSTRUCTION_PREF_REQUEST = 'GET_MASTER_CONSTRUCTION_PREF_REQUEST';
export const GET_MASTER_CONSTRUCTION_PREF_SUCCESS = 'GET_MASTER_CONSTRUCTION_PREF_SUCCESS';
export const GET_MASTER_CONSTRUCTION_PREF_ERROR = 'GET_MASTER_CONSTRUCTION_PREF_ERROR';

export const GET_DATA_CHECK_REQUEST = 'GET_DATA_CHECK_REQUEST';
export const GET_DATA_CHECK_SUCCESS = 'GET_DATA_CHECK_SUCCESS';
export const GET_DATA_CHECK_ERROR = 'GET_DATA_CHECK_ERROR';

export const GET_DATA_CHECK_PREF_REQUEST = 'GET_DATA_CHECK_PREF_REQUEST';
export const GET_DATA_CHECK_PREF_SUCCESS = 'GET_DATA_CHECK_PREF_SUCCESS';
export const GET_DATA_CHECK_PREF_ERROR = 'GET_DATA_CHECK_PREF_ERROR';

export const GET_CLIENT_REFERENCES_REQUEST = 'GET_CLIENT_REFERENCES_REQUEST';
export const GET_CLIENT_REFERENCES_SUCCESS = 'GET_CLIENT_REFERENCES_SUCCESS';
export const GET_CLIENT_REFERENCES_ERROR = 'GET_CLIENT_REFERENCES_ERROR';

export const GET_MASTER_PROJECT_LOG_REQUEST = 'GET_MASTER_PROJECT_LOG_REQUEST';
export const GET_MASTER_PROJECT_LOG_SUCCESS = 'GET_MASTER_PROJECT_LOG_SUCCESS';
export const GET_MASTER_PROJECT_LOG_ERROR = 'GET_MASTER_PROJECT_LOG_ERROR';

export const GET_MASTER_PROJECT_PREF_REQUEST = 'GET_MASTER_PROJECT_PREF_REQUEST';
export const GET_MASTER_PROJECT_PREF_SUCCESS = 'GET_MASTER_PROJECT_PREF_SUCCESS';
export const GET_MASTER_PROJECT_PREF_ERROR = 'GET_MASTER_PROJECT_PREF_ERROR';

export const GET_PROJECT_PRELIM_INFO_REQUEST = 'GET_PROJECT_PRELIM_INFO_REQUEST';
export const GET_PROJECT_PRELIM_INFO_SUCCESS = 'GET_PROJECT_PRELIM_INFO_SUCCESS';
export const GET_PROJECT_PRELIM_INFO_ERROR = 'GET_PROJECT_PRELIM_INFO_ERROR';

export const GET_CONTACT_JOB_DD_LIST_REQUEST = 'GET_CONTACT_JOB_DD_LIST_REQUEST';
export const GET_CONTACT_JOB_DD_LIST_SUCCESS = 'GET_CONTACT_JOB_DD_LIST_SUCCESS';
export const GET_CONTACT_JOB_DD_LIST_ERROR = 'GET_CONTACT_JOB_DD_LIST_ERROR';

export const GET_PROJECT_INFO_HOT_SHEET_REQUEST = 'GET_PROJECT_INFO_HOT_SHEET_REQUEST';
export const GET_PROJECT_INFO_HOT_SHEET_SUCCESS = 'GET_PROJECT_INFO_HOT_SHEET_SUCCESS';
export const GET_PROJECT_INFO_HOT_SHEET_INFO_ERROR = 'GET_PROJECT_INFO_HOT_SHEET_INFO_ERROR';

export const GET_BID_RESULT_RECAP_REQUEST = 'GET_BID_RESULT_RECAP_REQUEST';
export const GET_BID_RESULT_RECAP_SUCCESS = 'GET_BID_RESULT_RECAP_SUCCESS';
export const GET_BID_RESULT_RECAP_ERROR = 'GET_BID_RESULT_RECAP_ERROR';

export const IMPORT_CONTACT_SOCIAL_MEDIA_REQUEST = 'IMPORT_CONTACT_SOCIAL_MEDIA_REQUEST';
export const IMPORT_CONTACT_SOCIAL_MEDIA_SUCCESS = 'IMPORT_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const IMPORT_CONTACT_SOCIAL_MEDIA_ERROR = 'IMPORT_CONTACT_SOCIAL_MEDIA_ERROR';

export const MERGE_CONTACT_SOCIAL_MEDIA_REQUEST = 'MERGE_CONTACT_SOCIAL_MEDIA_REQUEST';
export const MERGE_CONTACT_SOCIAL_MEDIA_SUCCESS = 'MERGE_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const MERGE_CONTACT_SOCIAL_MEDIA_ERROR = 'MERGE_CONTACT_SOCIAL_MEDIA_ERROR';

export const EDIT_CONTACT_SOCIAL_MEDIA_REQUEST = 'EDIT_CONTACT_SOCIAL_MEDIA_REQUEST';
export const EDIT_CONTACT_SOCIAL_MEDIA_SUCCESS = 'EDIT_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const EDIT_CONTACT_SOCIAL_MEDIA_ERROR = 'EDIT_CONTACT_SOCIAL_MEDIA_ERROR';

export const DELETE_CONTACT_SOCIAL_MEDIA_REQUEST = 'DELETE_CONTACT_SOCIAL_MEDIA_REQUEST';
export const DELETE_CONTACT_SOCIAL_MEDIA_SUCCESS = 'DELETE_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const DELETE_CONTACT_SOCIAL_MEDIA_ERROR = 'DELETE_CONTACT_SOCIAL_MEDIA_ERROR';

export const GET_CONTACT_SOCIAL_MEDIA_REQUEST = 'GET_CONTACT_SOCIAL_MEDIA_REQUEST';
export const GET_CONTACT_SOCIAL_MEDIA_SUCCESS = 'GET_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const GET_CONTACT_SOCIAL_MEDIA_ERROR = 'GET_CONTACT_SOCIAL_MEDIA_ERROR';

export const GET_PROFIT_SALES_REQUEST = 'GET_PROFIT_SALES_REQUEST';
export const GET_PROFIT_SALES_SUCCESS = 'GET_PROFIT_SALES_SUCCESS';
export const GET_PROFIT_SALES_ERROR = 'GET_PROFIT_SALES_ERROR';

export const GET_BID_RESULT_RECAP_PREF_REQUEST = 'GET_BID_RESULT_RECAP_PREF_REQUEST';
export const GET_BID_RESULT_RECAP_PREF_SUCCESS = 'GET_BID_RESULT_RECAP_PREF_SUCCESS';

export const GET_PROFIT_SALES_PREF_REQUEST = 'GET_PROFIT_SALES_PREF_REQUEST';
export const GET_PROFIT_SALES_PREF_SUCCESS = 'GET_PROFIT_SALES_PREF_SUCCESS';

// project funnel
export const GET_PROJECT_FUNNEL_PREF_REQUEST = 'GET_PROJECT_FUNNEL_PREF_REQUEST';
export const GET_PROJECT_FUNNEL_PREF_SUCCESS = 'GET_PROJECT_FUNNEL_PREF_SUCCESS';

export const GET_PROJECT_FUNNEL_REPORT_REQUEST = 'GET_PROJECT_FUNNEL_REPORT_REQUEST';
export const GET_PROJECT_FUNNEL_REPORT_SUCCESS = 'GET_PROJECT_FUNNEL_REPORT_SUCCESS';
export const GET_PROJECT_FUNNEL_REPORT_ERROR = 'GET_PROJECT_FUNNEL_REPORT_ERROR';


export const OUTLOOK_CONTACT_SOCIAL_MEDIA_REQUEST = 'OUTLOOK_CONTACT_SOCIAL_MEDIA_REQUEST';
export const OUTLOOK_CONTACT_SOCIAL_MEDIA_SUCCESS = 'OUTLOOK_CONTACT_SOCIAL_MEDIA_SUCCESS';
export const OUTLOOK_CONTACT_SOCIAL_MEDIA_ERROR = 'OUTLOOK_CONTACT_SOCIAL_MEDIA_ERROR';

export const OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_REQUEST =
  'OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_REQUEST';
export const OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_SUCCESS =
  'OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_SUCCESS';
export const OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_ERROR = 'OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_ERROR';

export const GET_COMPANY_DD_REQUEST = 'GET_COMPANY_DD_REQUEST';
export const GET_COMPANY_DD_SUCCESS = 'GET_COMPANY_DD_SUCCESS';
export const GET_COMPANY_DD_ERROR = 'GET_COMPANY_DD_ERROR';

export const UPDATE_EVENT_DATE_REQUEST = 'UPDATE_EVENT_DATE_REQUEST';
export const UPDATE_EVENT_DATE_SUCCESS = 'UPDATE_EVENT_DATE_SUCCESS';
export const UPDATE_EVENT_DATE_ERROR = 'UPDATE_EVENT_DATE_ERROR';


export const GET_BID_MISSED_WIDGET_REQUEST = 'GET_BID_MISSED_WIDGET_REQUEST';
export const GET_BID_MISSED_WIDGET_SUCCESS = 'GET_BID_MISSED_WIDGET_SUCCESS';
export const GET_BID_MISSED_WIDGET_ERROR = 'GET_BID_MISSED_WIDGET_ERROR';

export const GET_MISSING_DATA_REQUEST = 'GET_MISSING_DATA_REQUEST';
export const GET_MISSING_DATA_SUCCESS = 'GET_MISSING_DATA_SUCCESS';
export const GET_MISSING_DATA_ERROR = 'GET_MISSING_DATA_ERROR';

export const GET_VARIABLE_MASTER_REQUEST = 'GET_VARIABLE_MASTER_REQUEST';
export const GET_VARIABLE_MASTER_SUCCESS = 'GET_VARIABLE_MASTER_SUCCESS';
export const GET_VARIABLE_MASTER_ERROR = 'GET_VARIABLE_MASTER_ERROR';

export const AddEdit_VARIABLE_MASTER_REQUEST = 'AddEdit_VARIABLE_MASTER_REQUEST';
export const AddEdit_VARIABLE_MASTER_SUCCESS = 'AddEdit_VARIABLE_MASTER_SUCCESS';
export const AddEdit_VARIABLE_MASTER_ERROR = 'AddEdit_VARIABLE_MASTER_ERROR';

export const ADD_VARIABLE_DATA = 'ADD_VARIABLE_DATA';
export const UPDATE_VARIABLE_DATA_BY_INDEX = 'UPDATE_VARIABLE_DATA_BY_INDEX';

export const UPDATE_CLOSE_WELCOME_REQUEST = 'UPDATE_CLOSE_WELCOME_REQUEST';
export const UPDATE_CLOSE_WELCOME_SUCCESS = 'UPDATE_CLOSE_WELCOME_SUCCESS';
export const UPDATE_CLOSE_WELCOME_ERROR = 'UPDATE_CLOSE_WELCOME_ERROR';

export const GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST';
export const GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS';
export const GET_SUBSCRIPTION_PLAN_ERROR = 'GET_SUBSCRIPTION_PLAN_ERROR';

export const GET_TERMS_CONDITION_REQUEST = 'GET_TERMS_CONDITION_REQUEST';
export const GET_TERMS_CONDITION_SUCCESS = 'GET_TERMS_CONDITION_SUCCESS';
export const GET_TERMS_CONDITION_ERROR = 'GET_TERMS_CONDITION_ERROR';

export const SET_CONTACT_AVATAR_REQUEST = 'SET_CONTACT_AVATAR_REQUEST';
export const SET_CONTACT_AVATAR_SUCCESS = 'SET_CONTACT_AVATAR_SUCCESS';
export const SET_CONTACT_AVATAR_ERROR = 'SET_CONTACT_AVATAR_ERROR';

export const GET_ASSIGNED_CONTACT_REQUEST = 'GET_ASSIGNED_CONTACT_REQUEST';
export const GET_ASSIGNED_CONTACT_SUCCESS = 'GET_ASSIGNED_CONTACT_SUCCESS';
export const GET_ASSIGNED_CONTACT_ERROR = 'GET_ASSIGNED_CONTACT_ERROR';

export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS';
export const EMAIL_VERIFY_ERROR = 'EMAIL_VERIFY_ERROR';
export const EMAIL_VERIFY_REQUEST = 'EMAIL_VERIFY_REQUEST';

export const UPDATE_FILTER_SECTION_SUCCESS = 'UPDATE_FILTER_SECTION_SUCCESS';

export const HIDE_CHANGE_ORDER_REQUEST = 'HIDE_CHANGE_ORDER_REQUEST';
export const OPEN_CHANGE_ORDER_REQUEST = 'OPEN_CHANGE_ORDER_REQUEST';

export const CANCEL_SCHEDULE_REQUEST = 'CANCEL_SCHEDULE_REQUEST';
export const CANCEL_SCHEDULE_SUCCESS = 'CANCEL_SCHEDULE_SUCCESS';
export const CANCEL_SCHEDULE_ERROR = 'CANCEL_SCHEDULE_ERROR';

export const GET_DEMO_ACCOUNT_REQUEST = 'GET_DEMO_ACCOUNT_REQUEST';
export const GET_DEMO_ACCOUNT_SUCCESS = 'GET_DEMO_ACCOUNT_SUCCESS';
export const GET_DEMO_ACCOUNT_ERROR = 'GET_DEMO_ACCOUNT_ERROR';

export const SKIP_ONBOARDING_REQUEST = 'SKIP_ONBOARDING_REQUEST';
export const SKIP_ONBOARDING_SUCCESS = 'SKIP_ONBOARDING_SUCCESS';
export const SKIP_ONBOARDING_ERROR = 'SKIP_ONBOARDING_ERROR';

export const GET_PERMISSION_STATUS_REQUEST = 'GET_PERMISSION_STATUS_REQUEST';
export const GET_PERMISSION_STATUS_SUCCESS = 'GET_PERMISSION_STATUS_SUCCESS';
export const GET_PERMISSION_STATUS_ERROR = 'GET_PERMISSION_STATUS_ERROR';

export const LOAD_JOB_STATUS_REQUEST = "LOAD_JOB_STATUS_REQUEST"
export const LOAD_JOB_STATUS_SUCCESS = "LOAD_JOB_STATUS_SUCCESS"
export const LOAD_JOB_STATUS_ERROR = "LOAD_JOB_STATUS_ERROR"

export const COPY_JOB_STATUS_REQUEST = "COPY_JOB_STATUS_REQUEST"
export const COPY_JOB_STATUS_SUCCESS = "COPY_JOB_STATUS_SUCCESS"
export const COPY_JOB_STATUS_ERROR = "COPY_JOB_STATUS_ERROR"
