import React, { Suspense } from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import { GetBidResultRecapAction } from '../../../actions/reports';
import ReactLoading from 'react-loading';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { CustomDateInput } from '../../../shared/custom-fields-controls/form-controls/custom-date-input';
import moment from 'moment';
import ExportExcelComponent from './../exportExcelComponent';
import TitleComponent from '../../Common/TitleComponent';
import { getAllProjectRoleAction } from '../../../actions/projectRole';

import {
  internalExcelContact,
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  formatDateWithTime,
  formatDateObject,
  formatDate,
  yearPickerValue,
  startDateFormatWithoutTimeUTCF,
  endDateFormatWithoutTimeUTCF,
} from '../../../utils/date-formatters';
import { formatMoney, formatNumber, roundFormatMoney } from '../../../utils/number-formatters';

var sortingOrder = '';
var sortOrders = 'desc';

let dataArr = [];
let tableData = [];
let noData = true;

let errMsg = '';
let periodStatus = '';
let analyzeStatus = '';

let d = new Date();
let currentYear = d.getFullYear();
let currentMonth = d.getMonth() + 1;
let currentQ;
if (currentMonth <= 3) currentQ = { label: '1st', value: 1 };
else if (currentMonth > 3 && currentMonth <= 6) currentQ = { label: '2nd', value: 2 };
else if (currentMonth > 6 && currentMonth <= 9) currentQ = { label: '3rd', value: 3 };
else currentQ = { label: '4th', value: 4 };

class BidResultRecapReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        quarter: currentQ,
      },
      contactData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      list2: [],
      formArr: [],
      selectBox: {},
      modal: false,
      fetchRequest: true,
      comLogo: '',
      currentPage: 1,
      sizePerPage: 10,
      searchText: '',
      OptionStatus: [],
      analyzeBy: null,
      period: '',
      year: null,
      month: null,
      quarter: null,
      projectRoleTypes: [],
      selectDateBox: {
        startDate: formatDateObject(),
        endDate: formatDateObject().add(1, 'Day'),
      },
      startDate: formatDateObject(),
      endDate: formatDateObject().add(1, 'day'),
      todayButton: false,
      optionMonths: [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
      ],
      optionQuarter: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
      ],
      OptionProfitSaleQuarter: [],
      errMsg: {
        year: '',
        month: '',
        quarter: '',
      },
      selectedMonth: { value: 1, label: 'January' },
      selectedYear: { value: currentYear, label: currentYear },
      dataForPdf: false,
      dataForExcel: false,
      sortKey: '',
      sortType: '',
    };
    this.toggle = this.toggle.bind(this);
    this.filterValidation = this.filterValidation.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.projectRoles?.allProjectRoleTypes?.length > 0 &&
      this.state.projectRoleTypes.length === 0
    ) {
      const allRoles = nextProps.projectRoles.allProjectRoleTypes;

      const OptionBidRecapAStatus = [
        { label: 'Building Type', value: 'buildingType' },
        { label: 'Occupancy Type', value: 'occupancyType' },
        { label: 'Negotiation Method ', value: 'negotiatingMethod' },
        { label: 'Job Scope', value: 'scopeArr' },
        { label: 'Status Code', value: 'jobStatusCodes' },
      ];

      const roleOptions = allRoles.map((role) => ({ label: role.name, value: role.code }));

      const optionStatus = roleOptions.concat(OptionBidRecapAStatus);

      let formData = this.state.formData;
      formData.analyzeBy = { value: optionStatus[0].value, label: optionStatus[0].label };

      this.setState({ projectRoleTypes: allRoles, OptionStatus: optionStatus, formData: formData });
    }

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      (this.state.dataForExcel || this.state.dataForPdf)
    ) {
      this.setState({
        totalCount: nextProps.listData.count,
        list: nextProps.listData.data,
        contactData: nextProps.listData.contactData,
        fetchRequest: false,
        dataForExcel: false,
        dataForPdf: false,
      });
      this.makeDataForExportExcel(nextProps.listData.data, nextProps.listData.contactData);
    } else {
      if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
        dataArr = [];

        this.setState({
          list: nextProps.listData.data,
          totalCount: nextProps.listData.count,
          contactData: nextProps.listData.contactData,
          fetchRequest: false,
        });
      }
    }
    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      dataArr = [];
      this.setState({ fetchRequest: false });
    }
  }

  componentDidMount() {
    sortingOrder = '';
    var formData = this.state.formData;

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    // Quarter DD
    let OptionProfitSaleQuarter = [];
    appConstants.OptionProfitSaleQuarter.map((v, i) => {
      if (v.name !== '' && v.value !== '') {
        OptionProfitSaleQuarter.push({ value: v.value, label: v.name });
        /*if(i === 0){
                  formData.quarter = { "value": v.value, "label": v.name }
                }*/
      }
      this.setState({ OptionProfitSaleQuarter, formData: formData });
      return true;
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    this.setState({ period: 'quarter' });
    this.toggle();

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleChangePeriod(val) {
    this.setState({ period: val });

    let formData = this.state.formData;
    let d = new Date();

    if (val == 'year') {
      let selectedYear = { value: d.getFullYear(), label: d.getFullYear() };
      this.setState({ selectedYear: selectedYear, year: d.getFullYear() });
    } else if (val == 'month') {
      let n = d.getMonth();
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      let selectedMonth = { value: n, label: monthNames[d.getMonth()] };
      this.setState({ selectedMonth: selectedMonth, month: n + 1 });
    }
  }

  handleChangePeriodValue(type, e) {
    let value = e.value;
    let stateType = {};
    stateType[type] = value;
    this.setState(stateType);

    let selectedMonth = { value: e.value, label: e.label };
    this.setState({ selectedMonth: selectedMonth });
  }

  handleYearChange(e) {
    let value = e.value;
    let selectedYear = { value: e.value, label: e.label };
    this.setState({ selectedYear: selectedYear, year: e.value });
  }

  handleChangePeriodData(type, elem) {
    let value = elem.target.value;
    let stateType = {};
    stateType[type] = value;
    this.setState(stateType);

    let formData = this.state.formData;
    formData['year'] = value;
    this.setState({ formData: formData });
  }

  handleChangePeriodDate(name, date) {
    let field = this.state.selectDateBox;
    field[name] = date;
    if (name === 'startDate') {
      this.setState({
        todayButton: field['startDate'] > formatDateObject().add(-1, 'day') ? false : 'Today',
      });

      let dateObj = '';

      if (date === null) {
        dateObj = formatDateObject().add(1, 'day');
        field['endDate'] = dateObj;
      } else {
        dateObj = formatDateObject(date).add(1, 'Day');
        if (field['startDate'] >= field['endDate']) {
          field['endDate'] = dateObj;
        }
      }
    }

    this.setState({
      selectDateBox: field,
    });
  }

  filterValidation(val1, val2) {
    let errMsg = this.state.errMsg;
    if (val1 !== null) {
      errMsg[val2] = '';
    } else {
      errMsg[val2] = 'This field is required.';
    }
    this.setState({ errMsg: errMsg });
  }

  filter(e, totalCount) {
    this.setState({ fetchRequest: true });
    let selectedMonth = this.state.selectedMonth;
    let analyze =
      this.state.formData.analyzeBy && this.state.formData.analyzeBy.value
        ? this.state.formData.analyzeBy.value
        : '';
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['10'];
    data['analyzeBy'] = analyze;
    analyzeStatus =
      this.state.formData.analyzeBy && this.state.formData.analyzeBy.label
        ? this.state.formData.analyzeBy.label
        : '';

    if (this.state.period === 'year') {
      data['year'] = this.state.year;
      periodStatus = this.state.year;
    } else if (this.state.period === 'month') {
      data['month'] = this.state.month;
      periodStatus = this.state.selectedMonth.label + ' ' + moment().format('YYYY');
    } else if (this.state.period === 'quarter') {
      data['quarter'] = Number(this.state.formData.quarter.value);

      if (data['quarter'] === 1) {
        periodStatus = 'Jan-Mar, ' + moment().format('YYYY');
      } else if (data['quarter'] === 2) {
        periodStatus = 'Apr-Jun, ' + moment().format('YYYY');
      } else if (data['quarter'] === 3) {
        periodStatus = 'Jul-Sept, ' + moment().format('YYYY');
      } else if (data['quarter'] === 4) {
        periodStatus = 'Oct-Dec, ' + moment().format('YYYY');
      }
    } else if (this.state.period === 'other') {
      data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
      data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
      periodStatus =
        formatDate(this.state.selectDateBox.startDate) +
        ' to ' +
        formatDate(this.state.selectDateBox.endDate);
    }
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;

    this.setState({ list: [], fetchRequest: true });
    tableData = [];
    if (!totalCount) this.toggle();

    this.props.GetBidResultRecapAction(data);

    noData = true;
  }

  onSortChange(sortName, sortOrder) {
    if (tableData && tableData.length > 0) {
      this.setState({ fetchRequest: true });
      let data = {};
      data['sortKey'] = sortName;
      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        sortOrders = 'desc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        sortOrders = 'asc';
        this.setState({ sortType: 'DESC' });
      }
      let analyze =
        this.state.formData.analyzeBy && this.state.formData.analyzeBy.value
          ? this.state.formData.analyzeBy.value
          : '';

      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['10'];
      data['analyzeBy'] = analyze;

      if (this.state.period === 'year') {
        data['year'] = this.state.year;
      } else if (this.state.period === 'month') {
        data['month'] = this.state.month;
      } else if (this.state.period === 'quarter') {
        data['quarter'] = Number(this.state.formData.quarter.value);
      } else if (this.state.period === 'other') {
        data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
        data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
      }

      data['moduleName'] = 'Bid Result Recap Report';

      this.props.GetBidResultRecapAction(data);
    }
  }

  dataCalculation() {
    let finalSum = [];
    finalSum.FProjectSum = 0;
    finalSum.FBidContractSum = 0;
    finalSum.FBidProfitSum = 0;
    finalSum.FBidProfitPerSum = 0;
    finalSum.FWonContractSum = 0;
    finalSum.FWonProfitSum = 0;
    finalSum.FWonProfitPerSum = 0;
    finalSum.FBidHitRatioSum = 0;
    finalSum.FProfitSum = 0;
    finalSum.FWin = 0;
    finalSum.FLoss = 0;
    finalSum.FOther = 0;
    finalSum.FWLBidRatio = 0;
    finalSum.FProfitSumCompany = 0;
    finalSum.FProfitWLSum = 0;
    finalSum.FProfitSumWL = 0;

    dataArr = this.state.list;
    dataArr = this.state.list;
    let dataArr1 = [];
    dataArr1 = this.state.list;
    if (this.state.list2 && this.state.list2.length > 0) {
      dataArr = this.state.list2.concat(dataArr);
    }

    //debugger;

    let processedProjects = [];

    if (dataArr1.length > 0) {
      noData = false;

      for (let x in dataArr) {
        //debugger;
        dataArr[x].allContract = 0;
        dataArr[x].allProfit = 0;
        dataArr[x].jobStatusAmountSumCont = 0;
        dataArr[x].projectCount = 0;
        dataArr[x].wCount = 0;
        dataArr[x].lCount = 0;
        dataArr[x].oCount = 0;
        dataArr[x].WLCount = 0;
        dataArr[x].bidHitRatioCount = 0;
        dataArr[x].finalProfitPercentage = 0;
        dataArr[x].jobBidLProfit = 0;
        dataArr[x].jobBidLContract = 0;
        dataArr[x].jobBidWLProfit = 0;
        dataArr[x].jobStatusAmountSumProfit = 0;
        dataArr[x].subTotal = [];
        dataArr[x].analyzer = '';

        //*** Analyser name
        if (dataArr[x].contactInfo) {
          if (dataArr[x].contactInfo.firstName || dataArr[x].contactInfo.lastName) {
            dataArr[x].analyzer =
              [dataArr[x].contactInfo.firstName, dataArr[x].contactInfo.lastName]
                .filter(Boolean)
                .join(' ') ?? '-';
          } else if (dataArr[x].contactInfo.negotiatingMethodName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.negotiatingMethodName
              ? dataArr[x].contactInfo.negotiatingMethodName
              : '';
          } else if (dataArr[x].contactInfo.jobScopeName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.jobScopeName
              ? dataArr[x].contactInfo.jobScopeName
              : '';
          } else if (dataArr[x].contactInfo.occupancyTypeName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.occupancyTypeName
              ? dataArr[x].contactInfo.occupancyTypeName
              : '';
          }
        }

        for (let y in dataArr[x].projects) {
          const curProject = dataArr[x].projects[y];

          const useForGlobal =
            processedProjects.length == 0 || !processedProjects.includes(curProject.objectId);
          if (useForGlobal) {
            processedProjects.push(curProject.objectId);
          }

          dataArr[x].projects[y].wonContractAmount = 0;
          dataArr[x].projects[y].wonContractAmount = 0;
          dataArr[x].projects[y].perProjectBidProfit = 0;
          dataArr[x].projects[y].perProjectWonProfit = 0;

          //*** Total count of Projects
          dataArr[x].projectCount = dataArr[x].projects.length;

          const curProjectContract = dataArr[x].projects[y].currentContractAmount;
          const curProjectProfit = dataArr[x].projects[y].currentGrossProfit;

          //**** Total Bid amount and profit amount
          dataArr[x].allContract += curProjectContract;
          dataArr[x].allProfit += curProjectProfit;
          // Add to total sum if global
          if (useForGlobal) {
            finalSum.FBidContractSum += curProjectContract;
            finalSum.FBidProfitSum += curProjectProfit;
            finalSum.FProjectSum += 1;
          }

          //**** Total won amount and profit amount
          const jobStatus = dataArr[x].projects[y].jobStatusCodesCode;
          if (
            jobStatus === 'A' ||
            jobStatus === 'C' ||
            jobStatus === 'D' ||
            jobStatus === 'W' ||
            jobStatus === 'SNS'
          ) {
            dataArr[x].projects[y].WLO_status = 'W';
            dataArr[x].wCount += 1;
            dataArr[x].jobStatusAmountSumCont += curProjectContract;
            dataArr[x].jobStatusAmountSumProfit += curProjectProfit;
            dataArr[x].projects[y].wonContractAmount = curProjectContract;
            dataArr[x].projects[y].wonProfitAmount = curProjectProfit;

            if (useForGlobal) {
              finalSum.FWin += 1;
              finalSum.FWonContractSum += curProjectContract;
              finalSum.FWonProfitSum += curProjectProfit;
            }
          } else if (jobStatus === 'XC') {
            dataArr[x].projects[y].WLO_status = 'L';
            dataArr[x].jobBidLProfit += curProjectProfit;
            dataArr[x].jobBidLContract += curProjectContract;
            dataArr[x].projects[y].wonContractAmount = 0;
            dataArr[x].projects[y].wonProfitAmount = 0;
            dataArr[x].lCount += 1;

            if (useForGlobal) {
              finalSum.FLoss += 1;
              //finalSum.jobBidLContract += curProjectContract;
              //finalSum.jobBidLProfit += curProjectProfit;
            }
          } else {
            dataArr[x].projects[y].WLO_status = 'O';
            dataArr[x].projects[y].wonContractAmount = 0;
            dataArr[x].projects[y].wonProfitAmount = 0;

            if (useForGlobal) {
              finalSum.FOther += 1;
              //finalSum.jobBidOContract += curProjectContract;
              //finalSum.jobBidOProfit += curProjectProfit;
            }
          }

          //*** Calculate profit percentage for won jobS
          if (
            isNaN(
              dataArr[x].projects[y].wonProfitAmount / dataArr[x].projects[y].wonContractAmount
            ) === true
          ) {
            dataArr[x].projects[y].perProjectWonProfit = 0.0;
          } else {
            dataArr[x].projects[y].perProjectWonProfit =
              (dataArr[x].projects[y].wonProfitAmount / dataArr[x].projects[y].wonContractAmount) *
              100;
            dataArr[x].projects[y].perProjectWonProfit =
              dataArr[x].projects[y].perProjectWonProfit.toFixed(2);
          }

          //*** Calculate profit percentage for bid jobS
          if (isNaN(curProjectProfit / curProjectContract) === true) {
            dataArr[x].projects[y].perProjectBidProfit = 0.0;
          } else {
            dataArr[x].projects[y].perProjectBidProfit =
              (curProjectProfit / curProjectContract) * 100;
            dataArr[x].projects[y].perProjectBidProfit =
              dataArr[x].projects[y].perProjectBidProfit.toFixed(2);
          }
        }

        //**** Bit hit ratio
        dataArr[x].WLCount = dataArr[x].wCount + dataArr[x].lCount;
        if (isNaN(dataArr[x].wCount / dataArr[x].WLCount) === true) {
          dataArr[x].bidHitRatioCount = 0;
        } else {
          dataArr[x].bidHitRatioCount = (dataArr[x].wCount / dataArr[x].WLCount) * 100;
        }

        //**** Final profit
        dataArr[x].jobBidWLProfit = dataArr[x].jobBidLProfit + dataArr[x].jobStatusAmountSumProfit;
        if (isNaN(dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobBidWLProfit) === true) {
          dataArr[x].finalProfitPercentage = 0;
        } else {
          dataArr[x].finalProfitPercentage =
            (dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobBidWLProfit) * 100;
        }

        //**** bid Profit percentage
        if (dataArr[x].bidProfitPercent === undefined) {
          if (isNaN(dataArr[x].allProfit / dataArr[x].allContract) === true) {
            dataArr[x].bidProfitPercent = 0.0;
          } else {
            dataArr[x].bidProfitPercent = (dataArr[x].allProfit / dataArr[x].allContract) * 100;
            dataArr[x].bidProfitPercent = dataArr[x].bidProfitPercent.toFixed(2);
          }
        }

        //**** Won Profit percentage
        if (dataArr[x].wonProfitPercent === undefined) {
          if (
            isNaN(dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobStatusAmountSumCont) === true
          ) {
            dataArr[x].wonProfitPercent = 0.0;
          } else {
            dataArr[x].wonProfitPercent =
              (dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobStatusAmountSumCont) * 100;
            dataArr[x].wonProfitPercent = dataArr[x].wonProfitPercent.toFixed(2);
          }
        }

        finalSum.FWonProfitPerSum += Math.round(dataArr[x].wonProfitPercent);

        finalSum.FBidHitRatioSum += dataArr[x].bidHitRatioCount;
        finalSum.FProfitSum += dataArr[x].finalProfitPercentage;
      }

      finalSum.FWLCount = finalSum.FWin + finalSum.FLoss;
      finalSum.FWLBidRatio = (finalSum.FWin / finalSum.FWLCount) * 100;
      finalSum.FProfitWLSum = (finalSum.FWonProfitSum / finalSum.FBidProfitSum) * 100;

      finalSum.FWLCount = finalSum.FWin + finalSum.FLoss;
      finalSum.FWLBidRatio = (finalSum.FWin / finalSum.FWLCount) * 100;
      finalSum.FBidProfitPerSum = Math.round(
        (finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100,
        2
      );

      tableData = [];

      for (let x in dataArr) {
        for (let y in dataArr[x].projects) {
          //**** Account Executive
          let accountExec = [];
          let projectMgr = [];

          let row = dataArr[x].projects[y];

          const curProjectContract = row.currentContractAmount;
          const curProjectProfit = row.currentGrossProfit;

          const array_PM = (row['PM'] ?? []).map((record) => record.contactAffiliationId);
          const array_EST = (row['EST'] ?? []).map((record) => record.contactAffiliationId);

          if (array_PM && array_PM.length > 0) {
            projectMgr = formatInternalContactData(array_PM, this.state.contactData);
          } else {
            projectMgr = <span>-</span>;
          }

          if (array_EST && array_EST.length > 0) {
            accountExec = formatInternalContactData(array_EST, this.state.contactData);
          } else {
            accountExec = <span>-</span>;
          }

          // Project list data
          var jobName = (
            <td>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].jobName ? dataArr[x].projects[y].jobName : '-'}
              </Link>
            </td>
          );
          var jobNum = (
            <td width='80'>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].jobNumFormatted ? dataArr[x].projects[y].jobNumFormatted : '-'}
              </Link>
            </td>
          );
          var altNum = (
            <td width='80'>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].altJobNum ? dataArr[x].projects[y].altJobNum : '-'}
              </Link>
            </td>
          );
          var status = (
            <td>
              {dataArr[x].projects[y].jobStatusCodesCode
                ? dataArr[x].projects[y].jobStatusCodesCode
                : '-'}
            </td>
          );
          var dueDate = (
            <td>
              {dataArr[x].projects[y].bidDue && dataArr[x].projects[y].bidDue.iso
                ? formatDate(dataArr[x].projects[y].bidDue.iso)
                : '-'}
            </td>
          );
          var dateSubmitted = (
            <td>
              {dataArr[x].projects[y].bidDue && dataArr[x].projects[y].bidDue.iso
                ? formatDate(dataArr[x].projects[y].bidDue.iso)
                : '-'}
            </td>
          );
          accountExec = <td>{accountExec}</td>;
          projectMgr = <td>{projectMgr}</td>;
          var bidContract = (
            <td className='textRight'>
              {curProjectContract
                ? formatNumber(Math.round(curProjectContract))
                : 0}
            </td>
          );
          var bidProfit = (
            <td className='textRight'>
              {curProjectProfit ? formatNumber(Math.round(curProjectProfit)) : 0}
            </td>
          );
          var bidProfitPer = (
            <td>
              {dataArr[x].projects[y].perProjectBidProfit
                ? Math.round(dataArr[x].projects[y].perProjectBidProfit)
                : 0}
              %
            </td>
          );
          var bidStatus = (
            <td>{dataArr[x].projects[y].WLO_status ? dataArr[x].projects[y].WLO_status : '-'}</td>
          );
          let hasBidResult =
            dataArr[x].projects[y].WLO_status &&
            (dataArr[x].projects[y].WLO_status === 'W' ||
              dataArr[x].projects[y].WLO_status === 'L');

          var wonContract = hasBidResult ? (
            <td className='textRight'>
              {dataArr[x].projects[y].wonContractAmount
                ? formatNumber(Math.round(dataArr[x].projects[y].wonContractAmount))
                : 0}
            </td>
          ) : (
            ''
          );
          var wonProfit = hasBidResult ? (
            <td className='textRight'>
              {dataArr[x].projects[y].wonProfitAmount
                ? formatNumber(Math.round(dataArr[x].projects[y].wonProfitAmount))
                : 0}
            </td>
          ) : (
            ''
          );
          var wonProfitPer = hasBidResult ? (
            <td>
              {dataArr[x].projects[y].perProjectWonProfit
                ? Math.round(dataArr[x].projects[y].perProjectWonProfit)
                : 0}
              %
            </td>
          ) : (
            ''
          );

          var tablerow = (
            <tr>
              {jobNum}
              {altNum}
              {jobName}
              {status}
              {dueDate}
              {dateSubmitted}
              {accountExec}
              {projectMgr}
              {bidContract}
              {bidProfit}
              {bidProfitPer}
              {bidStatus}
              {wonContract}
              {wonProfit}
              {wonProfitPer}
            </tr>
          );
          tableData.push(tablerow);
        }

        //Sub total
        var subtotalTitle = <td colSpan='2'>Subtotals for: </td>;
        var bidHitRatioTitle = <td>Bid Hit Ratio:</td>;
        var projectCountTitle = <td colSpan='2'># Jobs:</td>;
        var bidHitRatio = (
          <td>
            {dataArr[x].WLCount > 0 && dataArr[x].bidHitRatioCount >= 0
              ? Math.round(dataArr[x].bidHitRatioCount) + '%'
              : '-'}
          </td>
        );
        var totalProfitPercentageTitle = <td>Profit Hit %: </td>;
        var totalProfitPercentage = (
          <td>
            {dataArr[x].WLCount > 0 && dataArr[x].finalProfitPercentage >= 0
              ? Math.round(dataArr[x].finalProfitPercentage) + '%'
              : '-'}
          </td>
        );
        var projectCount = <td>{dataArr[x].projectCount ? dataArr[x].projectCount : 0}</td>;
        var td1 = <td></td>;
        var totalBidContract = (
          <td className='textRight'>
            {dataArr[x].allContract
              ? roundFormatMoney(dataArr[x].allContract)
              : 0}
          </td>
        );
        var totalBidProfit = (
          <td className='textRight'>
            {dataArr[x].allProfit
              ? roundFormatMoney(dataArr[x].allProfit)
              : 0}
          </td>
        );
        var totalBidProfitPer = (
          <td>{dataArr[x].bidProfitPercent ? Math.round(dataArr[x].bidProfitPercent) : 0}%</td>
        );
        var td3 = <td></td>;
        var totalWonContract = (
          <td className='textRight'>
            {dataArr[x].jobStatusAmountSumCont
              ? roundFormatMoney(dataArr[x].jobStatusAmountSumCont)
              : 0}
          </td>
        );
        var totalWonProfit = (
          <td className='textRight'>
            {dataArr[x].jobStatusAmountSumProfit
              ? roundFormatMoney(dataArr[x].jobStatusAmountSumProfit)
              : 0}
          </td>
        );
        var totalWonProfitPer = (
          <td>{dataArr[x].wonProfitPercent ? Math.round(dataArr[x].wonProfitPercent) : 0}%</td>
        );

        var analyzerName = (
          <td colSpan='2'>
            {dataArr[x].isContactAnalyzeBy ? (
              dataArr[x].label && dataArr[x]?.objectId !== 'Unassigned' ? (
                formatExternalContactData([dataArr[x].objectId], this.state.contactData, false)
              ) : //<Link to={{ pathname: `/contact-details/` + dataArr[x].objectId }}>
              //  {dataArr[x].label}
              //</Link>)
              dataArr[x].objectId === 'Unassigned' ? (
                'Unassigned'
              ) : (
                'Deleted (' + dataArr[x].objectId + ')'
              )
            ) : (
              <>{dataArr[x].label}</>
            )}
          </td>
        );

        var sr2 = (
          <tr className='border border2 subtotal'>
            {subtotalTitle}
            {/*td1*/}
            {bidHitRatioTitle}
            {bidHitRatio}
            {projectCountTitle}
            {projectCount}
            {td1}
            {totalBidContract}
            {totalBidProfit}
            {totalBidProfitPer}
            {td3}
            {totalWonContract}
            {totalWonProfit}
            {totalWonProfitPer}
          </tr>
        );
        tableData.push(sr2);

        var sr3 = (
          <tr className='border border2 subtotal'>
            {analyzerName}
            {/*td1*/}
            {totalProfitPercentageTitle}
            {totalProfitPercentage}
            {td1}
            {td3}
            {td1}
            {td1}
            {td1}
            {td1}
            {td1}
            {td3}
            {td1}
            {td1}
            {td1}
          </tr>
        );
        tableData.push(sr3);
      }

      // net total
      var companyTotalTitle = <td colSpan='2'>Company Totals: </td>;
      const totalSpacer = <td></td>;
      var FbidHitRatio = (
        <td>
          {finalSum.FWLCount > 0 && finalSum.FWLBidRatio
            ? Math.round(finalSum.FWLBidRatio) + '%'
            : '-'}
        </td>
      );
      var FbidHitRatioTitle = <td>Bid Hit Ratio:</td>;
      var FtotalProfitPercentage = (
        <td>
          {finalSum.FWLCount > 0 && finalSum.FProfitWLSum
            ? Math.round(finalSum.FProfitWLSum) + '%'
            : '-'}
        </td>
      );
      var FtotalProfitPercentageTitle = <td>Profit %:</td>;
      var FprojectCount = <td>{finalSum.FProjectSum ? finalSum.FProjectSum : 0}</td>;
      var FprojectCountTitle = <td colSpan={2}># Jobs</td>;
      var td11 = <td></td>;
      var td21 = <td></td>;
      var FtotalBidContract = (
        <td className='textRight'>
          {finalSum.FBidContractSum
            ? roundFormatMoney(finalSum.FBidContractSum)
            : 0}
        </td>
      );
      var FtotalBidProfit = (
        <td className='textRight'>
          {finalSum.FBidProfitSum
            ? roundFormatMoney(finalSum.FBidProfitSum)
            : 0}
        </td>
      );
      var FtotalBidProfitPer = (
        <td>
          {finalSum.FBidProfitSum / finalSum.FBidContractSum
            ? Math.round((finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100)
            : 0}
          %
        </td>
      );
      var td31 = <td></td>;
      var FtotalWonContract = (
        <td className='textRight'>
          {finalSum.FWonContractSum
            ? roundFormatMoney(finalSum.FWonContractSum)
            : 0}
        </td>
      );

      var FtotalWonProfit = (
        <td className='textRight'>
          {finalSum.FWonProfitSum
            ? roundFormatMoney(finalSum.FWonProfitSum)
            : 0}
        </td>
      );
      var FtotalWonProfitPer = (
        <td>
          {finalSum.FWonProfitSum / finalSum.FWonContractSum
            ? Math.round((finalSum.FWonProfitSum / finalSum.FWonContractSum) * 100)
            : 0}
          %
        </td>
      );

      var sr3 = (
        <tr className='border border2 total'>
          {companyTotalTitle}
          {FbidHitRatioTitle}
          {FbidHitRatio}
          {FprojectCountTitle}
          {FprojectCount}
          {td21}
          {FtotalBidContract}
          {FtotalBidProfit}
          {FtotalBidProfitPer}
          {td31}
          {FtotalWonContract}
          {FtotalWonProfit}
          {FtotalWonProfitPer}
        </tr>
      );
      tableData.push(sr3);

      var sr4 = (
        <tr className='border border2 total'>
          {td1}
          {td1}
          {FtotalProfitPercentageTitle}
          {FtotalProfitPercentage}
          {td1}
          {td3}
          {td1}
          {td1}
          {td1}
          {td1}
          {td1}
          {td3}
          {td1}
          {td1}
          {td1}
        </tr>
      );
      tableData.push(sr4);
    }
  }

  handleChangeSelectBox(name, value) {
    let formData = this.state.formData;
    formData[name] = value;
    this.setState({ formData });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Bid Result Recap Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }
  excelExport() {
    let { totalCount } = this.state;
    this.setState({
      filterStatus: false,
      dataForExcel: true,
      dataForPdf: false,
    });
    this.filter(this, totalCount);
  }

  downloadPdf() {
    let { totalCount } = this.state;
    this.setState({
      filterStatus: false,
      dataForExcel: false,
      dataForPdf: true,
    });
    this.filter(this, totalCount);
  }

  makeDataForExportExcel = (list, contactData) => {
    let dbDataArr = [];
    let dbDataArrB = [];
    let dataArr = list;

    let finalSum = [];
    finalSum.FProjectSum = 0;
    finalSum.FBidContractSum = 0;
    finalSum.FBidProfitSum = 0;
    finalSum.FBidProfitPerSum = 0;
    finalSum.FWonContractSum = 0;
    finalSum.FWonProfitSum = 0;
    finalSum.FWonProfitPerSum = 0;
    finalSum.FBidHitRatioSum = 0;
    finalSum.FProfitSum = 0;
    finalSum.FWin = 0;
    finalSum.FLoss = 0;
    finalSum.FOther = 0;
    finalSum.FWLBidRatio = 0;
    finalSum.FProfitSumCompany = 0;
    finalSum.FProfitWLSum = 0;
    finalSum.FProfitSumWL = 0;

    //debugger;

    let processedProjects = [];

    if (dataArr.length > 0) {
      noData = false;

      for (let x in dataArr) {
        //debugger;
        dataArr[x].allContract = 0;
        dataArr[x].allProfit = 0;
        dataArr[x].jobStatusAmountSumCont = 0;
        dataArr[x].projectCount = 0;
        dataArr[x].wCount = 0;
        dataArr[x].lCount = 0;
        dataArr[x].oCount = 0;
        dataArr[x].WLCount = 0;
        dataArr[x].bidHitRatioCount = 0;
        dataArr[x].finalProfitPercentage = 0;
        dataArr[x].jobBidLProfit = 0;
        dataArr[x].jobBidLContract = 0;
        dataArr[x].jobBidWLProfit = 0;
        dataArr[x].jobStatusAmountSumProfit = 0;
        dataArr[x].subTotal = [];
        dataArr[x].analyzer = '';

        //*** Analyser name
        if (dataArr[x].contactInfo) {
          if (dataArr[x].contactInfo.firstName || dataArr[x].contactInfo.lastName) {
            dataArr[x].analyzer =
              [dataArr[x].contactInfo.firstName, dataArr[x].contactInfo.lastName]
                .filter(Boolean)
                .join(' ') ?? '-';
          } else if (dataArr[x].contactInfo.negotiatingMethodName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.negotiatingMethodName
              ? dataArr[x].contactInfo.negotiatingMethodName
              : '';
          } else if (dataArr[x].contactInfo.jobScopeName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.jobScopeName
              ? dataArr[x].contactInfo.jobScopeName
              : '';
          } else if (dataArr[x].contactInfo.occupancyTypeName) {
            dataArr[x].analyzer = dataArr[x].contactInfo.occupancyTypeName
              ? dataArr[x].contactInfo.occupancyTypeName
              : '';
          }
        }

        for (let y in dataArr[x].projects) {
          const curProject = dataArr[x].projects[y];

          const useForGlobal =
            processedProjects.length == 0 || !processedProjects.includes(curProject.objectId);
          if (useForGlobal) {
            processedProjects.push(curProject.objectId);
          }

          dataArr[x].projects[y].wonContractAmount = 0;
          dataArr[x].projects[y].wonContractAmount = 0;
          dataArr[x].projects[y].perProjectBidProfit = 0;
          dataArr[x].projects[y].perProjectWonProfit = 0;

          //*** Total count of Projects
          dataArr[x].projectCount = dataArr[x].projects.length;

          const curProjectContract = dataArr[x].projects[y].currentContractAmount;
          const curProjectProfit = dataArr[x].projects[y].currentGrossProfit;

          //**** Total Bid amount and profit amount
          dataArr[x].allContract += curProjectContract;
          dataArr[x].allProfit += curProjectProfit;
          // Add to total sum if global
          if (useForGlobal) {
            finalSum.FBidContractSum += curProjectContract;
            finalSum.FBidProfitSum += curProjectProfit;
            finalSum.FProjectSum += 1;
          }

          //**** Total won amount and profit amount
          const jobStatus = dataArr[x].projects[y].jobStatusCodesCode;
          if (
            jobStatus === 'A' ||
            jobStatus === 'C' ||
            jobStatus === 'D' ||
            jobStatus === 'W' ||
            jobStatus === 'SNS'
          ) {
            dataArr[x].projects[y].WLO_status = 'W';
            dataArr[x].wCount += 1;
            dataArr[x].jobStatusAmountSumCont += curProjectContract;
            dataArr[x].jobStatusAmountSumProfit += curProjectProfit;
            dataArr[x].projects[y].wonContractAmount = curProjectContract;
            dataArr[x].projects[y].wonProfitAmount = curProjectProfit;

            if (useForGlobal) {
              finalSum.FWin += 1;
              finalSum.FWonContractSum += curProjectContract;
              finalSum.FWonProfitSum += curProjectProfit;
            }
          } else if (jobStatus === 'XC') {
            dataArr[x].projects[y].WLO_status = 'L';
            dataArr[x].jobBidLProfit += curProjectProfit;
            dataArr[x].jobBidLContract += curProjectContract;
            dataArr[x].projects[y].wonContractAmount = 0;
            dataArr[x].projects[y].wonProfitAmount = 0;
            dataArr[x].lCount += 1;

            if (useForGlobal) {
              finalSum.FLoss += 1;
              //finalSum.jobBidLContract += curProjectContract;
              //finalSum.jobBidLProfit += curProjectProfit;
            }
          } else {
            dataArr[x].projects[y].WLO_status = 'O';
            dataArr[x].projects[y].wonContractAmount = 0;
            dataArr[x].projects[y].wonProfitAmount = 0;

            if (useForGlobal) {
              finalSum.FOther += 1;
              //finalSum.jobBidOContract += curProjectContract;
              //finalSum.jobBidOProfit += curProjectProfit;
            }
          }

          //*** Calculate profit percentage for won jobS
          if (
            isNaN(
              dataArr[x].projects[y].wonProfitAmount / dataArr[x].projects[y].wonContractAmount
            ) === true
          ) {
            dataArr[x].projects[y].perProjectWonProfit = 0.0;
          } else {
            dataArr[x].projects[y].perProjectWonProfit =
              (dataArr[x].projects[y].wonProfitAmount / dataArr[x].projects[y].wonContractAmount) *
              100;
            dataArr[x].projects[y].perProjectWonProfit =
              dataArr[x].projects[y].perProjectWonProfit.toFixed(2);
          }

          //*** Calculate profit percentage for bid jobS
          if (isNaN(curProjectProfit / curProjectContract) === true) {
            dataArr[x].projects[y].perProjectBidProfit = 0.0;
          } else {
            dataArr[x].projects[y].perProjectBidProfit =
              (curProjectProfit / curProjectContract) * 100;
            dataArr[x].projects[y].perProjectBidProfit =
              dataArr[x].projects[y].perProjectBidProfit.toFixed(2);
          }
        }

        //**** Bit hit ratio
        dataArr[x].WLCount = dataArr[x].wCount + dataArr[x].lCount;
        if (isNaN(dataArr[x].wCount / dataArr[x].WLCount) === true) {
          dataArr[x].bidHitRatioCount = 0;
        } else {
          dataArr[x].bidHitRatioCount = (dataArr[x].wCount / dataArr[x].WLCount) * 100;
        }

        //**** Final profit
        dataArr[x].jobBidWLProfit = dataArr[x].jobBidLProfit + dataArr[x].jobStatusAmountSumProfit;
        if (isNaN(dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobBidWLProfit) === true) {
          dataArr[x].finalProfitPercentage = 0;
        } else {
          dataArr[x].finalProfitPercentage =
            (dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobBidWLProfit) * 100;
        }

        //**** bid Profit percentage
        if (dataArr[x].bidProfitPercent === undefined) {
          if (isNaN(dataArr[x].allProfit / dataArr[x].allContract) === true) {
            dataArr[x].bidProfitPercent = 0.0;
          } else {
            dataArr[x].bidProfitPercent = (dataArr[x].allProfit / dataArr[x].allContract) * 100;
            dataArr[x].bidProfitPercent = dataArr[x].bidProfitPercent.toFixed(2);
          }
        }

        //**** Won Profit percentage
        if (dataArr[x].wonProfitPercent === undefined) {
          if (
            isNaN(dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobStatusAmountSumCont) === true
          ) {
            dataArr[x].wonProfitPercent = 0.0;
          } else {
            dataArr[x].wonProfitPercent =
              (dataArr[x].jobStatusAmountSumProfit / dataArr[x].jobStatusAmountSumCont) * 100;
            dataArr[x].wonProfitPercent = dataArr[x].wonProfitPercent.toFixed(2);
          }
        }

        finalSum.FWonProfitPerSum += Math.round(dataArr[x].wonProfitPercent);

        finalSum.FBidHitRatioSum += dataArr[x].bidHitRatioCount;
        finalSum.FProfitSum += dataArr[x].finalProfitPercentage;
      }

      finalSum.FWLCount = finalSum.FWin + finalSum.FLoss;
      finalSum.FWLBidRatio = (finalSum.FWin / finalSum.FWLCount) * 100;
      finalSum.FProfitWLSum = (finalSum.FWonProfitSum / finalSum.FBidProfitSum) * 100;

      finalSum.FWLCount = finalSum.FWin + finalSum.FLoss;
      finalSum.FWLBidRatio = (finalSum.FWin / finalSum.FWLCount) * 100;
      finalSum.FBidProfitPerSum = Math.round(
        (finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100,
        2
      );

      const rolesLoaded = this.state.projectRoleTypes.length > 0;
      const analyzeByOptions = rolesLoaded && this.state.OptionStatus;

      const roles = this.state.projectRoleTypes;

      const estShort =
        (rolesLoaded && roles.find((role) => role.code == 'EST'))?.shortName ?? 'EST';
      const projMgrShort =
        (rolesLoaded && roles.find((role) => role.code == 'PM'))?.shortName ?? 'PM';

      const dbHeaderArr = [
        'Job Num.',
        'Alt Job#',
        'Job Name',
        'Status',
        'Due Date',
        'Bid Date',
        estShort,
        projMgrShort,
        'Contract Amt.',
        'Profit (est.)',
        'Profit %',
        'W/L/O',
        'Contract Amt. Result',
        'Profit (est.) Result',
        'Profit % Result',
      ];

      dbDataArr.push(dbHeaderArr);

      for (let x in dataArr) {
        for (let y in dataArr[x].projects) {
          const curProject = dataArr[x].projects[y];
          //**** Account Executive

          let estimators = '';
          if (curProject.EST && curProject.EST.length > 0) {
            estimators = internalExcelContact(curProject.EST, contactData);
          } else {
            estimators = '-';
          }

          let projectMgr = '';
          if (curProject.PM && curProject.PM.length > 0) {
            projectMgr = internalExcelContact(curProject.PM, contactData);
          } else {
            projectMgr = '-';
          }

          let row = curProject;

          const curProjectContract = row.currentContractAmount;
          const curProjectProfit = row.currentGrossProfit;

          // Project list data
          dbDataArrB = [];
          for (let q in dbHeaderArr) {
            switch (dbHeaderArr[q]) {
              case 'Job Name':
                var jobName = row.jobName ? row.jobName : '-';
                dbDataArrB.push(jobName);
                break;
              case 'Job Num.':
                var jobNum = row.jobNumFormatted ? row.jobNumFormatted : '-';
                dbDataArrB.push(jobNum);
                break;
              case 'Alt Job#':
                var altNum = row.altJobNum ? row.altJobNum : '-';
                dbDataArrB.push(altNum);
                break;
              case 'Status':
                var status = row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
                dbDataArrB.push(status);
                break;
              case 'Due Date':
                var dueDate = row.bidDue && row.bidDue.iso ? formatDate(row.bidDue.iso) : '-';
                dbDataArrB.push(dueDate);
                break;
              case 'Bid Date':
                var dateSubmitted =
                  row.bidDue && row.bidDue.iso ? formatDate(row.bidDue.iso) : '-';
                dbDataArrB.push(dateSubmitted);
                break;
              case estShort:
                dbDataArrB.push(estimators);
                break;
              case projMgrShort:
                dbDataArrB.push(projectMgr);
                break;
              case 'Contract Amt.':
                var bidContract = curProjectContract
                  ? formatNumber(Math.round(curProjectContract))
                  : 0;
                dbDataArrB.push(bidContract);
                break;
              case 'Profit (est.)':
                var bidProfit = row.currentGrossProfit
                  ? formatNumber(Math.round(curProjectProfit))
                  : 0;
                dbDataArrB.push(bidProfit);
                break;
              case 'Profit %':
                var bidProfitPer =
                  (row.perProjectBidProfit ? Math.round(row.perProjectBidProfit) : 0) + '%';
                dbDataArrB.push(bidProfitPer);
                break;
              case 'W/L/O':
                var bidStatus = row.WLO_status ? row.WLO_status : '-';
                dbDataArrB.push(bidStatus);
                break;
              case 'Contract Amt. Result':
                var wonContract = row.wonContractAmount
                  ? formatNumber(Math.round(row.wonContractAmount))
                  : 0;
                dbDataArrB.push(wonContract);
                break;
              case 'Profit (est.) Result':
                var wonProfit = row.wonProfitAmount
                  ? formatNumber(Math.round(row.wonProfitAmount))
                  : 0;
                dbDataArrB.push(wonProfit);
                break;
              case 'Profit % Result':
                var wonProfitPer =
                  (row.perProjectWonProfit ? Math.round(row.perProjectWonProfit) : 0) + '%';
                dbDataArrB.push(wonProfitPer);
                break;
            }
          }
          dbDataArr.push(dbDataArrB);
          //}
        }
        dbDataArrB = [];
        for (let q in dbHeaderArr) {
          if (q == '0') {
            dbDataArrB.push('Subtotals for: ');
          } else if (q == '1') {
            let analyzerName = dataArr[x].label;
            if (dataArr[x].isContactAnalyzeBy) {
              if (dataArr[x]?.objectId !== 'Unassigned') {
                analyzerName = internalExcelContact(dataArr[x].objectId, contactData);
              } else if (dataArr[x].objectId === 'Unassigned') {
                analyzerName = 'Unassigned';
              } else {
                analyzerName = 'Deleted (' + dataArr[x].objectId + ')';
              }
            }
            dbDataArrB.push(analyzerName);
          } else if (q == '2') {
            let totalProfitPercentage =
              'Profit Hit %:' +
              (dataArr[x].finalProfitPercentage
                ? Math.round(dataArr[x].finalProfitPercentage)
                : 0) +
              '%';
            dbDataArrB.push(totalProfitPercentage);
          } else if (q == '3') {
            let projectCount = dataArr[x].projectCount ? dataArr[x].projectCount : 0;
            dbDataArrB.push(projectCount);
            
          } else if (dbHeaderArr[q] === 'Contract Amt.') {
            var totalBidContract = dataArr[x].allContract
              ? roundFormatMoney(dataArr[x].allContract)
              : 0;
            dbDataArrB.push(totalBidContract);
          } else if (dbHeaderArr[q] === 'Profit (est.)') {
            var totalBidProfit = dataArr[x].allProfit
              ? roundFormatMoney(dataArr[x].allProfit)
              : 0;
            dbDataArrB.push(totalBidProfit);
          } else if (dbHeaderArr[q] === 'Profit %') {
            var totalBidProfit = dataArr[x].allProfit
              ? roundFormatMoney(dataArr[x].allProfit)
              : 0;
            dbDataArrB.push(totalBidProfit);
          } else if (dbHeaderArr[q] === 'Contract Amt. Result') {
            var totalWonContract = dataArr[x].jobStatusAmountSumCont
              ? roundFormatMoney(dataArr[x].jobStatusAmountSumCont)
              : 0;
            dbDataArrB.push(totalWonContract);
          } else if (dbHeaderArr[q] === 'Profit (est.) Result') {
            var totalWonProfit = dataArr[x].jobStatusAmountSumProfit
              ? roundFormatMoney(dataArr[x].jobStatusAmountSumProfit)
              : 0;
            dbDataArrB.push(totalWonProfit);
          } else if (dbHeaderArr[q] === 'Profit % Result') {
            var totalWonProfitPer =
              (dataArr[x].wonProfitPercent ? Math.round(dataArr[x].wonProfitPercent) : 0) + '%';
            dbDataArrB.push(totalWonProfitPer);
          } else {
            dbDataArrB.push('');
          }
        }
        dbDataArr.push(dbDataArrB);
        dbDataArr.push(['']);
      }

      // net total

      dbDataArrB = [];
      dbDataArrB.push('Company Totals: ');

      let FbidHitRatio =
        finalSum.FWLCount > 0 && finalSum.FWLBidRatio
          ? Math.round(finalSum.FWLBidRatio) + '%'
          : '-';

      dbDataArrB.push(FbidHitRatio);

      let FtotalProfitPercentage =
        finalSum.FWLCount > 0 && finalSum.FProfitWLSum
          ? Math.round(finalSum.FProfitWLSum) + '%'
          : '-';

      dbDataArrB.push(FtotalProfitPercentage);

      let FprojectCount = finalSum.FProjectSum ? finalSum.FProjectSum : 0;
      dbDataArrB.push(FprojectCount);

      dbDataArrB.push('');
      dbDataArrB.push('');
      dbDataArrB.push('');
      dbDataArrB.push('');

      let FtotalBidContract = finalSum.FBidContractSum
        ? roundFormatMoney(finalSum.FBidContractSum)
        : 0;
      dbDataArrB.push(FtotalBidContract);

      let FtotalBidProfit = finalSum.FBidProfitSum
        ? roundFormatMoney(finalSum.FBidProfitSum)
        : 0;
      dbDataArrB.push(FtotalBidProfit);

      let FtotalBidProfitPer =
        (finalSum.FBidProfitSum / finalSum.FBidContractSum
          ? Math.round((finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100)
          : 0) + '%';

      dbDataArrB.push(FtotalBidProfitPer);

      dbDataArrB.push('');

      let FtotalWonContract = finalSum.FWonContractSum
        ? roundFormatMoney(finalSum.FWonContractSum)
        : 0;
      dbDataArrB.push(FtotalWonContract);

      let FtotalWonProfit = finalSum.FWonProfitSum
        ? roundFormatMoney(finalSum.FWonProfitSum)
        : 0;
      dbDataArrB.push(FtotalWonProfit);

      var FtotalWonProfitPer =
        (finalSum.FWonProfitSum / finalSum.FWonContractSum
          ? Math.round((finalSum.FWonProfitSum / finalSum.FWonContractSum) * 100)
          : 0) + '%';
      dbDataArrB.push(FtotalWonProfitPer);

      dbDataArr.push(dbDataArrB);
    }

    this.setState({ multiDataSet: dbDataArr });
  };

  render() {
    let yearPickerValues = [];
    let optionYears = yearPickerValue(yearPickerValues);

    const { formData, multiDataSet } = this.state;

    this.dataCalculation();

    let tableOption = appConstants.TableOption;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['noDataText'] = this._setTableOption();
    tableOption['btnGroup'] = this.createCustomButtonGroup;

    const rolesLoaded = this.state.projectRoleTypes.length > 0;
    const analyzeByOptions = rolesLoaded && this.state.OptionStatus;

    const roles = this.state.projectRoleTypes;

    const estShort = (rolesLoaded && roles.find((role) => role.code == 'EST'))?.shortName ?? 'EST';
    const projMgrShort =
      (rolesLoaded && roles.find((role) => role.code == 'PM'))?.shortName ?? 'PM';

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Bid Result Recap Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>

          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead no-print'>
                <h2>Bid Result Recap Report</h2>
                <ExportExcelComponent
                  reportName='Bid_Result_Recap_Report'
                  onClick={this.excelExport.bind(this)}
                  data={multiDataSet}
                />
                <ReactToPrint
                  trigger={() => (
                    <a href='#/' className='btn rightalignmentBtn'>
                      <i className='fa fa-print' aria-hidden='true'></i> Print PDF
                    </a>
                  )}
                  content={() => this.componentRef}
                />
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>

              <div
                className='row col-xl-10 col-lg-12 col-md-12'
                ref={(el) => (this.componentRef = el)}
              >
                <div className='searchbar'>
                  <div className='col-md-12'>
                    <div className='pdfLogoBox'>
                      <div className='reportHead'>
                        <div className='logo'>
                          <img src={this.state.comLogo} alt='Upload Logo' />
                        </div>
                        <h2>Bid Result Recap Report</h2>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='analyzedBy'>
                      <strong>Analyzed By:</strong> {analyzeStatus ? analyzeStatus : ''}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div>
                      <strong>Reporting Period :</strong> {periodStatus ? periodStatus : ''}{' '}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='profit-projection reportTableSection bigTable bigTable1'>
                      <table>
                        <thead>
                          <tr>
                            <th colSpan='8'>&nbsp;</th>
                            <th colSpan='3' className='text-center'>
                              Jobs Bid
                            </th>
                            <th colSpan='5' className='text-center'>
                              Bid Outcome
                            </th>
                          </tr>
                          <tr>
                            <th width='80'>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('jobNum', sortOrders);
                                }}
                              >
                                Job Num.
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('altJobNum', sortOrders);
                                }}
                              >
                                Alt Job#
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th width='150'>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('jobName', sortOrders);
                                }}
                              >
                                Job Name
                              </span>
                            </th>
                            <th>Status</th>
                            <th>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('bidDue', sortOrders);
                                }}
                              >
                                Due Date
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('bidDue', sortOrders);
                                }}
                              >
                                Bid Date
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th width='80'>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('estimatorArr', sortOrders);
                                }}
                              >
                                {estShort}
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className='cursorPointer'
                                onClick={(e) => {
                                  this.onSortChange('projectManagerArr', sortOrders);
                                }}
                              >
                                {projMgrShort}
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                              </span>
                            </th>
                            <th className='textRight'>Contract Amt.</th>
                            <th className='textRight'>Profit (est.)</th>
                            <th>Profit %</th>
                            <th>W/L/O</th>
                            <th className='textRight'>Contract Amt.</th>
                            <th className='textRight'>Profit (est.)</th>
                            <th>Profit %</th>
                          </tr>
                        </thead>
                        <tbody>{tableData}</tbody>
                      </table>
                      {this.state.fetchRequest && (
                        <ReactLoading
                          className='table-loader'
                          type={appConstants.LOADER_TYPE}
                          color={appConstants.LOADER_COLOR}
                          height={appConstants.LOADER_HEIGHT}
                          width={appConstants.LOADER_WIDTH}
                        />
                      )}
                      {!this.state.fetchRequest &&
                        this.state.list &&
                        this.state.list.length == 0 && (
                          <div className='textCenter'>No data found.</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select</ModalHeader>
          <ModalBody className='reportPopupFilter'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  {analyzeByOptions && (
                    <div className='form-group'>
                      <label htmlFor='analyzeBy'>Analyze By</label>
                      <Select
                        name='analyzeBy'
                        components={makeAnimated()}
                        value={this.state.formData.analyzeBy}
                        selected={this.state.formData.analyzeBy}
                        options={analyzeByOptions}
                        onChange={this.handleChangeSelectBox.bind(this, 'analyzeBy')}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group margin0'>
                    <label htmlFor='status'>Select Reporting Period</label>
                  </div>
                </div>
              </div>
              <div className='form-group margin0'>
                <div className='row'>
                  <div className='col-md-3 lineheight'>
                    <Input
                      type='radio'
                      id='year'
                      name='period'
                      checked={this.state.period === 'year'}
                      onClick={this.handleChangePeriod.bind(this, 'year')}
                    />{' '}
                    <label htmlFor='year'>Year</label>
                  </div>

                  <div className='col-md-9'>
                    {this.state.period === 'year' && (
                      <div>
                        <Select
                          name='year'
                          components={makeAnimated()}
                          value={this.state.selectedYear}
                          onChange={this.handleYearChange.bind(this)}
                          options={optionYears}
                        />

                        <span className='error'>{this.state.errMsg.year}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='form-group margin0'>
                <div className='row'>
                  <div className='col-md-3 lineheight'>
                    <Input
                      type='radio'
                      name='period'
                      id='month'
                      checked={this.state.period === 'month'}
                      onClick={this.handleChangePeriod.bind(this, 'month')}
                    />{' '}
                    <label htmlFor='month'>Month</label>
                  </div>
                  <div className='col-md-9'>
                    {this.state.period === 'month' && (
                      <div>
                        <Select
                          name='month'
                          value={this.state.selectedMonth}
                          components={makeAnimated()}
                          options={this.state.optionMonths}
                          onChange={this.handleChangePeriodValue.bind(this, 'month')}
                        />
                        <span className='error'>{this.state.errMsg.month}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='form-group margin0'>
                <div className='row'>
                  <div className='col-md-3 lineheight'>
                    <Input
                      type='radio'
                      id='quarter'
                      name='period'
                      checked={this.state.period === 'quarter'}
                      onClick={this.handleChangePeriod.bind(this, 'quarter')}
                    />{' '}
                    <label htmlFor='quarter'>Quarter</label>
                  </div>
                  <div className='col-md-9'>
                    {this.state.period === 'quarter' && (
                      <div>
                        <Select
                          name='quarter'
                          components={makeAnimated()}
                          value={this.state.formData.quarter}
                          options={this.state.OptionProfitSaleQuarter}
                          onChange={this.handleChangeSelectBox.bind(this, 'quarter')}
                        />
                        <span className='error'>{this.state.errMsg.quarter}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='form-group margin0'>
                <div className='row'>
                  <div className='col-md-12 lineheight'>
                    <Input
                      type='radio'
                      id='other'
                      name='period'
                      checked={this.state.period === 'other'}
                      onClick={this.handleChangePeriod.bind(this, 'other')}
                    />{' '}
                    <label htmlFor='other'>Other</label>
                  </div>
                </div>

                <div className='form-group'>
                  {this.state.period === 'other' && (
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='startDate'>Start Date</label>
                        <CustomDateInput
                          name='startDate'
                          className='form-control'
                          selected={this.state.selectDateBox.startDate}
                          onChange={this.handleChangePeriodDate.bind(this, 'startDate')}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          selectsStart
                          todayButton='Today'
                          showMonthDropdown
                        />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='endDate'>End Date</label>
                        <CustomDateInput
                          name='endDate'
                          className='form-control'
                          selected={this.state.selectDateBox.endDate}
                          onChange={this.handleChangePeriodDate.bind(this, 'endDate')}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          selectsEnd
                          todayButton={this.state.todayButton}
                          showMonthDropdown
                          minDate={formatDateObject(this.state.selectDateBox.startDate).add(
                            1,
                            'day'
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='container'>
              <div className='row'>
                <div className='col-md-6'></div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn btn-primary' disabled={errMsg} onClick={this.filter.bind(this)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.bidResultRecapList,
    projectRoles: state.projectRoleType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetBidResultRecapAction,
    getAllProjectRoleAction,
  })(BidResultRecapReport)
);
