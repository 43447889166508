import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { appConstants } from '../../../_constants';
import {
  GetProfitSalesAction,
  GetProfitSalesReportPrefAction,
  SetProfitSalesReportPrefAction,
} from '../../../actions/reports';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  formatDateObject,
  formatDate,
  formatDateMMDDYY,
} from '../../../utils/date-formatters';
import _ from 'lodash';
import { formatExternalContactData } from '../../../utils/contact-formatters.jsx';
import { formatMoneyParens, formatNumber, roundFormatMoney } from '../../../utils/number-formatters';

import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import CustomTable, { customSortByKey } from '../../Common/CustomTable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import TitleComponent from '../../Common/TitleComponent';
import { ResponsivePie } from '@nivo/pie';
import DateRangeSelector from '../../../shared/report-date-range-selector/DateRangeSelector';
import moment from 'moment';

const ProfitSalesReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [jobInclusionLabel, setJobInclusionLabel] = useState('');
  const jobInclusionLabelOptions = appConstants.OptionProfitSaleJobs.map((job) => ({
    value: job.value,
    label: job.name,
  }));
  const [analysisType, setAnalysisType] = useState('sales');
  const [activeAndDeliveredStatus, setActiveAndDeliveredStatus] = useState(true);
  const [includeBiddingJobs, setIncludeBiddingJobs] = useState(true);
  const [analyzeBy, setAnalyzeBy] = useState('');

  const [reportData, setReportData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const [state, setState] = useState({
    accountId: localStorage.getItem('accountId'),
    userId: localStorage.getItem('userId'),
    selectBox: {},
    modal: false,
    fetchRequest: true,
    reportStartDate: Date(),
    reportEndDate: Date(),
    OptionStatus: [],
    projectRoleTypes: [],
    dateRange: {
      mode: 'year',
      value: new Date().getFullYear().toString(),
      startDate: moment().startOf('year').toDate(),
      endDate: moment().endOf('year').toDate(),
    },
    hidden: true,
    notHidden: false,
    activeStatus: true,
    includeBiddingJobs: true,
    profitAndSalesListPref: {},
    isPrefsInitialized: false,
    noData: true,
  });

  const [analyzeByOptions, setAnalyzeByOptions] = useState([]);

  const listData = useSelector((state) => state.reports.profitAndSalesList);
  const profitAndSalesListPref = useSelector((state) => state.reports.profitAndSalesListPref);
  const projectRoles = useSelector((state) => state.projectRoleType);

  useEffect(() => {
    setState({...state,isPrefsInitialized: false, projectRoleTypes: [], OptionStatus: []});
    dispatch(
      getAllProjectRoleAction({
        className: 'projectRoleTypes',
        accountId: state.accountId,
      })
    );

    dispatch(GetProfitSalesReportPrefAction());
  }, []);

  useEffect(() => {
    if (projectRoles?.allProjectRoleTypes?.length > 0 && state.projectRoleTypes.length === 0) {
      const allRoles = projectRoles.allProjectRoleTypes;
      const OptionProfitSalesStatus = [
        { label: 'Building Type', value: 'buildingType' },
        { label: 'Occupancy Type', value: 'occupancyType' },
        { label: 'Negotiation Method ', value: 'negotiatingMethod' },
        { label: 'Job Scope', value: 'scopeArr' },
      ];

      const roleOptions = allRoles.map((role) => ({ label: role.name, value: role.code }));
      const optionStatus = roleOptions.concat(OptionProfitSalesStatus);

      const selectBox = state.selectBox;
      if (!selectBox.analyzeBy?.value) {
        selectBox.analyzeBy = { value: optionStatus[0].value, label: optionStatus[0].label };
        setAnalysisType(optionStatus[0].value);
      }
      if (!selectBox.jobBasedOn?.value) {
        selectBox.jobBasedOn = jobInclusionLabelOptions[0];
        setJobInclusionLabel(jobInclusionLabelOptions[0].value);
      }

      setState({ ...state, projectRoleTypes: allRoles, OptionStatus: optionStatus, selectBox });
      setAnalyzeByOptions(optionStatus);
    }

    if (listData && listData.success) {
      setReportData(listData.data);
      setState({ ...state, fetchRequest: false });
    }
  }, [projectRoles, listData, state.projectRoleTypes]);

  useEffect(() => {
    if (_.isEmpty(profitAndSalesListPref) && analyzeByOptions.length>0){
      toggleFilter();
    } else if ( analyzeByOptions.length > 0) {
      const {
        analyzeBy,
        jobBasedOn,
        whatToAnalyze,
        includeBiddingJobs,
        activeAndDeliveredStatus,
        dateRange
      } = profitAndSalesListPref;

      const selectedAnalyzeBy = analyzeByOptions.find((el) => el.value === analyzeBy|| el?.id === analyzeBy);
      const selectedJobBasedOn = jobInclusionLabelOptions.find((el) => el.value === jobBasedOn);

      const isTodayVisible = jobBasedOn === 'activeAndDelivered';

      setJobInclusionLabel(selectedJobBasedOn);
      setAnalysisType(whatToAnalyze);
      setActiveAndDeliveredStatus(activeAndDeliveredStatus);
      setIncludeBiddingJobs(includeBiddingJobs);
      setAnalyzeBy(selectedAnalyzeBy);

      setState({
        ...state,
        dateRange: {
          mode: dateRange?.mode ?? 'year',
          value: dateRange?.periodValue ?? new Date().getFullYear().toString(),
          startDate: dateRange?.startDate ? new Date(dateRange.startDate) : formatDateObject().startOf('year'),
          endDate: dateRange?.endDate ? new Date(dateRange.endDate) : formatDateObject().endOf('year'),
        },
        hidden: !isTodayVisible,
        notHidden: isTodayVisible,
        selectBox: {
          analyzeBy: selectedAnalyzeBy,
          jobBasedOn: selectedJobBasedOn,
        },
        isPrefsInitialized: true,
      });
    }
  }, [profitAndSalesListPref, analyzeByOptions]);

  useEffect(() => {
    if (state.isPrefsInitialized) {
      submitReportRequest();
    }
  }, [state.isPrefsInitialized]);

  const toggleFilter = (shouldClose = false) => {
    if (shouldClose) {
      if (!state.modal) return;
      setState({ ...state, modal: false });
      return;
    }
    setState({ ...state, modal: !state.modal });
  };

  const handleChangeBox = (name, value) => {
    switch (name) {
      case 'analyzeBy':
        setAnalyzeBy(value);
        break;
      case 'jobBasedOn':
        if (value.value === 'activeAndDelivered') {
          setState({ ...state, hidden: false, notHidden: true });
          setJobInclusionLabel(value);
        } else {
          setState({ ...state, hidden: true, notHidden: false });
          setJobInclusionLabel(value);
        }
        break;
    }
  };

  const handleChangeRadio = (e) => {
    setAnalysisType(e.target.value);
  };

  const changeWhatToAnalyze = (e) => {
    if (state.notHidden) {
      setActiveAndDeliveredStatus(!activeAndDeliveredStatus);
    } else if (state.hidden) {
      setIncludeBiddingJobs(!includeBiddingJobs);
    }
  };

  const [reportDataCustom, setReportDataCustom] = useState([]);

  const dataCalculation = (dataArr) => {
    if (!dataArr || dataArr?.length == 0) return;

    let finalSum = [];
    finalSum.FProjectSum = 0;
    finalSum.FBidContractSum = 0;
    finalSum.FBidProfitSum = 0;
    finalSum.FBidProfitPerSum = 0;

    let pieChartTotal = 0;

    let processedProjects = [];

    let projectTable = [];

    if (dataArr?.length > 0) {
      setState({ ...state, noData: false });
      for (let x in dataArr) {
        dataArr[x].allContract = 0;
        dataArr[x].allProfit = 0;
        dataArr[x].includeBiddingJobsAmountSumCont = 0;
        dataArr[x].jobBidLProfit = 0;
        dataArr[x].jobBidWLProfit = 0;
        dataArr[x].includeBiddingJobsAmountSumProfit = 0;
        dataArr[x].subTotal = [];
        dataArr[x].analyzer = '';

        //*** Analyser name
        if (
          dataArr[x].isContactAnalyzeBy &&
          dataArr[x].itemInfo &&
          dataArr[x].itemInfo?.displayNameWithCompany?.length > 0
        ) {
          dataArr[x].analyzer = dataArr[x].itemInfo?.displayNameWithCompany;
        } else if (dataArr[x].label && dataArr[x].objectId != 'undefined') {
          dataArr[x].analyzer = dataArr[x].label;
        } else {
          dataArr[x].analyzer = 'Unassigned';
        }

        for (let y in dataArr[x].projects) {
          dataArr[x].projects[y].perProjectBidProfit = 0;
          dataArr[x].projects[y].selectedDate = '';

          const curProject = dataArr[x].projects[y];
          const useForGlobal =
            processedProjects.length == 0 || !processedProjects.includes(curProject.objectId);
          if (useForGlobal) {
            processedProjects.push(curProject.objectId);
          }

          dataArr[x].projects[y].useForGlobal = useForGlobal;

          dataArr[x].projects[y].perProjectBidProfit = 0;
          dataArr[x].projects[y].selectedDate = '';

          const currentContractAmount = curProject.currentContractAmount;
          const currentGrossProfit = curProject.currentGrossProfit;

          if (useForGlobal) {
            finalSum.FBidContractSum += currentContractAmount;
            finalSum.FBidProfitSum += currentGrossProfit;
          }

          //**** Total Bid amount and profit amount
          dataArr[x].allContract += dataArr[x].projects[y].currentContractAmount;
          dataArr[x].allProfit += dataArr[x].projects[y].currentGrossProfit;

          //**** Total won amount and profit amount
          let jobBasedOn = state.jobBasedOn;
          // if(jobBasedOn === 'activeAndDelivered'){
          //   console.log('&&&&&&&&&&&&&&&&')
          //   dataArr[x].projects[y].selectedDate = formatDateObject(new Date());
          // }else{
          dataArr[x].projects[y].selectedDate =
            dataArr[x].projects[y][jobBasedOn] && dataArr[x].projects[y][jobBasedOn].iso;
          //}

          //*** Calculate profit percentage for bid jobS
          if (
            isNaN(
              dataArr[x].projects[y].currentGrossProfit /
                dataArr[x].projects[y].currentContractAmount
            ) === true
          ) {
            dataArr[x].projects[y].perProjectBidProfit = 0.0;
          } else {
            dataArr[x].projects[y].perProjectBidProfit =
              (dataArr[x].projects[y].currentGrossProfit /
                dataArr[x].projects[y].currentContractAmount) *
              100;
            dataArr[x].projects[y].perProjectBidProfit = Math.round(
              dataArr[x].projects[y].perProjectBidProfit,
              2
            );
          }

          projectTable.push({
            ...dataArr[x].projects[y],
            analyzer: dataArr[x].analyzer,
            analyzerId: dataArr[x].objectId,
            isContact: dataArr[x].isContactAnalyzeBy,
            contactId: dataArr[x].itemInfo.contactId,
            companyId: dataArr[x].itemInfo.companyId,
          });
        }

        //**** Final profit
        dataArr[x].jobBidWLProfit =
          dataArr[x].jobBidLProfit + dataArr[x].includeBiddingJobsAmountSumProfit;
        if (
          isNaN(dataArr[x].includeBiddingJobsAmountSumProfit / dataArr[x].jobBidWLProfit) === true
        ) {
          dataArr[x].finalProfitPercentage = 0;
        } else {
          dataArr[x].finalProfitPercentage =
            (dataArr[x].includeBiddingJobsAmountSumProfit / dataArr[x].jobBidWLProfit) * 100;
        }

        //**** bid Profit percentage
        if (dataArr[x].bidProfitPercent === undefined) {
          if (isNaN(dataArr[x].allProfit / dataArr[x].allContract) === true) {
            dataArr[x].bidProfitPercent = 0.0;
          } else {
            dataArr[x].bidProfitPercent = (dataArr[x].allProfit / dataArr[x].allContract) * 100;
            dataArr[x].bidProfitPercent = dataArr[x].bidProfitPercent.toFixed(2);
          }
        }

        //*** Total project, Net Amount of Bid Contract/Profit/ProfitPercentage, Won Contract/Profit/ProfitPercentage, Bid hit ratio and Profit
        //finalSum.FBidProfitPerSum += Math.round(dataArr[x].bidProfitPercent);

        // if(dataArr[x].bidProfitPercent===undefined){

        //}
      }

      if (
        isNaN(finalSum.FBidProfitSum / finalSum.FBidContractSum) === true ||
        finalSum.FBidContractSum === 0
      ) {
        finalSum.FBidProfitPerSum = 0.0;
      } else {
        finalSum.FBidProfitPerSum = (finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100;
        finalSum.FBidProfitPerSum = finalSum.FBidProfitPerSum.toFixed(2);
      }

      let graphDataNew = [];
      let tableData = [];

      for (let x in dataArr) {
        for (let y in dataArr[x].projects) {
          let row = dataArr[x].projects[y];

          // Project list data
          let jobNum = (
            <td>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].jobNumFormatted
                  ? dataArr[x].projects[y].jobNumFormatted
                  : '-'}
              </Link>
            </td>
          );
          let altNum = (
            <td>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].altJobNum ? dataArr[x].projects[y].altJobNum : '-'}
              </Link>
            </td>
          );
          var jobName = (
            <td>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {dataArr[x].projects[y].jobName ? dataArr[x].projects[y].jobName : '-'}
              </Link>
            </td>
          );
          var dueDate = (
            <td hidden={state.notHidden}>
              {dataArr[x].projects[y][jobInclusionLabel.value ?? 'bidDate']
                ? formatDate(dataArr[x].projects[y][jobInclusionLabel.value ?? 'bidDate'])
                : '-'}
            </td>
          );
          var bidContract = (
            <td className='textRight'>
              {dataArr[x].projects[y].currentContractAmount
                ? formatNumber(Math.round(dataArr[x].projects[y].currentContractAmount))
                : 0}
            </td>
          );
          var bidProfit = (
            <td className='textRight'>
              {dataArr[x].projects[y].currentGrossProfit
                ? formatNumber(Math.round(dataArr[x].projects[y].currentGrossProfit))
                : 0}
            </td>
          );
          var bidProfitPer = (
            <td>
              {dataArr[x].projects[y].perProjectBidProfit
                ? Math.round(dataArr[x].projects[y].perProjectBidProfit)
                : 0}
              %
            </td>
          );

          var tablerow = (
            <tr>
              {jobNum}
              {altNum}
              {jobName}
              {dueDate}
              {bidContract}
              {bidProfit}
              {bidProfitPer}
            </tr>
          );
          tableData.push(tablerow);
        }

        //Sub total
        var title = <td>Jobs: </td>;
        var totalBidContract = (
          <td className='textRight'>
            {dataArr[x].allContract ? roundFormatMoney(dataArr[x].allContract) : 0}
          </td>
        );
        var totalBidProfit = (
          <td className='textRight'>
            {dataArr[x].allProfit ? roundFormatMoney(dataArr[x].allProfit) : 0}
          </td>
        );
        var totalBidProfitPer = (
          <td>{dataArr[x].bidProfitPercent ? Math.round(dataArr[x].bidProfitPercent) : 0}%</td>
        );
        var td3 = <td hidden={state.notHidden}></td>;
        var td4 = <td></td>;

        let analyzerName =
          dataArr[x]?.isContactAnalyzeBy && dataArr[x].itemInfo.objectId ? (
            <td>
              {formatExternalContactData(
                [dataArr[x].itemInfo.objectId],
                [dataArr[x].itemInfo],
                false,
                true
              )}
            </td>
          ) : dataArr[x].label?.length > 0 ? (
            <td>{dataArr[x].label}</td>
          ) : (
            <td>Unassigned</td>
          );

        var sr = (
          <tr className='border border2 subtotal'>
            {title}
            {analyzerName}
            {td3}
            {td4}
            {totalBidContract}
            {totalBidProfit}
            {totalBidProfitPer}
          </tr>
        );
        tableData.push(sr);

        // graph data Calculation
        let pieChartPer = 0;

        if (analysisType === 'sales') {
          //pieChartPer = (dataArr[x].allContract/finalSum.FBidContractSum)*100
          if (dataArr[x].allContract > 0) {
            pieChartPer = dataArr[x].allContract;
          }
        } else if (analysisType === 'profits') {
          //pieChartPer = (dataArr[x].allProfit/finalSum.FBidProfitSum)*100
          if (dataArr[x].allProfit > 0) {
            pieChartPer = dataArr[x].allProfit;
          }
        }

        const dataGraphArr = { id: dataArr[x].analyzer, value: pieChartPer };
        if (dataArr[x].analyzer === 'Unassigned') {
          graphDataNew.unshift(dataGraphArr);
        } else {
          graphDataNew.push(dataGraphArr);
        }
        pieChartTotal += pieChartPer;
      }

      const minPercentage = 5;
      const minValue = pieChartTotal * (minPercentage / 100);
      const otherData = graphDataNew.filter((item) => item.value < minValue);
      const otherDataTotal = otherData.reduce((acc, item) => acc + item.value, 0);
      const otherDataItem = { id: 'Other', value: otherDataTotal };
      const filteredData = graphDataNew.filter((item) => item.value >= minValue);
      const graphDataNewFinal = [...filteredData, otherDataItem];

      if (otherDataTotal > 0) {
        graphDataNew = graphDataNewFinal;
      } else {
        // setGraphData(graphDataNew);
      }

      // net total
      var netTotal = <td>Company Totals: </td>;
      var td11 = <td hidden={state.notHidden}></td>;
      var td21 = <td></td>;
      var FtotalBidContract = (
        <td className='textRight'>
          {finalSum.FBidContractSum ? roundFormatMoney(finalSum.FBidContractSum) : 0}
        </td>
      );
      var FtotalBidProfit = (
        <td className='textRight'>
          {finalSum.FBidProfitSum ? roundFormatMoney(finalSum.FBidProfitSum) : 0}
        </td>
      );
      var FtotalBidProfitPer = (
        <td>{finalSum.FBidProfitPerSum ? Math.round(finalSum.FBidProfitPerSum) : 0}%</td>
      );

      var sr3 = (
        <tr className='border border2 total'>
          {netTotal}
          {td11}
          {td21}
          {td21}
          {FtotalBidContract}
          {FtotalBidProfit}
          {FtotalBidProfitPer}
        </tr>
      );
      tableData.push(sr3);

      setGraphData(graphDataNew);
      setReportDataCustom(projectTable);
      buildColumns(finalSum);
    }
  };

  const [timePeriodLabel, setTimePeriodLabel] = useState('');
  const [jobsBasedOnLabel, setJobsBasedOnLabel] = useState('');
  const [analyzeByLabel, setAnalyzeByLabel] = useState('');

  const submitReportRequest = (e) => {
    setState({ ...state, fetchRequest: true });
    let data = {};

    data['accountId'] = state.accountId;
    data['analyzeBy'] = analyzeBy?.value;
    data['whatToAnalyze'] = analysisType;
    data['dateRange'] = state.dateRange;

    if (state.notHidden) {
      data['includeBiddingJobs'] = false;
      data['activeAndDeliveredStatus'] = activeAndDeliveredStatus;
    } else if (state.hidden) {
      data['activeAndDeliveredStatus'] = false;
      data['includeBiddingJobs'] = includeBiddingJobs;

      data['startDate'] = state.dateRange.startDate;  
      data['endDate'] = state.dateRange.endDate;
      
    }

    data['jobBasedOn'] = jobInclusionLabel?.value ?? 'bidDate';

    setState({ ...state, fetchRequest: true, noData: true });
    setReportData([]);

    toggleFilter(true);

    if (!state.isPrefsInitialized) {
      setState({ ...state, isPrefsInitialized: true });
    }
    dispatch(SetProfitSalesReportPrefAction(data));
    dispatch(GetProfitSalesAction(data));

    setColumns([]);

    setTimePeriodLabel(
      <span>
        {formatDateMMDDYY(state.dateRange.startDate)} - {formatDateMMDDYY(state.dateRange.endDate)}
      </span>
    );


    setJobsBasedOnLabel(
      <span>
        {jobInclusionLabel?.label}
      </span>
    );

    setAnalyzeByLabel(<span>{analyzeBy?.label}</span>);

    setReportData([]);
    setGraphData([]);
  };

  useEffect(() => {
    dataCalculation(reportData);
  }, [reportData]);

  const dateName = state.hidden? (jobInclusionLabel?.label ?? 'Bid Date'): jobInclusionLabel?.label??'';
  const dateValue = state.hidden? jobInclusionLabel?.value ?? 'bidDate': jobInclusionLabel?.value??'';

  const [columns, setColumns] = useState([]);

  const buildColumns = (finalSum) => {
    const columns = [
      {
        Header: analyzeByLabel,
        id: 'analyzer',
        accessor: 'analyzer',
        show: false,
        canGroupBy: true,
        defaultGrouped: true,
        minWidth: 100,
        groupedColSpan: 5,
        hideMenu: true,
        hideGroupByExpand: true,

        Cell: (props) => {
          let contactId = props.row.original?.contactId;
          let companyId = props.row.original?.companyId;

          if (!contactId && props.row.isGrouped) {
            contactId = props.row.subRows[0].original?.contactId;
            companyId = props.row.subRows[0].original?.companyId;
          }

          return (
            <div style={{ display: 'contents' }}>
              <b>
                {contactId ? (
                  <a href={`/contact-details/${contactId}`}>{props.value}</a>
                ) : companyId ? (
                  <a href={`/company-details/${companyId}`}>{props.value}</a>
                ) : (
                  <>{props.value}</>
                )}
              </b>
            </div>
          );
        },
        aggregate: 'count',
        Aggregated: ({ cell }) => {
          return <>{cell.row.values.analyzer}</>;
        },

        Footer: () => {
          return <b> Company Totals:</b>;
        },
      },
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        width: 40,
        groupedColSpan: 0,
        hideMenu: true,
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/project-details/` + props.row.original?.objectId,
                state: { row: props.row.original },
              }}
            >
              {props.value}
            </Link>
          );
        },
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 40,
        hideMenu: true,
        groupedColSpan: 0,
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/project-details/` + props.row.original?.objectId,
                state: { row: props.row.original },
              }}
            >
              {props.value}
            </Link>
          );
        },
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 195,
        groupedColSpan: 1,
        hideMenu: true,
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/project-details/` + props.row.original?.objectId,
                state: { row: props.row.original },
              }}
            >
              {props.value}
            </Link>
          );
        },
      },
      {
        Header: dateName,
        groupedColSpan: 1,
        hideMenu: true,
        accessor: dateValue.value ? dateValue.value + '.iso' : 'bidDate.iso',
        Cell: (props) => {
          return (
            <div className={'text-center'}>
              {props.value ? formatDate(props.value) : props.row.subRows?.length > 0 ? '' : '-'}
            </div>
          );
        },
        show: state.notHidden,
        sortType: customSortByKey,
      },
      {
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        groupedColSpan: 1,
        hideMenu: true,
        Cell: (props) => {
          return <div className='text-end'>{roundFormatMoney(props.value)}</div>;
        },
        className: 'textRight',
        aggregate: 'sum',

        Aggregated: ({ value }) => {
          return (
            <div className='text-end'>
              <b>{`${roundFormatMoney(value)}`}</b>
            </div>
          );
        },
        Footer: () => {
          return (
            <div className='text-end'>
              <b>{roundFormatMoney(finalSum.FBidContractSum)}</b>
            </div>
          );
        },
      },
      {
        Header: 'Gross Profit',
        accessor: 'currentGrossProfit',
        groupedColSpan: 1,
        hideMenu: true,
        Cell: (props) => {
          return <div className='text-end'>{roundFormatMoney(props.value)}</div>;
        },
        className: 'textRight',
        aggregate: 'sum',
        Aggregated: ({ value }) => {
          return (
            <div className='text-end'>
              <b>{`${roundFormatMoney(value)}`}</b>
            </div>
          );
        },
        Footer: () => {
          return (
            <div className='text-end'>
              <b>{roundFormatMoney(finalSum.FBidProfitSum)}</b>
            </div>
          );
        },
      },
      {
        Header: 'Profit %',
        accessor: 'perProjectBidProfit',
        groupedColSpan: 1,
        hideMenu: true,
        Cell: (props) => {
          return (
            <div className='text-end'>{props.value ? Math.round(props.value) + '%' : 0 + '%'} </div>
          );
        },
        aggregate: 'average',
        Aggregated: ({ cell }) => {
          const rowValues = cell?.row?.values;
          const totalCtr = rowValues?.currentContractAmount;
          const totalProfit = rowValues?.currentGrossProfit;
          return (
            <div className='text-end'>
              <b>{`${((totalProfit * 100) / totalCtr).toFixed(1)}%`}</b>
            </div>
          );
        },
        Footer: () => {
          return (
            <div className='text-end'>
              <b>{finalSum.FBidProfitPerSum}%</b>
            </div>
          );
        },
      },
    ];

    setColumns(columns);
  };

  const [printContainer, setPrintContainer] = useState(null);
  const printLogo = localStorage.getItem('companyLogo') || appConstants.CONTENT_CPOST_LOGO_URL;

  const handleDateRangeChange = (newDateRange) => {
    setState((prevState) => ({
      ...prevState,
      dateRange: newDateRange,
    }));
  };

  return (
    <div>
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `Profit & Sales Report` }} />
        <div>
          <Suspense fallback='loading'>
            <Header props={history} />
          </Suspense>
        </div>

        <div className='contactBox'>
          <div className='container'>
            <div className='reportHead no-print'>
              <h2>Profit &amp; Sales Analysis Report</h2>
              {/*Excel export <br />
              Print*/}
              <button className='btn rightalignmentBtn' onClick={() => toggleFilter()}>
                <i className='fa fa-filter'></i> Apply Filters
              </button>
            </div>

            <div ref={(el) => setPrintContainer(el)}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='pdfLogoBox'>
                    <div className='reportHead'>
                      <div className='logo'>
                        <img src={printLogo} alt='Upload Logo' />
                      </div>
                      <h2>Profit &amp; Sales Analysis Report</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row psr'>
                { (!state.notHidden)&& (
                  <div className='col-md-4'>
                  <div>
                    <strong>Time Period: </strong>
                    {timePeriodLabel}
                  </div>
                </div>)}
                <div className='col-md-4'>
                  <div>
                    <strong>Select Jobs Based on: </strong>
                    {jobsBasedOnLabel}
                  </div>
                </div>
                <div className='col-md-4'>
                  <div>
                    <strong>Analyzed by: </strong> {analyzeByLabel}
                  </div>
                </div>
              </div>

              {reportData?.length > 0 && (
                <div className='row' style={{ minHeight: 300, fill: 'white' }}>
                  <div className='col svgGraph'>
                    <ResponsivePie
                      data={graphData}
                      margin={{ top: 30, right: 80, bottom: 40, left: 0 }}
                      innerRadius={0.5}
                      cornerRadius={3}
                      colors={{ scheme: 'tableau10' }}
                      borderWidth={1}
                      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                      padAngle={2}
                      arcLabelsTextColor={'#ffffff'}
                      arcLabelsSkipAngle={15}
                      // format values with commas and dollars
                      valueFormat={(value) => formatMoneyParens(value)}
                      arcLabel={(e) => roundFormatMoney((e.value / 1000), { prefix: '$' }) + 'k'}
                    />
                  </div>
                </div>
              )}

              <div className='row'>
                <div className='col-md-12'>
                  <div className='profit-projection reportTableSection bigTable bigTable1 profitSalesReport'>
                    <div className='table-responsive'>
                      <CustomTable
                        columns={columns}
                        data={reportDataCustom}
                        loading={state.fetchRequest}
                        noDataText='No data found.'
                        canShowFooter={true}
                        componentRef={printContainer}
                        groupUp={true}
                        pageSizeArray={[200, 500]}
                        canShowColumnSettings={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <Modal
        backdrop='static'
        keyboard={true}
        isOpen={state.modal}
        toggle={() => toggleFilter(true)}
        className={history.className}
      >
        <ModalHeader>Select</ModalHeader>
        <ModalBody>
          <div className='container'>
            <div className='form-group'>
              <div className='row'>
                <div className='col-md-6'>
                  <label htmlFor='analyzeBy'>
                    <strong>Analyze By</strong>
                  </label>
                  <Select
                    name='analyzeBy'
                    components={makeAnimated()}
                    value={analyzeBy}
                    options={analyzeByOptions ?? []}
                    onChange={(value) => handleChangeBox('analyzeBy', value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label htmlFor='jobBasedOn'>
                    <strong>Select Jobs Based on</strong>
                  </label>
                  <Select
                    name='jobBasedOn'
                    components={makeAnimated()}
                    value={jobInclusionLabel}
                    options={jobInclusionLabelOptions}
                    onChange={(value) => handleChangeBox('jobBasedOn', value)}
                  />
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label>
                    <strong>What to Analyze</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label>
                    <input
                      type='radio'
                      value='sales'
                      checked={analysisType === 'sales'}
                      onChange={handleChangeRadio}
                    />{' '}
                    Sales ($)&nbsp;&nbsp;
                  </label>
                  <label>
                    <input
                      type='radio'
                      value='profits'
                      checked={analysisType === 'profits'}
                      onChange={handleChangeRadio}
                    />{' '}
                    Profit ($)
                  </label>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='row mt-4'>
                <div className='col-md-6'>
                  <label htmlFor='includeBiddingJobs' hidden={state.notHidden}>
                    <input
                      type='checkbox'
                      style={{ transform: 'scale(1.3)', marginRight: 10 }}
                      className=''
                      name='includeBiddingJobs'
                      checked={includeBiddingJobs}
                      onChange={(e) => {
                        changeWhatToAnalyze(e);
                      }}
                    />
                    &nbsp; Include Active, PreCon &amp; Phase 1-3 Jobs
                  </label>
                  <label htmlFor='activeand' hidden={state.hidden}>
                    <input
                      type='checkbox'
                      style={{ transform: 'scale(1.3)', marginRight: 10 }}
                      className=''
                      name='activeAndDeliveredStatus'
                      checked={activeAndDeliveredStatus}
                      onChange={(e) => {
                        changeWhatToAnalyze(e);
                      }}
                    />
                    &nbsp; Also include Phase 1-3 Jobs
                  </label>
                </div>
                <div className='col-md-6'></div>
              </div>
            </div>

            <div hidden={state.notHidden}>

              <div className='row mt-4'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='status'>
                      <strong>Time Period</strong>
                    </label>
                    <DateRangeSelector
                      onChange={handleDateRangeChange}
                      initialMode={state.dateRange.mode}
                      initialValue={state.dateRange.value}
                      initialStart={state.dateRange.startDate}
                      initialEnd={state.dateRange.endDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => toggleFilter(true)}>
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              submitReportRequest(e);
              toggleFilter(true);
            }}
          >
            Next
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProfitSalesReport;
