import { createContext, useContext, useState } from 'react';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { getEnabledReports } from '../../modules/settings';
import { isAllowed } from '../../_constants';
import { notification } from 'antd';


const NavContext = createContext<any>({
  mainMenu: {
    leads: [],
    bidding: [],
    bidsPending: [],
    active: [],
    delivered: [],
    warranty: [],
    completed: [],
  },
});

export const NavigationProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();

  const [mainMenu, setMainMenu] = useState<any>({
    leads: [],
    bidding: [],
    bidsPending: [],
    active: [],
    delivered: [],
    warranty: [],
    completed: [],
  });

  const refreshMenu = () => {
    return dispatch(getEnabledReports())
      .unwrap()
      .then((data = []) => {
        setMainMenu(() => {
          const result = {
            leads: [],
            bidding: [],
            bidsPending: [],
            active: [],
            delivered: [],
            warranty: [],
            completed: [],
          } as any;

          if (!data.length) 
            {
              if(data.error||data.success === false)
              {
                const errorMsg = data.error || data.msg;
                console.log('Error in fetching data '+errorMsg);

                if(data?.code===419){
                  // show notification 
                  notification.error({
                    message: 'Session Expired',
                    description: 'Please login to continue',
                  });

                }

                if(data?.code===401||data?.code===419)
                {
                  
                  //Redirect to login page
                  window.location.href = '/login';

                }
              }
              
              return result;
            }

          data.forEach((settings: any) => {
            const item = {
              section: settings.section,
              label: settings.name,
              tooltip: settings.definition,
              value: settings._id,
              isAllowed: isAllowed(settings.permissions),
              reportType: settings.reportType,
              path: settings.isStatic ? settings.url : `/report-view/${settings.section}/${settings._id}`,
            };

            switch (item.section) {
              case 'leads':
                result.leads.push(item);
                break;
              case 'bidding':
                result.bidding.push(item);
                break;
              case 'bidsPending':
                result.bidsPending.push(item);
                break;
              case 'active':
                result.active.push(item);
                break;
              case 'delivered':
                result.delivered.push(item);
                break;
              case 'warranty':
                result.warranty.push(item);
                break;
              case 'completed':
                result.completed.push(item);
                break;
              default:
                break;
            }
          });

          return result;
        });
      }).catch(() => {

        notification.error({
          message: 'Error fetching data from server',
          description: 'Please check your internet connection and try again',
        });
      });
  };

  return (
    <NavContext.Provider
      value={{
        mainMenu,
        refreshMainMenu: refreshMenu,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = useContext(NavContext);

  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationProvider');
  }

  return context;
};
