import * as actionTypes from '../actions/actionTypes';
const initState = {
  message: '',
  statusBidDue: false,
  contactDropDownList: [],
  isReportDD: false,
  isReportDDError: false,
  isReport: false,
  isReportError: false,
  isSPReport: false,
  isSPReportError: false,
  buttonActive: false,
  netTotal: {},
  AddressLabelList: [],
  addressLabelListPref: null,
  leadSummaryList: [],
  leadSummaryListPref: null,
  contactReportList: [],
  contactListPref: null,
  LeadSourceList: [],
  leadSourceListPref: null,
  clientList: [],

  companiesList: [],
  companyJobList: [],
  companyJobListPref: null,
  companyListViewPref: null,
  isCompanyCreated: false,

  customizedList: false,
  isCustomizedList: false,
  isCustomizedListError: false,
  customizeProjectPref: null,

  bidHistoryList: [],
  bidHistoryListPref: null,
  
  bidsDueList: [],
  bidsDueListPref: null,
  noBidReportList: [],
  noBidReportListPref: null,
  bidPendingRecapList: [],
  bidPendingRecapListPref: null,
  bidResultRecapList: [],
  bidResultRecapListPref: null,
  lostJobsList: [],
  lostJobsListPref: null,
  activeProjectList: [],
  activeProjectListPref: null,
  masterConstructionList: [],
  masterConstructionListPref: null,
  contactJobList: [],
  contactJobListPref: null,
  projectPrelimInfoList: [],
  projectInfoHotSheetList: [],
  timeSheetList: [],
  projectHistory: [],
  projectHistoryPref: null,
  profitProjectList: [],
  profitProjectListForecast: [],
  profitAndSalesList: [],
  profitAndSalesListPref: null,
  profitPerWeekList: [],
  deliveredReportList: [],
  deliveredReportListPref: null,
  lienPeriodList: [],
  warrantyStatusList: [],
  warrantyStatusListPref: null,
  projectFollowUpList: [],
  projectFollowUpListPref: null,
  clientReferenceList: [],
  dataCheckReportList: [],
  dataCheckReportListPref: null,
  employeeRosterList: [],
  employeeRosterListPref: null,
  masterProjectLogsList: [],
  masterProjectLogListPref: null,
  profitProjectListPref: null,
  projectFunnelList: [],
  projectFunnelListPref: null,
};
export default (state = initState, action = {}) => {
  switch (action.type) {
    // Lead summary report
    case actionTypes.GET_PROJECT_LEAD_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        leadSummaryList: [],
        leadSummaryListPref: null,
      };
    case actionTypes.GET_PROJECT_LEAD_REPORT_SUCCESS:
      return {
        ...state,
        leadSummaryList: action.payload.response.data.result,
        message: 'Lead summary report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_LEAD_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        leadSummaryList: [],
      };

    case actionTypes.GET_PROJECT_LEAD_PREF_REQUEST:
      return {
        ...state,
        leadSummaryListPref: null,
      };
    case actionTypes.GET_PROJECT_LEAD_PREF_SUCCESS: {
      return {
        ...state,
        leadSummaryListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_PROJECT_LEAD_PREF_ERROR:
      return {
        ...state,
        leadSummaryListPref: null,
      };

      // Bid history report
    case actionTypes.GET_BID_HISTORY_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidHistory: false,
        buttonActive: false,
        bidHistoryList: [],
      };
    case actionTypes.GET_BID_HISTORY_REPORT_SUCCESS:
      return {
        ...state,
        bidHistoryList: action.payload.response.data.result,
        message: 'Bid history report Listed successfully',
        buttonActive: true,
        statusBidHistory: true,
      };
    case actionTypes.GET_BID_HISTORY_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidHistory: false,
        buttonActive: false,
        bidHistoryList: [],
      };

    // Bid due report
    case actionTypes.GET_BID_DUE_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        bidsDueList: [],
        bidsDueListPref: null,
      };
    case actionTypes.GET_BID_DUE_REPORT_SUCCESS:
      return {
        ...state,
        bidsDueList: action.payload.response.data.result,
        message: 'Bid due report Listed successfully',
        buttonActive: true,
        statusBidDue: true,
      };
    case actionTypes.GET_BID_DUE_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        bidsDueList: [],
      };
    case actionTypes.GET_BID_DUE_PREF_REQUEST:
      return {
        ...state,
        bidsDueListPref: null,
      };
    case actionTypes.GET_BID_DUE_PREF_SUCCESS: {
      return {
        ...state,
        bidsDueListPref: {
          //...state.bidsDueListPref,
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_BID_DUE_PREF_ERROR:
      return {
        ...state,
        bidsDueListPref: null,
      };

    // Lead source report
    case actionTypes.GET_LEAD_SOURCE_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        LeadSourceList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_LEAD_SOURCE_REPORT_SUCCESS:
      return {
        ...state,
        LeadSourceList: action.payload.response.data.result,
        message: 'Lead source report Listed Successfully',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_LEAD_SOURCE_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        LeadSourceList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };
    case actionTypes.GET_LEAD_SOURCE_PREF_REQUEST:
      return {
        ...state,
        leadSourceListPref: null,
      };
    case actionTypes.GET_LEAD_SOURCE_PREF_SUCCESS: {
      return {
        ...state,
        leadSourceListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_LEAD_SOURCE_PREF_ERROR:
      return {
        ...state,
        leadSourceListPref: null,
      };

    case actionTypes.GET_PROFIT_PER_WEEK_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        profitPerWeekList: [],
      };
    case actionTypes.GET_PROFIT_PER_WEEK__REPORT_SUCCESS:
      return {
        profitPerWeekList: action.payload.response.data.result,
        message: 'Profit Per Week Report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_PROFIT_PER_WEEK__REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        profitPerWeekList: [],
      };

    // Bid pending report
    case actionTypes.GET_BID_PENDING_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        bidPendingRecapList: [],
      };
    case actionTypes.GET_BID_PENDING_REPORT_SUCCESS:
      return {
        ...state,
        bidPendingRecapList: action.payload.response.data.result,
        message: 'Bid pending report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_BID_PENDING_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        bidPendingRecapList: [],
      };
    case actionTypes.GET_BID_PENDING_PREF_REQUEST:
      return {
        ...state,
        bidPendingRecapListPref: null,
      };
    case actionTypes.GET_BID_PENDING_PREF_SUCCESS: {
      return {
        ...state,
        bidPendingRecapListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_BID_PENDING_PREF_ERROR:
      return {
        ...state,
        bidPendingRecapListPref: null,
      };

    // employee roaster report
    case actionTypes.GET_EMP_ROSTER_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        employeeRosterList: [],
      };
    case actionTypes.GET_EMP_ROSTER_REPORT_SUCCESS:
      return {
        ...state,
        employeeRosterList: action.payload.response.data.result,
        message: 'Employee Roster report listed successfully',
        statusBidDue: true,
        buttonActive: false,
      };
    case actionTypes.GET_EMP_ROSTER_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        employeeRosterList: [],
      };

    case actionTypes.GET_EMP_ROSTER_PREF_REQUEST:
      return {
        ...state,
        employeeRosterListPref: null,
      };
    case actionTypes.GET_EMP_ROSTER_PREF_SUCCESS: {
      return {
        ...state,
        employeeRosterListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_EMP_ROSTER_PREF_ERROR:
      return {
        ...state,
        employeeRosterListPref: null,
      };


    // active project report
    case actionTypes.GET_ACTIVE_PROJECT_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        activeProjectListPref: null,
        activeProjectList: [],
      };
    case actionTypes.GET_ACTIVE_PROJECT_REPORT_SUCCESS:
      return {
        ...state,
        activeProjectList: action.payload.response.data.result,
        message: 'Active Project report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_ACTIVE_PROJECT_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        activeProjectList: [],
      };

    case actionTypes.GET_ACTIVE_PROJECT_PREF_REQUEST:
      return {
        ...state,
        activeProjectListPref: null,
      };
    case actionTypes.GET_ACTIVE_PROJECT_PREF_SUCCESS: {
      return {
        ...state,
        activeProjectListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_ACTIVE_PROJECT_PREF_ERROR:
      return {
        ...state,
        activeProjectListPref: null,
      };

    //address label report
    case actionTypes.GET_ADDRESS_LABEL_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        AddressLabelList: [],
      };
    case actionTypes.GET_ADDRESS_LABEL_REPORT_SUCCESS:
      return {
        ...state,
        AddressLabelList: action.payload.response.data.result,
        message: 'Address Label Report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_ADDRESS_LABEL_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        AddressLabelList: [],
      };

    case actionTypes.GET_ADDRESS_LABEL_PREF_REQUEST:
      return {
        ...state,
        addressLabelListPref: null,
      };
    case actionTypes.GET_ADDRESS_LABEL_PREF_SUCCESS: {
      return {
        ...state,
        addressLabelListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_ADDRESS_LABEL_PREF_ERROR:
      return {
        ...state,
        addressLabelListPref: null,
      };

    //contact list report
    case actionTypes.GET_CONTACT_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        contactReportList: [],
      };
    case actionTypes.GET_CONTACT_LIST_REPORT_SUCCESS:
      return {
        ...state,
        contactReportList: action.payload.response.data.result,
        message: 'Contact List report listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_CONTACT_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        contactReportList: [],
      };

    case actionTypes.GET_CONTACT_LIST_PREF_REQUEST:
      return {
        ...state,
        contactListPref: null,
      };
    case actionTypes.GET_CONTACT_LIST_PREF_SUCCESS: {
      return {
        ...state,
        contactListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_CONTACT_LIST_PREF_ERROR:
      return {
        ...state,
        contactListPref: null,
      };


      //customize project list report
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        customizedList: false,
        isCustomizedList: false,
        isCustomizedListError: false,
      };
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS:
      return {
        ...state,
        customizedList: action.payload.response.data.result,
        statusBidDue: true,
        buttonActive: true,
        isCustomizedList: true,
        isCustomizedListError: false,
      };
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR:
      console.log('action.payload', action.payload);
      return {
        ...state,
        message: action.payload.error,
        success: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        customizedList: false,
        isCustomizedList: false,
        isCustomizedListError: true,
      };
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_PREF_REQUEST:
      return {
        ...state,
        customizeProjectPref: null,
      };
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_PREF_SUCCESS: {
      return {
        ...state,
        customizeProjectPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_CUSTOMIZE_PROJECT_LIST_PREF_ERROR:
      return {
        ...state,
        customizeProjectPref: null,
      };

    //customize projectClient
    case actionTypes.GET_CLIENT_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        clientList: [],
      };
    case actionTypes.GET_CLIENT_LIST_REPORT_SUCCESS:
      return {
        clientList: action.payload.response.data.result.data,
        contactData: action.payload.response.data.result.contactData,
        message: 'Client Successfully Listed successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_CLIENT_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        clientList: [],
      };

    //Company List Report
    case actionTypes.GET_COMPANY_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        isCompanyCreated: false,
        companiesList: [],
      };
    case actionTypes.GET_COMPANY_LIST_REPORT_SUCCESS:
      return {
        ...state,
        companiesList: action.payload.response.data.result,
        message: 'Company Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
        isCompanyCreated: true,
      };
    case actionTypes.GET_COMPANY_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        isCompanyCreated: false,
        companiesList: [],
      };
    case actionTypes.GET_COMPANY_LIST_VIEW_PREF_REQUEST:
      return {
        ...state,
        companyListViewPref: null,
      };
    case actionTypes.GET_COMPANY_LIST_VIEW_PREF_SUCCESS: {
      return {
        ...state,
        companyListViewPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_COMPANY_LIST_VIEW_PREF_ERROR:
      return {
        ...state,
        companyListViewPref: null,
      };

     
   
    //Contact Job List
    case actionTypes.GET_CONTACT_JOB_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        contactJobList: [],
      };
    case actionTypes.GET_CONTACT_JOB_LIST_REPORT_SUCCESS:
      return {
        ...state,
        contactJobList: action.payload.response.data.result,
        message: 'Contact Job List Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_CONTACT_JOB_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        contactJobList: [],
      };
    case actionTypes.GET_CONTACT_JOB_LIST_PREF_REQUEST:
      return {
        ...state,
        contactJobListPref: null,
      };
    case actionTypes.GET_CONTACT_JOB_LIST_PREF_SUCCESS: {
      return {
        ...state,
        contactJobListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_CONTACT_JOB_LIST_PREF_ERROR:
      return {
        ...state,
        contactJobListPref: null,
      };

    //Company Job List
    case actionTypes.GET_COMPANY_JOB_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        companyJobList: [],
      };
    case actionTypes.GET_COMPANY_JOB_LIST_REPORT_SUCCESS:
      return {
        ...state,
        companyJobList: action.payload.response.data.result,
        message: 'Company Job List Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_COMPANY_JOB_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        companyJobList: [],
      };
    case actionTypes.GET_COMPANY_JOB_LIST_PREF_REQUEST:
      return {
        ...state,
        companyJobListPref: null,
      };
    case actionTypes.GET_COMPANY_JOB_LIST_PREF_SUCCESS: {
      return {
        ...state,
        companyJobListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_COMPANY_JOB_LIST_PREF_ERROR:
      return {
        ...state,
        companyJobListPref: null,
      };

    //Contact job DD list
    case actionTypes.GET_CONTACT_JOB_DD_LIST_REQUEST:
      return {
        ...state,
        message: '',
        contactDropDownList: [],
        isReportDD: false,
        isReportDDError: false,
        buttonActive: false,
      };
    case actionTypes.GET_CONTACT_JOB_DD_LIST_SUCCESS:
      return {
        ...state,
        contactDropDownList: action.payload.response.data.result,
        message: 'Contact DD Successfully Listed',
        isReportDD: true,
        isReportDDError: false,
        buttonActive: true,
      };
    case actionTypes.GET_CONTACT_JOB_DD_LIST_ERROR:
      return {
        ...state,
        message: action.payload.error,
        contactDropDownList: [],
        isReportDD: false,
        isReportDDError: true,
        buttonActive: false,
      };

    //No Bid Report
    case actionTypes.GET_NO_BID_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        noBidReportList: [],
      };
    case actionTypes.GET_NO_BID_REPORT_SUCCESS:
      return {
        ...state,
        noBidReportList: action.payload.response.data.result,
        message: 'Company Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_NO_BID_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        noBidReportList: [],
      };

    case actionTypes.GET_NO_BID_PREF_REQUEST:
      return {
        ...state,
        noBidReportListPref: null,
      };
    case actionTypes.GET_NO_BID_PREF_SUCCESS: {
      return {
        ...state,
        noBidReportListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_NO_BID_PREF_ERROR:
      return {
        ...state,
        noBidReportListPref: null,
      };
    //Delivered Project Report
    case actionTypes.GET_DELIVERED_REPORT_REQUEST:
      return {
        ...state,
        deliveredReportList: [],
        deliveredReportListPref: null,
        message: '',
        statusBidDue: false,
        buttonActive: false,
      };
    case actionTypes.GET_DELIVERED_REPORT_SUCCESS:
      return {
        ...state,
        deliveredReportList: action.payload.response.data.result,
        message: 'Delivered Project Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_DELIVERED_REPORT_ERROR:
      return {
        ...state,
        deliveredReportList: [],
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
      };

    case actionTypes.GET_DELIVERED_PREF_REQUEST:
      return {
        ...state,
        deliveredReportListPref: null,
      };
    case actionTypes.GET_DELIVERED_PREF_SUCCESS: {
      return {
        ...state,
        deliveredReportListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_DELIVERED_PREF_ERROR:
      return {
        ...state,
        deliveredReportListPref: null,
      };

    case actionTypes.GET_DELIVERED_PREF_REQUEST:
      return {
        ...state,
        deliveredReportListPref: null,
      };
    case actionTypes.GET_DELIVERED_PREF_SUCCESS: {
      return {
        ...state,
        deliveredReportListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_DELIVERED_PREF_ERROR:
      return {
        ...state,
        deliveredReportListPref: null,
      };


      //Lien Period Report
    case actionTypes.GET_LIEN_PERIOD_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        lienPeriodList: [],
        lienPeriodListPref: null,
      };
    case actionTypes.GET_LIEN_PERIOD_REPORT_SUCCESS:
      return {
        ...state,
        lienPeriodList: action.payload.response.data.result,
        message: 'Lien Period Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_LIEN_PERIOD_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        lienPeriodList: [],
      };

    case actionTypes.GET_LIEN_PERIOD_PREF_REQUEST:
      return {
        ...state,
        lienPeriodListPref: null,
      };
    case actionTypes.GET_LIEN_PERIOD_PREF_SUCCESS: {
      return {
        ...state,
        lienPeriodListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_LIEN_PERIOD_PREF_ERROR:
      return {
        ...state,
        lienPeriodListPref: null,
      };

    //Warranty Status Report
    case actionTypes.GET_WARRANTY_STATUS_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        warrantyStatusList: [],
        warrantyStatusListPref: null,
      };
    case actionTypes.GET_WARRANTY_STATUS_REPORT_SUCCESS:
      return {
        ...state,
        warrantyStatusList: action.payload.response.data.result,
        //warrantyStatusList: action.payload.response.data,
        message: 'Warranty Status Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_WARRANTY_STATUS_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        warrantyStatusList: [],
      };

    case actionTypes.GET_WARRANTY_STATUS_PREF_REQUEST:
      return {
        ...state,
        warrantyStatusListPref: null,
      };
    case actionTypes.GET_WARRANTY_STATUS_PREF_SUCCESS: {
      return {
        ...state,
        warrantyStatusListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_WARRANTY_STATUS_PREF_ERROR:
      return {
        ...state,
        bidsDueListPref: null,
      };

    //Follow up Project Report
    case actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        projectFollowUpList: [],
        projectFollowUpListPref: null,
      };
    case actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS:
      return {
        ...state,
        projectFollowUpList: action.payload.response.data.result,
        message: 'Follow Up Project Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        projectFollowUpList: [],
      };

    case actionTypes.GET_FOLLOW_UP_PROJECT_PREF_REQUEST:
      return {
        ...state,
        projectFollowUpListPref: null,
      };
    case actionTypes.GET_FOLLOW_UP_PROJECT_PREF_SUCCESS: {
      return {
        ...state,
        projectFollowUpListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_FOLLOW_UP_PROJECT_PREF_ERROR:
      return {
        ...state,
        projectFollowUpListPref: null,
      };

    //Lost Job Project Report
    case actionTypes.GET_LOST_JOB_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        lostJobsList: [],
      };
    case actionTypes.GET_LOST_JOB_REPORT_SUCCESS:
      return {
        ...state,
        lostJobsList: action.payload.response.data.result,
        message: 'Lost job Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_LOST_JOB_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        lostJobsList: [],
      };
    case actionTypes.GET_LOST_JOB_PREF_REQUEST:
      return {
        ...state,
        lostJobsListPref: null,
      };
    case actionTypes.GET_LOST_JOB_PREF_SUCCESS: {
      return {
        ...state,
        lostJobsListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_LOST_JOB_PREF_ERROR:
      return {
        ...state,
        lostJobsListPref: null,
      };

    //Profit Projection Report
    case actionTypes.GET_PROFIT_PROJECTION_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        profitProjectList: [],
      };
    case actionTypes.GET_PROFIT_PROJECTION_FORECAST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        profitProjectListForecast: [],
      };
    case actionTypes.GET_PROFIT_PROJECTION_REPORT_SUCCESS:
      return {
        ...state,
        profitProjectList: action.payload.response.data,
        message: 'Profit Projection Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_PROFIT_PROJECTION_REPORT_FORECAST_SUCCESS:
      return {
        ...state,
        profitProjectListForecast: action.payload.response.data,
        message: 'Profit Projection Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
      case actionTypes.GET_PROFIT_PROJECTION_REPORT_FORECAST_ERROR:
        return {
          ...state,
          message: action.payload.error,
          statusBidDue: false,
          buttonActive: false,
          profitProjectListForecast: [],
        };
    case actionTypes.GET_PROFIT_PROJECTION_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        profitProjectList: [],
      };
    case actionTypes.GET_PROFIT_PROJECTION_PREF_REQUEST:
      return {
        ...state,
        profitProjectListPref: null,
      };
    case actionTypes.GET_PROFIT_PROJECTION_PREF_SUCCESS: {
      return {
        ...state,
        profitProjectListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_PROFIT_PROJECTION_PREF_ERROR:
      return {
        ...state,
        profitProjectListPref: null,
      };

    case actionTypes.GET_PROJECT_FUNNEL_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        projectFunnelList: [],
      };
    case actionTypes.GET_PROJECT_FUNNEL_REPORT_SUCCESS:
      return {
        ...state,
        projectFunnelList: action.payload.response.data,
        message: 'Project Funnel Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_FUNNEL_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        projectFunnelList: [],
      };
    case actionTypes.GET_PROJECT_FUNNEL_PREF_REQUEST:
      return {
        ...state,
        projectFunnelListPref: null,
      };
    case actionTypes.GET_PROJECT_FUNNEL_PREF_SUCCESS: {
      return {
        ...state,
        projectFunnelListPref: {
          ...action.payload.preferences
        },
      };
    }
    //Wip Project View List Report
    case actionTypes.GET_WIP_PROJECT_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        reportList: [],
      };
    case actionTypes.GET_WIP_PROJECT_LIST_REPORT_SUCCESS:
      return {
        reportList: action.payload.response.data,
        message: 'WIP Report Successfully Listed',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.GET_WIP_PROJECT_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        reportList: [],
      };
    //Wip Project Update List Report
    case actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        statusBidDue: false,
        buttonActive: false,
        reportList: [],
      };
    case actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_SUCCESS:
      return {
        reportList: action.payload.response,
        message: 'WIP Report updated Successfully',
        statusBidDue: true,
        buttonActive: true,
      };
    case actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_ERROR:
      return {
        ...state,
        message: action.payload.error,
        statusBidDue: false,
        buttonActive: false,
        reportList: [],
      };

    //Project History Report
    case actionTypes.GET_PROJECT_HISTORY_DD_REQUEST:
      return {
        ...state,
        message: '',
        reportList: [],
        isReportDD: false,
        isReportDDError: false,
        buttonActive: false,
      };
    case actionTypes.GET_PROJECT_HISTORY_DD_SUCCESS:
      return {
        ...state,
        reportList: action.payload.response.data.result,
        message: 'Project DD Report Successfully Listed',
        isReportDD: true,
        isReportDDError: false,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_HISTORY_DD_ERROR:
      return {
        ...state,
        message: action.payload.error,
        reportList: [],
        isReportDD: false,
        isReportDDError: true,
        buttonActive: false,
      };
      case actionTypes.GET_BID_HISTORY_PREF_REQUEST:
      return {
        ...state,
        bidHistoryListPref: null,
      };
    case actionTypes.GET_BID_HISTORY_PREF_SUCCESS: {
      return {
        ...state,
        bidHistoryListPref: {
          //...state.bidHistoryListPref,
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_BID_HISTORY_PREF_ERROR:
      return {
        ...state,
        bidHistoryListPref: null,
      };

    //Project History Report
    case actionTypes.GET_PROJECT_HISTORY_REQUEST:
      return {
        ...state,
        message: '',
        projectHistory: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        projectHistory: action.payload.response.data.result,
        message: 'Project History Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_HISTORY_ERROR:
      return {
        ...state,
        message: action.payload.error,
        projectHistory: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };
    case actionTypes.GET_PROJECT_HISTORY_PREF_REQUEST:
      return {
        ...state,
        projectHistoryPref: null,
      };
    case actionTypes.GET_PROJECT_HISTORY_PREF_SUCCESS: {
      return {
        ...state,
        projectHistoryPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_PROJECT_HISTORY_PREF_ERROR:
      return {
        ...state,
        projectHistoryPref: null,
      };

    //Timesheet Form
    case actionTypes.GET_TIMESHEET_REQUEST:
      return {
        ...state,
        message: '',
        timeSheetList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_TIMESHEET_SUCCESS:
      return {
        timeSheetList: action.payload.response.data.result,
        message: 'Timesheet Form Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_TIMESHEET_ERROR:
      return {
        ...state,
        message: action.payload.error,
        timeSheetList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };

    //Master Constructions Report
    case actionTypes.GET_MASTER_CONSTRUCTION_REQUEST:
      return {
        ...state,
        message: '',
        masterConstructionList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_MASTER_CONSTRUCTION_SUCCESS:
      return {
        ...state,
        masterConstructionList: action.payload.response.data.result,
        message: 'Master Construction Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_MASTER_CONSTRUCTION_ERROR:
      return {
        ...state,
        message: action.payload.error,
        masterConstructionList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };
    case actionTypes.GET_MASTER_CONSTRUCTION_PREF_REQUEST:
      return {
        ...state,
        masterConstructionListPref: null,
      };
    case actionTypes.GET_MASTER_CONSTRUCTION_PREF_SUCCESS: {
      console.log('==>', action.payload)
      return {
        ...state,
        masterConstructionListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_MASTER_CONSTRUCTION_PREF_ERROR:
      return {
        ...state,
        masterConstructionListPref: null,
      };

    //Data Check Report
    case actionTypes.GET_DATA_CHECK_REQUEST:
      return {
        ...state,
        message: '',
        dataCheckReportList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_DATA_CHECK_SUCCESS:
      return {
        ...state,
        dataCheckReportList: action.payload.response.data.result,
        message: 'Data Check Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_DATA_CHECK_ERROR:
      return {
        ...state,
        message: action.payload.error,
        dataCheckReportList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };
    case actionTypes.GET_DATA_CHECK_PREF_REQUEST:
      return {
        ...state,
        dataCheckReportListPref: null,
      };
    case actionTypes.GET_DATA_CHECK_PREF_SUCCESS: {
      return {
        ...state,
        dataCheckReportListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_DATA_CHECK_PREF_ERROR:
      return {
        ...state,
        dataCheckReportListPref: null,
      };

    //Client Reference Report
    case actionTypes.GET_CLIENT_REFERENCES_REQUEST:
      return {
        ...state,
        message: '',
        clientReferenceList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_CLIENT_REFERENCES_SUCCESS:
      return {
        clientReferenceList: action.payload.response.data.result,
        message: 'Client References Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_CLIENT_REFERENCES_ERROR:
      return {
        ...state,
        message: action.payload.error,
        clientReferenceList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };

    //Master Project Log Report
    case actionTypes.GET_MASTER_PROJECT_LOG_REQUEST:
      return {
        ...state,
        message: '',
        masterProjectLogsList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_MASTER_PROJECT_LOG_SUCCESS:
      return {
        ...state,
        masterProjectLogsList: action.payload.response.data.result,
        message: 'Master Project Log Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_MASTER_PROJECT_LOG_ERROR:
      return {
        ...state,
        message: action.payload.error,
        masterProjectLogsList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };
    case actionTypes.GET_MASTER_PROJECT_PREF_REQUEST:
      return {
        ...state,
        masterProjectLogListPref: null,
      };
    case actionTypes.GET_MASTER_PROJECT_PREF_SUCCESS: {
      return {
        ...state,
        masterProjectLogListPref: {
          ...action.payload.preferences
        },
      };
    }
    case actionTypes.GET_MASTER_PROJECT_PREF_ERROR:
      return {
        ...state,
        masterProjectLogListPref: null,
      };

    //Preliminary Info Report
    case actionTypes.GET_PROJECT_PRELIM_INFO_REQUEST:
      return {
        ...state,
        message: '',
        projectPrelimInfoList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_PROJECT_PRELIM_INFO_SUCCESS:
      return {
        projectPrelimInfoList: action.payload.response.data.result,
        message: 'Preliminary Info Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_PRELIM_INFO_ERROR:
      return {
        ...state,
        message: action.payload.error,
        projectPrelimInfoList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };

    //Project info hot sheet Report
    case actionTypes.GET_PROJECT_INFO_HOT_SHEET_REQUEST:
      return {
        ...state,
        message: '',
        projectInfoHotSheetList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_PROJECT_INFO_HOT_SHEET_SUCCESS:
      return {
        projectInfoHotSheetList: action.payload.response.data.result,
        message: 'Project Info Hot Sheet Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_PROJECT_INFO_HOT_SHEET_INFO_ERROR:
      return {
        ...state,
        message: action.payload.error,
        projectInfoHotSheetList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };

    //Bid result recap Report
    case actionTypes.GET_BID_RESULT_RECAP_REQUEST:
      return {
        ...state,
        message: '',
        bidResultRecapList: [],
        isReport: false,
        isReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_BID_RESULT_RECAP_SUCCESS:
      return {
        bidResultRecapList: action.payload.response.data.result,
        message: 'Bid Result Recap Report Successfully Listed',
        isReport: true,
        isReportError: false,
        buttonActive: true,
      };
    
    case actionTypes.GET_BID_RESULT_RECAP_ERROR:
      return {
        ...state,
        message: action.payload.error,
        bidResultRecapList: [],
        isReport: false,
        isReportError: true,
        buttonActive: false,
      };

    case actionTypes.GET_BID_RESULT_RECAP_PREF_REQUEST:
      return {
        ...state,
        bidResultRecapListPref: null,
      };
    case actionTypes.GET_BID_RESULT_RECAP_PREF_SUCCESS: {
      return {
        ...state,
        bidResultRecapListPref: {
          ...action.payload.preferences
        },
      };
    }

    //Profit and Sales Report
    case actionTypes.GET_PROFIT_SALES_REQUEST:
      return {
        ...state,
        message: '',
        profitAndSalesList: [],
        isSPReport: false,
        isSPReportError: false,
        buttonActive: false,
      };
    case actionTypes.GET_PROFIT_SALES_SUCCESS:
      return {
        ...state,
        profitAndSalesList: action.payload.response.data.result,
        message: 'Profit and Sales Report Successfully Listed',
        isSPReport: true,
        isSPReportError: false,
        buttonActive: true,
      };
    case actionTypes.GET_PROFIT_SALES_ERROR:
      return {
        ...state,
        message: action.payload.error,
        profitAndSalesList: [],
        isSPReport: false,
        isSPReportError: true,
        buttonActive: false,
      };
      case actionTypes.GET_PROFIT_SALES_PREF_REQUEST:
        return {
          ...state,
          profitAndSalesListPref: null,
        };
      case actionTypes.GET_PROFIT_SALES_PREF_SUCCESS: {
        return {
          ...state,
          profitAndSalesListPref: {
            ...action.payload.preferences
          },
        };
      }

    default:
      return state;
  }
};
