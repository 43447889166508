import React, { useEffect, useMemo, useState, Suspense } from 'react';

import { Link } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import { connect, useSelector } from 'react-redux';
import { getAllProjectAction } from '../../../actions/project';
import { withRouter } from 'react-router-dom';

import ReactLoading from 'react-loading';
import { CustomTable, customSortByKey, multiSelectFilter } from '../../Common/CustomTable';
import { formatMoney, roundFormatMoney } from '../../../utils/number-formatters';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import usePagination from '../../../shared/data-grid/use-pagination';
import RemotePagination from '../../../shared/data-grid/remote-pagination/remote-pagination.jsx';
import useSorting, { headerWithSort } from '../../../shared/data-grid/use-sorting/use-sorting.jsx';
import store from '../../../modules/store';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  formatDateWithTime,
  formatDateObject,
  formatDate,
  formatDateUTC,
  formatDateWithoutTimeUTC,
} from '../../../utils/date-formatters';
import { isAllowed, moduleConstants } from '../../../_constants';

const LeadsTable = ({ list, total, prjProps }) => {
  
  const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');

  const data = useSelector((state) => state.list);
  const loading = useSelector((state) => state.fetchRequest);
  const { allProjectsCount } = useSelector((state) => state);

  const pagination = usePagination({ total });

  const { limit, offset, setPage } = pagination;

  const sortingState = useSorting('', 'desc');

  const { sortingField, sortingOrder } = sortingState;

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    prjProps.onSortChange(sortingField, sortingOrder);
    store.dispatch(
      getAllProjectAction({
        displayLimit: limit,
        page: offset / limit,
        sortKey: prjProps.state.sortForm.sortKey,
        sortType: prjProps.state.sortForm.sortType,
        searchValue,
        type: 'lead',
        jobStatusCodes:
          prjProps.state.searchSelectBox?.jobStatusCodes?.value !== 'All'
            ? prjProps.state.searchSelectBox.jobStatusCodes.value
            : '',
        accountExec:
          prjProps.state.searchSelectBox?.accountExec?.value !== 'All'
            ? prjProps.state.searchSelectBox.accountExec.value
            : '',
        clientId:
          prjProps.state.searchSelectBox?.client?.value !== 'All'
            ? prjProps.state.searchSelectBox.client.value
            : '',
      })
    );
  }, [limit, offset, sortingField, sortingOrder, searchValue]);

  useEffect(() => {
    setPage(1);
  }, [searchValue, limit]);

  const columns = useMemo(
    () => [
      {
        Header: headerWithSort('Project Name', 'jobName'),
        accessor: 'jobName',
        disableSortBy: true,
        width: 165,
        Cell: (props) => prjProps.onCellButton(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Status',
        accessor: 'projectStatus',
        width: 50,
        disableSortBy: true,
        Cell: (props) => prjProps.onGetStatusCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: headerWithSort('Bid Date', 'bidDue'),
        accessor: 'bidDue',
        width: 80,
        disableSortBy: true,
        Cell: (props) =>
          prjProps.onGetBidDateCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: headerWithSort('Start Date', 'startDate'),
        accessor: 'startDate2',
        width: 80,
        disableSortBy: true,
        Cell: (props) =>
          prjProps.onGetStartDateCell(props.column.id, props.row.original, props.value),
      },
      {
        Header: appConstants.accountExecLabelNameShort,
        accessor: 'accountExecArr',
        sortType: customSortByKey,
        sortKey: appConstants.roleList.accountExecArrLabel,
        filter: SelectColumnFilter,
        width: 60,
        disableSortBy: true,
        Cell: (props) => (
          <>
            {prjProps.props.contactRedirect.showAccountExecStatus(
              props.column.id,
              props.row.original,
              props.value
            )}
          </>
        ),
      },
      hasContractValPermissions&&{
        Header: headerWithSort('Contract Amount', 'currentContractAmount'),
        id: 'currentContractAmount',
        width: 100,
        disableSortBy: true,
        Cell: (props) => prjProps.onContractVal(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Client',
        id: appConstants.roleList.clientName,
        width: 100,
        Cell: (props) => (
          <>
            {prjProps.props.contactRedirect.showClient(
              props.column.id,
              props.row.original,
              props.value
            )}
          </>
        ),
      },
    ],
    [sortingState]
  );

  return (
    <>
      <CustomTable
        columns={columns.filter(Boolean)}
        list={list}
        inlineEdit={false}
        canShowExport={false}
        canShowPagination={false}
        canShowGlobalFilter={false}
        canShowColumnSettings={false}
        canShowDateFilter={false}
        canShowRowSelection={false}
        isLoading={loading}
        useControlledState={(state) => ({ ...state, ...sortingState })}
      />
      <RemotePagination {...pagination} />
    </>
  );
};

class LeadLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      row: [],
      list: [],
      leadList: [],
      searchSelectBox: {
        jobStatusCodes: { value: 'All', label: 'All' },
        accountExec: { value: 'All', label: 'All' },
        client: { value: 'All', label: 'All' },
      },
      fetchRequest: false,
      leadListStatus: false,
      totalCount: 0,
      key: 0,

      sizePerPage: 15,
      currentPage: 1,
      sortForm: { sortType: 'DESC', sortKey: '' },
    };

    this.getProjectList = this.getProjectList.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    formatDateWithoutTimeUTC(new Date());
    formatDateUTC(new Date());
  }

  UNSAFE_componentWillMount() {
    this.getProjectList(this.state.key);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterData && this.state.filterStatus !== nextProps.filterStatus) {
      this.setState({
        filterStatus: nextProps.filterStatus,
        searchSelectBox: nextProps.filterData,
      });
      this.getProjectList();
    }

    if (
      this.state.leadListStatus !== nextProps.leadListStatus &&
      nextProps.leadListStatus === true
    ) {
      this.setState({
        leadListStatus: !this.state.leadListStatus,
      });
      //  this.UNSAFE_componentWillMount();
    }

    if (!this.props.createSuccess && nextProps.createSuccess) {
      this.getProjectList(this.state.key);
    }

    let list = [];
    if (nextProps.allLead && nextProps.allLead.length > 0) {
      list = nextProps.allLead.filter((lead) => {
        return lead;
      });
      this.setState({
        fetchRequest: nextProps.fetchRequest,
        totalCount: nextProps.allProjectsCount,
      });
    } else {
      this.setState({ totalCount: 0 });
    }
    this.setState({ leadList: list });

    if (nextProps.allProjectsCount === 0) {
      this.setState({ fetchRequest: true });
    }
  }

  getProjectList(key) {
    this.setState({ currentPage: 1 });
    //let searchSelectBox = this.state.searchSelectBox;
    let allProject = {};
    const { searchSelectBox } = this.state;
    if (searchSelectBox.jobStatusCodes.value !== 'All') {
      allProject['jobStatusCodes'] = searchSelectBox.jobStatusCodes.value;
    }
    if (searchSelectBox.accountExec.value !== 'All') {
      allProject['accountExec'] = searchSelectBox.accountExec.value;
    }
    if (searchSelectBox.client.value !== 'All') {
      allProject['clientId'] = searchSelectBox.client.value;
    }

    allProject['type'] = 'lead';
    allProject['accountId'] = this.state.accountId;
    allProject['userId'] = this.state.userId;
    allProject['page'] = 0;
    allProject['displayLimit'] = this.state.sizePerPage;
    this.props.getAllProjectAction(allProject);
  }

  cellButton(cell, row, enumObject, rowIndex) {
    if (
      row.projectStatus.toLocaleUpperCase() === 'LEAD' ||
      row.projectStatus.toLocaleUpperCase() === 'Not Bid (closed)'.toLocaleUpperCase()
    )
      return (
        <div>
          <Link to={{ pathname: `/lead-details/` + row.objectId }}>{row.jobName}</Link>
        </div>
      );
    else
      return (
        <div>
          <Link to={{ pathname: `/project-details/` + row.objectId }}>{row.jobName}</Link>
        </div>
      );
  }

  changeDataFormate(cell, row, enumObject, rowIndex) {
    return formatDate(cell).format(appConstants.DATEFORMAT);
  }

  showProjectStatus(cell, row, enumObject, rowIndex) {
    return <p>{row.projectStatus}</p>;
  }

  showStartDate(cell, row, enumObject, rowIndex) {
    return <p>{row.startDate && formatDate(row.startDate)}</p>;
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  showAmountLead(cell, row, enumObject, rowIndex) {
    // if (row && row.currentContractAmount && row.currentContractAmount !== '') {
    //     let amount = Math.floor(row.currentContractAmount);
    //     return (<p>{row.currentContractAmount && formatMoney(currentContractAmount)}</p>)
    // }

    let data =
      row && row.currentContractAmount
        ? roundFormatMoney(row.currentContractAmount)
        : '-';
    return <div className=''>{data}</div>;
  }

  showAmountProject(cell, row, enumObject, rowIndex) {
    // if (row.originalContractAmount && row.originalContractAmount !== '') {
    //     let originalContractAmount = Math.floor(row.originalContractAmount);
    //     return (<p>{row.originalContractAmount && formatMoney(originalContractAmount)}</p>)
    // }
    if (row.currentContractAmount && row.currentContractAmount !== '') {
      let originalContractAmount = Math.floor(row.currentContractAmount);
      return (
        <p>{row.currentContractAmount && formatMoney(originalContractAmount)}</p>
      );
    }
  }

  onPageChange(page, sizePerPage) {
    let allProject = {};
    allProject['page'] = page === -1 ? 0 : page - 1;
    allProject['displayLimit'] = sizePerPage;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });

    let searchSelectBox = this.state.searchSelectBox;
    if (searchSelectBox.jobStatusCodes.value !== 'All') {
      allProject['jobStatusCodes'] = searchSelectBox.jobStatusCodes.value;
    }
    if (searchSelectBox.accountExec.value !== 'All') {
      allProject['accountExec'] = searchSelectBox.accountExec.value;
    }
    if (searchSelectBox.client.value !== 'All') {
      allProject['clientId'] = searchSelectBox.client.value;
    }

    allProject['type'] = this.state.key === 0 ? 'lead' : 'project';
    allProject['accountId'] = this.state.accountId;
    allProject['userId'] = this.state.userId;
    //this.props.getAllProjectAction(allProject);
  }

  onSortChange = (sortName, sortOrder) => {
    let sortForm = this.state.sortForm;

    if (sortOrder === 'asc' || sortOrder === 'ASC') {
      sortOrder = 'ASC';
    } else {
      sortOrder = 'DESC';
    }

    sortForm['sortType'] = sortOrder;
    sortForm['sortKey'] = sortName;

    this.setState({ sortForm });
  };

  internalData(cell, row, enumObject, rowIndex) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  onExternalData(name, row, cell, rowIndex) {
    if (row && row['accountExecArr'] && row['accountExecArr']?.length > 0) {
      return formatExternalContactData(row['accountExecArr'], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  onGetStatusCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.projectStatus ? row.projectStatus : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onGetBidDateCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.bidDue && row.bidDue.iso ? formatDate(row.bidDue.iso) : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onGetStartDateCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.startDate && row.startDate.iso ? formatDate(row.startDate.iso) : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onContractVal(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.currentContractAmount ? '$' + formatMoney(row.currentContractAmount) : '-'}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onCellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
          {row && row.jobName ? row.jobName : '-'}
        </Link>
      </div>
    );
  }

  onSizePerPageList(sizePerPage) {}

  render() {
    return (
      <>
        <LeadsTable list={this.state.leadList} total={this.state.totalCount} prjProps={this} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allLead: state.project.allLead,
    createSuccess: state.project.createSuccess,
    isStatus: state.project.isStatus,
    allProjectsCount: state.project.allProjectsCount,
    filterData: state.project.filterData,
    filterStatus: state.project.filterStatus,
    fetchRequest: state.project.fetchRequest,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectAction,
  })(LeadLists)
);
