import React, { Suspense } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getAllBuildingTypeAction } from '../../../actions/buildingType';
import { GetClientReferenceAction } from '../../../actions/reports';
import { formatExternalContactData } from '../../../utils/contact-formatters';
import { formatDate } from '../../../utils/date-formatters';
import { appConstants } from '../../../_constants';
import CustomTable from '../../Common/CustomTable';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { ClientReferenceReportModal } from './client-references-report-modal';
import { isAllowed, moduleConstants } from '../../../_constants';
import { roundFormatMoney, formatNumber } from '../../../utils/number-formatters';
import { formatAddressText } from '../../../utils/contact-formatters.jsx';

let statusName = '';
let buildType = '';
let amt = '';
let year = '';
let size = '';

class ClientReferenceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      modal: false,
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      DDList: [],
      active: null,
      delivered: null,
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listData !== this.props.listData) {
      if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
        this.setState({
          totalCount: nextProps.listData.count,
          list: nextProps.listData.data,
          contactData: nextProps.listData.contactData,
          fetchRequest: false,
        });
      }
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length === 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 2000);
    }

    if (nextProps.allBuildingType && nextProps.allBuildingType.length > 0) {
      let DDList = [];
      nextProps.allBuildingType.map((v, i) => {
        if (v.objectId !== '' && v.buildingTypeName !== '') {
          DDList.push({
            value: v.buildingTypeCode,
            label: v.buildingTypeName + '(' + v.buildingTypeCode + ')',
            id: v.objectId,
          });
        }
        this.setState({ DDList });
        return true;
      });
    }
  }

  list() {
    let data = {};
    data['className'] = 'buildingTypes';
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['27'];
    this.props.getAllBuildingTypeAction(data);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      formData: {
        period: 'active&delivered',
        buildingTypeCode: '',
        contractAmount: '',
        year: '',
        size: '',
      },
    });
    this.toggle();
  }

  toggle(val) {
    this.list();

    if (this.state.formData.period === 'active') {
      statusName = 'Active';
    } else if (this.state.formData.period === 'delivered') {
      statusName = 'Delivered';
    } else if (this.state.formData.period === 'active&delivered') {
      statusName = 'Active and Delivered';
    }

    const matchedBuildingType = this.state.DDList.find(
      (x) => x.id === this.state.formData.buildingTypeCode || x.value === this.state.formData.buildingTypeCode);

    buildType =
      matchedBuildingType && matchedBuildingType.label ? matchedBuildingType.label : '';
    amt = this.state.formData.contractAmount ? this.state.formData.contractAmount : '';
    year = this.state.formData.year ? this.state.formData.year : '';
    size = this.state.formData.size ? this.state.formData.size : '';

    this.setState({ modal: !this.state.modal });
  }

  onSubmit(data) {
    const formData = this.state.formData;
    for (const [key, value] of Object.entries(data)) {
      formData[key] = value;
    }
    this.setState({ formData });

    this.filteredData();
  }

  filteredData() {
    this.setState({ fetchRequest: true });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['page'] = 0;
    data['displayLimit'] = 99999999;

    if (this.state.formData.period === 'active') {
      data['jobType'] = 'active';
    } else if (this.state.formData.period === 'delivered') {
      data['jobType'] = 'delivered';
    } else if (this.state.formData.period === 'active&delivered') {
      data['jobType'] = 'active&delivered';
    }

    data['buildingTypeCode'] =
      this.state.formData.buildingTypeCode && this.state.formData.buildingTypeCode.value
        ? this.state.formData.buildingTypeCode.value
        : '';
    data['size'] = this.state.formData.size ? parseInt(this.state.formData.size, 10) : 0;
    data['contractAmount'] = this.state.formData.contractAmount
      ? parseInt(this.state.formData.contractAmount, 10)
      : 0;
    data['year'] = this.state.formData.year ? parseInt(this.state.formData.year, 10) : 0;
    data['moduleName'] = 'Client References Report';
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;

    this.toggle();
    this.props.GetClientReferenceAction(data);
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Client References Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['27'];
    if (this.state.formData.period === 'active') {
      data['jobType'] = 'active';
    } else if (this.state.formData.period === 'delivered') {
      data['jobType'] = 'delivered';
    } else if (this.state.formData.period === 'active&delivered') {
      data['jobType'] = 'active&delivered';
    }
    data['buildingTypeCode'] =
      this.state.formData.buildingTypeCode && this.state.formData.buildingTypeCode.value
        ? this.state.formData.buildingTypeCode.value
        : '';
    data['size'] = this.state.formData.size ? parseInt(this.state.formData.size, 10) : 0;
    data['contractAmount'] = this.state.formData.contractAmount
      ? parseInt(this.state.formData.contractAmount, 10)
      : 0;
    data['year'] = this.state.formData.year ? parseInt(this.state.formData.year, 10) : 0;

    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    //  }
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });

    this.props.GetClientReferenceAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['27'];
    if (this.state.formData.period === 'active') {
      data['jobType'] = 'active';
    } else if (this.state.formData.period === 'delivered') {
      data['jobType'] = 'delivered';
    } else if (this.state.formData.period === 'active&delivered') {
      data['jobType'] = 'active&delivered';
    }
    data['buildingTypeCode'] =
      this.state.formData.buildingTypeCode ?? '';
    data['size'] = this.state.formData.size ? parseInt(this.state.formData.size, 10) : 0;
    data['contractAmount'] = this.state.formData.contractAmount
      ? parseInt(this.state.formData.contractAmount, 10)
      : 0;
    data['year'] = this.state.formData.year ? parseInt(this.state.formData.year, 10) : 0;
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    text = text ? text.trim() : '';
    if (text !== '') {
      // if (text.length >= 3) {
      data['search'] = text;
      this.setState({ sizePerPage: 50 });
      //  }
    }
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ searchText: text });

    this.props.GetClientReferenceAction(data);
  }

  renderFluidCell(rowData) {
    const row = rowData?.row?.original;
    return (
      <div className='d-flex justify-content-between' style={{ padding: 15 }}>
        <div className='flex col-4'>
          <div>
            <strong>
              <Link
                to={{
                  pathname: `/project-details/` + row.objectId,
                  state: { row },
                }}
              >
                {row.jobName ? row.jobName : ''}{' '}
              </Link>
            </strong>
          </div>
          {row?.clientArr?.length > 0 && (
            <span>
              <strong>Client:</strong>
              {row.clientArr
                ? formatExternalContactData(row.clientArr.map(x=>x.objectId), row.clientArr)
                : ''}
            </span>
          )}
          {row && row.architectArr && row.architectArr.length > 0 && (
            <span>
              <strong>Architect:</strong>
              <Link to={{ pathname: `/contact-details/` + row.architectArr?.[0].contactId }}>
                {row.architectArr
                  ? formatExternalContactData(row.architectArr.map(x=>x.objectId), row.architectArr)
                  : ''}
              </Link>
            </span>
          )}
        </div>
        <div className='flex col-6'>
          <p className='mb-0 me-4'>
            <span>
              <strong>Address: </strong>
              {row.jobAddress ? formatAddressText(row) : ''}
            </span>
          </p>
          <p className='mb-0'>
            <span>
              <strong>Description: </strong>
              {row.marketingDescription ? row.marketingDescription : ''}
            </span>
          </p>
        </div>
        <div className='flex col-2'>
          <p className='mb-0 text-right'>
            <span>
              <strong>Completed: </strong>
              {typeof row.endDate === 'object' ? formatDate(row.endDate?.iso) : ''}
            </span>
          </p>
          <p className='mb-0 text-right'>
            <span>
              <strong> Contract Amt.: </strong>{' '}
              {row?.currentContractAmount ? roundFormatMoney(row?.currentContractAmount) : 0}
            </span>
          </p>
          {row.sqFeet > 0 ? (
            <p className='mb-0 text-right'>
              <span>
                <strong> Size (Sq.ft.): </strong>{' '}
                {row.sqFeet ? formatNumber(Math.round(row.sqFeet)) : 0}
              </span>
            </p>
          ) : (
            ''
          )}
          <p className='mb-0 text-right'>
            <span>
              <strong> Negotiating Method: </strong>
              {row.negotiatingMethod && row.negotiatingMethod.negotiatingMethodName
                ? row.negotiatingMethod.negotiatingMethodName
                : ''}
            </span>
          </p>

          <p className='mb-0 text-right'>
            <span>
              <strong> Job Scope: </strong> {row.scopeArrStr ?? ''}
            </span>
          </p>
        </div>
      </div>
    );
  }

  render() {
    
    const columns = [
      {
        Header: `${statusName} Jobs${amt>0 ? ', Over $' + amt : ''}${
          year ? ', Built since ' + year : ''
        }${size ? ', Size ' + size : ''}${buildType ? ', Building Type: ' + buildType : ''}`,
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        sortType: 'DESC',
        width: '1130',
        sortType: 'datetime',
        Cell: (props) => this.renderFluidCell(props),
      },
    ];

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Client Reference Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>

          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead no-print'>
                <h2>Client References Report</h2>
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>
              <div className=''>
                <div className=''>
                  <div className=' reportTableSection' ref={(el) => (this.componentRef = el)}>
                    {this.createCustomButtonGroup()}
                    <CustomTable
                      columns={columns}
                      canShowColumnSettings={false}
                      componentRef={this.componentRef}
                      className='client-reference-table'
                      customTableTitle=' '
                      list={this.state.list}
                      preferences={() => {}}
                      onSavePreferences={() => {}}
                      isLoading={this.state.fetchRequest}
                      canShowRowSelection={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <ClientReferenceReportModal
          open={this.state.modal}
          onClose={this.toggle}
          onSubmit={this.onSubmit.bind(this)}
          data={this.state.formData}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.clientReferenceList,
    isReport: state.reports.isReport,
    isReportError: state.reports.isReportError,
    allBuildingType: state.buildingType.allBuildingType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetClientReferenceAction,
    getAllBuildingTypeAction,
  })(ClientReferenceReport)
);
