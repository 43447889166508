import Joi from 'joi';
import _ from 'lodash';

/**
 * Converts a currency string to a number
 * @param {string|number} value - Currency string or number to convert
 * @returns {number} - Parsed number value, rounded to 2 decimal places
 */
export const currencyToNumber = (value) => {
  // Handle null/undefined
  if (value == null) {
    return 0;
  }

  // For numbers, just ensure proper rounding
  if (typeof value === 'number') {
    return Math.round(value * 100) / 100;
  }

  // For strings, clean and parse
  const cleanValue = String(value)
    .replace(/[^\d.-]/g, '') // Remove non-digit characters except dots and dashes
    .replace(/(?!^)-/g, '') // Remove any dash that's not at the beginning
    .replace(/^\./, '0.') // Add leading zero if starting with decimal point
    .replace(/^(-?)0+(?=\d)/, '$1'); // Remove leading zeros, preserving negative sign

  const parsed = parseFloat(cleanValue);
  return isNaN(parsed) ? 0 : Math.round(parsed * 100) / 100;
};

/**
 * Formats a number as currency or formatted number
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const formatNumber = (amount, options = {}) => {
  const {
    prefix = '',
    suffix = '',
    decimalCount = 2,
    locale = 'en-US',
    useGrouping = true,
    autoDecimal = false, // If true, only shows decimals when non-zero
    parentheses = false, // If true, formats negative numbers with parentheses
    round = false // If true, rounds the number before formatting
  } = options;

  // Handle empty values
  if (amount === null || amount === undefined || amount === '' || 
      (typeof amount === 'string' && amount.trim() === '')) {
    return '';
  }

  // Parse the input
  let numValue = typeof amount === 'string' ? currencyToNumber(amount) : amount;
  
  // Handle NaN
  if (isNaN(numValue)) {
    return '';
  }
  
  // Round if requested
  if (round) {
    numValue = Math.round(numValue);
  }

  // Determine if negative for parentheses formatting
  const isNegative = numValue < 0;
  
  // Determine decimal places to show
  let minimumFractionDigits = decimalCount;
  let maximumFractionDigits = decimalCount;
  
  if (autoDecimal) {
    // Allow between 0 and 2 decimal places depending on the value
    minimumFractionDigits = 0;
    maximumFractionDigits = 2;
  }

  // Format the number
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping
  });
  
  // Format with absolute value if using parentheses
  const formattedNumber = formatter.format(parentheses && isNegative ? Math.abs(numValue) : numValue);
  
  // Assemble the final output
  let result = `${prefix}${formattedNumber}${suffix}`;
  
  // Apply parentheses for negative values if needed
  if (parentheses && isNegative) {
    result = `(${result})`;
  }
  
  return result;
};

/**
 * Formats a number as currency
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const formatMoney = (amount, options = {}) => {
  return formatNumber(amount, {
    prefix: '$',
    decimalCount: 2,
    ...options
  });
};

/**
 * Formats a number as currency with parentheses for negative values
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const formatMoneyParens = (amount, options = {}) => {
  return formatMoney(amount, {
    parentheses: true,
    decimalCount: 0,
    ...options
  });
};

/**
 * Formats a number as currency and rounds it
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const roundFormatMoney = (amount, options = {}) => {
  return formatMoney(amount, {
    round: true,
    decimalCount: 0,
    ...options
  });
};

/**
 * Formats a number as currency with automatic decimal places
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const formatMoneyAuto = (amount, options = {}) => {
  return formatMoney(amount, {
    prefix: '',
    autoDecimal: true,
    ...options
  });
};

/**
 * Formats a number as currency with automatic decimal places
 * @param {number|string} amount - Amount to format
 * @param {Object} options - Formatting options
 * @returns {string} - Formatted currency string
 */
export const formatNumberAuto = (amount, options = {}) => {
  return formatNumber(amount, {
    prefix: '',
    autoDecimal: true,
    decimalCount: 2,
    ...options
  });
};

/**
 * Formats a number as a percentage
 * @param {number} value - Value to format
 * @param {number} decimalCount - Number of decimal places
 * @returns {string} - Formatted percentage string
 */
export const formatPercent = (value, decimalCount = 2) => {
  return formatNumber(value, {
    decimalCount,
    suffix: '%'
  });
};

/**
 * Formats estimate data for display or calculation
 * @param {Array} estData - Array of estimate data objects
 * @param {boolean} asCurrency - Whether to format as currency (true) or convert to numbers (false)
 * @returns {Array} - Processed estimate data
 */
export const formatEstData = (estData = [], asCurrency = true) => {
  if (!estData || !Array.isArray(estData)) {
    return [];
  }

  const estDataClone = _.cloneDeep(estData);
  const formatter = asCurrency ? formatNumberAuto : currencyToNumber;
  
  const toStringValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value === 'string') {
      return value.trim();
    }
    if (typeof value === 'number') {
      return value.toString();
    }
    return String(value);
  };
  const qtyFormatter = asCurrency ? toStringValue : currencyToNumber;

  estDataClone.forEach((scope) => {
    if (!scope) return;
    
    const {
      subTotalCost,
      subTotalCtr,
      subTotalProfit,
      subTotalProfitPers,
      qty,
      unitPrice,
      unitProfit,
      unitCost,
      items = []
    } = scope;

    // Set quantity and unit values
    if (!qty) {
      scope.qty = formatter(asCurrency ? 1 : '1');
      scope.unitPrice = formatter(subTotalCtr);
      scope.unitCost = formatter(subTotalCost);
      scope.unitProfit = formatter(subTotalProfit);
    } else {
      scope.qty = qtyFormatter(qty);
      scope.unitPrice = formatter(unitPrice);
      scope.unitCost = formatter(unitCost);
      scope.unitProfit = formatter(unitProfit);
    }

    // Format subtotals
    scope.subTotalCost = formatter(subTotalCost);
    scope.subTotalCtr = formatter(subTotalCtr);
    scope.subTotalProfit = formatter(subTotalProfit);

    // Handle profit percentage formatting
    if (asCurrency) {
      scope.subTotalProfitPers = typeof subTotalProfitPers === 'number' 
        ? subTotalProfitPers.toFixed(1) 
        : subTotalProfitPers;
    } else {
      scope.subTotalProfitPers = typeof subTotalProfitPers === 'string'
        ? currencyToNumber(subTotalProfitPers)
        : subTotalProfitPers;
    }

    // Process items
    if (Array.isArray(items)) {
      items.forEach((item) => {
        if (!item) return;
        
        const { cost, total, profit, qty, unitPrice, unitCost, profitPers } = item;

        item.cost = formatter(cost);
        item.total = formatter(total);
        item.profit = formatter(profit);
        item.qty = qtyFormatter(qty);
        item.unitPrice = formatter(unitPrice);
        item.unitCost = formatter(unitCost);
        
        // Handle profit percentage formatting for items
        if (asCurrency) {
          item.profitPers = typeof profitPers === 'number' 
            ? profitPers.toFixed(1) 
            : profitPers;
        } else {
          item.profitPers = typeof profitPers === 'string'
            ? currencyToNumber(profitPers)
            : profitPers;
        }
      });
    }
  });

  return estDataClone;
};

/**
 * Calculates totals from estimate data
 * @param {Array} estData - Array of estimate data objects
 * @returns {Object} - Object containing cost, profit, and amount totals
 */
export const calculateEstTotals = (estData) => {
  if (!estData || !Array.isArray(estData)) {
    return { cost: 0, profit: 0, amount: 0 };
  }

  const cost = estData.reduce((acc, curr) => acc + currencyToNumber(curr?.subTotalCost || 0), 0);
  const profit = estData.reduce((acc, curr) => acc + currencyToNumber(curr?.subTotalProfit || 0), 0);
  const amount = estData.reduce((acc, curr) => acc + currencyToNumber(curr?.subTotalCtr || 0), 0);

  return { cost, profit, amount };
};

/**
 * Validates a string or number using Joi
 * @type {Joi.Schema}
 * 
 */

export const joiStringOrNumber = Joi.alternatives().try(
  Joi.string().custom((value, helpers) => {
    const number = currencyToNumber(value);
    if (isNaN(number)) {
      return helpers.error('any.invalid');
    }
    return number;
  }),
  Joi.number()
);

