import Joi from 'joi';
import { Moment } from 'moment';
import moment from 'moment';
import { joiMoment } from '../../utils/validation';
import { joiStringOrNumber } from '../../utils/number-formatters';


export type ManageContractTypeSchema = {
  title: string;
  contract: string;
  contractDate: Moment | null;
  primeContract: boolean;
  contractType: string | null;
  isLumpSum: boolean;
  retainage: number;
  description: string;
  exclusions: string;
  comment: string;
  startDate: Moment | null;
  endDate: Moment | null;
  estTotalCost: number;
  estTotalProfit: number;
  estTotalContract: number;
  estData: Array<any>;
  projectDuration: string | null;
  revNum: number;
  createEstimate: boolean;
  acceptedBy?: string | null;
  acceptedDate?: Moment | null;
  originBidId?: string | null;
  qbEstimateId?: string | null;
};

export const manageContractValidationSchema = Joi.object({
  title: Joi.string().trim().allow(null, '').label('Title'),
  contract: Joi.string().trim().allow(null, '').label('Contract #'),
  contractDate: joiMoment({ required: false }).allow(null).empty(null).label('Contract Date'),
  primeContract: Joi.boolean().label('Prime Contract'),
  contractType: Joi.string().allow(null, '').empty('').label('Contract Type'),
  isLumpSum: Joi.boolean().label('Is Lump Sum'),
  retainage: Joi.number().label('Retainage'),
  description: Joi.string().allow(null, '').label('Description'),
  exclusions: Joi.string().allow(null, '').label('Exclusions'),
  comment: Joi.string().allow(null, '').label('Comment'),
  startDate: joiMoment({ required: true }).label('Start Date'),
  // end date should be greater than or equal to start date
  endDate: joiMoment({ required: true }).label('End Date').custom((value, helper) => {
    const startDate = helper.state.ancestors[0].startDate;
    if (startDate && value && moment(value).isBefore(startDate)) {
      return helper.message({
        custom: '{#label} should be greater than "Start Date"',
      });
    }

    return value;
  }),
  estTotalCost: Joi.number().label('Original Cost'),
  estTotalProfit: Joi.number().label('Original Gross Profit'),
  estTotalContract: Joi.number().label('Original Contract Amount'),
  estData: Joi.array().items(
    Joi.object({
      scopeId: Joi.string().optional().allow('', null),
      subTotalCost: joiStringOrNumber.optional().allow('', null),
      subTotalCtr: joiStringOrNumber.optional().allow('', null),
      subTotalProfit: joiStringOrNumber.optional().allow('', null),
      subTotalProfitPers: joiStringOrNumber.optional().allow('', null),
      qty: joiStringOrNumber.optional().allow('', null),
      unitPrice: joiStringOrNumber.optional().allow('', null),
      unitCost: joiStringOrNumber.optional().allow('', null),
      unitProfit: joiStringOrNumber.optional().allow('', null),
      allowChangeScope: Joi.boolean().optional().allow(null),
      description: Joi.string().optional().allow('', null),
      serviceDate: joiMoment({ required: false }).allow(null).empty(null),
      items: Joi.array().items(Joi.object().unknown(true)).optional().allow(null),
      billable: Joi.boolean().optional().allow(null),
      taxable: Joi.boolean().optional().allow(null),
      qbItemId: Joi.string().optional().allow('', null),
      lineItemId: Joi.string().optional().allow('', null),
    }))
  .optional(),
  projectDuration: Joi.string().trim().empty('').label('Project Duration'),
  revNum: Joi.number().label('Revision'),
  createEstimate: Joi.boolean().failover(false).label('Create Estimate'),
  acceptedBy: Joi.string().allow('', null).label('Accepted By'),
  acceptedDate: joiMoment().allow(null).label('Accepted Date'),
  originBidId: Joi.string().optional().allow('', null).label('Original Bid Id'),
  qbEstimateId: Joi.string().optional().allow('', null).label('Quickbooks Estimate Id'),
});
