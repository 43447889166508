import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import {
  addUserSettings,
  disableUserSetting,
  enableUserSetting,
  resetUserSetting,
  updateUserSettings,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import FormSelect from '../../../shared/form-controls/form-select';
import FormMultiselect from '../../../shared/form-controls/form-multiselect';
import { moduleConstants, updateTypes } from '../../../_constants';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export const EditProjectActionModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  settingId,
  jobStatusCodes = [],
}) => {
  const modelName = 'projectActions';

  const isUpdate = !!settingId;
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      name: '',
      code: '',
      title: '',
      definition: '',
      updateType: '',
      allowReasonCodes: false,
      allowComments: false,
      showHeader: false,
      initialStatus: [],
      nextStatus: [],
      defaultNextStatus: '',
      actionPermissionModule: '',
      trash: false,
      actionFields: [],

    },
  });

  const defaultValueOptions = [
    { label: 'None', value: 'none' },
    { label: 'Today', value: 'today' },
    { label: 'Clear', value: 'clear' }
  ];

  // TODO: should be replaced with dispatch
  const setting = useAppSelector((state) => {
    const settings = state.rtk.settings.getUserSettings.data;

    const setting = settings.find((s) => s._id === settingId);

    return setting;
  });

  useEffect(() => {
    if (!open) return;

    if (!setting || !isUpdate) {
      form.reset(
        {
          name: '',
          code: '',
          title: '',
          definition: '',
          initialStatus: [],
          nextStatus: [],
          defaultNextStatus: '',
          updateType: '',
          trash: false,
          showHeader: false,
          allowReasonCodes: false,
          allowComments: false,
          actionPermissionModule: '',
          actionFields: [],
        },
        { keepDefaultValues: true }
      );

      return;
    }

    form.reset(
      {
        name: setting.name,
        code: setting.code,
        definition: setting.definition,
        initialStatus: setting.initialStatus,
        nextStatus: setting.nextStatus,
        defaultNextStatus: setting.defaultNextStatus,
        showHeader: setting.showHeader,
        allowReasonCodes: setting.allowReasonCodes,
        title: setting.title,
        allowComments: setting.allowComments,
        trash: setting.trash,
        actionPermissionModule: setting.actionPermissionModule,
        updateType: setting.updateType,
        actionFields: setting.actionFields || [],
      },
      { keepDefaultValues: true }
    );
  }, [setting, isUpdate, open]);

   // Setup field array for managing date fields
   const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: "actionFields"
  });

  // Handle field reordering
  const onDragEnd = (result) => {
    if (!result.destination) return;
    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    
    move(sourceIndex, destinationIndex);
  };


  const handleAddField = () => {
    append({
      fieldName: '',
      parseField: '',
      required: false,
      defaultValue: 'none',
      showField: true
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (isUpdate) {
      await dispatch(updateUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    } else {
      await dispatch(addUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    }

    onSubmit();
    handleClose();
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (setting.trash) {
      await dispatch(enableUserSetting([modelName, settingId])).unwrap();
    } else {
      await dispatch(disableUserSetting([modelName, settingId])).unwrap();
    }
    onSubmit();
  }, [setting, setting]);

  const [handleReset, resetLoading] = useSubmit(async () => {
    await dispatch(resetUserSetting([modelName, settingId])).unwrap();

    onSubmit();
  }, [settingId]);

  const [statusCodeOptions, setStatusCodeOptions] = useState([]);

  useEffect(() => {
    // set status code options for actions multiselect
    if (jobStatusCodes?.length > 0) {
      jobStatusCodes = jobStatusCodes.filter((jobStatusCode) => jobStatusCode.isSystemMaster);
      setStatusCodeOptions(
        jobStatusCodes.map(({ jobStatusCodesName, jobStatusCodesCode, _id, trash }) => ({
          label: jobStatusCodesName + ' [' + jobStatusCodesCode + ']',
          value: jobStatusCodesCode,
          _id: _id,
          trash: trash,
        }))
      );
    }
  }, [jobStatusCodes]);

  const nextStatusOptions = form.watch('nextStatus');

  const [actionPermissionOptions, setActionPermissionModuleOptions] = useState([]);

  useEffect(() => {
    if (moduleConstants) {
      const moduleConstantKeys = Object.keys(moduleConstants);

      setActionPermissionModuleOptions(
        moduleConstantKeys.map((value) => ({
          label: moduleConstants[value],
          value: moduleConstants[value],
        }))
      );
    }
  }, [moduleConstants]);

  const [allowedDefaultOptions, setAllowedDefaultOptions] = useState([]);
  useEffect(() => {
    if (nextStatusOptions?.length > 0) {
      setAllowedDefaultOptions(nextStatusOptions.map((value) => ({ label: value, value })));
    }
  }, [nextStatusOptions]);

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} Project Action</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput label='Name' name='name' required />
              </div>

              <div className='col-6'>
                <FormInput label='Code' name='code' required />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Title' name='title' required />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Definition' name='definition' textArea />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <FormCheckbox label='Allow reason codes' name='allowReasonCodes' />
              </div>
              <div className='col-6'>
                <FormCheckbox label='Allow comments' name='allowComments' />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <FormCheckbox label='Show Header' name='showHeader' />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <FormMultiselect
                  label='Initial Status'
                  name='initialStatus'
                  options={statusCodeOptions}
                />
              </div>
              <div className='col-6'>
                <FormMultiselect
                  label='Next Status'
                  name='nextStatus'
                  options={statusCodeOptions}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <FormSelect
                  label='Default Next Status'
                  name='defaultNextStatus'
                  options={allowedDefaultOptions}
                />
              </div>
              <div className='col-6'>
                <FormSelect
                  label='Action permission module'
                  name='actionPermissionModule'
                  options={actionPermissionOptions}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
              <FormCheckbox label='backwardsStatus' name='backwardsStatus' />
                <FormCheckbox label='Trash' name='trash' />
              </div>
              <div className='col-6'>
                <FormSelect
                  label='Update Type'
                  name='updateType'
                  options={updateTypes.map((value) => ({ label: value, value }))}
                />
              </div>
            </div>
          </div>

          <div className='row mt-4'>
              <div className='col-12'>
                <h5 className='mb-3'>Action Fields</h5>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="actionFields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {fields.map((field, index) => (
                          <Draggable 
                            key={field.id} 
                            draggableId={field.id} 
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="card mb-3"
                              >
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div {...provided.dragHandleProps} className="me-2">
                                      <i className="fa fa-grip-vertical"></i>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger btn-sm"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                  
                                  <div className="row">
                                    <div className="col-3">
                                      <FormInput
                                        label="Field Label"
                                        name={`actionFields.${index}.fieldName`}
                                        required
                                      />
                                    </div>
                                    <div className="col-3">
                                      <FormInput
                                        label="Parse Field Name"
                                        name={`actionFields.${index}.parseField`}
                                        required
                                      />
                                    </div>
                                    <div className="col-2">
                                      <FormSelect
                                        label="Default Value"
                                        name={`actionFields.${index}.defaultValue`}
                                        options={defaultValueOptions}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <FormCheckbox
                                        label="Required"
                                        name={`actionFields.${index}.required`}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <FormCheckbox
                                        label="Show Field"
                                        name={`actionFields.${index}.showField`}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <FormCheckbox
                                        label="Show Time"
                                        name={`actionFields.${index}.showTime`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2"
                  onClick={handleAddField}
                >
                  <i className="bi bi-plus-circle me-2"></i>
                  Add Field
                </button>
              </div>
            </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {isUpdate && !!setting.isLocalCopy && (
          <button className='btn btn-primary me-auto' onClick={handleReset} disabled={resetLoading}>
            Reset to default
          </button>
        )}

        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button className='btn btn-primary' onClick={handleToggle} disabled={toggleLoading}>
            {setting.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={form.formState.isSubmitting}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
