import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { formatMoney} from '../../utils/number-formatters';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { updateProjectStatusAction } from '../../actions/projectStatus';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { useSelector } from 'react-redux';
import { renderStatusHeader } from '../../components/Common/UpdateProjectStatusModal/update-project-status-modal';
import styles from './update-project-dollars-modal.module.scss';
import CurrencyInput from '../../components/Common/UpdateProjectStatusModal/CurrencyInput';

const UpdateProjectDollarsModal = ({ isOpen, toggle, projectData, onUpdate }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  const [contractValues, setContractValues] = useState({
    currentContractAmount: projectData.currentContractAmount || projectData.originalContractAmount,
    currentGrossProfit: projectData.currentGrossProfit || projectData.originalGrossProfit,
    comment: ''
  });

  const accountInfo = useSelector(selectGeneralInfo);
  const restrictContractAdjustments = accountInfo?.restrictContractAdjustments ?? true;

  useEffect(() => {
    setContractValues({
      currentContractAmount: projectData.currentContractAmount || projectData.originalContractAmount,
      currentGrossProfit: projectData.currentGrossProfit || projectData.originalGrossProfit,
      comment: ''
    });
  }, [projectData]);

  const calculateCosts = () => {
    return contractValues.currentContractAmount - contractValues.currentGrossProfit;
  };

  const calculateVariancePercentage = (original, current) => {
    if(!original && !current) return 0;
    if (!original) return Infinity*(current>0?1:-1);
    if(!current) return -100;
    return ((current - original) / original * 100);
  };

  const renderVariance = (original, current, positiveIsBad=true) => {
    const variance = calculateVariancePercentage(original, current);
    
    if(variance===0)  return '-';
    const shouldBeDanger = variance>0 && positiveIsBad || variance<0 && !positiveIsBad;
    return <span className={shouldBeDanger ? 'text-danger' : 'text-success'}>{(variance>0)?'+':''}{variance.toFixed(1)}%</span>;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setContractValues(prev => {
      const updates = { ...prev, [name]: value };
      
      // Ensure gross profit doesn't exceed contract amount
      if (name === 'currentContractAmount' && updates.currentGrossProfit > value) {
        updates.currentGrossProfit = value;
      }
      if (name === 'currentGrossProfit' && value > updates.currentContractAmount) {
        updates.currentGrossProfit = updates.currentContractAmount;
      }
      
      return updates;
    });
  };

  const handleCommentChange = (e) => {
    setContractValues(prev => ({ ...prev, comment: e.target.value }));
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        updateProjectStatusAction({
          projectId: projectData.objectId,
          ...projectData,
          ...contractValues,
          updateType:'UpdateDollars',
        })
      );
      onUpdate(contractValues);
      toggle();
    } catch (err) {
      setErrorMsg('Failed to update contract values');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog modal-lg">
      <ModalHeader toggle={toggle}>Update actual / projected dollars</ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          {renderStatusHeader(projectData, errorMsg)}

          <table className='dollarsTable'>
            <thead>
              <tr>
                <th>Dollars</th>
                <th>Original</th>
                <th>Actual / projected *</th>
                <th>Variance (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contract amount</td>
                <td>{formatMoney(projectData.originalContractAmount)}</td>
                <td>
                  {restrictContractAdjustments ? (
                    formatMoney(contractValues.currentContractAmount)
                  ) : (
                    <CurrencyInput
                      name="currentContractAmount"
                      value={contractValues.currentContractAmount}
                      onChange={handleInputChange}
                    />
                  )}
                </td>
                <td className="">
                  {renderVariance(projectData.originalContractAmount, contractValues.currentContractAmount, false)}
                </td>
              </tr>
              <tr>
                <td>Costs</td>
                <td>{formatMoney(projectData.originalContractAmount - projectData.originalGrossProfit)}</td>
                <td>{formatMoney(calculateCosts())}</td>
                <td className="">
                  {renderVariance(
                    projectData.originalContractAmount - projectData.originalGrossProfit, calculateCosts(),
                  )}
                </td>
              </tr>
              <tr>
                <td>Gross profit</td>
                <td>{formatMoney(projectData.originalGrossProfit)}</td>
                <td>
                  <CurrencyInput
                    name="currentGrossProfit"
                    value={contractValues.currentGrossProfit}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="">
                  {renderVariance(projectData.originalGrossProfit, contractValues.currentGrossProfit, false)}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="form-group mt-4">
            <label><strong>Comment:</strong></label>
            <textarea
              className="form-control"
              rows="3"
              value={contractValues.comment}
              onChange={handleCommentChange}
              placeholder="Comment"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-primary" onClick={toggle}>
          Cancel
        </button>
        <button 
          className="btn btn-primary" 
          onClick={handleUpdate} 
          disabled={isLoading}
        >
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export { UpdateProjectDollarsModal };