import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cpostLogo from '../../static/images/cpostIcon.png';

const AdminSidebar = ({ location }) => {
  const [isMastersOpen, setIsMastersOpen] = useState(false);
  const { pathname } = location;

  const isLinkActive = (path) => pathname && pathname.includes(path);

  return (
    <div className="bg-white shadow h-100 min-vh-100 border-end" style={{ width: '260px' }}>
      <div className="p-3 border-bottom">
        <Link to="/admin/home" className="text-decoration-none">
          <div className="text-center">
            <img 
              src={cpostLogo}
              alt="Contractor Command Post" 
              className="img-fluid mb-2"
              style={{ maxHeight: '64px' }}
            />
            <div className="text-dark fw-medium small">
              Contractor's Command Post
            </div>
          </div>
        </Link>
      </div>

      <nav className="p-3">
        <ul className="nav nav-pills flex-column">
          {/* Main Navigation Items */}
          <li className="nav-item">
            <Link 
              to="/admin/accounts"
              className="nav-link text-dark"
            >
              <i className="fa fa-building me-2"></i>
              ACCOUNTS
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/admin/projects"
              className="nav-link text-dark"
            >
              <i className="fa fa-clipboard me-2"></i>
              PROJECTS
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/admin/users"
              className="nav-link text-dark"
            >
              <i className="fa fa-person me-2"></i>
              USERS
            </Link>
          </li>

          {/* Masters Accordion */}
          <li className="nav-item">
            <button
              onClick={() => setIsMastersOpen(!isMastersOpen)}
              className="nav-link text-dark d-flex align-items-center justify-content-between w-100 border-0 bg-transparent"
            >
              <span>
                <i className="fa fa-gear me-2"></i>
                MASTERS
              </span>
              <i className={`fa fa-chevron-${isMastersOpen ? 'right' : 'down'}`}></i>
            </button>

            <div className={`collapse ${isMastersOpen ? 'show' : ''}`}>
              <ul className="nav flex-column ms-3 mt-2">
                {[
                  { path: 'user-types', label: 'User Types', icon: 'user' },
                  { path: 'default-role-permissions/status-change', label: 'User Permissions', icon: 'shield' },
                  { path: 'report-settings', label: 'Report Settings', icon: 'file-text' },
                  { path: 'project-fields', label: 'Project Fields', icon: 'list-check' },
                  { path: 'job-status', label: 'Job Status Codes', icon: 'tag' },
                  { path: 'job-scope', label: 'Job Scopes', icon: 'briefcase' },
                  { path: 'building-type', label: 'Building Types', icon: 'building' },
                  { path: 'reason-codes', label: 'Reason Codes', icon: 'ticket' },
                  { path: 'project-role-types', label: 'Project Role Types', icon: 'hammer' },
                  { path: 'contact-role', label: 'Contact Roles', icon: 'users' },
                  { path: 'negotiation-method', label: 'Negotiating Methods', icon: 'handshake' },
                  { path: 'form-templates', label: 'Form Templates', icon: 'file' },
                  { path: 'project-actions', label: 'Project Status Actions', icon: 'arrow-right' }
                ].map((item) => (
                  <li key={item.path} className="nav-item">
                    <Link
                      to={`/admin/${item.path}`}
                      className={`nav-link ${
                        isLinkActive(item.path)
                          ? 'active bg-light text-primary'
                          : 'text-secondary'
                      }`}
                    >
                      <i className={`fa fa-${item.icon} me-2`}></i>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>

          {/* Additional Navigation Items */}
          {[
            { path: 'plans', label: 'SUBSCRIPTION LIST', icon: 'card-checklist' },
            { path: 'notification-types', label: 'NOTIFICATION TYPES', icon: 'bell' },
            { path: 'widgets', label: 'WIDGETS', icon: 'grid' },
            { path: 'reset-password', label: 'CHANGE PASSWORD', icon: 'key' },
            { path: 'help-list', label: 'HELP', icon: 'question-circle' },
            { path: 'module-options', label: 'MODULES', icon: 'boxes' },
            { path: 'termsCondition', label: 'TERMS & CONDITIONS', icon: 'file-text' }
          ].map((item) => (
            <li key={item.path} className="nav-item">
              <Link
                to={`/admin/${item.path}`}
                className="nav-link text-dark"
              >
                <i className={`bi bi-${item.icon} me-2`}></i>
                {item.label}
              </Link>
            </li>
          ))}

          {/* Logout Button */}
          <li className="nav-item mt-2">
            <Link
              to="/admin/logout"
              className="nav-link text-danger"
            >
              <i className="bi bi-box-arrow-right me-2"></i>
              LOGOUT
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminSidebar;