import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetProfitSalesAction } from '../../../actions/reports';
import { ResponsivePie } from '@nivo/pie';
import loaderImage from '../../static/images/loading_i.gif';
import { formatMoney, roundFormatMoney } from '../../../utils/number-formatters';

const ProfitSalesWidget = ({ componentData, profitList, GetProfitSalesAction }) => {
  const [formData, setFormData] = useState({
    whatToAnalyze: 'profits',
    jobStatus: false,
    dateType: 'dateType',
    analyzeBy: '',
    jobBasedOn: '',
  });

  const [profitData, setProfitData] = useState(componentData?.profitData || {});
  const [fetchRequest, setFetchRequest] = useState(true);
  const [dataLength, setDataLength] = useState(false);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (profitData) {
      fetchProfitData(profitData);
    }
  }, [profitData]);

  useEffect(() => {
    if (profitList && profitList.data) {
      if (profitList.data.length > 0) {
        profitCalculation(profitList.data);
        setFetchRequest(false);
        setDataLength(false);
      } else {
        setFetchRequest(true);
      }

      if (profitList.data.length === 0) {
        setDataLength(true);
      }
    }
  }, [profitList]);

  const fetchProfitData = (profitData) => {
    const data = {
      analyzeBy: 'PM',
      whatToAnalyze: profitData,
      jobStatus: false,
      jobBasedOn: 'activeAndDelivered',
      activeAndDeliveredStatus: false,
    };
    GetProfitSalesAction(data);
  };

  const profitCalculation = (profitArr) => {
    let finalSum = {};
    finalSum.FBidContractSum = 0;
    finalSum.FBidProfitSum = 0;
    finalSum.FBidProfitPerSum = 0;

    if (profitArr.length > 0) {
      for (let x in profitArr) {
        profitArr[x].allContract = 0;
        profitArr[x].allProfit = 0;
        profitArr[x].analyzer = '';

        //*** Analyser name
        if (profitArr[x].itemInfo) {
          profitArr[x].analyzer = profitArr[x].itemInfo?.displayName ?? 'Unassigned';
        }

        for (let y in profitArr[x].projects) {
          profitArr[x].projects[y].perProjectBidProfit = 0;
          profitArr[x].projects[y].selectedDate = '';

          //**** Total Bid amount and profit amount
          profitArr[x].allContract += profitArr[x].projects[y].currentContractAmount;
          profitArr[x].allProfit += profitArr[x].projects[y].currentGrossProfit;

          //**** Total won amount and profit amount
          var jobBasedOn = formData.jobBasedOn;
          profitArr[x].projects[y].selectedDate = profitArr[x].projects[y][jobBasedOn];
        }

        //**** bid Profit percentage
        if (profitArr[x].bidProfitPercent === undefined) {
          if (isNaN(profitArr[x].allProfit / profitArr[x].allContract) === true) {
            profitArr[x].bidProfitPercent = 0.0;
          } else {
            profitArr[x].bidProfitPercent =
              (profitArr[x].allProfit / profitArr[x].allContract) * 100;
            profitArr[x].bidProfitPercent = profitArr[x].bidProfitPercent.toFixed(2);
          }
        }

        //*** Total project, Net Amount of Bid Contract/Profit/ProfitPercentage, Won Contract/Profit/ProfitPercentage, Bid hit ratio and Profit
        finalSum.FBidContractSum += profitArr[x].allContract;
        finalSum.FBidProfitSum += profitArr[x].allProfit;
        finalSum.FBidProfitPerSum += Math.round(profitArr[x].bidProfitPercent);
      }

      let pieChartTotal = 0;
      let graphDataNew = [];
      for (let x in profitArr) {
        //********** graph data Calculation
        let pieChartPer = 0;
        if (formData.whatToAnalyze === 'profits') {
          if (profitArr[x].allProfit > 0) {
            pieChartPer = profitArr[x].allProfit;
          }
        }
        pieChartTotal += pieChartPer;

        if (profitArr[x].analyzer === 'Unassigned') {
          graphDataNew.unshift({ id: profitArr[x].analyzer, value: pieChartPer });
        }
        else {
          graphDataNew.push({ id: profitArr[x].analyzer, value: pieChartPer });
        }
      }

      const minPercentage = 5;
      const minValue = (pieChartTotal * minPercentage) / 100;
      const otherData = graphDataNew.filter((item) => item.value < minValue);
      const otherDataTotal = otherData.reduce((acc, item) => acc + item.value, 0);
      const otherDataItem = { id: 'Other', value: otherDataTotal };
      const filteredData = graphDataNew.filter((item) => item.value >= minValue);
      const graphDataNewFinal = [...filteredData, otherDataItem];

      if (otherDataTotal > 0) {
        setGraphData(graphDataNewFinal);
      } else {
        setGraphData(graphDataNew);
      }
    }
  };

  const [legends, setLegends] = useState([]);

  return (
    <div>
      {graphData && graphData.length > 0 && (
        <div className='row' style={{ minHeight: 300, fill: 'white' }}>
          <div className='col-md-8 svgGraph'>
            <ResponsivePie
              data={graphData}
              animate={false}
              margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
              innerRadius={0.7}
              cornerRadius={3}
              colors={{ scheme: 'tableau10' }}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              padAngle={2}
              arcLabelsTextColor={"#ffffff"}
              arcLabelsSkipAngle={1}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              forwardLegendData={setLegends}
              legends={[
                {
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: -3000,
                  translateY: 0,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemsSpacing: 0,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  itemDirection: 'left-to-right',
                },]}
              // format values with commas and dollars
              valueFormat={(value) => formatMoney(value, { prefix: '$', decimalCount: 0 })}
              arcLabel={(e) => roundFormatMoney((e.value / 1000), { prefix: '$', decimalCount: 0 }) + 'k'}
            />
          </div>
          <div className='col-md-4'>
            {legends.length > 0 && (
              <div className='row mb-4' style={{ minHeight: '30px' }}>
                 {legends.map((legend, index) => {
                  return (
                    <div key={'legend_' + index}>
         
                          <div style={{ width: '12px', height: '12px', borderRadius: '50%',display: 'inline-block', backgroundColor: legend.color }}></div>

                          <div style={{ fontSize: '10pt', display: 'inline', verticalAlign:'middle' }} > {legend.label}</div>
                          <div style={{ fontSize: '10pt',  }} > {legend.data.formattedValue?? legend.data.value}</div>
                    </div>
                  );
                })}
              </div>
            )}
            <ul className='clearfix mt-auto'>
              <li>
                <strong>Report Period:</strong> As of today
              </li>
              <li>
                <strong>Grouped by:</strong> Active, Delivered, and Pre-Con Jobs{' '}
              </li>
              <li>
                <strong>Analyzed by:</strong> Project Manager
              </li>
            </ul>
          </div>
        </div>
      )}

      {fetchRequest && !dataLength && (
        <div className='msgBox'>
          <img src={loaderImage} width='100' height='100' alt='loading' />
        </div>
      )}

      {fetchRequest && dataLength && (
        <div className='msgBox'>
          <div>No data found!</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profitList: state.reports.profitAndSalesList,
  isSPReport: state.reports.isSPReport,
  isSPReportError: state.reports.isSPReportError,
});

export default withRouter(connect(mapStateToProps, { GetProfitSalesAction })(ProfitSalesWidget));
