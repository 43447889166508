import React, {Suspense} from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import { GetProfitPerWeekReportAction } from '../../../actions/reports';
import ReactLoading from 'react-loading';
import ReactToPrint from 'react-to-print';

import { CustomDateInput } from '../../../shared/custom-fields-controls/form-controls/custom-date-input';
import ExportExcelComponent from './../exportExcelComponent';
import TitleComponent from  "../../Common/TitleComponent";
import {
  internalExcelContact,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { formatDateWithTime,formatDateObject,formatDate,formatDateAdd, startDateFormatWithoutTimeUTCF, endDateFormatWithoutTimeUTCF } from '../../../utils/date-formatters';
import { formatMoney, roundFormatMoney } from '../../../utils/number-formatters';
import {sortAsc} from '../../../components/Auth/Auth';

let dataArr = [];
let tableData = [];
let noData = true;

var sortingOrder = '';
var sortOrders = 'desc';
var profitDateStatus = '';

class ProfitPerWeekReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      selectBox: {},
      errorUploading: true,
      successUploading: true,
      alert: {},
      fetchRequest: true,
      comLogo: '',
      modal: false,
      totalCount: 0,
      currentPage: 1,
      listExcel: [],
      dataForPdf: false,
      dataForExcel: false,
      //sizePerPage: appConstants.TableSizePerPageList[2].value,
      sizePerPage: 2000,
      searchText: '',
      selectDateBox: {
        startDate: formatDateObject().subtract(1, 'years'),
        endDate: formatDateObject(),
      },
      contactData: {},
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    dataArr = [];
    tableData = [];
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      !this.state.dataForExcel &&
      !this.state.dataForPdf
    ) {
      this.setState({
        totalCount: nextProps.listData.count,
        list: nextProps.listData.data,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      (this.state.dataForExcel || this.state.dataForPdf)
    ) {
      this.makeDataForExportExcel(nextProps.listData.data);
      this.setState({ dataForExcel: false, dataForPdf: false });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 1000);
    }
  }

  makeDataForExportExcel = (list) => {
    const multiDataSet = [
      {
        columns: [
          {title: 'Job Num.', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Alt Job#', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Job Name', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Status', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Profit', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Contract Amt.', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Proj.Mgr', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Sup.', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Start Date', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'End Date', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Duration/Weeks', width: {wch: 10}, height: {wch: 'auto'}},
          {title: 'Profit/Week', width: {wch: 10}, height: {wch: 'auto'}},
        ],
        data: [],
      },
    ];
    let dbHeader = [
      'Job Num',
      'Alt Job Num',
      'Job Name',
      'Status',
      'Profit',
      'Contract Amt',
      'Proj.Mgr',
      'Gen. Sup',
      'Start Date',
      'End Date',
      'Duration/Weeks',
      'Profit/Week',
    ];
    let dbDataArr = [];
    let dbDataArrB = [];

    var finalSum = [];
    finalSum.FProjectSum = 0;
    finalSum.FContractSum = 0;
    finalSum.FProfitSum = 0;
    finalSum.FProfitPerWeek = 0;
    let blankWBgColor = { font: { bold: true } };
    for (var x in list) {
      dbDataArrB = [];
      let row = list[x];
      let profitPW;
      let durationW = 0;
      if (row && row.WAmt) {
        let durationData = row.WAmt;
        //Duration per Weeks
        durationW = durationData.toFixed(3);
        //Profit Per week
        //profitPW = row.currentProfit / durationW;

        var end = new Date(formatDate(row.endDate && row.endDate.iso)); //todays date
        var start = new Date(formatDate(row.startDate && row.startDate.iso)); // another date
        var dateDiff = (end.valueOf() + 86400000 - start.valueOf()) / 86400000;
        //  console.log('^^^^^^^^^^^', dateDiff)
        profitPW = (row.currentProfit * 7) / dateDiff;
      }

      finalSum.FProfitSum += list[x].currentProfit;
      finalSum.FContractSum += list[x].currentContractAmount;
      finalSum.FProfitPerWeek += profitPW;

      let firmNames = '';
      for (var y in dbHeader) {
        switch (dbHeader[y]) {
          case 'Job Num':
            dbDataArrB.push(row.jobNumFormatted ? row.jobNumFormatted : '-');
            break;
          case 'Alt Job Num':
            dbDataArrB.push(row.altJobNum ? row.altJobNum : '-');
            break;
          case 'Job Name':
            dbDataArrB.push(row.jobName ? row.jobName : '-');
            break;
          case 'Status':
            dbDataArrB.push(row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-');
            break;
          case 'Profit':
            dbDataArrB.push(
              row.currentProfit ? roundFormatMoney(row.currentProfit) : 0
            );
            break;
          case 'Contract Amt':
            let ContractAmount =
              row && row.currentContractAmount
                ? roundFormatMoney(row.currentContractAmount)
                : 0;
            dbDataArrB.push(ContractAmount);
            break;
          case 'Proj.Mgr':
            let projectMgr = '';
            if (row.projectManagerArr && row.projectManagerArr.length > 0) {
              projectMgr = internalExcelContact(
                row.projectManagerArr,
                this.state.contactData
              );
              // let projectManagerArr = sortAsc(row.projectManagerArr,"userFullName");
              // projectManagerArr.map((item, index) => {
              //     let comma;
              //     if (index != 0) {
              //         comma = ", ";
              //     } else {
              //         comma = "";
              //     }
              //      projectMgr += comma + getAcronymCode(item.userFullName);
              // });
            }

            dbDataArrB.push(projectMgr);
            break;
          case 'Gen. Sup':
            let generalMgr = '';
            if (row.projectSupervisorArr && row.projectSupervisorArr.length > 0) {
              generalMgr = internalExcelContact(
                row.projectSupervisorArr,
                this.state.contactData
              );
              // let projectSupervisorArr = sortAsc(row.projectSupervisorArr,"userFullName");
              // projectSupervisorArr.map((item, index) => {
              //     let comma;
              //     if (index != 0) {
              //         comma = ", ";
              //     } else {
              //         comma = "";
              //     }
              //         generalMgr += comma + getAcronymCode(item.userFullName);
              //
              // });
            }
            dbDataArrB.push(generalMgr);
            break;
          case 'Start Date':
            dbDataArrB.push(row.startDate ? formatDate(row.startDate) : '-');
            break;
          case 'End Date':
            dbDataArrB.push(row.endDate ? formatDate(row.endDate) : '-');
            break;
          case 'Duration/Weeks':
            let durationData = row.WAmt;
            //Duration per Weeks
            let durationW = durationData.toFixed(3);
            dbDataArrB.push(durationW ? durationW : '-');
            break;
          case 'Profit/Week':
            var end = new Date(formatDate(row.endDate)); //todays date
            var start = new Date(formatDate(row.startDate)); // another date
            var dateDiff = (end.valueOf() + 86400000 - start.valueOf()) / 86400000;
            let profitPW = (row.currentProfit * 7) / dateDiff;
            dbDataArrB.push(profitPW ? formatMoney(profitPW) : 0);
            break;
        }
      }
      dbDataArr.push(dbDataArrB);
    }
    dbDataArrB = [];
    for (var y in dbHeader) {
      switch (dbHeader[y]) {
        case 'Job Num':
          dbDataArrB.push({ value: 'Totals:', style: blankWBgColor });
          break;
        case 'Profit':
          let FProfitSum = finalSum.FProfitSum
            ? roundFormatMoney(finalSum.FProfitSum)
            : 0;
          dbDataArrB.push({ value: FProfitSum, style: blankWBgColor });
          break;
        case 'Contract Amt':
          let FContractSum = finalSum.FContractSum
            ? roundFormatMoney(finalSum.FContractSum)
            : 0;
          dbDataArrB.push({ value: FContractSum, style: blankWBgColor });
          break;
        case 'Profit/Week':
          let FProfitPerWeek = finalSum.FProfitPerWeek
            ? roundFormatMoney(finalSum.FProfitPerWeek)
            : 0;
          dbDataArrB.push({ value: FProfitPerWeek, style: blankWBgColor });
          break;
        default:
          dbDataArrB.push({ value: '', style: blankWBgColor });
          break;
      }
    }
    dbDataArr.push(dbDataArrB);
    // console.log("dbDataArr", dbDataArr);
    multiDataSet[0].data = dbDataArr;
    this.setState({ multiDataSet });
  };

  componentDidMount() {
    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
  }

  UNSAFE_componentWillMount() {
    
  }

  excelExport() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['22'];
    // data['startDate'] = moment(this.state.selectDateBox.startDate).format('YYYY-MM-DD');
    // data['endDate'] = moment(this.state.selectDateBox.endDate).format('YYYY-MM-DD');
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    data['page'] = 0;
    data['displayLimit'] = this.state.totalCount;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.props.GetProfitPerWeekReportAction(data);
    this.setState({
      dataForExcel: true,
      dataForPdf: false,
    });
  }

  downloadPdf() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['22'];
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    // data['startDate'] = moment(this.state.selectDateBox.startDate).format('YYYY-MM-DD');
    // data['endDate'] = moment(this.state.selectDateBox.endDate).format('YYYY-MM-DD');
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    data['page'] = 0;
    data['displayLimit'] = this.state.totalCount;

    console.log('data', data);
    this.props.GetProfitPerWeekReportAction(data);

    this.setState({
      dataForExcel: false,
      dataForPdf: true,
    });
  }

  toggle(val) {
    if(val===false)
      this.setState({ modal: false });
    else if(val===true)
      this.setState({ modal: true });
    else
      this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    e.preventDefault();
    this.setState({ fetchRequest: true, list: [] });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['22'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    data['page'] = 0;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['displayLimit'] = 2000;

    profitDateStatus =
      'Includes jobs ending between ' +
      formatDate(this.state.selectDateBox.startDate) +
      ' and ' +
      formatDate(this.state.selectDateBox.endDate);

    this.toggle(false);
    this.props.GetProfitPerWeekReportAction(data);
  }

  handleDatePicker = (name, date) => {
    let field = this.state.selectDateBox;
    field[name] = date;

    if (name === 'startDate') {
      let dateObj = '';
      if (date === null) {
        dateObj = formatDateObject().add(1, 'day');
        field['endDate'] = dateObj;
      } else {
        dateObj = formatDateObject(date).add(1, 'Day');
        if (field['startDate'] > field['endDate']) {
          field['endDate'] = dateObj;
        }
      }
    }

    this.setState({
      selectDateBox: field,
    });
  };

  createCustomButtonGroup = (props) => {
    return (
      <div className="pdfLogoBox">
        <div className="reportHead">
          <div className="logo">
            <img src={this.state.comLogo} alt="Upload Logo" />
          </div>
          <h2>Profit Per Week Analysis Report</h2>
          <div>
            Includes jobs ending between{' '}
            {formatDate(this.state.selectDateBox.startDate)} and{' '}
            {formatDate(this.state.selectDateBox.endDate)}
          </div>
        </div>
      </div>
    );
  };

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['22'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    if (this.state.searchText === '') {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
    } else {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
      data['search'] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetProfitPerWeekReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['22'];
    data['page'] = 0;
    data['displayLimit'] = 2000;
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    if (text !== '') {
      if (text.length >= 3) {
        data['search'] = text;
        this.setState({ sizePerPage: 2000 });
      }
    }
    this.setState({ searchText: text });
    this.props.GetProfitPerWeekReportAction(data);
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      // this.setState({ fetchRequest: true })
      let data = {};
      data['sortKey'] = sortName;
      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        sortOrders = 'desc';
        this.setState({ sortType: data['sortType'] });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        sortOrders = 'asc';
        this.setState({ sortType: data['sortType'] });
      }

      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['22'];
      data['startDate'] = startDateFormatWithoutTimeUTCF(
        this.state.selectDateBox.startDate
      );
      data['endDate'] = endDateFormatWithoutTimeUTCF(
        this.state.selectDateBox.endDate
      );
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetProfitPerWeekReportAction(data);
    }
  }

  dataCalculation() {
    var finalSum = [];
    finalSum.FProjectSum = 0;
    finalSum.FContractSum = 0;
    finalSum.FProfitSum = 0;
    finalSum.FProfitPerWeek = 0;

    dataArr = this.state.list;
    var x = null;
    var y = null;

    if (dataArr.length > 0) {
      noData = false;
      tableData = [];
      for (x in dataArr) {
        let row = dataArr[x];

        //Project Manager
        let projectMgr = [];
        if (row.projectManagerArr && row.projectManagerArr.length > 0) {
          projectMgr = formatInternalContactData(row.projectManagerArr, this.state.contactData);
          // let projectManagerArr = sortAsc(row.projectManagerArr,"userFullName");
          // projectManagerArr.map((item, index) => {
          //     let comma;
          //     if (index != 0) {
          //         comma = ", ";
          //     } else {
          //         comma = "";
          //     }
          //     let projectMgrName = <Link to={{ pathname: `/contact-details/`+item.userId }}>{comma}{getAcronymCode(item.userFullName)}</Link>;
          //     projectMgr.push(projectMgrName)
          // });
        } else {
          projectMgr = <span>-</span>;
        }

        //General Superintendent
        let generalMgr = [];
        if (row.projectSupervisorArr && row.projectSupervisorArr.length > 0) {
          generalMgr = formatInternalContactData(
            row.projectSupervisorArr,
            this.state.contactData
          );
          // let projectSupervisorArr = sortAsc(row.projectSupervisorArr,"userFullName");
          // projectSupervisorArr.map((item, index) => {
          //     let comma;
          //     if (index != 0) {
          //         comma = ", ";
          //     } else {
          //         comma = "";
          //     }
          //     let generalMgrName = <Link to={{ pathname: `/contact-details/`+item.userId }}>{comma}{getAcronymCode(item.userFullName)}</Link>;
          //     generalMgr.push(generalMgrName)
          // });
        } else {
          generalMgr = <span>-</span>;
        }

        let durationW;
        let profitPW;
        if (row && row.WAmt) {
          let durationData = row.WAmt;
          //Duration per Weeks
          durationW = durationData.toFixed(3);
          //Profit Per week
          //profitPW = row.currentProfit / durationW;

          var end = new Date(formatDate(row.endDate)); //todays date
          var start = new Date(formatDate(row.startDate)); // another date
          var dateDiff = (end.valueOf() + 86400000 - start.valueOf()) / 86400000;
          //  console.log('^^^^^^^^^^^', dateDiff)
          profitPW = (row.currentProfit * 7) / dateDiff;
        }

        finalSum.FProfitSum += dataArr[x].currentProfit;
        finalSum.FContractSum += dataArr[x].currentContractAmount;
        finalSum.FProfitPerWeek += profitPW;

        // Project list data
        var jobNum = (
          <td width="60">
            <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
              {row.jobNumFormatted ? row.jobNumFormatted : '-'}
            </Link>
          </td>
        );
        var altNum = (
          <td width="80">
            <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
              {row.altJobNum ? row.altJobNum : '-'}
            </Link>
          </td>
        );
        var jobName = (
          <td width="100">
            <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
              {row.jobName ? row.jobName : '-'}
            </Link>
          </td>
        );
        var status = <td width="80">{row.jobStatusCodesCode ? row.jobStatusCodesCode : '-'}</td>;
        var profit = (
          <td width="80" className="textRight">
            {row.currentProfit ? roundFormatMoney(row.currentProfit) : 0}
          </td>
        );
        var contractAmt = (
          <td width="90" className="textRight">
            {row && row.currentContractAmount
              ? roundFormatMoney(row.currentContractAmount)
              : 0}
          </td>
        );
        var projM = <td width="80">{projectMgr}</td>;
        var GenM = <td width="80">{generalMgr}</td>;
        var startDate = (
          <td width="80">{row.startDate ? formatDate(row.startDate) : '-'}</td>
        );
        var endDate = (
          <td width="80">{row.endDate ? formatDate(row.endDate) : '-'}</td>
        );
        var durationPerWeek = <td width="90">{durationW ? durationW : '-'}</td>;
        var profitPerWeek = (
          <td width="80" className="textRight">
            {profitPW ? formatMoney(profitPW) : 0}
          </td>
        );

        var tablerow = (
          <tr>
            {jobNum}
            {altNum}
            {jobName}
            {status}
            {profit}
            {contractAmt}
            {projM}
            {GenM}
            {startDate}
            {endDate}
            {durationPerWeek}
            {profitPerWeek}
          </tr>
        );
        tableData.push(tablerow);
      }

      // net total
      var netTotal = <td>Totals: </td>;
      var td11 = <td></td>;
      var td12 = <td></td>;
      var netProfit = (
        <td className="textRight">
          {finalSum.FProfitSum ? roundFormatMoney(finalSum.FProfitSum) : 0}
        </td>
      );
      var netContract = (
        <td className="textRight">
          {finalSum.FContractSum
            ? roundFormatMoney(finalSum.FContractSum)
            : 0}
        </td>
      );
      var td13 = <td></td>;
      var td14 = <td></td>;
      var td15 = <td></td>;
      var td16 = <td></td>;
      var td17 = <td></td>;
      var netProfitPerWeek = (
        <td className="textRight">
          {finalSum.FProfitPerWeek
            ? roundFormatMoney(finalSum.FProfitPerWeek)
            : 0}
        </td>
      );

      var sr3 = (
        <tr className="border border2 total">
          {netTotal}
          {td11}
          {td11}
          {td12}
          {netProfit}
          {netContract}
          {td13}
          {td14}
          {td15}
          {td16}
          {td17}
          {netProfitPerWeek}
        </tr>
      );
      tableData.push(sr3);
    }
  }

  render() {
    const { multiDataSet } = this.state;
    let tableOption = {
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      sizePerPageList: appConstants.TableSizePerPageList,
      afterDeleteRow: this.handleDeleteButtonClick,
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text,
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      alwaysShowAllBtns: true, // Always show next and previous buttondefault is bottom, top and both is all available
      onSearchChange: this.onSearchChange,
      btnGroup: this.createCustomButtonGroup,
    };

    this.dataCalculation();
    let fStatus = { date: profitDateStatus };

    return (
      <div>
        <div className="ContactListReport">
        <TitleComponent data={{title:`Profit-Per-Week Report`}}/>
          <div>
            <Suspense fallback="loading"><Header props={this.props} /></Suspense>
          </div>
          <div className="contactBox">
            <div className="container">
            <div className="row">
              <div className="reportHead">
                <h2>Profit Per Week Analysis Report</h2>
                <ExportExcelComponent
                  onClick={this.excelExport.bind(this)}
                  data={multiDataSet}
                  reportName="Profit_Per_Week_Analysis_Report"
                />
                <ReactToPrint
                  trigger={() => (
                    <a href="#/" className="btn rightalignmentBtn">
                      <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Print PDF
                    </a>
                  )}
                  content={() => this.componentRef}
                />
                <button className="btn rightalignmentBtn" onClick={() => this.toggle(true)}>
                  <i className="fa fa-filter"></i> Apply Filters
                </button>
              </div>
              </div>

              <div ref={(el) => (this.componentRef = el)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pdfLogoBox">
                      <div className="reportHead">
                        <div className="logo">
                          <img src={this.state.comLogo} alt="Upload Logo" />
                        </div>
                        <h2>Profit Per Week Analysis Report</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row searchbar">
                  <div className="col-md-12">
                    <div>{profitDateStatus}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profit-projection reportTableSection bigTable bigTable1">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('jobNum', sortOrders);
                                }}
                              >
                                Job Num.
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('altJobNum', sortOrders);
                                }}
                              >
                                Alt Job#
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>Job Name</th>
                            <th>Status</th>
                            <th className="textRight">
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('grossProfit', sortOrders);
                                }}
                              >
                                Profit
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th className="textRight">
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('currentContractAmount', sortOrders);
                                }}
                              >
                                Contract Amt.
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('projectManagerName', sortOrders);
                                }}
                              >
                                Proj.Mgr.
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('projectSupervisorName', sortOrders);
                                }}
                              >
                                Supt.
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('startDate', sortOrders);
                                }}
                              >
                                Start Date
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>
                              <span
                                className="cursorPointer"
                                onClick={(e) => {
                                  this.onSortChange('endDate', sortOrders);
                                }}
                              >
                                End Date
                                <i className={'fa fa-sort' + sortingOrder} aria-hidden="true"></i>
                              </span>
                            </th>
                            <th>Duration/Weeks</th>
                            <th>Profit/Week</th>
                          </tr>
                        </thead>
                        <tbody>{tableData}</tbody>
                      </table>
                      {this.state.fetchRequest && (
                        <ReactLoading
                          className="table-loader"
                          type={appConstants.LOADER_TYPE}
                          color={appConstants.LOADER_COLOR}
                          height={appConstants.LOADER_HEIGHT}
                          width={appConstants.LOADER_WIDTH}
                        />
                      )}
                      {!this.state.fetchRequest &&
                        this.state.list &&
                        this.state.list.length == 0 && (
                          <div className="textCenter">No data found.</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select Date</ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span>
                    The report will contain all jobs whose end date falls between the start/end
                    dates selected.
                  </span>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date</label>
                    <CustomDateInput
                      className="form-control"
                      selected={formatDateObject(
                        this.state.selectDateBox.startDate
                      )}
                      onChange={this.handleDatePicker.bind(this, 'startDate')}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      required={true}
                      placeholderText="Click to select a date"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="endDate">End Date</label>
                    <CustomDateInput
                      className="form-control"
                      selected={formatDateObject(this.state.selectDateBox.endDate)}
                      onChange={this.handleDatePicker.bind(this, 'endDate')}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      required={true}
                      placeholderText="Click to select a date"
                      selectsEnd
                      startDate={formatDateObject(
                        this.state.selectDateBox.startDate
                      )}
                      endDate={formatDateObject(this.state.selectDateBox.endDate)}
                      minDate={formatDateObject(this.state.selectDateBox.startDate)}
                      maxDate={formatDateAdd(5, 'months')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={(e) => this.filteredData(e)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.profitPerWeekList,
  };
}

export default (
  withRouter(connect(mapStateToProps, { GetProfitPerWeekReportAction })(ProfitPerWeekReport))
);
