import { useCallback, useMemo, useRef } from 'react';
import { useBlockLayout, useTable } from 'react-table';
import {Tooltip} from 'react-tooltip';
import { FixedSizeList as List } from 'react-window';
import { TableStyles } from '../../styles';
import { getScrollbarWidth } from '../../utils/get-scrollbar-width';
import useBoundingRect from '../hooks/use-bounding-rect';

type MultidimensionalGridProps = {
  itemHeight?: number;
  columnDimension?: Array<any>;
  rowDimension?: Array<any>;
  matrixData?: any;
  getColumnIndex?: (column: any) => unknown;
  getColumnHeader?: (column: any) => unknown;
  getColumnDefinition?: (column: any) => unknown;
  hasColumnDefinition?: (column: any) => unknown;
  getRowIndex?: (row: any) => unknown;
  getRowHeader?: (row: any) => unknown;
  Cell?: (cellProps: any) => unknown;
  RowHeaderCell?: (cellProps: any) => unknown;
  useControlledState?: (state: any) => unknown;
  maxHeight?: number;
  rowNameHeader?: string;
};

const MultidimensionalGrid = ({
  itemHeight = 40,
  columnDimension = [],
  rowDimension = [],
  matrixData = {},
  getColumnIndex = (column) => column._id,
  getColumnHeader = (column) => column.moduleName,
  getColumnDefinition = (column) => column.moduleDefinition,
  hasColumnDefinition = (column) => column.moduleDefinition?.length > 0,
  getRowIndex = (row) => row._id,
  getRowHeader = (row) => row.roleName,
  Cell = ({ value }: any) => value || '<Empty>',
  RowHeaderCell = ({ value }) => value,
  useControlledState = (state) => ({ ...state }),
  maxHeight = 1500,
  rowNameHeader = '',
}: MultidimensionalGridProps) => {
  const { wrapperRef, height } = useBoundingRect();

  const maxTableHeight = useMemo(() => {
    const calculated = height - itemHeight - getScrollbarWidth();
    return maxHeight > calculated ? calculated : maxHeight;
  }, [height, itemHeight]);

  const data = useMemo(() => {
    return rowDimension.map((record) => {
      const rowIndex = getRowIndex(record);

      const rowData = matrixData[rowIndex as number];

      return { ...rowData, _rowIndex: rowIndex, _rowTitle: getRowHeader(record), _row: record };
    });
  }, [rowDimension, matrixData]);

  const tableHeight = useMemo(() => {
    const calculatedHeight = data.length * itemHeight;

    return calculatedHeight <= maxTableHeight ? calculatedHeight : maxTableHeight;
  }, [maxTableHeight, data.length, itemHeight]);

  const memoizedCell = useCallback(Cell, []);
  const memoizedRowHeaderCell = useCallback(RowHeaderCell, []);

  const columns = useMemo(() => {
    return [
      {
        Header: () => rowNameHeader,
        Definition: () => '',
        hasDefinition: () => false,
        accessor: '_rowTitle',
        Cell: memoizedRowHeaderCell,
        width: 200,
      },
      ...columnDimension.map((record) => ({
        Header: () => getColumnHeader(record),
        Definition: () => getColumnDefinition(record),
        hasDefinition: () => hasColumnDefinition(record),
        accessor: getColumnIndex(record),
        Cell: memoizedCell,
      })),
    ];
  }, [columnDimension]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns as any,
      data,
      useControlledState: useControlledState as any,
    },
    useBlockLayout
  );

  const rowHeaderRef = useRef<any>(null);

  return (
    <TableStyles ref={wrapperRef}>
      <div className={`${rows?.length ? 'react-table-empty' : ''}`} style={{ overflowY: 'auto' }}>
        <table className='ml-auto mr-auto mt-0' {...getTableProps()}>
          <thead style={{ backgroundColor: 'rgb(245,245,245)' }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id as string}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps({
                      style: {
                        paddingLeft: 6,
                        borderBottom: '1px solid #00000045',
                      },
                    })}
                  >
                    <span data-tooltip-id={column.id} data-tooltip-html=
                      {column.hasDefinition() ? (column.Definition()) : ''}>
                      {column.render('Header')}
                      {column.hasDefinition() ? (
                        <>
                          &nbsp;<i className='fa-lg fa-solid fa-circle-info'></i>
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                    <Tooltip id={column.id} delayShow={100} place='bottom'/>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <List
            itemKey={(index) => data[index]._rowIndex}
            itemData={data}
            itemCount={data.length}
            itemSize={itemHeight}
            height={tableHeight}
            ref={rowHeaderRef}
            width={`calc(100% + ${getScrollbarWidth() + 1}px)`}
            style={{ position: 'absolute', width: 200, overflow: 'hidden', zIndex: 1 }}
          >
            {({ index, style }) => {
              const row = rows[index];
              prepareRow(row);

              const rowProps = row.getRowProps({
                style: {
                  ...style,
                  borderBottom: '1px solid #00000022',
                  minWidth: `calc(100% - ${getScrollbarWidth() + 1}px))`,
                  width: 'unset',
                },
              });

              const cell = row.cells[0];

              const cellProps = cell.getCellProps({
                style: {
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 12,
                  paddingLeft: 6,
                  lineHeight: 1.2,
                  backgroundColor: 'rgb(245,245,245)',
                  borderBottom: '1px solid #00000045',
                  borderRight: '1px solid #00000045',
                },
              });

              return (
                <div
                  className='d-flex align-items-center justify-content-start is-edit-table'
                  {...rowProps}
                  key={`${row.index}-${cell.column.id}`}
                >
                  <div {...cellProps}>{cell.render('Cell')}</div>;
                </div>
              );
            }}
          </List>
          <tbody {...getTableBodyProps()}>
            <>
              {rows?.length ? (
                <List
                  itemKey={(index) => data[index]._rowIndex}
                  itemData={data}
                  itemCount={data.length}
                  itemSize={itemHeight}
                  height={tableHeight}
                  width={`calc(100% + ${getScrollbarWidth() + 1}px)`}
                  onScroll={({ scrollOffset }) => rowHeaderRef.current.scrollTo(scrollOffset)}
                >
                  {({ index, style }) => {
                    const row = rows[index];
                    prepareRow(row);

                    const rowProps = row.getRowProps({
                      style: {
                        ...style,
                        borderBottom: '1px solid #00000022',
                        minWidth: `calc(100% - ${getScrollbarWidth() + 1}px))`,
                        width: 'unset',
                      },
                    });

                    return (
                      <div
                        className='d-flex align-items-center justify-content-start'
                        {...rowProps}
                        key={`${row.index}`}
                      >
                        {row.cells.map((cell) => {
                          const cellProps = cell.getCellProps({
                            style: {
                              height: 40,
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: 12,
                              paddingLeft: 6,
                              lineHeight: 1.2,
                            },
                          });

                          return (
                            <div {...cellProps} key={cell.column.id}>
                              {cell.render('Cell')}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                </List>
              ) : (
                <>{!data.length && <p>No data found!</p>}</>
              )}
            </>
          </tbody>
        </table>
      </div>
    </TableStyles>
  );
};

export default MultidimensionalGrid;
