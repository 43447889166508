import React, { Suspense } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getAllWidgetPreferencesAction } from '../../actions/widget';
import { formatBidDueDate } from '../../utils/date-formatters';
import { isAllowed, moduleConstants, reportAccess, userInteraction } from '../../_constants';
import {useAuth} from '../Auth/Auth';
import TitleComponent from '../Common/TitleComponent';
import Footer from '../Layout/Footer.js';
import Header from '../Layout/Header.js';
import loaderImage1 from '../static/images/loading_i.gif';

// Actions to eventually migrate
import { getAllHome, getCloseWelcomeAction } from '../../actions/home';

import { ReportViewPage } from '../../pages/report-view-page/report-view-page';

import { TeamActivityWidget } from '../../widgets/team-activity-widget/team-activity-widget.tsx';
import BidDateMissed from './Widgets/BidDateMissedWidget.js';
import NetProfitForecastChartWidget from './Widgets/NetProfitForecastChartWidget';
import FinancialForecastChartWidget from './Widgets/FinancialForecastChartWidget';
import BiggestGainers from './Widgets/GainersWidget';
import BiggestLosers from './Widgets/LosersWidget';
import CommonMissingData from './Widgets/MissingData/CommonMissingData';
import ProfitSalesWidget from './Widgets/ProfitAnalysisWidget';
import SalesAnalysisWidget from './Widgets/SalesAnalysisWidget';
import ProfitProjectionWidget from './Widgets/ProfitProjectionWidget';
import ProjectFunnelChartWidget from './Widgets/ProjectFunnelChartWidget';
//import ReminderWidget from './Widgets/ReminderWidget'; // to be created and moved
import ReminderModal from './Widgets/ReminderWidgetModal';
//import RecentAwardsAndBids from './Widgets/RecentAwardsAndBids'; // to be created and moved



class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      onboardingStatus: parseInt(localStorage.getItem('onboardingStatus'), 10),
      biggestGainers: [],
      biggestLosers: [],
      remindersData: [],
      awardsData: [],
      bidData: [],
      biggestGainersArr: [],
      biggestLosersArr: [],
      widgetPreferences: [],
      hiddenButton: false,
      reminderModal: false,
      reminderType: '',
      showProcessing: false,
      fetchRequest: false,
      fetchAwardsData: true,
      fetchBiggestLosersArr: true,
      fetchBiggestGainersArr: true,
    };

    this.closeWelcome = this.closeWelcome.bind(this);

    this.getReminder = this.getReminder.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let widgetPreferences = nextProps.widgetPreferences;

    if (widgetPreferences && widgetPreferences && widgetPreferences.length > 0) {
      this.setState({ widgetPreferences });
    }

    let propsData;
    if (nextProps.dashboardInfo) {
      propsData = nextProps.dashboardInfo.data;
    }

    if (nextProps.dashboardInfo && propsData) {
      if (propsData?.remindersData?.length>0) {
        this.setState({ remindersData: propsData.remindersData });
      }

      if (propsData.biggestGainersLosers && propsData.biggestGainersLosers.gainersArr.length > 0) {
        this.setState({
          biggestGainersArr: propsData.biggestGainersLosers.gainersArr,
          fetchBiggestGainersArr: false,
        });
      }
      if (
        propsData.biggestGainersLosers &&
        propsData.biggestGainersLosers.gainersArr.length === 0
      ) {
        this.setState({ fetchBiggestGainersArr: false });
      }
      if (propsData.biggestGainersLosers && propsData.biggestGainersLosers.losersArr.length > 0) {
        this.setState({
          biggestLosersArr: propsData.biggestGainersLosers.losersArr,
          fetchBiggestLosersArr: false,
        });
      }
      if (propsData.biggestGainersLosers && propsData.biggestGainersLosers.losersArr.length === 0) {
        this.setState({ fetchBiggestLosersArr: false });
      }
      if (propsData.recentAwardsData && propsData.recentAwardsData.awardsData.length > 0) {
        this.setState({
          awardsData: propsData.recentAwardsData.awardsData,
          fetchAwardsData: false,
        });
      }
      if (propsData.recentAwardsData && propsData.recentAwardsData.awardsData.length == 0) {
        this.setState({ fetchAwardsData: false });
      }

      if (propsData.recentAwardsData && propsData.recentAwardsData.bidData.length > 0) {
        this.setState({ bidData: propsData.recentAwardsData.bidData });
      }
      this.setState({ showProcessing: false });
    }

    if (this.state.fetchRequest === false) {
      this.setState({ fetchRequest: true });
    }

    if (this.state.showProcessing === true) {
      this.setState({ showProcessing: false });
    }
  }

  componentDidMount() {
    let closeStatus = {};

    const userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      closeStatus = JSON.parse(userInfo);
      if (closeStatus) this.setState({ hiddenButton: closeStatus.closeWelcomeStatus });
      else 
      {const { logout } = useAuth();
          logout();
    }
    } else {
      const { logout } = useAuth();
          logout();
    }

    if (this.state.userId) {
      let widgetRequestParams = {};
      widgetRequestParams['accountId'] = this.state.accountId;
      widgetRequestParams['userId'] = this.state.userId;
      widgetRequestParams['enabledOnly'] = true;
      this.props.getAllWidgetPreferencesAction(widgetRequestParams);

      this.fetchWidgetData();

      this.setState({ showProcessing: true });
    }
  }

  closeWelcome() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['closeWelcomeStatus'] = true;

    let userInfo = {};
    if (localStorage.getItem('userInfo') !== 'undefined') {
      userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo.closeWelcomeStatus = true;
      if (userInfo && userInfo.closeWelcomeStatus)
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }
    this.props.getCloseWelcomeAction(data);
    this.setState({ hiddenButton: true });
  }

  fetchWidgetData() {
    if (this.state.userId) {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['userId'] = this.state.userId;
      data['page'] = 0;
      data['displayLimit'] = 5;

      this.props.getAllHome(data);

      this.setState({ showProcessing: true });
    }
  }

  onModalClose() {
    this.setState({ reminderModal: false });
  }

  getReminder(e) {
    this.setState({ reminderType: e.target.dataset.reminderType });
    this.setState({ reminderModal: true });
  }

  renderWidgetLazy(widgetObj) {
    /*
    let widgetComponent = widgetObj.widgetComponent;
    let Component = React.lazy(() => import(`../../widgets/${widgetComponent}`));
    return (
      <Suspense fallback='loading'>
        <Component
          props={this.props}
          useAuth={useAuth}
          componentData={{
            widgetPreferences: widgetObj,
          }}
        />
      </Suspense>
    );*/
  }

  widget_recentAwardsAndBids() {
    const awards = this.state.awardsData.map(function (data, index) {
      return (
        <li key={index}>
          <span className='one'>
            <Link
              to={{
                pathname: `/project-details/` + data.objectId,
                state: { row: data },
              }}
            >
              {data.jobName}
            </Link>
          </span>{' '}
        </li>
      );
    });

    const bidDataInfo = this.state.bidData.map(function (data, index) {
      return (
        <li key={index}>
          <span className='one'>
            <Link
              to={{
                pathname: `/project-details/` + data.objectId,
                state: { row: data },
              }}
            >
              {data.jobName}
            </Link>
          </span>
          <span className='bidDue'>{data.bidDue ? formatBidDueDate(data.bidDue) : ''}</span>
        </li>
      );
    });

    return (
      <div className='col-md-6'>
        <div className='welcomeDescription welcomeDescriptionForecast'>
          <h3>
            Recent Awards &amp; Bids{' '}
            {isAllowed(reportAccess.BDR, 'anyAssigned') && (
              <a href='/bids-due-report' className='link'>
                Bids Due Report
              </a>
            )}
          </h3>
          <div className='dashInner'>
            {!this.state.fetchAwardsData && (
              <Scrollbars style={{ height: '100%' }}>
                <span>
                  <strong>Current Bids</strong>
                </span>
                <span className='bidDate'>
                  <strong>Bid Date &amp; Time</strong>
                </span>
                <ul className='clearfix rabd'>{bidDataInfo}</ul>
                <p>
                  <strong>Recent Awards (projects won)</strong>
                </p>
                <ul className='clearfix rabd'>{awards}</ul>
              </Scrollbars>
            )}
            {(!this.state.fetchAwardsData &&
              this.state.awardsData &&
              this.state.awardsData.length === 0) ||
              (!this.state.fetchAwardsData &&
                this.state.bidData &&
                this.state.bidData.length === 0 && (
                  <div className='msgBox1'>
                    <span>No data available!</span>
                  </div>
                ))}
            {this.state.fetchAwardsData && (
              <div id='loaderBox' className='msgBox'>
                <img src={loaderImage1} width='100' height='100' />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  widget_salesAnalysis() {
    return (
      <div className='col-lg-6'>
        <div className='welcomeDescription'>
          <h3>
            Sales Analysis Report{' '}
            {isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned') &&
              isAllowed(reportAccess.P_SAR, 'anyAssigned') && (
                <a href='/profit-sales-report' className='link'>
                  View full report
                </a>
              )}
          </h3>
          {!isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned') ? (
            <>Permission to view contract values required</>
          ) : (
            <div className='dashInner'>
              <SalesAnalysisWidget props={this.props}  componentData={{ salesData: 'sales' }} />
            </div>
          )}
        </div>
      </div>
    );
  }

  widget_profitAnalysis() {
    return (
      <div className='col-lg-6'>
        <div className='welcomeDescription'>
          <h3>
            Profit Analysis Report{' '}
            {isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') && (
              <a href='/profit-sales-report' className='link'>
                View full report
              </a>
            )}
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              <div className='dashInner'>
                <ProfitSalesWidget props={this.props} useAuth={useAuth} componentData={{ profitData: 'profits' }} />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  widget_biggestGainers() {
    return (
      <div className='col-md-6'>
        <div className='welcomeDescription whbg'>
          <h3>
            Biggest Gainers{' '}
            <span className='font15'>(Projects with profit that increased the most)</span>
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              {!this.state.fetchBiggestGainersArr &&
                this.state.biggestGainersArr &&
                this.state.biggestGainersArr.length === 0 && (
                  <div className='msgBox1'>
                    <span>No data available!</span>
                  </div>
                )}
              {this.state.fetchBiggestGainersArr && (
                <div id='loaderBox' className='msgBox'>
                  <img src={loaderImage1} width='100' height='100' />
                </div>
              )}
              <div className='dashInner'>
                <Scrollbars style={{ height: '100%' }}>
                  <BiggestGainers
                    props={this.props}
                    useAuth={useAuth}
                    componentData={{
                      gainersData: this.state.biggestGainersArr,
                    }}
                  />
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  widget_projectFunnel() {
    return (
      <div className='col-lg-6'>
        <div className='welcomeDescription welcomeDescriptionForecast'>
          <h3>
            Sales Pipeline{' '}
            {isAllowed(reportAccess.PPR, 'anyAssigned') && false && (
              <a href='/profit-projection-report' className='link'>
                View full report
              </a>
            )}
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
                <ProjectFunnelChartWidget
                  new={true}
                  props={this.props}
                  useAuth={useAuth}
                />
            </>
          )}
        </div>
      </div>
    );
  }

  widget_financialForecast() {
    return (
      <div className='col-lg-6'>
        <div className='welcomeDescription welcomeDescriptionForecast'>
          <h3>
            Financial Forecast{' '}
            {isAllowed(reportAccess.PPR, 'anyAssigned') && (
              <a href='/profit-projection-report' className='link'>
                View full report
              </a>
            )}
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              { (
                <FinancialForecastChartWidget
                  new={true}
                  props={this.props}
                  useAuth={useAuth}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  widget_netProfitForecast() {
    return (
      <div className='col-lg-6'>
        <div className='welcomeDescription welcomeDescriptionForecast'>
          <h3>
            Net Profit Forecast{' '}
            {isAllowed(reportAccess.PPR, 'anyAssigned') && (
              <a href='/profit-projection-report' className='link'>
                View full report
              </a>
            )}
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              { (
                <NetProfitForecastChartWidget
                  new={true}
                  props={this.props}
                  useAuth={useAuth}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  widget_biggestLosers() {
    return (
      <div className='col-md-6'>
        <div className='welcomeDescription whbg'>
          <h3>
            Biggest Losers{' '}
            <span className='font15'>(Projects with profit that decreased the most)</span>
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              {!this.state.fetchBiggestLosersArr &&
                this.state.biggestLosersArr &&
                this.state.biggestLosersArr.length === 0 && (
                  <div className='msgBox1'>
                    <span>No data available!</span>
                  </div>
                )}
              {this.state.fetchBiggestLosersArr && (
                <div id='loaderBox' className='msgBox'>
                  <img src={loaderImage1} width='100' height='100' />
                </div>
              )}
              <div className='dashInner'>
                <Scrollbars style={{ height: '100%' }}>
                  <BiggestLosers
                    props={this.props}
                    useAuth={useAuth}
                    componentData={{
                      losersData: this.state.biggestLosersArr,
                    }}
                  />
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  widget_reminders() {
    /*let reminders = this.state.remindersData.map(function (data, index) {
      return (
        <p key={index}>
          <i className='fa fa-bell' aria-hidden='true'></i> <strong>{data.title}</strong>,{' '}
          {data.description} at {formatDateObject(data.startDate.iso).format('h:mm A')}
          {formatDateObject(data.endDate.iso).format('h:mm A')}
        </p>
      );
    });*/

    return (
      <div className='col-md-6'>
        <div className='welcomeDescription'>
          <h3>Reminders</h3>
          <div className='dashInner'>
            <Scrollbars style={{ height: '100%' }}>
              <p>Scheduled calls/ call backs</p>
              <p>Automatically set call backs (pending bids)</p>
              <a className='anchor' onClick={this.getReminder}>
                Reminders set by users or requested from another user
              </a>
              {this.state.reminderModal && (
                <ReminderModal
                  props={this.props}
                  onClick={this.onModalClose}
                  useAuth={useAuth}
                  componentData={{
                    remindersData: this.state?.remindersData??[],
                    reminderType: this.state?.reminderType,
                  }}
                />
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  widget_bidDateMissed() {
    return (
      <div className='col-md-6'>
        <div className='welcomeDescription bdm'>
          <h3>Bid Date Missed</h3>
          <div className='dashInner'>
            <Scrollbars style={{ height: '100%' }}>
                <BidDateMissed
                  props={this.props}
                  useAuth={useAuth}
                />
              
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  welcomeSection() {
    return (
      <div className='row' hidden={this.state.hiddenButton}>
        <div className='col-md-12'>
          <div className='dashboardGreyBox'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='welcomeDD'>
                  <h3>Welcome</h3>
                  <div className='dashInner flex'>
                    <Scrollbars style={{ height: '100%' }}>
                      <p>
                        <strong>Welcome to your Command Post dashboard.</strong>
                      </p>
                      <p>
                        This dashboard provides important highlights about your business operations.
                        Getting going with Command Post is easy. Step 1 is add companies that you
                        work with to Command Post. You can reminderType them in or upload them all at once
                        using our downloadable Excel spreadsheet. Step 2 is to upload your contacts.
                        Step 3 is to add projects.
                      </p>
                      <p>
                        If you need assistance, please send message to us using a link in the footer
                        of this page.
                      </p>
                      <p>
                        Happy Contracting!
                        <br />
                        Command Post
                      </p>
                      <p className='closeTab'>
                        <strong>
                          <a onClick={this.closeWelcome}>Close this</a>
                        </strong>
                      </p>
                    </Scrollbars>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='welcomeDD'>
                  <h3>Next Steps</h3>
                  <div className='dashInner'>
                    <Scrollbars style={{ height: '100%' }}>
                      <p
                        className={
                          this.state.onboardingStatus === 1 ||
                          this.state.onboardingStatus === 2 ||
                          this.state.onboardingStatus === 3 ||
                          this.state.onboardingStatus === 4
                            ? ''
                            : 'onboarding-link'
                        }
                      >
                        Step 1 :{' '}
                        <Link to={{ pathname: `/import-companies` }}>
                          Add Companies You Work With.{' '}
                        </Link>
                      </p>
                      <p>Make sure you have up to date company information.</p>
                      <p
                        className={
                          this.state.onboardingStatus === 2 ||
                          this.state.onboardingStatus === 3 ||
                          this.state.onboardingStatus === 4
                            ? ''
                            : 'onboarding-link'
                        }
                      >
                        Step 2 : <Link to={{ pathname: `/import-contacts` }}>Add Contact</Link>{' '}
                      </p>
                      <p>Make sure you have up to date contact information.</p>
                      <span
                        className={
                          localStorage.getItem('baseUserRole') === 'cppAdmin' ? '' : 'btnpagenon'
                        }
                      >
                        <p
                          className={
                            this.state.onboardingStatus === 3 || this.state.onboardingStatus === 4
                              ? ''
                              : 'onboarding-link'
                          }
                        >
                          Step 3 : <Link to={{ pathname: `/import-projects` }}>Add Project</Link>
                        </p>
                        <p>Make sure you have up to date project information.</p>
                        <p className={this.state.onboardingStatus === 4 ? '' : 'onboarding-link'}>
                          Step 4 :{' '}
                          <Link
                            to={{
                              pathname: `/edit-pending-project-uploads/` + this.state.accountId,
                            }}
                          >
                            Edit Pending Project
                          </Link>
                        </p>
                        <p>Make sure you have up to date project information.</p>
                      </span>
                      <p>
                        <Link to={{ pathname: `/wip-view` }}>
                          Download/Upload Work In Progress (WIP) Report
                        </Link>
                      </p>
                      <p>
                        <a href='/social-contact-view'>
                          See Temporary Contacts of Social Media (Gmail and Outlook) & Phonebook in
                          the list
                        </a>
                      </p>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  widget_missingData() {
    return (
      <div className='col-md-6'>
        <div className='welcomeDescription'>
          <h3>Check for missing data</h3>
          <div className='dashInner'>
            <Scrollbars style={{ height: '100%' }}>
              <CommonMissingData props={this.props} useAuth={useAuth} />
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  widget_custom_report(widgetCode,widgetName,section='bidding') {
    return (
      <div className='col-md-12'>
        <div className='welcomeDescription heightauto'>
          <h3>
            {widgetName}{' '}
            {isAllowed(reportAccess.LS, 'anyAssigned') && (
              <a href={`/report-view/${section}/${widgetCode}`} className='link'>
                View full report
              </a>
            )}
          </h3>
          <div className='dashInner'>
              <ReportViewPage isWidget={true} reportId={widgetCode}/>
          </div>
        </div>
      </div>
    );
  }

  widget_profitProjectionReport() {
    return (
      <div className='col-md-12'>
        <div className='welcomeDescription heightauto'>
          <h3>
            Profit Projection Report{' '}
            {isAllowed(reportAccess.PPR, 'anyAssigned') && (
              <a href='/profit-projection-report' className='link'>
                View full report
              </a>
            )}
          </h3>
          {!isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned') ? (
            <>Permission to view project profit required</>
          ) : (
            <>
              <div className='dashInner'>
                <ProfitProjectionWidget
                  props={this.props}
                  useAuth={useAuth}
                  
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  render() {
    const props = { ...this.props, isWidget: true };

    const { widgetPreferences, widgetPreferencesLoading } = this.state;

    return (
      <Suspense fallback='loading'>
        <div>
          <TitleComponent data={{ title: `Dashboard` }} />
          <Suspense fallback='loading'>
            <Header props={this.props} />
          </Suspense>
          <div className='welcomeContainer'>
            <div className='container'>
              {this.welcomeSection()}

              <div className='row justify-content-md-center dashboardWrapper row-gap-4'>
                {widgetPreferences.map(({ widgetCode, isReport,widgetName,section }, index) => {
                  if(isReport){
                    return this.widget_custom_report(widgetCode,widgetName,section);
                  }

                  switch (widgetCode) {
                    case 'SalesPieChart':
                      return (
                        isAllowed(userInteraction.PROFITSALESW, 'anyAssigned') &&
                        this.widget_salesAnalysis()
                      );
                    case 'ProfitPieChart':
                      return (
                        isAllowed(userInteraction.PROFITSALESW, 'anyAssigned') &&
                        this.widget_profitAnalysis()
                      );
                    case 'BiggestGainers':
                      return (
                        isAllowed(userInteraction.BG, 'anyAssigned') && this.widget_biggestGainers()
                      );
                    case 'BiggestLosers':
                      return (
                        isAllowed(userInteraction.BL, 'anyAssigned') && this.widget_biggestLosers()
                      );
                    case 'FinancialForecastChart':
                      return (
                        isAllowed(userInteraction.FF, 'anyAssigned') && this.widget_netProfitForecast()//this.widget_financialForecast()
                      );
                    case 'NetProfitForecastChart':
                      return (
                        isAllowed(userInteraction.FF, 'anyAssigned') && this.widget_netProfitForecast()
                      );
                    case 'ProjectFunnelChart':
                      return (
                        isAllowed(userInteraction.FF, 'anyAssigned') && this.widget_projectFunnel()
                      );
                    case 'RecentAwardsAndBids':
                      return (
                        isAllowed(userInteraction.RABD, 'anyAssigned') &&
                        this.widget_recentAwardsAndBids()
                      );
                    case 'TeamActivity':
                      return isAllowed(userInteraction.TA, 'anyAssigned') && <TeamActivityWidget />;
                    case 'Reminders':
                      return (
                        isAllowed(userInteraction.RD, 'anyAssigned') && this.widget_reminders()
                      );
                    case 'BidDateMissed':
                      return (
                        isAllowed(userInteraction.BDMD, 'anyAssigned') &&
                        this.widget_bidDateMissed()
                      );
                    case 'MissingDataCheck':
                      return (
                        isAllowed(userInteraction.CMD, 'anyAssigned') && this.widget_missingData()
                      );
                    case 'ProfitProjectionReport':
                      return (
                        isAllowed(userInteraction.PPW, 'anyAssigned') &&
                        this.widget_profitProjectionReport()
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    widgetPreferences: state.dashboard.widgetPreferences,
    widgetPreferencesLoading: state.dashboard.isFetched,
    dashboardInfo: state.home.dashboardData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllHome,
    getCloseWelcomeAction,
    getAllWidgetPreferencesAction,
  })(Home)
);
