import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { appConstants } from '../../../_constants';
import { GetBidResultRecapAction, GetBidResultRecapPrefAction, SetBidResultRecapPrefAction } from '../../../actions/reports';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import CustomTable from '../../Common/CustomTable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import TitleComponent from '../../Common/TitleComponent';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import DateRangeSelector from '../../../shared/report-date-range-selector/DateRangeSelector';
import moment from 'moment';
import _ from 'lodash';
import { formatExternalContactData, formatInternalContactData } from '../../../utils/contact-formatters';
import { formatDate, formatDateMMDDYY, formatDateObject, startDateFormatWithoutTimeUTCF, endDateFormatWithoutTimeUTCF } from '../../../utils/date-formatters';
import { formatMoney, formatNumber, roundFormatMoney } from '../../../utils/number-formatters';
import ReactToPrint from 'react-to-print';

const BidResultRecapReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);

  // State for filters and data
  const [analyzeBy, setAnalyzeBy] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [processedData, setProcessedData] = useState({
    tableData: [],
    winLossData: [],
    profitByDimensionData: [],
    finalSummary: {},
    exportData: []
  });
  const [analyzeByOptions, setAnalyzeByOptions] = useState([]);
  const [analyzeByLabel, setAnalyzeByLabel] = useState('');
  const [periodStatus, setPeriodStatus] = useState('');
  const [noData, setNoData] = useState(true);
  const [sortKey, setSortKey] = useState('');
  const [sortType, setSortType] = useState('');
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [printContainer, setPrintContainer] = useState(null);

  // Main state object
  const [state, setState] = useState({
    accountId: localStorage.getItem('accountId'),
    userId: localStorage.getItem('userId'),
    fetchRequest: true,
    projectRoleTypes: [],
    period: 'quarter',
    dateRange: {
      mode: 'quarter',
      value: getCurrentQuarter().value,
      startDate: moment().startOf('quarter').toDate(),
      endDate: moment().endOf('quarter').toDate(),
    },
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    quarter: getCurrentQuarter(),
    isPrefsInitialized: false,
  });

  // Get initial quarter value
  function getCurrentQuarter() {
    const currentMonth = new Date().getMonth() + 1;
    if (currentMonth <= 3) return { label: '1st', value: 1 };
    else if (currentMonth <= 6) return { label: '2nd', value: 2 };
    else if (currentMonth <= 9) return { label: '3rd', value: 3 };
    else return { label: '4th', value: 4 };
  }

  // Redux data
  const listData = useSelector((state) => state.reports.bidResultRecapList);
  const bidRecapPrefs = useSelector((state) => state.reports.bidResultRecapListPref);
  const projectRoles = useSelector((state) => state.projectRoleType);

  // Table columns
  const [columns, setColumns] = useState([]);

  // Load initial data
  useEffect(() => {
    // Load project roles and preferences
    dispatch(getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: state.accountId,
    }));

    dispatch(GetBidResultRecapPrefAction());
  }, []);

  // Process project roles when loaded
  useEffect(() => {
    if (projectRoles?.allProjectRoleTypes?.length > 0 && state.projectRoleTypes.length === 0) {
      const allRoles = projectRoles.allProjectRoleTypes;

      const OptionBidRecapAStatus = [
        { label: 'Building Type', value: 'buildingType' },
        { label: 'Occupancy Type', value: 'occupancyType' },
        { label: 'Negotiation Method', value: 'negotiatingMethod' },
        { label: 'Job Scope', value: 'scopeArr' },
        { label: 'Status Code', value: 'jobStatusCodes' },
      ];

      const roleOptions = allRoles.map((role) => ({ label: role.name, value: role.code }));
      const optionStatus = roleOptions.concat(OptionBidRecapAStatus);

      // Set default analyze by option if not already set
      if (!analyzeBy) {
        setAnalyzeBy(optionStatus[0]);
      }

      setState({ ...state, projectRoleTypes: allRoles });
      setAnalyzeByOptions(optionStatus);
    }
  }, [projectRoles]);

  // Handle preferences when loaded
  useEffect(() => {
    if (!_.isEmpty(bidRecapPrefs) && analyzeByOptions.length > 0 && !state.isPrefsInitialized) {
      try {
        // Find the analyzeBy option that matches the saved preference
        const selectedAnalyzeBy = analyzeByOptions.find(opt => 
          opt.value === bidRecapPrefs.analyzeBy
        );
        
        if (selectedAnalyzeBy) {
          setAnalyzeBy(selectedAnalyzeBy);
        } else if (analyzeByOptions.length > 0) {
          // Default to first option if saved preference not found
          setAnalyzeBy(analyzeByOptions[0]);
        }

        // Handle date range preference
        let newDateRange = state.dateRange;
        let newPeriod = bidRecapPrefs.period || 'quarter';
        
        if (bidRecapPrefs.period === 'other' && bidRecapPrefs.dateRange) {
          // Convert ISO strings to Date objects if needed
          newDateRange = {
            mode: 'range',
            startDate: typeof bidRecapPrefs.dateRange.startDate === 'string' 
              ? new Date(bidRecapPrefs.dateRange.startDate) 
              : bidRecapPrefs.dateRange.startDate,
            endDate: typeof bidRecapPrefs.dateRange.endDate === 'string'
              ? new Date(bidRecapPrefs.dateRange.endDate)
              : bidRecapPrefs.dateRange.endDate
          };
        } else if (bidRecapPrefs.period === 'quarter' && bidRecapPrefs.quarter) {
          // Handle quarter preference
          const quarterValue = typeof bidRecapPrefs.quarter === 'object' 
            ? bidRecapPrefs.quarter.value 
            : bidRecapPrefs.quarter;
          
          const quarterObj = {
            value: quarterValue,
            label: quarterValue === 1 ? '1st' : 
                   quarterValue === 2 ? '2nd' :
                   quarterValue === 3 ? '3rd' : '4th'
          };

          newDateRange = {
            mode: 'quarter',
            value: quarterValue,
            startDate: moment().quarter(quarterValue).startOf('quarter').toDate(),
            endDate: moment().quarter(quarterValue).endOf('quarter').toDate()
          };

          setState(prevState => ({
            ...prevState,
            quarter: quarterObj
          }));
        }

        // Update state with all preferences
        setState(prevState => ({
          ...prevState,
          period: newPeriod,
          year: bidRecapPrefs.year || new Date().getFullYear(),
          month: bidRecapPrefs.month || (new Date().getMonth() + 1),
          dateRange: newDateRange,
          isPrefsInitialized: true
        }));
      } catch (error) {
        console.error("Error applying preferences:", error);
        // Set default values if there's an error
        if (analyzeByOptions.length > 0 && !analyzeBy) {
          setAnalyzeBy(analyzeByOptions[0]);
        }
        setState(prevState => ({
          ...prevState,
          isPrefsInitialized: true
        }));
      }
    } else if (analyzeByOptions.length > 0 && !state.isPrefsInitialized) {
      // If no preferences, use default values
      if (!analyzeBy && analyzeByOptions.length > 0) {
        setAnalyzeBy(analyzeByOptions[0]);
      }
      setState(prevState => ({
        ...prevState,
        isPrefsInitialized: true
      }));
    }
  }, [bidRecapPrefs, analyzeByOptions]);

  // Submit report request when preferences are initialized
  useEffect(() => {
    if (state.isPrefsInitialized) {
      submitReportRequest();
    }
  }, [state.isPrefsInitialized]);

  // Process data when it arrives from API
  useEffect(() => {
    if (listData && listData.success) {
      setReportData(listData.data);
      setState({ ...state, fetchRequest: false });
    }
  }, [listData]);

  // Process report data when it changes
  useEffect(() => {
    if (reportData.length > 0) {
      const processedResults = processReportData(reportData, listData?.contactData || []);
      setProcessedData(processedResults);
      setNoData(false);
      buildColumns(processedResults.finalSummary);
    }
  }, [reportData]);

  // Toggle filter modal
  const toggleFilter = (shouldClose = false) => {
    if (shouldClose) {
      if(settingsModalOpen) {
        setSettingsModalOpen(false);
      }
    }
    else {
      setSettingsModalOpen(!settingsModalOpen);
    }
  };

  // Handle change in period selection
  const handleChangePeriod = (val) => {
    setState({ ...state, period: val });

    let newState = { ...state, period: val };
    const d = new Date();

    if (val === 'year') {
      newState.year = d.getFullYear();
    } else if (val === 'month') {
      newState.month = d.getMonth() + 1;
    } else if (val === 'quarter') {
      newState.quarter = getCurrentQuarter();
    }

    setState(newState);
  };

  // Handle date range selection
  const handleDateRangeChange = (newDateRange) => {
    setState({ ...state, dateRange: newDateRange });
  };

  // Handle analyze by selection
  const handleChangeAnalyzeBy = (selectedOption) => {
    setAnalyzeBy(selectedOption);
  };

  // Submit report request
  const submitReportRequest = () => {
    setState({ ...state, fetchRequest: true });
    let data = {};
    data['accountId'] = state.accountId;
    data['moduleName'] = appConstants.reportModuleName['10'];
    data['analyzeBy'] = analyzeBy?.value || '';
    data['sortKey'] = sortKey;
    data['sortType'] = sortType;

    // Set reporting period based on selection
    if (state.period === 'year') {
      data['year'] = state.year;
      setPeriodStatus(state.year.toString());
    } else if (state.period === 'month') {
      data['month'] = state.month;
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      setPeriodStatus(monthNames[state.month - 1] + ' ' + moment().format('YYYY'));
    } else if (state.period === 'quarter') {
      data['quarter'] = Number(state.quarter.value);
      
      if (data['quarter'] === 1) {
        setPeriodStatus('Jan-Mar, ' + moment().format('YYYY'));
      } else if (data['quarter'] === 2) {
        setPeriodStatus('Apr-Jun, ' + moment().format('YYYY'));
      } else if (data['quarter'] === 3) {
        setPeriodStatus('Jul-Sept, ' + moment().format('YYYY'));
      } else if (data['quarter'] === 4) {
        setPeriodStatus('Oct-Dec, ' + moment().format('YYYY'));
      }
    } else if (state.period === 'other') {
      data['startDate'] = startDateFormatWithoutTimeUTCF(state.dateRange.startDate);
      data['endDate'] = endDateFormatWithoutTimeUTCF(state.dateRange.endDate);
      setPeriodStatus(
        formatDate(state.dateRange.startDate) + ' to ' + formatDate(state.dateRange.endDate)
      );
    }

    if(state.isPrefsInitialized) {
    // Save preferences
    dispatch(SetBidResultRecapPrefAction({
      analyzeBy: analyzeBy?.value,
      period: state.period,
      year: state.year,
      month: state.month,
      quarter: state.quarter,
      dateRange: state.period === 'other' ? state.dateRange : undefined
    }));
  }

    // Update analyze by label for display
    setAnalyzeByLabel(analyzeBy?.label || '');

    // Fetch data
    setReportData([]);
    setNoData(true);
    if(settingsModalOpen) {
      toggleFilter(true); 
    }
    dispatch(GetBidResultRecapAction(data));
  };

  // Process report data
  const processReportData = (dataArr, contactData) => {
    let finalSum = {
      FProjectSum: 0,
      FBidContractSum: 0,
      FBidProfitSum: 0,
      FBidProfitPerSum: 0,
      FWonContractSum: 0,
      FWonProfitSum: 0,
      FWonProfitPerSum: 0,
      FBidHitRatioSum: 0,
      FProfitSum: 0,
      FWin: 0,
      FLoss: 0,
      FOther: 0,
      FWLCount: 0,
      FWLBidRatio: 0,
      FProfitSumCompany: 0,
      FProfitWLSum: 0,
      FProfitSumWL: 0
    };

    let tableData = [];
    let projectsData = [];
    let winLossData = [];
    let profitByDimensionData = [];
    let exportData = [];

    if (!dataArr || dataArr.length === 0) {
      return { tableData, winLossData, profitByDimensionData, finalSummary: finalSum, exportData };
    }

    let processedProjects = [];

    // Process each analyzer group (e.g., estimator, building type)
    for (let x in dataArr) {
      const analyzerGroup = dataArr[x];
      analyzerGroup.allContract = 0;
      analyzerGroup.allProfit = 0;
      analyzerGroup.jobStatusAmountSumCont = 0;
      analyzerGroup.projectCount = 0;
      analyzerGroup.wCount = 0;
      analyzerGroup.lCount = 0;
      analyzerGroup.oCount = 0;
      analyzerGroup.WLCount = 0;
      analyzerGroup.bidHitRatioCount = 0;
      analyzerGroup.finalProfitPercentage = 0;
      analyzerGroup.jobBidLProfit = 0;
      analyzerGroup.jobBidLContract = 0;
      analyzerGroup.jobBidWLProfit = 0;
      analyzerGroup.jobStatusAmountSumProfit = 0;

      // Generate analyzer name
      if (analyzerGroup.contactInfo) {
        if (analyzerGroup.contactInfo.firstName || analyzerGroup.contactInfo.lastName) {
          analyzerGroup.analyzer = [analyzerGroup.contactInfo.firstName, analyzerGroup.contactInfo.lastName]
            .filter(Boolean)
            .join(' ') || '-';
        } else if (analyzerGroup.contactInfo.negotiatingMethodName) {
          analyzerGroup.analyzer = analyzerGroup.contactInfo.negotiatingMethodName || '';
        } else if (analyzerGroup.contactInfo.jobScopeName) {
          analyzerGroup.analyzer = analyzerGroup.contactInfo.jobScopeName || '';
        } else if (analyzerGroup.contactInfo.occupancyTypeName) {
          analyzerGroup.analyzer = analyzerGroup.contactInfo.occupancyTypeName || '';
        }
      } else {
        analyzerGroup.analyzer = analyzerGroup.label || 'Unknown';
      }

      // Process projects within each analyzer group
      for (let y in analyzerGroup.projects) {
        const project = analyzerGroup.projects[y];
        const useForGlobal = !processedProjects.includes(project.objectId);
        
        if (useForGlobal) {
          processedProjects.push(project.objectId);
        }

        project.wonContractAmount = 0;
        project.wonProfitAmount = 0;
        project.perProjectBidProfit = 0;
        project.perProjectWonProfit = 0;

        // Count total projects
        analyzerGroup.projectCount = analyzerGroup.projects.length;

        const curProjectContract = project.currentContractAmount;
        const curProjectProfit = project.currentGrossProfit;

        // Sum bid amounts and profits
        analyzerGroup.allContract += curProjectContract;
        analyzerGroup.allProfit += curProjectProfit;
        
        if (useForGlobal) {
          finalSum.FBidContractSum += curProjectContract;
          finalSum.FBidProfitSum += curProjectProfit;
          finalSum.FProjectSum += 1;
        }

        // Determine project status (Win/Loss/Other)
        const jobStatus = project.jobStatusCodesCode;
        if (['A', 'C', 'D', 'W', 'SNS'].includes(jobStatus)) {
          project.WLO_status = 'W';
          analyzerGroup.wCount += 1;
          analyzerGroup.jobStatusAmountSumCont += curProjectContract;
          analyzerGroup.jobStatusAmountSumProfit += curProjectProfit;
          project.wonContractAmount = curProjectContract;
          project.wonProfitAmount = curProjectProfit;

          if (useForGlobal) {
            finalSum.FWin += 1;
            finalSum.FWonContractSum += curProjectContract;
            finalSum.FWonProfitSum += curProjectProfit;
          }
        } else if (jobStatus === 'XC') {
          project.WLO_status = 'L';
          analyzerGroup.jobBidLProfit += curProjectProfit;
          analyzerGroup.jobBidLContract += curProjectContract;
          project.wonContractAmount = 0;
          project.wonProfitAmount = 0;
          analyzerGroup.lCount += 1;

          if (useForGlobal) {
            finalSum.FLoss += 1;
          }
        } else {
          project.WLO_status = 'O';
          project.wonContractAmount = 0;
          project.wonProfitAmount = 0;

          if (useForGlobal) {
            finalSum.FOther += 1;
          }
        }

        // Calculate profit percentages
        if (project.wonContractAmount === 0) {
          project.perProjectWonProfit = 0;
        } else {
          project.perProjectWonProfit = (project.wonProfitAmount / project.wonContractAmount) * 100;
        }

        if (curProjectContract === 0) {
          project.perProjectBidProfit = 0;
        } else {
          project.perProjectBidProfit = (curProjectProfit / curProjectContract) * 100;
        }

        // Add to projects data array for table
        projectsData.push({
          ...project,
          analyzer: analyzerGroup.analyzer,
          analyzerValue: analyzerGroup.label,
          objectId: project.objectId,
          jobNumFormatted: project.jobNumFormatted,
          altJobNum: project.altJobNum,
          jobName: project.jobName,
          jobStatus: project.jobStatusCodesCode,
          bidDueDate: project.bidDue?.iso ? new Date(project.bidDue.iso) : null,
          bidSubmittedDate: project.bidSubmitted?.iso ? new Date(project.bidSubmitted.iso) : null,
          accountExec: project.EST || [],
          projectMgr: project.PM || [],
          currentContractAmount: curProjectContract,
          currentGrossProfit: curProjectProfit,
          profitPercentage: project.perProjectBidProfit,
          bidStatus: project.WLO_status,
          wonContractAmount: project.wonContractAmount,
          wonProfitAmount: project.wonProfitAmount,
          wonProfitPercentage: project.perProjectWonProfit,
          analyzerGroup: analyzerGroup.objectId,
          isFirstInGroup: y === '0'
        });
      }

      // Calculate bid hit ratio
      analyzerGroup.WLCount = analyzerGroup.wCount + analyzerGroup.lCount;
      analyzerGroup.bidHitRatioCount = analyzerGroup.WLCount > 0 ? 
        (analyzerGroup.wCount / analyzerGroup.WLCount) * 100 : 0;

      // Calculate final profit percentage
      analyzerGroup.jobBidWLProfit = analyzerGroup.jobBidLProfit + analyzerGroup.jobStatusAmountSumProfit;
      analyzerGroup.finalProfitPercentage = analyzerGroup.jobBidWLProfit > 0 ?
        (analyzerGroup.jobStatusAmountSumProfit / analyzerGroup.jobBidWLProfit) * 100 : 0;

      // Calculate bid profit percentage
      analyzerGroup.bidProfitPercent = analyzerGroup.allContract > 0 ?
        (analyzerGroup.allProfit / analyzerGroup.allContract) * 100 : 0;

      // Calculate won profit percentage
      analyzerGroup.wonProfitPercent = analyzerGroup.jobStatusAmountSumCont > 0 ?
        (analyzerGroup.jobStatusAmountSumProfit / analyzerGroup.jobStatusAmountSumCont) * 100 : 0;

      finalSum.FWonProfitPerSum += analyzerGroup.wonProfitPercent;
      finalSum.FBidHitRatioSum += analyzerGroup.bidHitRatioCount;
      finalSum.FProfitSum += analyzerGroup.finalProfitPercentage;

      // Add to win/loss chart data
      winLossData.push({
        name: analyzerGroup.analyzer,
        win: analyzerGroup.wCount,
        loss: analyzerGroup.lCount,
        other: analyzerGroup.oCount,
        hitRatio: Math.round(analyzerGroup.bidHitRatioCount)
      });

      // Add to profit by dimension chart data
      profitByDimensionData.push({
        name: analyzerGroup.analyzer,
        contractAmount: analyzerGroup.allContract,
        profitAmount: analyzerGroup.allProfit,
        profitPercentage: Math.round(analyzerGroup.bidProfitPercent)
      });
    }

    // Calculate final summary values
    finalSum.FWLCount = finalSum.FWin + finalSum.FLoss;
    finalSum.FWLBidRatio = finalSum.FWLCount > 0 ? (finalSum.FWin / finalSum.FWLCount) * 100 : 0;
    finalSum.FProfitWLSum = finalSum.FBidProfitSum > 0 ? (finalSum.FWonProfitSum / finalSum.FBidProfitSum) * 100 : 0;
    finalSum.FBidProfitPerSum = finalSum.FBidContractSum > 0 ? 
      (finalSum.FBidProfitSum / finalSum.FBidContractSum) * 100 : 0;

    // Prepare export data
    exportData = prepareExportData(dataArr, contactData, finalSum);

    return {
      tableData: tableData,
      projectsData: projectsData,
      winLossData: winLossData,
      profitByDimensionData: profitByDimensionData,
      finalSummary: finalSum,
      exportData: exportData
    };
  };

  // Build table columns
  const buildColumns = (finalSum) => {
    const roles = state.projectRoleTypes;
    const estShort = (roles.length > 0 && roles.find((role) => role.code === 'EST'))?.shortName ?? 'EST';
    const projMgrShort = (roles.length > 0 && roles.find((role) => role.code === 'PM'))?.shortName ?? 'PM';

    const columns = [
      {
        Header: analyzeByLabel || 'Category',
        id: 'analyzer',
        accessor: 'analyzer',
        show: true,
        canGroupBy: true,
        defaultGrouped: true,
        minWidth: 150,
        hideMenu: true,
        aggregate: 'count',
        Cell: ({ value }) => (
          <div style={{ display: 'contents' }}>
            <b>{value || ''}</b>
          </div>
        ),
        Footer: () => <b>Company Totals:</b>
      },
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        width: 80,
        hideMenu: true,
        Cell: ({ row, value }) => (
          (row?.original) ?
          <Link to={`/project-details/${row.original.objectId}`}>
            {value || '-'}
          </Link> : < div>{value || '-'}</div>  
        ),
        disableGroupBy: true
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 80,
        hideMenu: true,
        Cell: ({ row, value }) => (
          (row?.original) ?
          <Link to={`/project-details/${row.original.objectId}`}>
            {value || '-'}
          </Link> : < div>{value || '-'}</div>  
        ),
        disableGroupBy: true
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 150,
        hideMenu: true,
        Cell: ({ row, value }) => (
          (row?.original) ?
          <Link to={`/project-details/${row.original.objectId}`}>
            {value || '-'}
          </Link> : < div>{value || '-'}</div>  
        ),
        disableGroupBy: true
      },
      {
        Header: 'Status',
        accessor: 'jobStatus',
        width: 80,
        hideMenu: true,
        Cell: ({ value }) => <div>{value || '-'}</div>,
        disableGroupBy: true
      },
      {
        Header: 'Bid Date',
        accessor: 'bidDueDate',
        width: 100,
        hideMenu: true,
        Cell: ({ value }) => (
          <div>{value ? formatDate(value) : '-'}</div>
        ),
        sortType: 'datetime',
        disableGroupBy: true
      },
      {
        Header: estShort,
        accessor: 'accountExec',
        width: 80,
        hideMenu: true,
        Cell: ({ value }) => {
          const affiliations = value || [];
          return (
            <div>
              {affiliations.length > 0 
                ? formatInternalContactData(
                    affiliations.map(a => a.contactAffiliationId),
                    listData?.contactData || []
                  )
                : '-'}
            </div>
          );
        },
        disableGroupBy: true
      },
      {
        Header: projMgrShort,
        accessor: 'projectMgr',
        width: 80,
        hideMenu: true,
        Cell: ({ value }) => {
          const affiliations = value || [];
          return (
            <div>
              {affiliations.length > 0 
                ? formatInternalContactData(
                    affiliations.map(a => a.contactAffiliationId),
                    listData?.contactData || []
                  )
                : '-'}
            </div>
          );
        },
        disableGroupBy: true
      },
      {
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        width: 100,
        hideMenu: true,
        aggregate: 'sum',
        Cell: ({ value }) => (
          <div className="text-end">
            {formatNumber(Math.round(value || 0))}
          </div>
        ),
        Aggregated: ({ value }) => (
          <div className="text-end">
            <b>{roundFormatMoney(value || 0)}</b>
          </div>
        ),
        Footer: () => (
          <div className="text-end">
            <b>{roundFormatMoney(finalSum.FBidContractSum || 0)}</b>
          </div>
        ),
      },
      {
        Header: 'Profit (est.)',
        accessor: 'currentGrossProfit',
        width: 100,
        hideMenu: true,
        aggregate: 'sum',
        Cell: ({ value }) => (
          <div className="text-end">
            {formatNumber(Math.round(value || 0))}
          </div>
        ),
        Aggregated: ({ value }) => (
          <div className="text-end">
            <b>{roundFormatMoney(value || 0)}</b>
          </div>
        ),
        Footer: () => (
          <div className="text-end">
            <b>{roundFormatMoney(finalSum.FBidProfitSum || 0)}</b>
          </div>
        ),
      },
      {
        Header: 'Profit %',
        accessor: 'profitPercentage',
        width: 80,
        hideMenu: true,
        // Custom aggregation handled in Aggregated component
        aggregate: (leafValues, aggregatedValues) => null,
        Cell: ({ value }) => (
          <div className="text-end">
            {Math.round(value || 0)}%
          </div>
        ),
        Aggregated: ({ row }) => {
          const contractSum = _.get(row, 'values.currentContractAmount', 0);
          const profitSum = _.get(row, 'values.currentGrossProfit', 0);
          const percent = contractSum > 0 ? (profitSum / contractSum) * 100 : 0;
          return (
            <div className="text-end">
              <b>{Math.round(percent)}%</b>
            </div>
          );
        },
        Footer: () => (
          <div className="text-end">
            <b>{Math.round(finalSum.FBidProfitPerSum || 0)}%</b>
          </div>
        ),
      },
      {
        Header: 'W/L/O',
        accessor: 'bidStatus',
        width: 80,
        hideMenu: true,
        // Count unique values
        aggregate: (leafValues, aggregatedValues) => null,
        Cell: ({ value }) => <div>{value || '-'}</div>,
        Aggregated: ({ row }) => {
          // Use aggregated rows or calculate hit ratio
          const rowValues = (row.subRows || []).map(r => r.values);
          const wins = rowValues.filter(v => v.bidStatus === 'W').length;
          const losses = rowValues.filter(v => v.bidStatus === 'L').length;
          const hitRatio = (wins + losses) > 0 ? Math.round((wins / (wins + losses)) * 100) : 0;
          return <div><b>Hit Ratio: {hitRatio}%</b></div>;
        },
        Footer: () => {
          const hitRatio = finalSum.FWLCount > 0 ? Math.round(finalSum.FWLBidRatio) : 0;
          return <div><b>Hit Ratio: {hitRatio}%</b></div>;
        }
      },
      {
        Header: 'Contract Amt.',
        accessor: 'wonContractAmount',
        width: 100,
        hideMenu: true,
        aggregate: 'sum',
        Cell: ({ row, value }) => {
          const status = row.values.bidStatus;
          if (status !== 'W' && status !== 'L') return null;
          return (
            <div className="text-end">
              {formatNumber(Math.round(value || 0))}
            </div>
          );
        },
        Aggregated: ({ value }) => (
          <div className="text-end">
            <b>{roundFormatMoney(value || 0)}</b>
          </div>
        ),
        Footer: () => (
          <div className="text-end">
            <b>{roundFormatMoney(finalSum.FWonContractSum || 0)}</b>
          </div>
        ),
      },
      {
        Header: 'Profit (est.)',
        accessor: 'wonProfitAmount',
        width: 100,
        hideMenu: true,
        aggregate: 'sum',
        Cell: ({ row, value }) => {
          const status = row.values.bidStatus;
          if (status !== 'W' && status !== 'L') return null;
          return (
            <div className="text-end">
              {formatNumber(Math.round(value || 0))}
            </div>
          );
        },
        Aggregated: ({ value }) => (
          <div className="text-end">
            <b>{roundFormatMoney(value || 0)}</b>
          </div>
        ),
        Footer: () => (
          <div className="text-end">
            <b>{roundFormatMoney(finalSum.FWonProfitSum || 0)}</b>
          </div>
        ),
      },
      {
        Header: 'Profit %',
        accessor: 'wonProfitPercentage',
        width: 80,
        hideMenu: true,
        // Custom aggregation handled in Aggregated component
        aggregate: (leafValues, aggregatedValues) => null,
        Cell: ({ row, value }) => {
          const status = row.values.bidStatus;
          if (status !== 'W' && status !== 'L') return null;
          return (
            <div className="text-end">
              {Math.round(value || 0)}%
            </div>
          );
        },
        Aggregated: ({ row }) => {
          const contractSum = _.get(row, 'values.wonContractAmount', 0);
          const profitSum = _.get(row, 'values.wonProfitAmount', 0);
          const percent = contractSum > 0 ? (profitSum / contractSum) * 100 : 0;
          return (
            <div className="text-end">
              <b>{Math.round(percent)}%</b>
            </div>
          );
        },
        Footer: () => {
          const percent = finalSum.FWonContractSum > 0 ? 
            (finalSum.FWonProfitSum / finalSum.FWonContractSum) * 100 : 0;
          return (
            <div className="text-end">
              <b>{Math.round(percent)}%</b>
            </div>
          );
        },
      },
    ];

    setColumns(columns);
  };

  // Prepare data for Excel export
  const prepareExportData = (dataArr, contactData, finalSum) => {
    const roles = state.projectRoleTypes;
    const estShort = (roles.length > 0 && roles.find((role) => role.code === 'EST'))?.shortName ?? 'EST';
    const projMgrShort = (roles.length > 0 && roles.find((role) => role.code === 'PM'))?.shortName ?? 'PM';

    const headers = [
      'Job Num.',
      'Alt Job#',
      'Job Name',
      'Status',
      'Due Date',
      'Bid Date',
      estShort,
      projMgrShort,
      'Contract Amt.',
      'Profit (est.)',
      'Profit %',
      'W/L/O',
      'Contract Amt. Result',
      'Profit (est.) Result',
      'Profit % Result',
    ];

    let exportData = [headers];

    for (let x in dataArr) {
      const analyzerGroup = dataArr[x];
      
      for (let y in analyzerGroup.projects) {
        const project = analyzerGroup.projects[y];
        const row = [];

        // Job Number
        row.push(project.jobNumFormatted || '-');
        
        // Alt Job Number
        row.push(project.altJobNum || '-');
        
        // Job Name
        row.push(project.jobName || '-');
        
        // Status
        row.push(project.jobStatusCodesCode || '-');
        
        // Due Date
        row.push(project.bidDue && project.bidDue.iso ? formatDate(project.bidDue.iso) : '-');
        
        // Bid Date
        row.push(project.bidSubmitted && project.bidSubmitted.iso ? formatDate(project.bidSubmitted.iso) : '-');

        contactData = contactData || [];
        
        // Estimator
        let estimators = '';
        if (project.EST && project.EST.length > 0) {
          estimators = project.EST.map(e => {
            const contact = contactData[e.contactAffiliationId];
            return contact ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim() : '';
          }).join(', ');
        }
        row.push(estimators || '-');
        
        // Project Manager
        let projectMgrs = '';
        if (project.PM && project.PM.length > 0) {
          projectMgrs = project.PM.map(p => {
            const contact = contactData[p.contactAffiliationId];
            return contact ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim() : '';
          }).join(', ');
        }
        row.push(projectMgrs || '-');
        
        // Contract Amount
        row.push(formatNumber(Math.round(project.currentContractAmount || 0)));
        
        // Profit Amount
        row.push(formatNumber(Math.round(project.currentGrossProfit || 0)));
        
        // Profit Percentage
        let bidProfitPct = 0;
        if (project.currentContractAmount > 0) {
          bidProfitPct = (project.currentGrossProfit / project.currentContractAmount) * 100;
        }
        row.push(`${Math.round(bidProfitPct)}%`);
        
        // W/L/O Status
        const bidStatus = project.WLO_status || 'O';
        row.push(bidStatus);
        
        // Won Contract Amount
        row.push(formatNumber(Math.round(bidStatus === 'W' ? project.currentContractAmount : 0)));
        
        // Won Profit Amount
        row.push(formatNumber(Math.round(bidStatus === 'W' ? project.currentGrossProfit : 0)));
        
        // Won Profit Percentage
        let wonProfitPct = 0;
        if (bidStatus === 'W' && project.currentContractAmount > 0) {
          wonProfitPct = (project.currentGrossProfit / project.currentContractAmount) * 100;
        }
        row.push(`${Math.round(wonProfitPct)}%`);
        
        exportData.push(row);
      }
      
      // Add subtotal row
      const subtotalRow = [];
      subtotalRow.push('Subtotals for:');
      subtotalRow.push(analyzerGroup.analyzer || analyzerGroup.label || 'Unknown');
      subtotalRow.push(`Bid Hit Ratio: ${Math.round(analyzerGroup.bidHitRatioCount || 0)}%`);
      subtotalRow.push(`# Jobs: ${analyzerGroup.projectCount || 0}`);
      subtotalRow.push('');
      subtotalRow.push('');
      subtotalRow.push('');
      subtotalRow.push('');
      subtotalRow.push(roundFormatMoney(analyzerGroup.allContract || 0));
      subtotalRow.push(roundFormatMoney(analyzerGroup.allProfit || 0));
      subtotalRow.push(`${Math.round(analyzerGroup.bidProfitPercent || 0)}%`);
      subtotalRow.push('');
      subtotalRow.push(roundFormatMoney(analyzerGroup.jobStatusAmountSumCont || 0));
      subtotalRow.push(roundFormatMoney(analyzerGroup.jobStatusAmountSumProfit || 0));
      subtotalRow.push(`${Math.round(analyzerGroup.wonProfitPercent || 0)}%`);
      
      exportData.push(subtotalRow);
      
      // Add profit hit row
      const profitHitRow = [];
      profitHitRow.push('');
      profitHitRow.push('');
      profitHitRow.push(`Profit Hit %: ${Math.round(analyzerGroup.finalProfitPercentage || 0)}%`);
      for (let i = 0; i < 12; i++) {
        profitHitRow.push('');
      }
      
      exportData.push(profitHitRow);
      exportData.push([]); // Empty row for spacing
    }
    
    // Add company totals
    const totalRow = [];
    totalRow.push('Company Totals:');
    totalRow.push(`Bid Hit Ratio: ${Math.round(finalSum.FWLBidRatio || 0)}%`);
    totalRow.push(`# Jobs: ${finalSum.FProjectSum || 0}`);
    totalRow.push('');
    totalRow.push('');
    totalRow.push('');
    totalRow.push('');
    totalRow.push('');
    totalRow.push(roundFormatMoney(finalSum.FBidContractSum || 0));
    totalRow.push(roundFormatMoney(finalSum.FBidProfitSum || 0));
    totalRow.push(`${Math.round(finalSum.FBidProfitPerSum || 0)}%`);
    totalRow.push('');
    totalRow.push(roundFormatMoney(finalSum.FWonContractSum || 0));
    totalRow.push(roundFormatMoney(finalSum.FWonProfitSum || 0));
    const wonProfitPct = finalSum.FWonContractSum > 0 ? 
      (finalSum.FWonProfitSum / finalSum.FWonContractSum) * 100 : 0;
    totalRow.push(`${Math.round(wonProfitPct)}%`);
    
    exportData.push(totalRow);
    
    // Add profit hit row for company totals
    const totalProfitHitRow = [];
    totalProfitHitRow.push('');
    totalProfitHitRow.push('');
    totalProfitHitRow.push(`Profit %: ${Math.round(finalSum.FProfitWLSum || 0)}%`);
    for (let i = 0; i < 12; i++) {
      totalProfitHitRow.push('');
    }
    
    exportData.push(totalProfitHitRow);
    
    return exportData;
  };

  // Handle sorting
  const handleSorting = (column, direction) => {
    setState({ ...state, fetchRequest: true });
    
    let sortKey = column.id;
    if (sortKey === 'jobNumFormatted') {
      sortKey = 'jobNum';
    }
    
    let sortType = direction === 'asc' ? 'ASC' : 'DESC';
    
    setSortKey(sortKey);
    setSortType(sortType);
    
    let data = {
      accountId: state.accountId,
      moduleName: appConstants.reportModuleName['10'],
      analyzeBy: analyzeBy?.value || '',
      sortKey: sortKey,
      sortType: sortType
    };
    
    if (state.period === 'year') {
      data['year'] = state.year;
    } else if (state.period === 'month') {
      data['month'] = state.month;
    } else if (state.period === 'quarter') {
      data['quarter'] = Number(state.quarter.value);
    } else if (state.period === 'other') {
      data['startDate'] = startDateFormatWithoutTimeUTCF(state.dateRange.startDate);
      data['endDate'] = endDateFormatWithoutTimeUTCF(state.dateRange.endDate);
    }
    
    dispatch(GetBidResultRecapAction(data));
  };

  // Handle Excel export - simplified to use current data
  const handleExcelExport = () => {
    // We'll rely on the ExportExcelComponent to handle the export with the current data
    // No need to refetch the data
  };

  return (
    <div>
      <div className="ContactListReport">
        <TitleComponent data={{ title: "Bid Result Recap Report" }} />
        <div>
          <Suspense fallback="loading">
            <Header props={history} />
          </Suspense>
        </div>

        <div className="contactBox">
          <div className="container">
            <div className="reportHead no-print">
              <h2>Bid Result Recap Report</h2>
              {/* Let react-table handle exports */}
             
              <button className="btn rightalignmentBtn" onClick={ () => toggleFilter() }>
                <i className="fa fa-filter"></i> Apply Filters
              </button>
            </div>

            <div ref={(el) => setPrintContainer(el)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="pdfLogoBox">
                    <div className="reportHead">
                      <div className="logo">
                        <img src={localStorage.getItem('companyLogo') || appConstants.CONTENT_CPOST_LOGO_URL} alt="Company Logo" />
                      </div>
                      <h2>Bid Result Recap Report</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="analyzedBy">
                    <strong>Analyzed By:</strong> {analyzeByLabel}
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <strong>Reporting Period:</strong> {periodStatus}
                  </div>
                </div>
              </div>

              {processedData.winLossData.length > 0 && (
                <div className="row mt-4 mb-4">
                  <div className="col-md-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title">Bid Hit Ratio by {analyzeByLabel}</h5>
                      </div>
                      <div className="card-body pb-8 mb-8" style={{ height: '350px' }}>
                        <ResponsiveBar
                          data={processedData.winLossData}
                          keys={['win', 'loss']}
                          indexBy="name"
                          margin={{ top: 20, right: 130, bottom: 80, left: 60 }}
                          padding={0.3}
                          valueScale={{ type: 'linear' }}
                          indexScale={{ type: 'band', round: true }}
                          colors={['#38A169', '#E53E3E']}
                          groupMode="grouped"
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: '#38A169',
                              size: 4,
                              padding: 1,
                              stagger: true
                            }
                          ]}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Number of Jobs',
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          labelSkipWidth={12}
                          labelSkipHeight={12}
                          legends={[
                            {
                              dataFrom: 'keys',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 120,
                              translateY: 0,
                              itemsSpacing: 2,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemDirection: 'left-to-right',
                              itemOpacity: 0.85,
                              symbolSize: 20,
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemOpacity: 1
                                  }
                                }
                              ]
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title">Contract Amounts by {analyzeByLabel}</h5>
                      </div>
                      <div className="card-body" style={{ height: '300px' }}>
                        <ResponsivePie
                          data={processedData.profitByDimensionData.map(item => ({
                            id: item.name,
                            label: item.name,
                            value: item.contractAmount,
                          }))}
                          margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                          innerRadius={0.5}
                          padAngle={0.7}
                          cornerRadius={3}
                          activeOuterRadiusOffset={8}
                          colors={{ scheme: 'nivo' }}
                          borderWidth={1}
                          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                          arcLinkLabelsSkipAngle={10}
                          arcLinkLabelsTextColor="#333333"
                          arcLinkLabelsThickness={2}
                          arcLinkLabelsColor={{ from: 'color' }}
                          arcLabelsSkipAngle={10}
                          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                          valueFormat={value => `$${Math.round(value / 1000)}k`}
                          legends={[
                            {
                              anchor: 'right',
                              direction: 'column',
                              justify: false,
                              translateX: 65,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemWidth: 100,
                              itemHeight: 18,
                              itemTextColor: '#999',
                              itemDirection: 'left-to-right',
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: 'circle'
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="profit-projection reportTableSection bigTable bigTable1">
                    <CustomTable
                      columns={columns}
                      data={processedData.projectsData || []}
                      loading={state.fetchRequest}
                      noDataText={noData ? 'No data found' : (state.fetchRequest ? 'Loading...' : 'No data found')}
                      canShowFooter={true}
                      onSortChange={handleSorting}
                      groupBy={['analyzer']}
                      groupUp={true}
                      pageSizeArray={[1000]}
                      canShowColumnSettings={false}
                      exportFileName="Bid_Result_Recap_Report"
                      exportable={true}
                      componentRef={printContainer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Footer />
      </div>

      <Modal
        backdrop="static"
        keyboard={true}
        isOpen={settingsModalOpen}
        toggle={() => toggleFilter()}
        className="modal-lg"
      >
        <ModalHeader toggle={() => toggleFilter(true)}>Select</ModalHeader>
        <ModalBody className="reportPopupFilter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="analyzeBy">Analyze By</label>
                  <Select
                    name="analyzeBy"
                    components={makeAnimated()}
                    value={analyzeBy}
                    options={analyzeByOptions}
                    onChange={handleChangeAnalyzeBy}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group margin0">
                  <label htmlFor="status">Select Reporting Period</label>
                </div>
              </div>
            </div>
            <div className="form-group margin0">
              <div className="row">
                <div className="col-md-3 lineheight">
                  <Input
                    type="radio"
                    id="year"
                    name="period"
                    checked={state.period === 'year'}
                    onClick={() => handleChangePeriod('year')}
                  />{' '}
                  <label htmlFor="year">Year</label>
                </div>

                <div className="col-md-9">
                  {state.period === 'year' && (
                    <div>
                      <Select
                        name="year"
                        components={makeAnimated()}
                        value={{ value: state.year, label: state.year }}
                        onChange={(option) => setState({ ...state, year: option.value })}
                        options={Array.from({ length: 10 }, (_, i) => {
                          const year = new Date().getFullYear() - i;
                          return { value: year, label: year };
                        })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin0">
              <div className="row">
                <div className="col-md-3 lineheight">
                  <Input
                    type="radio"
                    name="period"
                    id="month"
                    checked={state.period === 'month'}
                    onClick={() => handleChangePeriod('month')}
                  />{' '}
                  <label htmlFor="month">Month</label>
                </div>
                <div className="col-md-9">
                  {state.period === 'month' && (
                    <div>
                      <Select
                        name="month"
                        value={{ 
                          value: state.month, 
                          label: [
                            'January', 'February', 'March', 'April', 'May', 'June',
                            'July', 'August', 'September', 'October', 'November', 'December'
                          ][state.month - 1] 
                        }}
                        components={makeAnimated()}
                        options={[
                          { value: 1, label: 'January' },
                          { value: 2, label: 'February' },
                          { value: 3, label: 'March' },
                          { value: 4, label: 'April' },
                          { value: 5, label: 'May' },
                          { value: 6, label: 'June' },
                          { value: 7, label: 'July' },
                          { value: 8, label: 'August' },
                          { value: 9, label: 'September' },
                          { value: 10, label: 'October' },
                          { value: 11, label: 'November' },
                          { value: 12, label: 'December' },
                        ]}
                        onChange={(option) => setState({ ...state, month: option.value })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin0">
              <div className="row">
                <div className="col-md-3 lineheight">
                  <Input
                    type="radio"
                    id="quarter"
                    name="period"
                    checked={state.period === 'quarter'}
                    onClick={() => handleChangePeriod('quarter')}
                  />{' '}
                  <label htmlFor="quarter">Quarter</label>
                </div>
                <div className="col-md-9">
                  {state.period === 'quarter' && (
                    <div>
                      <Select
                        name="quarter"
                        components={makeAnimated()}
                        value={state.quarter}
                        options={[
                          { value: 1, label: '1st' },
                          { value: 2, label: '2nd' },
                          { value: 3, label: '3rd' },
                          { value: 4, label: '4th' },
                        ]}
                        onChange={(option) => setState({ ...state, quarter: option })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin0">
              <div className="row">
                <div className="col-md-12 lineheight">
                  <Input
                    type="radio"
                    id="other"
                    name="period"
                    checked={state.period === 'other'}
                    onClick={() => handleChangePeriod('other')}
                  />{' '}
                  <label htmlFor="other">Custom Date Range</label>
                </div>
              </div>

              <div className="form-group">
                {state.period === 'other' && (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <DateRangeSelector
                        onChange={handleDateRangeChange}
                        initialMode="range"
                        initialValue=""
                        initialStart={state.dateRange.startDate}
                        initialEnd={state.dateRange.endDate}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={() => toggleFilter(true)}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={submitReportRequest}>
            Apply
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BidResultRecapReport;