import { useState, forwardRef, useImperativeHandle, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../../../shared/hooks/use-app-dispatch';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useAppSelector } from '../../../../shared/hooks/use-app-selector';
import { selectAllJobScopes, selectOptionTemplateJobScope } from '../../../../selectors/jobScope';
import { isAllowed, moduleConstants } from '../../../../_constants';
import { notification } from 'antd';
import { TotalSection } from './total-section/total-section';
import { ScopeEstimateModule } from './scope-estimate-module/scope-estimate-module';
import styles from './style.module.scss';
import { getUserSettings } from '../../../../modules/settings';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import _ from 'lodash';
import FormInput from '../../../../shared/form-controls/form-input/form-input';
import { currencyToNumber } from '../../../../utils/number-formatters';

type JobScopeLineItemsProps = {
  projectData: any;
  projectBidData?: any;
  isLocked: boolean;
  isChangeOrder?: boolean;
  toggleDetailedModeCallback?: (newVal: boolean) => void;
};

export const JobScopeLineItems = forwardRef(
  (
    {
      projectData = {},
      projectBidData = {},
      isLocked,
      isChangeOrder = false,
      toggleDetailedModeCallback,
    }: JobScopeLineItemsProps,
    ref
  ) => {
    const { watch, control, setValue, getValues } = useFormContext();
    const estData = watch('estData', []);
    const estDataArray = useFieldArray({ name: 'estData', control: control });

    // Memoize visible columns configuration to avoid recreating on every render
    const visibleColumns = useMemo(() => ({
      description: true,
      serviceDate: false,
      qty: true,
      unitCost: true,
      cost: true,
      markup: true,
      unitPrice: true,
      total: true,
      taxable: true,
      billable: false,
    }), []);

    // Save column configuration to localStorage when it changes
    useEffect(() => {
      localStorage.setItem('estDataColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    // State variables
    const [detailedMode, setDetailedMode] = useState(false);
    const [showAsPers, setShowAsPers] = useState<boolean>(true);
    const [isTotalLocked, setIsTotalLocked] = useState<boolean>(false);
    const [lineItemOptions, setLineItemOptions] = useState([]);
    const [estDataLoaded, setEstDataLoaded] = useState(false);
    const [hasRemovedScopes] = useState(false);

    // Access dispatch and selectors
    const dispatch = useAppDispatch();
    const jobScopes = useAppSelector(selectOptionTemplateJobScope);
    const allJobScopes = useAppSelector(selectAllJobScopes);
    const hasBidAmountPermissions = isAllowed(moduleConstants.EBAGP);

    // Update detailed mode in parent component
    useEffect(() => {
      toggleDetailedModeCallback && toggleDetailedModeCallback(detailedMode);
    }, [detailedMode, toggleDetailedModeCallback]);

    // Load line item options on component mount
    useEffect(() => {
      dispatch(getUserSettings(['lineItemTypes']))
        .unwrap()
        .then((data) => {
          setLineItemOptions(data);
        });
    }, [dispatch]);

    // Function to efficiently update form fields in batches
    const updateEstDataFields = useCallback(
      (items) => {
        const estDataLocal = _.cloneDeep(items ?? getValues('estData'));
        
        // Process each item and its sub-items
        estDataLocal.forEach((item, index) => {
          const baseFieldPath = `estData.${index}`;
          const updates = {
            [`${baseFieldPath}.lineItemId`]: item.lineItemId,
            [`${baseFieldPath}.qbItemId`]: item.qbItemId,
            [`${baseFieldPath}.scopeId`]: item.scopeId,
            [`${baseFieldPath}.subTotalCost`]: item.subTotalCost,
            [`${baseFieldPath}.subTotalProfit`]: item.subTotalProfit,
            [`${baseFieldPath}.subTotalCtr`]: item.subTotalCtr,
            [`${baseFieldPath}.subTotalProfitPers`]: item.subTotalProfitPers,
            [`${baseFieldPath}.description`]: item.description ?? '',
            [`${baseFieldPath}.serviceDate`]: item.serviceDate,
            [`${baseFieldPath}.qty`]: item.qty ?? 0,
            [`${baseFieldPath}.unitCost`]: item.unitCost ?? 0,
            [`${baseFieldPath}.unitPrice`]: item.unitPrice ?? 0,
            [`${baseFieldPath}.taxable`]: item.taxable ?? false,
            [`${baseFieldPath}.billable`]: item.billable ?? true,
            [`${baseFieldPath}.items`]: item.items
          };
          
          // Apply all updates at once per item
          Object.entries(updates).forEach(([key, value]) => {
            setValue(key, value);
          });
          
          // Process sub-items if they exist
          if (item?.items?.length > 0) {
            item.items.forEach((subItem, subIndex) => {
              const subFieldPath = `${baseFieldPath}.items.${subIndex}`;
              const subUpdates = {
                [`${subFieldPath}.lineItemId`]: subItem.lineItemId,
                [`${subFieldPath}.qbItemId`]: subItem.qbItemId,
                [`${subFieldPath}.scopeId`]: subItem.scopeId,
                [`${subFieldPath}.qty`]: subItem.qty ?? 0,
                [`${subFieldPath}.unitCost`]: subItem.unitCost ?? 0,
                [`${subFieldPath}.unitPrice`]: subItem.unitPrice ?? 0,
                [`${subFieldPath}.cost`]: subItem.cost,
                [`${subFieldPath}.profit`]: subItem.profit,
                [`${subFieldPath}.total`]: subItem.total,
                [`${subFieldPath}.profitPers`]: subItem.profitPers,
                [`${subFieldPath}.description`]: subItem.description ?? '',
                [`${subFieldPath}.serviceDate`]: subItem.serviceDate,
                [`${subFieldPath}.taxable`]: subItem.taxable ?? false,
                [`${subFieldPath}.billable`]: subItem.billable ?? true
              };
              
              // Apply all sub-item updates at once
              Object.entries(subUpdates).forEach(([key, value]) => {
                setValue(key, value);
              });
            });
          }
        });
      },
      [getValues, setValue]
    );

    // Handle drag and drop of top-level items
    const onDragEnd = useCallback((result) => {
      if (!result.destination) return;

      const estDataLocal = getValues('estData');
      const items = _.cloneDeep(estDataLocal);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Update form with reordered items
      setValue('estData', items);
      updateEstDataFields(items);
    }, [getValues, setValue, updateEstDataFields]);

    // Handle drag and drop of nested items
    const onDragEndNested = useCallback((scopeIndex, result) => {
      if (!result.destination) return;

      const estDataLocal = getValues('estData');
      const items = _.cloneDeep(estDataLocal);
      const nestedItems = _.cloneDeep(items[scopeIndex].items || []);
      const [reorderedItem] = nestedItems.splice(result.source.index, 1);
      nestedItems.splice(result.destination.index, 0, reorderedItem);
      
      // Update the nested items
      items[scopeIndex].items = nestedItems;
      setValue('estData', items);
      updateEstDataFields(items);
    }, [getValues, setValue, updateEstDataFields]);

    // Expose buildScope method to parent via ref
    useImperativeHandle(ref, () => ({ buildScope }), []);

    // Helper function to get available job scopes
    const getAvailableJobScopes = useCallback(() => {
      const existingScopeIds = estData.map((scope: any) => scope.scopeId);
      return jobScopes.filter((scope: any) => !existingScopeIds.includes(scope.value));
    }, [estData, jobScopes]);

    // Helper function to get all available job scopes
    const getAllAvailableJobScopes = useCallback(() => {
      const existingScopeIds = estData.map((scope: any) => scope.scopeId);
      return allJobScopes.filter((scope: any) => !existingScopeIds.includes(scope.value));
    }, [estData, allJobScopes]);

    // Function to build a scope object
    const buildScope = useCallback(
      (id: string, isInitial = false, isCO = isChangeOrder, data = projectData, bidData = projectBidData) => {
        const availableJobScopes = getAvailableJobScopes();
        const allJobScopesAvailable = getAllAvailableJobScopes();

        if (availableJobScopes.length === 0) {
          return;
        }

        const hasLegacyScopeArr = data?.scopeArr?.length > 0;
        const currentJobScopes = hasLegacyScopeArr
          ? data?.scopeArr?.map((scope) => scope.objectId)
          : data.scope?.length > 0
            ? data.scope
            : [];
            
        // Filter available job scopes by current job scopes
        const notAddedJobScopes = currentJobScopes.filter((scope: any) => {
          return allJobScopesAvailable.find((availableScope: any) => availableScope.value === scope);
        });

        let scopeId = notAddedJobScopes.length > 0 
          ? notAddedJobScopes[0] 
          : availableJobScopes[0].value;
          
        const scopeAvailable = allJobScopesAvailable.find((scope) => scope.value === id);
        
        if (id && scopeAvailable) {
          scopeId = id;
        } else if (id === 'clearScope') {
          // Handle special "clearScope" case
          if (!isInitial || isCO) {
            return {
              scopeId: null,
              subTotalCost: 0,
              subTotalProfit: 0,
              subTotalCtr: 0,
              subTotalProfitPers: 0,
              qty: 1,
              unitCost: 0,
              unitPrice: 0,
              taxable: false,
              billable: true,
              description: '',
              allowChangeScope: true,
              lineItemId: null,
            };
          } else {
            // Use project data to assign totals
            const subTotalCost = data.contractAmount - data.grossProfit;
            const subTotalProfit = data.grossProfit;
            const subTotalCtr = data.contractAmount;
            const subTotalProfitPers = (subTotalProfit / subTotalCost) * 100;
            
            return {
              scopeId: null,
              subTotalCost: subTotalCost,
              subTotalProfit: subTotalProfit,
              subTotalCtr: subTotalCtr,
              subTotalProfitPers,
              qty: 1,
              unitCost: subTotalCost,
              unitPrice: subTotalCtr,
              taxable: false,
              billable: true,
              description: '',
              allowChangeScope: true,
              lineItemId: null,
            };
          }
        }

        // Find matching line item
        const matchedLineItemId = lineItemOptions.find((li) => (li as any).jobScopeId === scopeId);

        // Determine financial values based on project type
        let { grossProfit = 0, contractAmount = 0 } = data;
        const isOriginalContract = !isCO && bidData.originalContractAmount !== undefined;

        if (isCO && isInitial) {
          grossProfit = bidData.estTotalProfit ?? bidData.profitDollars;
          contractAmount = bidData.estTotalContract ?? bidData.coTotalAmount;
        } else if (isInitial && isOriginalContract) {
          grossProfit = bidData.originalGrossProfit;
          contractAmount = bidData.originalContractAmount;
        }

        // Check if scope exists in bid data
        if (bidData?.estData?.map((scope: any) => scope.scopeId).includes(scopeId)) {
          const selectedScope = bidData.estData.find((scope: any) => scope.scopeId === scopeId);
          const { subTotalCost, subTotalProfit, subTotalCtr, subTotalProfitPers } = selectedScope;

          return {
            scopeId,
            subTotalCost: subTotalCost,
            subTotalProfit: subTotalProfit,
            subTotalCtr: subTotalCtr,
            subTotalProfitPers,
            qty: 1,
            unitCost: subTotalCost,
            unitPrice: subTotalCtr,
            taxable: false,
            billable: true,
            description: '',
            allowChangeScope: !id,
            lineItemId: (matchedLineItemId as any)?._id,
          };
        } else {
          // Create new scope with calculated values
          const subTotalProfit = isInitial ? +grossProfit : 0;
          const subTotalCost = isInitial ? +contractAmount - subTotalProfit : 0;
          const subTotalCtr = isInitial ? subTotalCost + subTotalProfit : 0;
          const subTotalProfitPers = isInitial ? (subTotalProfit / subTotalCost) * 100 : 0;

          return {
            scopeId,
            subTotalCost: subTotalCost,
            subTotalProfit: subTotalProfit,
            subTotalCtr: subTotalCtr,
            subTotalProfitPers,
            qty: 1,
            unitCost: subTotalCost,
            unitPrice: subTotalCtr,
            taxable: false,
            billable: true,
            description: '',
            allowChangeScope: !id,
            lineItemId: (matchedLineItemId as any)?._id,
          };
        }
      },
      [
        projectData, 
        projectBidData, 
        getAvailableJobScopes, 
        getAllAvailableJobScopes, 
        lineItemOptions, 
        isChangeOrder
      ]
    );

    // Function to add a new scope to the form
    const onAddScope = useCallback((id: string, isInitial = false, insertIndex?: number) => {
      const newScopes = buildScope(id, isInitial);
      if (!newScopes) return;

      if (!insertIndex || estData.length === 0) {
        // Add to empty list
        setValue('estData', [newScopes]);
        updateEstDataFields([newScopes]);
      } else {
        // Insert at specific position
        const _localEstData = getValues('estData');
        const items = _.cloneDeep(_localEstData);
        items.splice(insertIndex, 0, newScopes);
        setValue('estData', items);
        updateEstDataFields(items);
      }
    }, [buildScope, estData.length, getValues, setValue, updateEstDataFields]);

    // Track if we need to recalculate totals
    const [shouldRecalculateTotals, setShouldRecalculateTotals] = useState(false);

    // Handler for adding a top-level item
    const onAddFirstLevelItem = useCallback((index: number) => {
      if (!hasBidAmountPermissions) {
        notification.error({ message: 'Insufficient permissions for adding line items' });
        return;
      }

      if (index === -1) {
        index = estData.length;
      }

      onAddScope('clearScope', false, index + 1);
      setShouldRecalculateTotals(true);
      
      if (!estDataLoaded) {
        setEstDataLoaded(true);
      }
    }, [hasBidAmountPermissions, estData.length, onAddScope, estDataLoaded]);

    // Handler for removing a top-level item
    const onRemoveFirstLevelItem = useCallback((index: number) => {
      if (!hasBidAmountPermissions) {
        notification.error({ message: 'Insufficient permissions for removing scope' });
        return;
      }

      if (index <= estData.length) {
        const _localEstData = getValues('estData');
        const items = _.cloneDeep(_localEstData);
        items.splice(index, 1);
        setValue('estData', items);
        updateEstDataFields(items);
      }

      if (estData.length <= 1) {
        onAddScope('clearScope', false);
        setShouldRecalculateTotals(true);
      }
    }, [hasBidAmountPermissions, estData.length, getValues, setValue, updateEstDataFields, onAddScope]);

    // Initialize estData based on project data
    useEffect(() => {
      const hasOriginalEstData = projectBidData?.estData?.length > 0;

      // If no estdata is available, add scopes based on project data or bid data
      if (!hasOriginalEstData && !estDataLoaded && !_.isEmpty(projectBidData)) {
        if (allJobScopes.length > 0) {
          if (
            (!projectBidData.estData || projectBidData.estData.length === 0) &&
            projectData?.scopeArr?.length > 0 &&
            estData.length === 0 &&
            estDataArray
          ) {
            if (hasRemovedScopes) return;
            projectData.scopeArr.forEach((scope, idx) => onAddScope(scope.objectId, idx === 0));
          }
          
          // If estData is empty just add an empty scope
          if ((estData.length === 0 && estDataArray) || !estDataArray) {
            onAddScope('clearScope', true);
            setEstDataLoaded(true);
          }
        }
      } else if (
        !estDataLoaded &&
        !_.isEmpty(projectBidData) &&
        hasOriginalEstData &&
        estData.length === 0
      ) {
        setValue('estData', projectBidData.estData);
      }

      // If estData has loaded, fill all old job scopes with matching line item data
      if (
        hasOriginalEstData &&
        lineItemOptions.length > 0 &&
        estData.length > 0 &&
        !estDataLoaded
      ) {
        if (estData.filter((x: any) => x.lineItemId || x.scopeId)?.length > 0) {
          const estDataLocal = getValues('estData');
          const items = _.cloneDeep(estDataLocal);
          
          // Find and assign line item IDs
          items.forEach((item) => {
            if (!item.lineItemId && item.scopeId) {
              const lineItemJobScope = item.scopeId;
              const matchedJobScope = allJobScopes.find(
                (scope) => scope.value === lineItemJobScope
              );
              
              if (matchedJobScope) {
                const lineItemObj = lineItemOptions.find(
                  (li) => (li as any)._id === matchedJobScope.lineItemId
                ) as any;
                
                if (lineItemObj) {
                  item.lineItemId = lineItemObj._id;
                  item.qbItemId = lineItemObj.qbItemId;
                }
              }
            }
          });

          setValue('estData', items);
          setEstDataLoaded(true);
        }
      }
    }, [
      lineItemOptions,
      estData.length,
      allJobScopes.length,
      projectBidData,
      projectData,
      estDataArray,
      estDataLoaded,
      hasRemovedScopes,
      getValues,
      setValue,
      onAddScope
    ]);

    // Recalculate totals when estData changes
    useEffect(() => {
      if (estData.length > 0) {
        // Calculate totals from all items
        const total = estData.reduce((acc, item) => {
          const value = typeof item.subTotalCtr === 'string' 
            ? currencyToNumber(item.subTotalCtr) 
            : item.subTotalCtr;
          return acc + (value || 0);
        }, 0);
        
        const profit = estData.reduce((acc, item) => {
          const value = typeof item.subTotalProfit === 'string' 
            ? currencyToNumber(item.subTotalProfit) 
            : item.subTotalProfit;
          return acc + (value || 0);
        }, 0);
        
        const cost = estData.reduce((acc, item) => {
          const value = typeof item.subTotalCost === 'string' 
            ? currencyToNumber(item.subTotalCost) 
            : item.subTotalCost;
          return acc + (value || 0);
        }, 0);

        // Batch update all totals
        setValue('estTotalCost', cost);
        setValue('estTotalProfit', profit);
        setValue('estTotalContract', total);

        setShouldRecalculateTotals(false);
      }
    }, [estData.length, estDataLoaded, shouldRecalculateTotals, setValue]);

    // Function to handle adding a new line item
    const onAddNewLineItem = useCallback((lineItem: any) => {
      dispatch(getUserSettings(['lineItemTypes']))
        .unwrap()
        .then((data) => {
          setLineItemOptions(data);

          // Find new line item in updated data
          const estDataLocal = getValues('estData');
          const items = _.cloneDeep(estDataLocal);
          const lineItemValue = lineItem.code;
          const lineItemObj = data.find((li) => li.code === lineItemValue);
          
          if (!lineItemObj) return;

          // Find and update any 'add_new' item
          const estDataIndex = items.findIndex((item) => item.lineItemId === 'add_new');
          if (estDataIndex > -1) {
            // Update top-level item
            items[estDataIndex] = {
              ...items[estDataIndex],
              lineItemId: lineItemObj._id,
              qbItemId: lineItemObj.qbItemId,
              unitCost: lineItemObj.cost,
              unitPrice: lineItemObj.price,
              qty: 1,
              subTotalCtr: lineItemObj.price,
              subTotalProfit: lineItemObj.price - lineItemObj.cost,
              subTotalCost: lineItemObj.cost,
              taxable: lineItemObj.taxable,
              billable: lineItemObj.billable
            };
            
            setValue(`estData.${estDataIndex}`, items[estDataIndex]);
          } else {
            // Check nested items
            let foundNestedItem = false;
            
            for (let x = 0; x < items.length; x++) {
              if (items[x].items) {
                const itemIndex = items[x].items.findIndex((item) => item.lineItemId === 'add_new');
                if (itemIndex > -1) {
                  // Update nested item
                  items[x].items[itemIndex] = {
                    ...items[x].items[itemIndex],
                    lineItemId: lineItemObj._id,
                    qbItemId: lineItemObj.qbItemId,
                    unitCost: lineItemObj.cost,
                    unitPrice: lineItemObj.price,
                    qty: 1,
                    cost: lineItemObj.cost,
                    total: lineItemObj.price,
                    profit: lineItemObj.price - lineItemObj.cost,
                    profitPers: ((lineItemObj.price - lineItemObj.cost) / lineItemObj.cost) * 100,
                    taxable: lineItemObj.taxable,
                    billable: lineItemObj.billable
                  };
                  
                  setValue(`estData.${x}.items.${itemIndex}`, items[x].items[itemIndex]);
                  foundNestedItem = true;
                  break;
                }
              }
            }
            
            // If we've updated a nested item, update the whole estData array
            if (foundNestedItem) {
              setValue('estData', items);
              updateEstDataFields(items);
            }
          }
        });
    }, [dispatch, getValues, setValue, updateEstDataFields]);

    // Check if we can create QuickBooks estimate
    const canCreateQBestimate = useCallback(() => {
      return lineItemOptions.filter((li: any) => li.qbItemId).length > 0;
    }, [lineItemOptions]);

    // Determine if we have an existing QuickBooks estimate
    const hasExistingQBestimate = projectBidData?.qbEstimateId;
    const hasEstData = !!estData.length;

    // Prepare the link for adding scopes
    const getAddScopeLink = useCallback(() => {
      const availableJobScopes = getAvailableJobScopes();
      const hasItems = estData.length > 0;

      if (isLocked || availableJobScopes.length === 0 || (hasItems && !hasBidAmountPermissions)) {
        return null;
      }

      const linkText = `${lineItemOptions?.length > 0 ? 'Add line item' : 'Add job scope'}`;

      return (
        <div className='row'>
          <div className='col-sm-4 mt-2'>
            <a onClick={() => onAddFirstLevelItem(-1)}>{linkText}</a>
          </div>
          <div className='col-sm-4 mt-2'>
            <a onClick={() => setDetailedMode(!detailedMode)}>
              {detailedMode ? 'Show summary' : 'Show expanded'}
            </a>
          </div>
        </div>
      );
    }, [
      getAvailableJobScopes, 
      estData.length, 
      isLocked, 
      hasBidAmountPermissions, 
      lineItemOptions, 
      detailedMode, 
      onAddFirstLevelItem
    ]);

    return (
      <>
        {(!isLocked || hasEstData) && <hr />}
        <div className={'flex-nowrap ' + styles.scopeEstimateContainer}>
          {hasEstData && (
            <>
              <div className='row flex-nowrap col-md-12 pb-2'>
                <div className={`${detailedMode ? 'col-md-2 ms-4 ps-3 ' : 'col-md-4'}  fw-bold`}>
                  {'Item name'}
                </div>
                {detailedMode && visibleColumns.description && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailedLong}`}>Description</div>
                )}
                {detailedMode && visibleColumns.serviceDate && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Service date</div>
                )}
                {detailedMode && visibleColumns.qty && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailedShort}`}>Qty</div>
                )}
                {detailedMode && visibleColumns.unitCost && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Unit cost</div>
                )}
                {(!detailedMode || visibleColumns.cost) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`}
                  >
                    Cost
                  </div>
                )}
                {(!detailedMode || visibleColumns.markup) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`}
                  >
                    Markup (
                    <span
                      className={!showAsPers ? '' : styles.active}
                      onClick={() => setShowAsPers(true)}
                    >
                      %
                    </span>
                    /
                    <span
                      className={showAsPers ? '' : styles.active}
                      onClick={() => setShowAsPers(false)}
                    >
                      $
                    </span>
                    )
                  </div>
                )}
                {detailedMode && visibleColumns.unitPrice && (
                  <>
                    <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Unit price</div>
                  </>
                )}
                {(!detailedMode || visibleColumns.total) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidthShort}`}
                  >
                    Total{' '}
                    {!isLocked && (
                      <i
                        role='button'
                        className={`fa-solid fa-${isTotalLocked ? 'lock' : 'unlock'} ${styles.grey}`}
                        onClick={() => setIsTotalLocked(!isTotalLocked)}
                      ></i>
                    )}
                  </div>
                )}

                {detailedMode && visibleColumns.taxable && (
                  <div className={`ps-1 fw-bold ${styles.inputWidthDetailedShort}`}>Taxable</div>
                )}
                {detailedMode && visibleColumns.billable && (
                  <div className={`ps-1 fw-bold ${styles.inputWidthDetailedShort}`}>Billable</div>
                )}

                <div className={`px-1 ${styles.toggleBtn} me-0`}></div>
              </div>
            </>
          )}
          
          {hasEstData && estDataLoaded && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='scopes'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {estData.map((firstLevelLineItem: any, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={
                          'level1:' + index.toString() + ':' + (firstLevelLineItem.scopeId || 'null')
                        }
                        index={index}
                        isDragDisabled={!detailedMode || isLocked}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <ScopeEstimateModule
                              dragHandleProps={provided.dragHandleProps}
                              id={firstLevelLineItem.scopeId || ''}
                              itemIndex={index}
                              isLocked={isLocked}
                              onRemoveFirstLevelItem={onRemoveFirstLevelItem}
                              options={jobScopes}
                              allOptions={allJobScopes}
                              showProfitAsPers={showAsPers}
                              isTotalLocked={isTotalLocked}
                              lineItemOptions={lineItemOptions}
                              onAddNewLineItem={onAddNewLineItem}
                              onAddFirstLevelItem={onAddFirstLevelItem}
                              detailedMode={detailedMode}
                              visibleColumns={visibleColumns}
                              onDragEndNested={(result) => onDragEndNested(index, result)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        
        {getAddScopeLink()}
        
        <TotalSection
          showProfitAsPers={showAsPers}
          initialValues={{ 
            currentGrossProfit: isChangeOrder ? projectBidData.estTotalProfit : projectData.currentGrossProfit,
            currentContractAmount: isChangeOrder ? projectBidData.estTotalContract : projectData.currentContractAmount
          }}
        />

        {(hasExistingQBestimate || canCreateQBestimate()) && (
          <div className='row mt-4'>
            {hasExistingQBestimate && (
              <>
                <a href={`${projectBidData.qbEstimateLink}`} target='_blank' rel='noreferrer'>
                  View linked Quickbooks estimate
                </a>
                <br />
              </>
            )}

            {!isLocked && (
              <>
                {!hasExistingQBestimate && (
                  <FormInput
                    type='checkbox'
                    Layout={HorizontalLayout}
                    control={control}
                    inputClassName={`form-check-input ` + styles.checkbox}
                    name='createEstimate'
                    label='Create Quickbooks estimate on save'
                    onChange={(value) => {
                      setValue('createEstimate', value);
                    }}
                  />
                )}

                {hasExistingQBestimate && (
                  <div>
                    <FormInput
                      type='checkbox'
                      Layout={HorizontalLayout}
                      control={control}
                      inputClassName={`form-check-input ` + styles.checkbox}
                      name='createEstimate'
                      label='Update Quickbooks estimate on save'
                      onChange={(value) => {
                        setValue('createEstimate', value);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
);