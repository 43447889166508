import _ from 'lodash';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { notification } from 'antd';
import { joiResolver } from '@hookform/resolvers/joi';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { getUpdateProjectForm } from '../../modules/projects';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { selectAllJobStatusOptions } from '../../selectors/jobStatusCode';
import { createUpdateProjectBid, getProjectBid, createEstimateEmail } from '../../modules/projects';
import { getAccountProjectSettings } from '../../modules/accounts';
import { getProjectStageByCode } from '../../shared/custom-fields-controls/constants';
import {
  BidTypeSchema,
  bidBiddingValidationSchema,
  bidPendingValidationSchema,
} from './bid-modal.schema';
import FormInput from '../../shared/form-controls/form-input';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';
import { JobScopeLineItems } from './sections/scope-estimates';
import { Header } from './sections/header';
import { ProjectScheduler } from './sections/project-scheduler';
import { getExistingProjectAction } from '../../actions/project';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { formatDateObjectOrNull } from '../../utils/date-formatters';
import { formatEstData, calculateEstTotals } from '../../utils/number-formatters';
import { BidScheduler } from './sections/bid-scheduler';
import ReactLoading from 'react-loading';
import { appConstants } from '../../_constants/app.constants';
import { getProjectRoles, getUserSettings, getReasonCodesByType } from '../../modules/settings';
import { ProjectCompanyTeam } from '../add-project-modal/sections/project-company-team';
import FormSelect from '../../shared/form-controls/form-select/form-select';
import { getReasonCodesByStatusCodeAction } from '../../actions/reasonCode';
import styles from './bid-modal.module.scss';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
import FormCheckbox from '../../shared/form-controls/form-checkbox';
import FormDatePicker from '../../shared/form-controls/form-date-picker';
import PdfPreviewModal from '../pdf-preview-modal/pdf-preview-modal';
import loaderImage from '../../components/static/images/loading_spinner.gif';
import { useHistory } from 'react-router-dom';

type BidModalProps = {
  projectId: string;
  isOpen: boolean;
  showSaveSubmit: boolean;
  toggle: () => void;
  toggleStatusUpdate: (state: string) => void;
  onSubmit: () => void;
  bidId?: string;
  bidScheduleExpanded?: boolean;
  isPromote?: boolean;
};

export const BidModal = ({
  projectId = '',
  isOpen = false,
  showSaveSubmit = false,
  toggle,
  toggleStatusUpdate,
  onSubmit = () => null,
  bidId = '',
  bidScheduleExpanded = false,
  isPromote = false,
}: BidModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Memoize default form values to avoid recreating on every render
  const defaultValuesBase = useMemo(() => ({
    bidId: '',
    bidInternalId: null,
    comment: '',
    exclusions: '',
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    bidStart: null,
    bidDue: null,
    startDate: null,
    endDate: null,
    bidCount: 0,
    bidRev: 0,
    bidURL: '',
    pendingReasonCodeId: '',
    resolvedReasonCodeId: '',
    pendingComment: '',
    resolvedComment: '',
    estData: [],
    projectTeam: {},
    createEstimate: false,
    wasAccepted: false,
    acceptedDate: null,
    acceptedBy: '',
  }), []);

  // Component state
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFileName, setPdfFileName] = useState('');
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [projectData, setProjectData] = useState<any>({});
  const [projectBidData, setProjectBidData] = useState<any>({});
  const [accountSettings, setAccountSettings] = useState<any>({});
  const [isPending, setIsPending] = useState(false);
  const [isResolved, setIsResolved] = useState(false);
  const [projectRoles, setProjectRoles] = useState([]);
  const [isShowAdditionalFields, setIsShowAdditionalFields] = useState(false);
  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  //const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [clientData, setClientData] = useState<any[]>([]);
  const [emailData, setEmailData] = useState({});
  const [bidLoaded, setBidLoaded] = useState(false);
  const [reasonCodeOptionsOutcome, setOutcomeReasonCodeOptions] = useState<any[]>([]);
  const [reasonCodeOptionsPending, setPendingReasonCodeOptions] = useState<any[]>([]);
  const [detailedMode, setDetailedMode] = useState(true);

  // Refs for child components
  const jobScopesRef = useRef<any>(null);
  const bidScheduleRef = useRef<any>(null);
  const projectScheduleRef = useRef<any>(null);

  // Get user info from local storage
  const userInfo = useMemo(() => 
    JSON.parse(localStorage.getItem('userInfo') || '{}'), 
  []);

  // Determine bid status
  const pendingStatuses = useMemo(() => ['BP', 'P1P', 'P2P', 'P3P', 'PC', 'OHP'], []);
  const terminalStatuses = useMemo(() => ['R', 'XC', 'NC'], []);
  const activePlusStatuses = useMemo(() => ['SNS', 'A', 'D', 'W', 'C', 'SUSP'], []);

  // Get job status options from the Redux store
  const allJobStatusOptions = useAppSelector(selectAllJobStatusOptions);
  
  // Determine if the bid is predefined
  const { jobStatusCodes } = projectData;
  const jobStatusCodesCode = useMemo(() => 
    allJobStatusOptions.find((st) => st.value === jobStatusCodes)?.code || '', 
  [allJobStatusOptions, jobStatusCodes]);
  
  const isBidPredefined = useMemo(() => 
    !!bidId && 
    projectData.curBidId !== bidId && 
    !activePlusStatuses.includes(projectData.jobStatusCodesCode),
  [bidId, projectData, activePlusStatuses]);
  
  // Set form validation schema based on bid status
  const defaultValues = useMemo(() => 
    isPending ? {...defaultValuesBase, bidSubmittedDate: null} : defaultValuesBase, 
  [defaultValuesBase, isPending]);
  
  const validationSchema = useMemo(() => 
    isPending ? bidPendingValidationSchema : bidBiddingValidationSchema, 
  [isPending]);

  // Get project stage
  const projectStage = useMemo(() => 
    getProjectStageByCode(jobStatusCodesCode), 
  [jobStatusCodesCode]);

  // Initialize the form with React Hook Form
  const form = useForm<BidTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(validationSchema),
    context: { accountSettings, projectRoles },
  });

  // Form watches
  const bidUrlWatch = form.watch('bidURL', '');
  const wasAccepted = form.watch('wasAccepted', false);
  const bidInternalId = form.watch('bidInternalId');

  // Determine if the form has additional data
  const hasAdditionalData = !!bidUrlWatch;
  
  // Check form submission state
  const isSubmitting = form.formState.isSubmitting;
  const canSave = !isLocked && !isSubmitting;

  // Check if user is admin when component mounts
  useEffect(() => {
    (async () => {
      if (userInfo.cppRoleId) {
        const cppRoles = await dispatch(getUserSettings(['cppRoles']));

        const isAdmin = cppRoles.payload
          .filter((row: any) => !row.trash && row.originalRoleCode === 'ADMIN')
          .map((row: any) => row._id)
          .includes(userInfo.cppRoleId);

        setIsAdmin(isAdmin);
      }
    })();
  }, [userInfo.cppRoleId, dispatch]);

  // Load reason codes when project ID or job status codes change
  useEffect(() => {
    if (projectId && jobStatusCodes) {
      dispatch(getReasonCodesByStatusCodeAction({ projectId, jobStatusCodes }));
    }
  }, [projectId, jobStatusCodes, dispatch]);

  // Reset component state and load data when modal opens
  useEffect(() => {
    if (isOpen) {
      setBidLoaded(false);
      setShowProcessing(true);
      
      // Create a batch of promises to load all required data
      const loadDataPromises = [
        dispatch(getAllJobScopeAction({ className: 'jobScopes', includeDisabled: true })),
        dispatch(getUpdateProjectForm({ projectId })).unwrap().then(data => setProjectData({ ...data })),
        dispatch(getAccountProjectSettings({})).unwrap().then(data => setAccountSettings({ ...data })),
        dispatch(getProjectRoles()).unwrap().then(data => {
          const dataFiltered = data.filter((role: any) => role.isLinkToBids);
          setProjectRoles(dataFiltered);
        }),
        dispatch(getReasonCodesByType('StatusChange')).unwrap().then(data => {
          setPendingReasonCodeOptions(
            data
              .filter(
                (reason: any) =>
                  !(projectBidData.pendingStatusCode?.length > 0) ||
                  reason.jobStatusCodes === projectBidData.pendingStatusCode
              )
              .map((reason) => ({ label: reason.reasonName, value: reason._id }))
          );
          setOutcomeReasonCodeOptions(
            data
              .filter(
                (reason: any) =>
                  !(projectBidData.resolvedStatus?.length > 0) ||
                  reason.jobStatusCodes === projectBidData.resolvedStatus
              )
              .map((reason) => ({ label: reason.reasonName, value: reason._id }))
          );
        })
      ];

      // Execute all promises in parallel
      Promise.all(loadDataPromises).catch(error => {
        console.error("Error loading bid data:", error);
        notification.error({ message: 'Error loading bid data' });
        setShowProcessing(false);
      });
    } else {
      // Reset form when modal closes
      form.reset({ ...defaultValues });
      setProjectData({});
      setIsLocked(false);
    }
  }, [isOpen, projectId, defaultValues, dispatch, form, projectBidData.pendingStatusCode, projectBidData.resolvedStatus]);

  // Initialize estData from project data
  const initialEstData = useCallback(() => {
    if (!projectData.scope) return [];
    
    return projectData.scope
      .map((id: string, index: number) =>
        jobScopesRef.current?.buildScope(id, index === 0, projectData)
      )
      .filter(Boolean);
  }, [projectData, jobScopesRef]);

  // Update lock state based on bid status
  useEffect(() => {
    const shouldBeLocked = (isPending || isResolved) && !isPromote;
    setIsLocked(shouldBeLocked);
  }, [isPending, isResolved, isPromote]);

  // Load bid data when modal opens
  useEffect(() => {
    if (isOpen && projectId && !_.isEmpty(projectData)) {
      const { curBidId, lastBidId } = projectData;
      const projectBidId = bidId ? bidId : curBidId || '';

      // Handle case when bid exists
      if (projectBidId) {
        (async () => {
          try {
            const bidData = await dispatch(
              (getProjectBid as any)({ projectId, bidId: projectBidId })
            ).unwrap();
            setProjectBidData(bidData);

            const isCurrentBid = projectData.curBidId === projectBidId;
            const wasProperlyResolved = !!bidData.markedResolved;

            // Determine bid status
            const isPending = isCurrentBid && pendingStatuses.includes(projectData.jobStatusCodesCode);
            const isResolved =
              wasProperlyResolved ||
              projectData.curBidId !== projectBidId ||
              activePlusStatuses.includes(projectData.jobStatusCodesCode);
              
            setIsPending(isPending);
            setIsResolved(isResolved);

            // Calculate totals based on estData
            const { cost, profit, amount } = calculateEstTotals(bidData.estData);

            // Prepare form values
            const formValues = {
              bidId: bidData.bidId,
              bidInternalId: bidData.objectId || bidData._id,
              comment: bidData.comment,
              exclusions: bidData.exclusions,
              estTotalCost: cost,
              estTotalProfit: profit,
              estTotalContract: amount,
              bidStart: formatDateObjectOrNull(bidData.bidStart),
              bidDue: formatDateObjectOrNull(bidData.bidDue),
              ...(isPending && {
                bidSubmittedDate: formatDateObjectOrNull(bidData.bidSubmittedDate),
              }),
              bidCount: bidData.bidCount,
              bidRev: bidData.bidRev,
              bidURL: bidData.bidURL,
              pendingComment: bidData.pendingComment,
              resolvedComment: bidData.resolvedComment,
              pendingReasonCodeId: bidData.pendingReasonCodeId,
              resolvedReasonCodeId: bidData.resolvedReasonCodeId,
              createEstimate: false,
              estData: formatEstData(bidData.estData),
              startDate: formatDateObjectOrNull(projectData.startDate),
              endDate: formatDateObjectOrNull(projectData.endDate),
              projectTeam: (isPending || isResolved ? bidData : projectData).projectTeam,
              wasAccepted: bidData.wasAccepted,
              acceptedDate: formatDateObjectOrNull(bidData.acceptedDate),
              acceptedBy: bidData.acceptedBy
            };

            // Reset form with loaded values
            form.reset(formValues, { keepDefaultValues: true });

            setIsShowAdditionalFields(!!bidData.bidURL);
            setBidLoaded(true);
            setShowProcessing(false);
          } catch (error) {
            console.error("Error loading bid data:", error);
            notification.error({ message: 'Error loading bid data' });
            setShowProcessing(false);
          }
        })();
      } else if (lastBidId) {
        // Load last bid data when no current bid
        (async () => {
          !isPromote && setIsLocked(true);

          try {
            const bidData = await dispatch(
              (getProjectBid as any)({ projectId, bidId: lastBidId })
            ).unwrap();
            
            setProjectBidData(bidData);
            setIsPending(false);
            setIsResolved(true);

            // Calculate totals
            const { cost, profit, amount } = calculateEstTotals(bidData.estData);

            // Reset form with calculated values
            form.reset(
              {
                ...defaultValues,
                bidStart: formatDateObjectOrNull(projectData.bidStart),
                bidDue: formatDateObjectOrNull(projectData.bidDue),
                ...(isPending && {
                  bidSubmittedDate: formatDateObjectOrNull(projectData.bidSubmittedDate),
                }),
                startDate: formatDateObjectOrNull(projectData.startDate),
                endDate: formatDateObjectOrNull(projectData.endDate),
                estTotalCost: cost,
                estTotalProfit: profit,
                estTotalContract: amount,
                estData: formatEstData(bidData.estData),
                createEstimate: false,
                projectTeam: (isPending || isResolved ? bidData : projectData).projectTeam,
              },
              { keepDefaultValues: true }
            );

            setIsShowAdditionalFields(!!bidData.bidURL);
            setBidLoaded(true);
            setShowProcessing(false);
          } catch (error) {
            console.error("Error loading previous bid data:", error);
            notification.error({ message: 'Error loading previous bid data' });
            setShowProcessing(false);
          }
        })();
      } else {
        // Create new bid from project data
        try {
          // Determine status based on project data
          const isResolved =
            terminalStatuses.includes(jobStatusCodesCode) ||
            activePlusStatuses.includes(jobStatusCodesCode);
            
          const isPending =
            (!isResolved && projectData.bidSubmittedDate !== null) ||
            pendingStatuses.includes(jobStatusCodesCode);

          setIsPending(isPending);
          setIsResolved(isResolved);

          // Initialize form with project data
          form.reset(
            {
              ...defaultValues,
              bidStart: formatDateObjectOrNull(projectData.bidStart),
              bidDue: formatDateObjectOrNull(projectData.bidDue),
              ...((isPending || isResolved) && {
                bidSubmittedDate: formatDateObjectOrNull(projectData.bidSubmittedDate),
              }),
              estData: initialEstData(),
              startDate: formatDateObjectOrNull(projectData.startDate),
              endDate: formatDateObjectOrNull(projectData.endDate),
              projectTeam: isPending ? {} : projectData.projectTeam,
              createEstimate: false,
            },
            { keepDefaultValues: true }
          );

          setIsShowAdditionalFields(false);
          setBidLoaded(true);
          setShowProcessing(false);
        } catch (error) {
          console.error("Error initializing new bid:", error);
          notification.error({ message: 'Error initializing new bid' });
          setShowProcessing(false);
        }
      }
    }
  }, [
    dispatch, 
    isOpen, 
    jobScopesRef, 
    projectData, 
    projectId, 
    bidId, 
    form, 
    defaultValues,
    initialEstData,
    pendingStatuses,
    terminalStatuses,
    activePlusStatuses,
    jobStatusCodesCode,
    isPending,
    isResolved,
    isPromote
  ]);

  // Handle PDF generation and email sending
  const handleSendPdf = useCallback(async () => {
    //setIsEmailModalOpen(true);
    setIsPdfModalOpen(false);

    const emailSubject = `Bid for ${projectData.projectName || 'Project'}`;
    const emailBody = `Please find attached the bid for ${projectData.projectName || 'your project'}.`;

    const sendEmailData = {
      to: clientData
        .filter((client) => client?.primaryEmail?.email?.length > 0)
        .map((client) => ({
          value: client.primaryEmail.email,
          label: client.primaryEmail.email,
          contactId: client.contactId,
          companyId: client.companyId,
          _id: client._id
        })),
      subject: emailSubject,
      body: emailBody,
      attachments: [{ fileName: pdfFileName, url: pdfUrl }]
    };

    try {
      const result = await dispatch(
        createEstimateEmail({
          projectId: projectId,
          pdfUrl: pdfUrl,
          bidId: projectBidData._id,
          emailData: sendEmailData,
          type: 'bid'
        })
      ).unwrap();

      if (result.emailId) {
        const url = `/project-details/${result.projectId}/email/edit/${result.emailId}`;
        history.push(url);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      notification.error({ message: 'Error sending email' });
    }
  }, [projectData, projectId, projectBidData._id, clientData, pdfUrl, pdfFileName, dispatch, history]);

  // Handle form submission with different actions
  const onFormSubmit = useCallback((markAction: string, shouldSave = true) => {
    // Log form errors for debugging
    if (form.formState.errors && Object.keys(form.formState.errors).length > 0) {
      console.log("Form validation errors:", form.formState.errors);
    }

    return form.handleSubmit(
      async (data) => {
        // Don't modify locked forms
        if (isLocked && shouldSave) {
          return;
        }

        // Validate estData
        const estData = data.estData ?? [];
        if (estData.length === 0) {
          notification.error({ message: 'Please add a line item to bid!' });
          return;
        }

        setShowProcessing(true);
        const shouldGeneratePdf = markAction === 'CreatePdf';

        // Prepare bid data object
        const bidData = {
          projectId,
          bidId: data.bidId,
          bidInternalId: data.bidInternalId,
          estTotalCost: +data.estTotalCost,
          estTotalProfit: +data.estTotalProfit,
          estTotalContract: +data.estTotalContract,
          comment: data.comment,
          exclusions: data.exclusions,
          bidDue: data.bidDue,
          bidStart: data.bidStart,
          bidSubmittedDate: data.bidSubmittedDate,
          startDate: data.startDate,
          endDate: data.endDate,
          bidCount: data.bidCount,
          bidRev: data.bidRev,
          bidURL: data.bidURL,
          pendingComment: data.pendingComment,
          resolvedComment: data.resolvedComment,
          pendingReasonCodeId: data.pendingReasonCodeId,
          resolvedReasonCodeId: data.resolvedReasonCodeId,
          isPending: isPending,
          isResolved: isResolved,
          estData: formatEstData(estData, false),
          createEstimate: data.createEstimate,
          projectTeam: data.projectTeam,
          wasAccepted: data.wasAccepted,
          acceptedDate: data.acceptedDate,
          acceptedBy: data.acceptedBy,
          shouldGeneratePdf: shouldGeneratePdf,
        };

        if (shouldSave) {
          try {
            // Save bid
            const result = await dispatch(createUpdateProjectBid({ ...bidData })).unwrap();

            if (!result._id) {
              notification.error({ message: 'Error saving bid' });
              setShowProcessing(false);
              return;
            }

            // Refresh project data
            await dispatch(getExistingProjectAction({ projectId }));
            await dispatch((getProjectBid as any)({ projectId, bidId: result._id }));

            // Handle PDF generation
            setPdfUrl(result.pdfUrl || '');
            setPdfFileName(result.pdfFileName || '');

            if (markAction === 'CreatePdf' && result.pdfUrl?.length > 0) {
              // Prepare email data
              const clientEmail = clientData[0]?.primaryEmail?.email || '';
              const emailSubject = `Bid for ${projectData.projectName || 'Project'}`;
              const emailBody = `Please find attached the bid for ${projectData.projectName || 'your project'}.`;

              setEmailData({
                to: [{ value: clientEmail, label: clientEmail }],
                subject: emailSubject,
                body: emailBody,
                attachments: [{ fileName: result.pdfFileName, url: result.pdfUrl }]
              });

              setIsPdfModalOpen(true);
            } else if (markAction === 'CreatePdf') {
              // Error handling for PDF generation
              notification.error({ message: 'Estimate saved, but error generating PDF' });
              setPdfUrl('');
              setPdfFileName('');

              if (shouldSave) {
                onSubmit();
              }

              setShowProcessing(false);
              return;
            }
          } catch (error) {
            console.error("Error saving bid:", error);
            notification.error({ message: 'Error saving bid' });
            setShowProcessing(false);
            return;
          }
        } else {
          setPdfUrl('');
          setPdfFileName('');
        }

        // Reset form and close modal
        form.reset({ ...defaultValues });
        toggle();

        if (shouldSave) {
          onSubmit();
        }

        setShowProcessing(false);

        // Handle status update if requested
        if (markAction?.length > 0 && markAction !== 'none' && !shouldGeneratePdf) {
          await dispatch(getExistingProjectAction({ projectId }));
          toggleStatusUpdate(markAction);
        }
      },
      (e) => {
        // Handle validation errors by expanding relevant sections
        const bidScheduleFields = ['bidStart', 'bidDue', 'bidSubmittedDate'];
        const projectScheduleFields = ['startDate', 'endDate'];
        const fieldsWithError = Object.keys(e);

        if (_.intersection(bidScheduleFields, fieldsWithError).length > 0) {
          bidScheduleRef.current?.setIsExpanded(true);
        }
        
        if (_.intersection(projectScheduleFields, fieldsWithError).length > 0) {
          projectScheduleRef.current?.setIsExpanded(true);
        }
      }
    );
  }, [
    form, 
    isLocked, 
    projectId, 
    isPending, 
    isResolved, 
    projectData, 
    clientData, 
    dispatch, 
    onSubmit, 
    toggle, 
    toggleStatusUpdate, 
    defaultValues, 
    projectBidData
  ]);

  // Filter bid roles by type
  const internalBidRoles = useMemo(() => {
    return projectRoles.filter((role: any) => role.type === 'internal');
  }, [projectRoles]);

  const externalBidRoles = useMemo(() => {
    return projectRoles.filter((role: any) => role.type === 'external');
  }, [projectRoles]);

  // Determine if past bids can be edited
  const canEditPastBid = useMemo(() =>
    (isPending || ((isResolved || !isBidPredefined) && (isAdmin || true))) && isLocked,
  [isPending, isResolved, isBidPredefined, isAdmin, isLocked]);

  // Create layout components with memoization
  const HorizontalLayoutCustom = useCallback(
    (props: any) => <HorizontalLayout {...props} labelWidth={6} controlWidth={4} />,
    []
  );

  const HorizontalLayoutInline = useCallback(
    (props: any) => <HorizontalLayout {...props} inline labelWidth={0} controlWidth={12} />,
    []
  );

  interface ModalHeaderProps {
    toggleFn: () => void;
    toggleFillTemplate: () => void;
    isLocked: boolean;
  }

  // Modal header component
  const ModalHeader: React.FC<ModalHeaderProps> = useCallback(({ toggleFn, toggleFillTemplate, isLocked }) => {
    return (
      <div className='modal-header'>
        <h5 className='modal-title'>{isLocked ? 'View' : 'Edit'} bid information</h5>
        <div className='ms-auto align-right'>
          {!appConstants.IS_PRODUCTION && (
            <span
              onClick={toggleFillTemplate}
              className='ms-auto px-2 py-1'
            >
              <i className='fa fa-file-text-o' />
            </span>
          )}
          <button className='btn-close' aria-label='Close' onClick={toggleFn} />
        </div>
      </div>
    );
  }, []);

  // Toggle fill template modal
  const toggleFillTemplate = useCallback(() => {
    setFillTemplateOpen(!fillTemplateOpen);
  }, [fillTemplateOpen]);

  // Memoize validation error message
  const validationError = useMemo(() => {
    const errors = [...Object.values(form.formState.errors)];
    return errors.length > 0 ? <span className='text-danger'>{errors[0]?.message as string}</span> : null;
  }, [form.formState.errors]);

  // Memoize dropdown actions
  const biddingActions = useCallback(() => {
    return (
      <>
        <ul className='dropdown-menu ml-auto'>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('CreatePdf')}>
              Save & Send to Client
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('Submitted')}>
              Save & Mark Submitted
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('DirectPromote')}>
              Save & Mark as Selected/Awarded
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('DirectLost')}>
              Save & Mark as Lost
            </a>
          </li>
        </ul>
      </>
    );
  }, [onFormSubmit]);

  const pendingActions = useCallback((shouldSave = false) => {
    return (
      <>
        <ul className='dropdown-menu ml-auto'>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('Approved', shouldSave)}>
              {shouldSave ? 'Save & ' : ''} Mark as Selected/Awarded
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('Rebid', shouldSave)}>
              {shouldSave ? 'Save & ' : ''} Mark as Rebid
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('Lost', shouldSave)}>
              {shouldSave ? 'Save & ' : ''} Mark as Lost
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('Rescinded', shouldSave)}>
              {shouldSave ? 'Save & ' : ''} Mark as Rescinded
            </a>
          </li>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={onFormSubmit('On Hold', shouldSave)}>
              {shouldSave ? 'Save & ' : ''} Mark as On Hold
            </a>
          </li>
        </ul>
      </>
    );
  }, [onFormSubmit]);

  return (
    <>
      <Modal
        backdrop='static'
        isOpen={isOpen}
        toggle={toggle}
        size={detailedMode ? 'xl' : 'lg'}
      >
        <ModalHeader toggleFn={toggle} toggleFillTemplate={toggleFillTemplate} isLocked={isLocked} />
        <ModalBody>
          <div className='container'>
            <FormProvider {...form}>
              {(!bidLoaded) ? (
                <ReactLoading
                  className='table-loader'
                  color={appConstants.LOADER_COLOR}
                  height={appConstants.LOADER_HEIGHT}
                  width={appConstants.LOADER_WIDTH}
                />
              ) : (
                <>
                  {showProcessing && (
                    <div className='loading_bg'>
                      <img className='ajax-loader' src={loaderImage} width='100' height='100' alt="Loading" />
                    </div>
                  )}
                  
                  <Header 
                    projectData={projectData} 
                    projectBidData={projectBidData} 
                    setClientsCallback={setClientData} 
                  />
                  
                  <BidScheduler
                    isPending={isPending}
                    isLocked={isLocked}
                    ref={bidScheduleRef}
                    accountSettings={accountSettings}
                    initiallyExpanded={bidScheduleExpanded || isPromote}
                  />
                  
                  <hr />
                  
                  {/* Description fields */}
                  <FormInput
                    label='Description / Inclusions'
                    placeholder='Enter a detailed description (optional)'
                    control={form.control}
                    name='comment'
                    textArea
                    disabled={isLocked}
                    Layout={HorizontalLayout}
                  />
                  
                  <FormInput
                    label='Exclusions'
                    placeholder='Enter work not included in this proposal'
                    control={form.control}
                    name='exclusions'
                    textArea
                    disabled={isLocked}
                    Layout={HorizontalLayout}
                  />
                  
                  {/* Conditional fields based on bid status */}
                  {(isPending || isResolved) && reasonCodeOptionsPending?.length > 0 && (
                    <FormSelect
                      label='Submitted Reason:'
                      control={form.control}
                      options={reasonCodeOptionsPending}
                      name='pendingReasonCodeId'
                      placeholder='Submitted Reason Code'
                      isClearable
                      disabled={isLocked}
                      Layout={HorizontalLayout}
                    />
                  )}
                  
                  {(isPending || isResolved) && (
                    <FormInput
                      label='Submitted Comment'
                      placeholder='Comment added when bid was submitted'
                      control={form.control}
                      name='pendingComment'
                      textArea
                      disabled={isLocked}
                      Layout={HorizontalLayout}
                    />
                  )}
                  
                  {isResolved && reasonCodeOptionsOutcome?.length > 0 && (
                    <FormSelect
                      label='Outcome Reason:'
                      control={form.control}
                      options={reasonCodeOptionsOutcome}
                      name='resolvedReasonCodeId'
                      placeholder='Outcome Reason Code'
                      isClearable
                      disabled={isLocked}
                      Layout={HorizontalLayout}
                    />
                  )}
                  
                  {isResolved && (
                    <FormInput
                      label='Outcome Comment'
                      placeholder='Comment added when bid was resolved'
                      control={form.control}
                      name='resolvedComment'
                      textArea
                      disabled={isLocked}
                      Layout={HorizontalLayout}
                    />
                  )}
                  
                  {isShowAdditionalFields && (
                    <FormInput
                      label='Bid URL'
                      placeholder='Enter bid URL'
                      control={form.control}
                      name='bidURL'
                      disabled={isLocked}
                      Layout={HorizontalLayout}
                    />
                  )}
                  
                  {/* Acceptance information */}
                  <div className={`row`}>
                    <div className='col-8'>
                      <FormCheckbox
                        name='wasAccepted'
                        label='Was Accepted&nbsp;'
                        control={form.control}
                        className={styles.control}
                        Layout={HorizontalLayoutCustom}
                        inputClassName='form-check-input-lg'
                        disabled={isLocked}
                      />
                    </div>
                    <div className='col-4'>
                      {wasAccepted && (
                        <FormDatePicker
                          label=''
                          name='acceptedDate'
                          placeholderText='Accepted Date'
                          control={form.control}
                          Layout={HorizontalLayoutInline}
                          disabled={isLocked}
                        />
                      )}
                    </div>
                  </div>
                  
                  {wasAccepted && (
                    <FormInput
                      label='Accepted By'
                      placeholder='Accepted By'
                      name='acceptedBy'
                      control={form.control}
                      Layout={HorizontalLayout}
                      disabled={isLocked}
                    />
                  )}
                  
                  {/* Toggle for additional fields */}
                  {!hasAdditionalData && (
                    <a
                      role='button'
                      className='d-flex justify-content-end'
                      onClick={() => setIsShowAdditionalFields(!isShowAdditionalFields)}
                    >
                      {isShowAdditionalFields ? 'Hide' : 'Show'} additional fields
                    </a>
                  )}
                  
                  {/* Project team fields */}
                  {(internalBidRoles?.length > 0 || externalBidRoles.length > 0) && (
                    <>
                      <hr />
                      {!isLocked && <div className='mb-3 fw-bold'>Team</div>}
                      <ProjectCompanyTeam
                        projectRoles={internalBidRoles}
                        isInternal
                        showHeader={false}
                        isUpdate={false}
                        disabled={isLocked}
                        formatAsTextWhenLocked={true}
                      />
                      <div className='mt-2' />
                      <ProjectCompanyTeam
                        projectRoles={externalBidRoles}
                        isInternal={false}
                        showHeader={false}
                        isUpdate={false}
                        disabled={isLocked}
                        formatAsTextWhenLocked={true}
                      />
                      {(isPending || isResolved) && !isLocked && (
                        <div className={styles.textHint}>
                          * changing roles on pending/past bids will not current affect project
                          assignments
                        </div>
                      )}
                    </>
                  )}
                  
                  {/* Project schedule and job scope line items */}
                  <ProjectScheduler
                    isLocked={isLocked}
                    projectStage={projectStage}
                    ref={projectScheduleRef}
                    accountSettings={accountSettings}
                  />
                  
                  <JobScopeLineItems
                    isLocked={isLocked}
                    projectData={projectData}
                    ref={jobScopesRef}
                    projectBidData={projectBidData}
                    toggleDetailedModeCallback={setDetailedMode}
                  />
                </>
              )}
            </FormProvider>
          </div>
        </ModalBody>
        
        <ModalFooter>
          {validationError}
          <button className='ms-auto btn btn-primary' onClick={toggle}>
            {isLocked ? 'Close' : 'Cancel'}
          </button>
          
          {!bidLoaded ? (
            <></>
          ) : (
            <>
              {/* Save button for bidding state */}
              {!isLocked && canSave && !isPending && showSaveSubmit && !isPromote && (
                <div className='btn-group'>
                  <button
                    className='btn btn-primary'
                    disabled={!canSave}
                    onClick={onFormSubmit('none')}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  {biddingActions()}
                </div>
              )}
              
              {/* Save button for pending state */}
              {!isLocked &&
                canSave &&
                isPending &&
                !isBidPredefined &&
                showSaveSubmit &&
                !isPromote && (
                  <div className='btn-group'>
                    <button
                      className='btn btn-primary'
                      disabled={!canSave}
                      onClick={onFormSubmit('none')}
                    >
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      type='button'
                      className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <span className='visually-hidden'>Toggle Dropdown</span>
                    </button>
                    {pendingActions(true)}
                  </div>
                )}
              
              {/* Unlock button for pending state */}
              {isLocked && isPending && !isBidPredefined && showSaveSubmit && (
                <div className='btn-group'>
                  <button className='btn btn-primary' onClick={() => setIsLocked(false)}>
                    {isSubmitting ? 'Loading...' : 'Unlock & Edit Pending Bid'}
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  {pendingActions()}
                </div>
              )}
              
              {/* Simple save button */}
              {((!showSaveSubmit && !isLocked) || isPromote) && (
                <button
                  className='btn btn-primary'
                  disabled={!canSave}
                  onClick={onFormSubmit('none')}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </button>
              )}
              
              {/* Unlock button for resolved bids */}
              {((isPending && isLocked && !isBidPredefined && !toggleStatusUpdate) ||
                (!showSaveSubmit && canEditPastBid)) && (
                  <button className='btn btn-primary' onClick={() => setIsLocked(false)}>
                    {isSubmitting
                      ? 'Loading...'
                      : isResolved
                        ? 'Unlock & Edit Resolved Bid'
                        : 'Unlock & Edit Pending Bid'}
                  </button>
                )}
            </>
          )}
        </ModalFooter>
      </Modal>
      
      {/* Template and PDF modals */}
      {fillTemplateOpen && projectData && (
        <FillTemplateModal
          open={fillTemplateOpen}
          toggle={toggleFillTemplate}
          objectId={bidInternalId}
          formType={'bid'}
        />
      )}
      
      <PdfPreviewModal
        isOpen={isPdfModalOpen}
        toggle={() => setIsPdfModalOpen(false)}
        pdfUrl={pdfUrl}
        onSend={handleSendPdf}
        title='Bid PDF Preview'
        onSendEmail={emailData}
      />
    </>
  );
};