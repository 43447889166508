import React, { useState, useRef, useEffect } from 'react';
import { formatMoney, currencyToNumber } from '../../../utils/number-formatters';

const CurrencyInput = ({ name, value, onChange, className = "form-control" }) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [displayValue, setDisplayValue] = useState(formatMoney(value));
  
  // Update the displayed value when the actual value changes
  useEffect(() => {
    setDisplayValue(formatMoney(value));
  }, [value]);
  
  // Restore cursor position after formatting
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [displayValue, cursorPosition]);
  
  const handleChange = (e) => {
    // Save cursor position before value changes
    const curPos = e.target.selectionStart;
    const oldVal = e.target.value;
    const newVal = e.target.value;
    
    // Count how many formatting characters were before the cursor
    const countFormatCharsBeforeCursor = (str, pos) => {
      const subStr = str.substring(0, pos);
      return (subStr.match(/[$,]/g) || []).length;
    };
    
    const formatCharsBefore = countFormatCharsBeforeCursor(oldVal, curPos);
    
    // Parse the value to a number
    const numValue = newVal ? parseFloat(newVal.replace(/[^0-9-.]+/g, '')) : 0;
    
    // Call the parent's onChange handler
    if (onChange) {
      onChange({
        target: {
          name,
          value: numValue
        }
      });
    }
    
    // Calculate where the cursor should be after formatting
    const formattedValue = formatMoney(numValue);
    const newFormatCharsBefore = countFormatCharsBeforeCursor(formattedValue, curPos);
    const formatCharDiff = newFormatCharsBefore - formatCharsBefore;
    
    // Set cursor position accounting for added/removed formatting chars
    setCursorPosition(Math.max(0, curPos + formatCharDiff));
    
    // Update the displayed value
    setDisplayValue(formattedValue);
  };
  
  return (
    <input
      ref={inputRef}
      type="text"
      className={className}
      name={name}
      value={displayValue}
      onChange={handleChange}
    />
  );
};

export default CurrencyInput;