import _ from 'lodash';
import { notification } from 'antd';
import { joiResolver } from '@hookform/resolvers/joi';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { getExistingProjectAction } from '../../actions/project';
import { getAccountProjectSettings } from '../../modules/accounts';
import {
  getUpdateProjectForm,
  getProjectOrigContract,
  updateProjectOrigContract,
  getProjectBid,
} from '../../modules/projects';
import {
  ManageContractTypeSchema,
  manageContractValidationSchema,
} from './manage-contract-modal.schema';
import { TopFields } from './sections/top-fields';
import { Header } from '../bid-modal/sections/header';
import { JobScopeLineItems } from '../bid-modal/sections/scope-estimates';
import { ProjectScheduler } from '../bid-modal/sections/project-scheduler';
import { formatDateObjectOrNull } from '../../utils/date-formatters';
import { formatEstData, calculateEstTotals } from '../../utils/number-formatters';
import {
  getProjectStageByCode,
  isCurrentOrPrevStage,
} from '../../shared/custom-fields-controls/constants';
import { BottomFields } from './sections/bottom-fields';
import { useWithPermissions } from '../../shared/with-permissions';
import { appConstants, moduleConstants } from '../../_constants';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
import ReactLoading from 'react-loading';
import loaderImage from '../../components/static/images/loading_spinner.gif';

type ManageContractModalProps = {
  projectId: string;
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (projectData: any) => void;
  onSuggestProgress?: (data: any) => void;
};

export const ManageContractModal = ({
  projectId = '',
  isOpen = false,
  toggle,
  onSubmit = () => null,
}: ManageContractModalProps) => {
  const dispatch = useAppDispatch();

  // Memoize default values to avoid recreating on every render
  const defaultValues = useMemo(() => ({
    title: '',
    contract: '',
    contractDate: null,
    primeContract: false,
    contractType: null,
    isLumpSum: false,
    retainage: 0,
    description: '',
    exclusions: '',
    comment: '',
    startDate: null,
    endDate: null,
    projectDuration: '',
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    estData: [],
    revNum: 0,
    createEstimate: false,
  }), []);

  // Component state
  const [projectData, setProjectData] = useState<any>({});
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [, setAccountSettings] = useState<any>({});
  const [origContractData, setOrigContractData] = useState<any>({});
  const [isActiveProject, setIsActiveProject] = useState<boolean>(false);
  const [contractLoaded, setContractLoaded] = useState(false);
  const [detailedMode, setDetailedMode] = useState(true);
  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);

  // Refs
  const jobScopesRef = useRef<any>(null);
  const projectScheduleRef = useRef<any>(null);

  // Permissions check
  const hasManageContractPermission = useWithPermissions({required: [moduleConstants.MANAGECONTRACT]}) || true;

  // Initialize form with React Hook Form
  const form = useForm<ManageContractTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(manageContractValidationSchema),
  });

  // Form watches and state
  const isSubmitting = form.formState.isSubmitting;
  const revNumWatch = form.watch('revNum', 0);

  // Build initial estData from project data
  const initialEstData = useCallback(() => {
    return (projectData.scope ?? [])
      .map((id: string, index: number) =>
        jobScopesRef.current?.buildScope(id, index === 0, false, projectData, origContractData)
      )
      .filter(Boolean);
  }, [projectData, origContractData, jobScopesRef]);

  // Load initial data when component mounts
  useEffect(() => {
    // Create a batch of promises to load all required data
    const loadDataPromises = [
      dispatch(getAllJobScopeAction({ className: 'jobScopes', includeDisabled: true })),
      dispatch(getAccountProjectSettings({}))
        .unwrap()
        .then((data: any) => setAccountSettings({ ...data }))
    ];

    // Execute all promises in parallel
    Promise.all(loadDataPromises).catch(error => {
      console.error("Error loading initial data:", error);
      notification.error({ message: 'Error loading initial data' });
    });
  }, [dispatch]);

  // Reset state and load project data when modal opens
  useEffect(() => {
    if (isOpen && !isSubmitting) {
      setShowProcessing(true);
      form.reset({ ...defaultValues });

      dispatch(getUpdateProjectForm({ projectId }))
        .unwrap()
        .then((data) => {
          setProjectData({
            ...data,
            currentGrossProfit: data.originalGrossProfit, // for totals
            currentContractAmount: data.originalContractAmount, // for totals
          });

          const { jobStatusCodesCode } = data;

          const projectStage = getProjectStageByCode(jobStatusCodesCode);
          const isActive = isCurrentOrPrevStage(projectStage, 'active');

          setIsLocked(isActive);
          setIsActiveProject(isActive);

          // Load appropriate data based on project stage
          if (isActive) {
            dispatch(getProjectOrigContract({ projectId }))
              .unwrap()
              .then((contractData) => {
                // if data is an object that is not empty, assign data
                if (_.isObject(contractData) && !_.isEmpty(contractData)) {
                  setOrigContractData({ ...contractData });
                } else {
                  // show notification if no data is returned
                  notification.error({ message: 'No contract data found' });
                }
                setShowProcessing(false);
              })
              .catch(error => {
                console.error("Error loading contract data:", error);
                notification.error({ message: 'Error loading contract data' });
                setShowProcessing(false);
              });
          } else if (data.curBidId || data.lastBidId) {
            dispatch(getProjectBid({ projectId, bidId: data.curBidId || data.lastBidId }))
              .unwrap()
              .then((bidData) => {
                const origDataFromBid = {
                  description: bidData.comment,
                  exclusions: bidData.exclusions,
                  baselineStartDate: bidData.estStartDate,
                  baselineEndDate: bidData.estEndDate,
                  estTotalCost: bidData.estTotalCost,
                  estTotalProfit: bidData.estTotalProfit,
                  estTotalContract: bidData.estTotalContract,
                  estData: formatEstData(bidData.estData),
                };

                setOrigContractData({ ...origDataFromBid });
                setShowProcessing(false);
              })
              .catch(error => {
                console.error("Error loading bid data:", error);
                notification.error({ message: 'Error loading bid data' });
                setShowProcessing(false);
              });
          } else {
            setShowProcessing(false);
          }
        })
        .catch(error => {
          console.error("Error loading project data:", error);
          notification.error({ message: 'Error loading project data' });
          setShowProcessing(false);
        });
    } else {
      // Reset state when modal closes
      setProjectData({});
      setOrigContractData({});
      setContractLoaded(false);
      form.reset({ ...defaultValues });
    }
  }, [isOpen, projectId, defaultValues, dispatch, isSubmitting, form]);

  // Initialize form with contract data
  useEffect(() => {
    if (isOpen && !_.isEmpty(projectData) && !isSubmitting && !contractLoaded) {
      const { contractDate = new Date(), estData = [] } = origContractData;
      const { cost, profit, amount } = calculateEstTotals(estData);

      const { baselineStartDate, baselineEndDate } = projectData;
      const currEstData = estData.length === 0 ? initialEstData() : estData;

      form.reset(
        {
          title: origContractData.title || '',
          contract: origContractData.contract || '',
          contractDate: formatDateObjectOrNull(contractDate),
          primeContract: !!origContractData.primeContract,
          contractType: origContractData.contractType || '',
          isLumpSum: !!origContractData.isLumpSum,
          retainage: origContractData.retainage || 0,
          description: origContractData.description || '',
          exclusions: origContractData.exclusions || '',
          comment: '', // always empty by default
          estTotalCost: cost,
          estTotalProfit: profit,
          estTotalContract: amount,
          estData: formatEstData(currEstData),
          startDate: formatDateObjectOrNull(baselineStartDate),
          endDate: formatDateObjectOrNull(baselineEndDate),
          revNum: origContractData.revNum,
          createEstimate: false,
        },
        { keepDefaultValues: true }
      );

      setContractLoaded(true);
    } else if (!isOpen) {
      setContractLoaded(false);
    }
  }, [isOpen, projectData, origContractData, projectId, isSubmitting, initialEstData, form]);

  // Toggle fill template modal
  const toggleFillTemplate = useCallback(() => {
    setFillTemplateOpen(!fillTemplateOpen);
  }, [fillTemplateOpen]);

  // Memoize validation error message
  const validationError = useMemo(() => {
    const errors = [...Object.values(form.formState.errors)];
    return errors.length > 0 ? <span className='text-danger'>{errors[0]?.message as string}</span> : null;
  }, [form.formState.errors]);

  // Handle form submission
  const onFormSubmit = useCallback(() => {
    console.log("Form submission started");

    if (form.formState.errors && Object.keys(form.formState.errors).length > 0) {
      console.log("Form validation errors:", form.formState.errors);
    }

    form.trigger().then(() => {
      console.log("Form triggered, proceeding with submission");

      form.handleSubmit(
        async (data) => {
          console.log("Form submitted successfully, data:", data);
          setShowProcessing(true);

          if (isLocked) {
            console.log("Form is locked, returning");
            setShowProcessing(false);
            return;
          }

          // Prepare data for submission
          const estData = data.estData.map(({ ...rest }) => rest);

          if (estData.length === 0) {
            console.log("No job scope added");
            notification.error({ message: 'Please add job scope' });
            setShowProcessing(false);
            return;
          }

          const { cost, profit, amount } = calculateEstTotals(estData);

          const projectOrigData = {
            projectId,
            title: data.title,
            contract: data.contract,
            contractDate: formatDateObjectOrNull(data.contractDate),
            primeContract: data.primeContract,
            contractType: data.contractType,
            isLumpSum: data.isLumpSum,
            retainage: data.retainage,
            description: data.description,
            exclusions: data.exclusions,
            comment: data.comment,
            baselineStartDate: data.startDate,
            baselineEndDate: data.endDate,
            originalCost: cost,
            originalGrossProfit: profit,
            originalContractAmount: amount,
            estData: formatEstData(estData, false),
            createEstimate: data.createEstimate,
          };

          try {
            await dispatch(updateProjectOrigContract({ ...projectOrigData })).unwrap();
            toggle();
            onSubmit(projectOrigData);
            await dispatch(getExistingProjectAction({ projectId }));
            setShowProcessing(false);
          } catch (error) {
            console.error("Error during form submission:", error);
            notification.error({ message: 'An error occurred while saving the contract' });
            setShowProcessing(false);
          }
        },
        (errors) => {
          console.log("Form validation failed, errors:", errors);
          notification.error({ message: 'Please fill out all required fields' });
          
          // Expand sections with errors
          const projectScheduleFields = ['startDate', 'endDate'];
          const fieldsWithError = Object.keys(errors);

          if (_.intersection(projectScheduleFields, fieldsWithError).length > 0) {
            projectScheduleRef.current?.setIsExpanded(true);
          }
          
          setShowProcessing(false);
        }
      )();
    }).catch((error) => {
      console.error("Error during form trigger:", error);
      setShowProcessing(false);
    });
  }, [dispatch, form, isLocked, onSubmit, projectId, toggle, projectScheduleRef]);

  // Mock account settings for project scheduler
  const accountSettingsMock = useMemo(() => ({ requireConstructionDatesOn: 'active' }), []);

  interface ModalHeaderProps {
    toggleFn: () => void;
    toggleFillTemplate: () => void;
    isLocked: boolean;
  }

  // Modal header component
  const ModalHeader: React.FC<ModalHeaderProps> = useCallback(({ toggleFn, toggleFillTemplate, isLocked }) => {
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {isLocked ? 'View' : 'Manage'} original contract
        </h5>
        <div className="ms-auto align-right">
          {!(appConstants.IS_PRODUCTION) && (
            <span
              onClick={toggleFillTemplate}
              className="ms-auto px-2 py-1"
            >
              <i className='fa fa-file-text-o'/>
            </span>
          )}
          <button className="btn-close" aria-label="Close" onClick={toggleFn} />
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <Modal backdrop='static' isOpen={isOpen} toggle={toggle} size={detailedMode ? 'xl' : 'lg'}>
        <ModalHeader toggleFn={toggle} toggleFillTemplate={toggleFillTemplate} isLocked={isLocked} />
        <ModalBody>
          <div className='container'>
            <FormProvider {...form}>
              {showProcessing && (
                <div className='loading_bg'>
                  <img className='ajax-loader' src={loaderImage} width='100' height='100' alt="Loading" />
                </div>
              )}
              
              {!contractLoaded && !showProcessing ? (
                <ReactLoading
                  className='table-loader'
                  color={appConstants.LOADER_COLOR}
                  height={appConstants.LOADER_HEIGHT}
                  width={appConstants.LOADER_WIDTH}
                />
              ) : (
                <>
                  <Header projectData={projectData} />
                  <br />
                  <TopFields isLocked={isLocked} />
                  <BottomFields isLocked={isLocked} isActiveProject={isActiveProject} />
                  <ProjectScheduler
                    isLocked={isLocked}
                    projectStage='active'
                    accountSettings={accountSettingsMock}
                    ref={projectScheduleRef}
                  />
                  <JobScopeLineItems
                    isLocked={isLocked}
                    projectData={projectData}
                    ref={jobScopesRef}
                    projectBidData={origContractData}
                    toggleDetailedModeCallback={setDetailedMode}
                    isChangeOrder={false}
                  />
                  <br />
                </>
              )}
            </FormProvider>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <span className='fw-bold'>Revision: {revNumWatch + 1}</span>
          </div>
          {validationError}
          <button className='ms-auto btn btn-primary' onClick={toggle}>
            {isLocked ? 'Close' : 'Cancel'}
          </button>
          {hasManageContractPermission && (
            isLocked ? (
              <button className='btn btn-primary' onClick={() => setIsLocked(false)}>
                {isSubmitting ? 'Loading...' : 'Unlock & Edit Contract'}
              </button>
            ) : (
              <button className='btn btn-primary' disabled={isSubmitting} onClick={onFormSubmit}>
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            )
          )}
        </ModalFooter>
      </Modal>
      {fillTemplateOpen && (
        <FillTemplateModal
          open={fillTemplateOpen}
          toggle={toggleFillTemplate}
          objectId={origContractData?.id || projectId}
          formType={'contract'}
        />
      )}
    </>
  );
};