import React, { Suspense } from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetProjectHistoryAction,
  GetProjectHistoryPrefAction,
  SetProjectHistoryPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import TitleComponent from '../../Common/TitleComponent';
import CustomTable from '../../Common/CustomTable';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import ChangeEventDateModal from '../../Dialogs/ChangeEventDateModal.jsx';
import { formatDateWithTime, formatDateObject, formatDate } from '../../../utils/date-formatters';
import { isAllowed, moduleConstants } from '../../../_constants';

import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { formatMoney, roundFormatMoney } from '../../../utils/number-formatters';


let jobAddressData;
let jobNumFormatted;
let jobName;

var sortingOrder = '';
var sortOrders = 'desc';

class ProjectHistoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      objectId: '',
      modal: false,
      fetchRequest: true,
      comLogo: '',
      isReportDD: true,
      isReportDDError: true,
      projectData: {},
      period: '',
      jobNumFormatted: null,
      jobName: null,
      eventDateModal: false,
      eventDateData: {},
      isUpdateStatus: false,
      isLoading: true,
      sortType: 'DESC',
      sortKey: '',
      noteModal: false,
      btnHide: true,
      projectRoleTypes: [],
      dynamicColumns: [],
    };
    this.toggle = this.toggle.bind(this);
    this.onJobStatus = this.onJobStatus.bind(this);
    this.onGetProjectDate = this.onGetProjectDate.bind(this);
    this.onComment = this.onComment.bind(this);
    this.onReasonCode = this.onReasonCode.bind(this);
    this.onChangeOrderNumber = this.onChangeOrderNumber.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 
    ) {
      this.setState({ projectData: nextProps.listData.data });
      if (
        nextProps.listData &&
        nextProps.listData.data &&
        nextProps.listData.data.projectActivities &&
        nextProps.listData.data.projectActivities.length > 0
      ) {
        this.setState({
          list: nextProps.listData.data.projectActivities,
          fetchRequest: false,
        });
      }
    }
  }

  componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;
    this.setState({ period: 'jobNumFormatted' });
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    const projectId = this.props?.match?.params?.objectId;

    if (projectId) {
      data['projectId'] = projectId;
      this.props.GetProjectHistoryAction(data);

      this.setState({ objectId: this.props?.location?.state?.projectData });
    }

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({ comLogo: localStorage.getItem('companyLogo') });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);

    this.props.GetProjectHistoryPrefAction();
  }

  toggle(val) {
    if (this.state.modal === true) {
      document.body.classList.remove('modal-open');
    }
    this.setState({ modal: !this.state.modal });
  }

  changeEventDate = (cell, row, rowIndex) => {
    this.setState((prevState) => ({
      eventDateModal: !prevState.eventDateModal,
      eventDateData: row,
    }));
  };

  filteredData(e) {
    e.preventDefault();
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['19'];

    if (this.state.period === 'jobNumFormatted') {
      data['projectId'] = this.state.jobNumObj.value;
      jobNumFormatted = this.state.jobNumObj.value;
    } else if (this.state.period === 'jobName') {
      data['projectId'] = this.state.jobNameObj.value;
      jobName = this.state.jobNameObj.value;
    }

    data['page'] = 0;
    data['displayLimit'] = 10;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;

    this.toggle();
    this.props.GetProjectHistoryAction(data);
  }

  handleChangePeriod(val) {
    this.setState({ period: val });
  }

  handleChangeJobNum(name, value) {
    let jobNumObj = this.state.jobNumObj;
    jobNumObj = value;
    this.setState({ jobNumObj });
  }

  handleChangeJobName(name, value) {
    let jobNameObj = this.state.jobNameObj;
    jobNameObj = value;
    this.setState({ jobNameObj });
  }

  onEventDate(cell, row, enumObject, rowIndex) {
    if (row && row.eventDate) {
      return (
        <a onClick={() => this.changeEventDate(cell, row, rowIndex)}>
          {formatDate(row.eventDate.iso)}
        </a>
      );
      //return formatDate(row.eventDate.iso);
    } else {
      return <span>-</span>;
    }
  }

  onJobStatus(cell, row, enumObject, rowIndex) {
    return row && row.jobStatusCodesName ? row.jobStatusCodesName : '-';
  }

  onProfitStatus(cell, row, enumObject, rowIndex) {
    let data = '';
    data =
      row && row.currentGrossProfit
        ? roundFormatMoney(row.currentGrossProfit)
        : 0;
    return <div className='textRight'>{data}</div>;
  }

  onCurrencyAmount(fieldName, cell, row, enumObject, rowIndex) {
    let data = row && row[fieldName] ? roundFormatMoney(row[fieldName]) : 0;
    return <div className='textRight'>{data}</div>;
  }

  onGetProjectDate(dateName, row) {
    if (row && typeof row.endDate === 'object') {
      return row[dateName] && row[dateName]?.iso
        ? formatDate(row[dateName].iso)
        : row[dateName]
        ? formatDate(row[dateName])
        : '';
    } else {
      return <span>-</span>;
    }
  }

  onComment(cell, row, enumObject, rowIndex) {
    // let data = row && row.comment ? row.comment : '-';
    // return data;

    const comment =
      row &&
      row.comment &&
      row.comment !== undefined &&
      row.comment.replace(/<[^>]+>/g, '').length > 25
        ? `${row.comment.substring(0, 25)}...`
        : row.comment;

    return row && row.comment ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(comment)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let rowData = row && row.comment ? row.comment : '-';
    this.setState({ noteModal: true, noteData: rowData });
  }

  onReasonCode(cell, row, enumObject, rowIndex) {
    if ((row && row.statusReasonCode) || row.startDateReasonCode || row.endDateReasonCode || row.changeOrderReasonCode) {

      return (
        <p>
           {row.changeOrderReasonCode && (
            <span>
              <strong> CO#{row.changeOrderNumber?.toString()}: </strong> {row.changeOrderReasonCode}
            </span>
          )}
          {row.statusReasonCode && (
            <span>
              <strong>Status:</strong>
              {row.statusReasonCode}
              <br />
            </span>
          )}
          {row.startDateReasonCode && (
            <span>
              <strong> Start Date:</strong> {row.startDateReasonCode}
              <br />{' '}
            </span>
          )}
          {row.endDateReasonCode && (
            <span>
              <strong> End Date: </strong> {row.endDateReasonCode}
            </span>
          )}
         
        </p>
      );
    } else {
      return <span>-</span>;
    }
  }

  onChangeOrderNumber(cell, row, enumObject, rowIndex) {
    const coNum = row?.changeOrderNumber>0 ? 'CO#'+row.changeOrderNumber.toString()+ ':': '';
    return [coNum].join(' ');
  }

  projectHistoryReportHeader = (props) => {
    const projectAssignments = this.state.projectData?.projectAssignments;
    const contactData = this.state.projectData?.contactData;

    const projectManagerRoleName = this.state.projectRoleTypes.find(
      (role) => role.code == 'PM'
    )?.name;
    const supervisorRoleName = this.state.projectRoleTypes.find((role) => role.code == 'SI')?.name;

    const projectManagerNames =
      projectAssignments &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'PM'),
        contactData,
        false,
        false
      );
    const supervisorNames =
      contactData &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'SI'),
        contactData,
        false,
        false
      );

    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Project History Report</h2>
          <div className='row searchbar'>
            <div className='col-md-6 pdfAlignInline'>
              <div className='field-row'>
                <div className='label-name'>Job Name:</div>
                <div className='projectHistoryJob ph-field'>{this.state.projectData.jobName}</div>
              </div>
              <div className='field-row'>
                <div className='label-name'>Job Address:</div>
                <div className='ph-field'>{jobAddressData}</div>
              </div>
            </div>
            <div className='col-md-6 pdfAlignInline last'>
              <div className='text-right'>
                <div className='field-row '>
                  <div className='label-name'>Job Number:</div>
                  <div className='ph-field'>{this.state.projectData.jobNumFormatted}</div>
                </div>
                <div className='field-row '>
                  <div className='label-name'>Alt Job Number:</div>
                  <div className='ph-field'>{this.state.projectData.altJobNum}</div>
                </div>
                {projectManagerRoleName?.length > 0 && (
                  <div className='field-row'>
                    <div className='label-name'>{projectManagerRoleName}:</div>
                    <div className='ph-field'>{projectManagerNames}</div>
                  </div>
                )}
                {supervisorRoleName?.length > 0 && (
                  <div className='field-row'>
                    <div className='label-name'>{supervisorRoleName}:</div>
                    <div className='ph-field'>{supervisorNames}</div>
                  </div>
                )}
                <div className='field-row'>
                  <div className='label-name'>Project Size (Sq. Feet):</div>
                  <div className='ph-field'>{this.state.projectData.sqFeet}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onChangePerson(cell, row, enumObject, rowIndex) {
    if (row && typeof row.eventChangeByUser === 'object') {
      let data = row.eventChangeByUser;
      if (data.contactId) {
        return (
          <span>
            <Link to={{ pathname: `/contact-details/` + data.contactId }}>
              {data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : '-'}
            </Link>
          </span>
        );
      } else {
        return (
          <span>
            {data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : '-'}
          </span>
        );
      }
    } else {
      return <span>-</span>;
    }
  }

  buildReportColumns() {

    const projectAssignments = this.state.projectData?.projectAssignments;

    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'assigned', projectAssignments);
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'assigned', projectAssignments);



    const columns = [
      {
        Header: 'Event Date',
        accessor: (row) =>
          row?.eventDate?.iso
            ? new Date(row.eventDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'eventDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('eventDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 90,
        Cell: (props) => this.onJobStatus(props.column.id, props.row.original, props.value),
      },
      hasContractValPermissions&&{
        Header: 'Original Contract Amount',
        accessor: 'originalContractAmount',
        width: 110,
        Cell: (props) =>
          this.onCurrencyAmount(
            'originalContractAmount',
            props.column.id,
            props.row.original,
            props.value
          ),
      },
      hasContractValPermissions&&{
        Header: 'Current Contract Amount',
        accessor: 'currentContractAmount',
        width: 110,
        Cell: (props) =>
          this.onCurrencyAmount(
            'currentContractAmount',
            props.column.id,
            props.row.original,
            props.value
          ),
      },
      hasProfitValPermissions&&{
        Header: 'Original Contract Profit',
        accessor: 'originalGrossProfit',
        width: 90,
        hidden: true,
        Cell: (props) =>
          this.onCurrencyAmount(
            'originalGrossProfit',
            props.column.id,
            props.row.original,
            props.value
          ),
      },
      
      hasProfitValPermissions&&{
        Header: 'Current Contract Profit',
        accessor: 'currentContractProfit',
        width: 90,
        Cell: (props) =>
          this.onCurrencyAmount(
            'currentContractProfit',
            props.column.id,
            props.row.original,
            props.value
          ),
      },
      hasProfitValPermissions&&{
        Header: 'Actual/Projected Profit',
        accessor: 'currentGrossProfit',
        width: 90,
        Cell: (props) =>
          this.onCurrencyAmount(
            'currentGrossProfit',
            props.column.id,
            props.row.original,
            props.value
          ),
      },
      {
        Header: 'Bid Date',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'bidDue',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('bidDue', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: true,
      },
      {
        Header: 'Original Contract Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'baselineStartDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('baselineStartDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Actual/Projected Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('startDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Original Contract End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'baselineEndDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('baselineEndDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: false,
      },
      {
        Header: 'Current Contract End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'currentBaselineEndDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('currentBaselineEndDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: false,
      },
      {
        Header: 'Actual/Projected End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('endDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: false,
      },
      
      
      {
        Header: 'Delivered Date',
        accessor: (row) =>
          row?.deliveredDate?.iso
            ? new Date(row.deliveredDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'deliveredDate',
        width: 90,
        Cell: (props) => <>{this.onGetProjectDate('deliveredDate', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: true,
      },
      
      
      
      {
        Header: 'Changed Date',
        accessor: (row) =>
          row?.updatedAt?.iso
            ? new Date(row.updatedAt.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'createdAt',
        width: 110,
        Cell: (props) => <>{this.onGetProjectDate('createdAt', props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Changed By',
        id: 'eventChangeByUser',
        width: 90,
        Cell: (props) => (
          <>{this.onChangePerson(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Reason Codes',
        accessor: (row) => {
          if (row?.statusReasonCode || row?.startDateReasonCode || row?.endDateReasonCode || row?.changeOrderReasonCode) {
            let rowReason = 'Status: ' + (row.statusReasonCode || 'NA');
            let rowStartDate = 'Start Date: ' + (row.startDateReasonCode || 'NA');
            let rowEndDate = 'EndDate: ' + (row.endDateReasonCode || 'NA');
            let rowCOReason = 'CO Reason: ' + (row.changeOrderReasonCode || 'NA');

            return [rowReason, rowStartDate, rowEndDate, rowCOReason].join(',');
          }

          return '';
        },
        id: 'statusReasonCode',
        width: 110,
        Cell: (props) => <>{this.onReasonCode(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'CO Number',
        accessor: 'changeOrderNumber',
        width: 90,
        Cell: (props) => (
          <>{this.onChangeOrderNumber(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        width: 200,
        Cell: (props) => <>{this.onComment(props.column.id, props.row.original, props.value)}</>,
      },
    ];

    this.setState({ dynamicColumns: columns.filter(Boolean) });
  }

  render() {
    const { btnHide, list, dynamicColumns } = this.state;

    const { listPref, SetProjectHistoryPrefAction } = this.props;

    let data = this.state.projectData;
    if (data) {
      jobAddressData =
        (data.jobAddress ? data.jobAddress : '') +
        (data.jobAddress ?', ' : '') +
        (data.jobAddress2 ? data.jobAddress2 : '') +
        (data.jobAddress2 ?', ' : '');
      jobAddressData +=
        (data.jobCity ? data.jobCity : '') +
        (data.jobCity ?', ' : '') +
        (data.jobState ? data.jobState : '') +
        (data.jobState ?', ' : '') +
        (data.jobZip ? data.jobZip : '');
    } else {
      jobAddressData = '-';
    }

   
    let row = this.state.projectData;

    const projectAssignments = this.state.projectData?.projectAssignments;
    
    const contactData = this.state.projectData?.contactData;

    const projectManagerRoleName = this.state.projectRoleTypes.find(
      (role) => role.code == 'PM'
    )?.name;
    const supervisorRoleName = this.state.projectRoleTypes.find((role) => role.code == 'SI')?.name;

    const projectManagerNames =
      projectAssignments &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'PM'),
        contactData,
        false,
        false
      );
    const supervisorNames =
      contactData &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'SI'),
        contactData,
        false,
        false
      );

    
      const dynamicFieldsLoaded =true;
    
      const dynamicColumnsBuilt = dynamicFieldsLoaded && dynamicColumns.length > 0;
  
      if(dynamicFieldsLoaded && !dynamicColumnsBuilt){
        this.buildReportColumns();
      }
  
  

    return (
      <div>
        <Suspense fallback='loading'>
          <div className='ContactListReport'>
            <TitleComponent data={{ title: `Project History Report` }} />
            <div>
              <Header props={this.props} />
            </div>
            <div className='contactBox'>
              <div className='container'>
                <div className='no-print no-break-print'>
                  <div className='reportHead'>
                    <h2>Project History Report</h2>
                  </div>

                  <div className='row searchbar no-print'>
                    <div className='col-md-6'>
                      <div className='field-row'>
                        <div className='label-name'>Job Name:</div>
                        <div className='projectHistoryJob ph-field'>
                          <Link
                            to={{
                              pathname: `/project-details/` + row.objectId,
                              state: { row },
                            }}
                          >
                            {row.jobName ? row.jobName : '-'}
                          </Link>
                        </div>
                      </div>
                      <div className='field-row'>
                        <div className='label-name'>Job Address:</div>
                        <div className='ph-field'>{jobAddressData}</div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='text-right'>
                        <div className='field-row '>
                          <div className='label-name'>Job Number:</div>
                          <div className='ph-field'>
                            <Link
                              to={{
                                pathname: `/project-details/` + row.objectId,
                                state: { row },
                              }}
                            >
                              {this.state.projectData.jobNumFormatted
                                ? this.state.projectData.jobNumFormatted
                                : '-'}
                            </Link>
                          </div>
                        </div>
                        <div className='field-row '>
                          <div className='label-name'>Alt Job Number:</div>
                          <div className='ph-field'>
                            <Link
                              to={{
                                pathname: `/project-details/` + row.objectId,
                                state: { row },
                              }}
                            >
                              {this.state.projectData.altJobNum
                                ? this.state.projectData.altJobNum
                                : '-'}
                            </Link>
                          </div>
                        </div>
                        {projectManagerRoleName?.length > 0 && (
                          <div className='field-row'>
                            <div className='label-name'>{projectManagerRoleName}:</div>
                            <div className='ph-field'>{projectManagerNames}</div>
                          </div>
                        )}
                        {supervisorRoleName?.length > 0 && (
                          <div className='field-row'>
                            <div className='label-name'>{supervisorRoleName}:</div>
                            <div className='ph-field'>{supervisorNames}</div>
                          </div>
                        )}
                        <div className='field-row'>
                          <div className='label-name'>Project Size (Sq. Feet):</div>
                          <div className='ph-field'>{this.state.projectData.sqFeet}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row no-break-print'>
                  <div className='col-md-12'>
                    {dynamicColumnsBuilt&&(<div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.projectHistoryReportHeader()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='project-history-table'
                        onSavePreferences={SetProjectHistoryPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
            {this.state.noteModal && (
              <NotePopUp
                isOpen={this.state.noteModal}
                onClick={this.onNoteModalClose}
                noteInfo={this.state.noteData}
              />
            )}
            <Footer />
          </div>


          <ChangeEventDateModal
            isOpen={this.state.eventDateModal}
            toggle={this.changeEventDate}
            eventDateData={this.state.eventDateData}
          />
        </Suspense>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.projectHistory,
    listPref: state.reports.projectHistoryPref,
    isUpdateStatus: state.project.isUpdateStatus,
    eventDateMessage: state.project.message,
    eventDateSuccess: state.project.success,
    projectRoles: state.projectRoleType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetProjectHistoryAction,
    GetProjectHistoryPrefAction,
    SetProjectHistoryPrefAction,
    getAllProjectRoleAction,
  })(ProjectHistoryReport)
);
