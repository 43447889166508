import { useEffect, useMemo, useState, useCallback } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSubmit } from '../../shared/hooks/use-submit';
import { createChangeOrderAsync, viewChangeOrdersAsync } from '../../modules/orders';
import { findContactAffiliations, userRolesDropdown } from '../../modules/contacts';

import loaderImage from '../../components/static/images/loading_spinner.gif';
import {
  ChangeOrderModalTypeSchema,
  changeOrderModalValidationSchema,
} from './change-order-modal.schema';
import { ChangeOrderFields } from './sections/change-order-fields';
import {
  formatDateUTC,
  formatDateObject,
  formatDateObjectOrNull,
} from '../../utils/date-formatters';
import { formatEstData } from '../../utils/number-formatters';
import styles from './change-order-modal.module.scss';
import _ from 'lodash';
import { useWithPermissions } from '../../shared/with-permissions';
import { appConstants, moduleConstants } from '../../_constants';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
import PdfPreviewModal from '../pdf-preview-modal/pdf-preview-modal';
import { useHistory } from 'react-router-dom';
import { createEstimateEmail } from '../../modules/projects';
import { getUserSettings } from '../../modules/settings';
import { selectAllJobScopes } from '../../selectors/jobScope';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { notification } from 'antd';

type ChangeOrderModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSubmit?: (...args) => Promise<any>;
  project: any;
  coId?: string;
};

export const ChangeOrderModal = ({
  isOpen = false,
  toggle,
  onSubmit: onSubmitCb = () => Promise.resolve(),
  project = {},
  coId = '',
}: ChangeOrderModalProps) => {
  // Memoize default values to avoid recreating on every render
  const defaultValues = useMemo(() => ({
    coNumber: null,
    coCostAmount: 0,
    profitDollars: 0,
    coTotalAmount: 0,
    comment: '',
    changeOrderReason: '',
    exclusions: '',
    effectiveDate: null,
    scheduleImpact: 0,
    wasAccepted: false,
    acceptedDate: null,
    acceptedBy: '',
    existingChangeOrders: [],
    scopeArr: [],
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    estData: [] as any[],
    createEstimate: false,
    qbEstimateId: '',
    shouldGeneratePdf: false,
    pdfUrl: '',
  }), []);

  // Initialize form with React Hook Form
  const form = useForm<ChangeOrderModalTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(changeOrderModalValidationSchema),
  });

  // Component state
  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>('');
  const [showProcessing, setShowProcessing] = useState(false);
  const [changeOrders, setChangeOrders] = useState<Array<any>>([]);
  const [currentCO, setCurrentCO] = useState<any>({
    ...defaultValues,
    coCostAmount: 0,
    profitDollars: 0,
    coTotalAmount: 0,
  });
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>(project?.objectId ?? '');
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFileName, setPdfFileName] = useState('');
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [clientData, setClientData] = useState<any[]>([]);
  const [emailData, setEmailData] = useState({});
  const [nextBestCO, setNextBestCO] = useState<number>(1);
  const [clientRoleId, setClientRoleId] = useState<string>('');
  const [projectTeamClientArr, setProjectTeamClientArr] = useState<string[]>([]);
  const [detailedMode, setDetailedMode] = useState(true);
  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  
  // Access hooks and selectors
  const history = useHistory();
  const dispatch = useAppDispatch();
  const allJobScopes = useAppSelector(selectAllJobScopes);
  const allowedChangeApproved = useWithPermissions({ required: [moduleConstants.MANAGECONTRACT] });

  // Form watches
  const wasAccepted = form.watch('wasAccepted');
  const submitting = form.formState.isSubmitting;

  // Update change order ID when coId prop changes
  useEffect(() => {
    if (coId?.length > 0) {
      setCurrentChangeOrderId(coId);
    } else {
      setCurrentChangeOrderId('');
    }
  }, [coId]);

  // Update project ID when project prop changes
  useEffect(() => {
    if (project?.objectId?.length > 0) {
      setProjectId(project.objectId);
    }
  }, [project.objectId]);

  // Load project roles and client info
  useEffect(() => {
    const loadRoles = async () => {
      try {
        dispatch(userRolesDropdown());
        const roles = await dispatch(getUserSettings(['projectRoleTypes'])).unwrap();
        const clRoleId = roles.find((role: any) => role.code === 'CL')?._id || '';
        setClientRoleId(clRoleId);
      } catch (error) {
        console.error("Error loading roles:", error);
        notification.error({ message: 'Error loading roles' });
      }
    };
    
    loadRoles();
  }, [dispatch]);

  // Identify client team members
  useEffect(() => {
    if (project?.userAssignData?.length > 0 && clientRoleId) {
      const clientAffsIds = project.userAssignData
        .filter((team) => team.projectRoleId === clientRoleId)
        .map((team) => team.contactAffiliationId);
      setProjectTeamClientArr(clientAffsIds);
    }
  }, [clientRoleId, project?.userAssignData, project?.objectId]);

  // Load client contact data
  useEffect(() => {
    const fetchClients = async () => {
      const clientAffsIds = projectTeamClientArr || [];

      if (clientAffsIds.length > 0) {
        try {
          const clientsData = await dispatch(
            findContactAffiliations({
              criteria: `${clientAffsIds.join(' ')}`,
              page: 1,
              count: clientAffsIds.length,
              isAccount: null,
              includeDeleted: true,
              fields: ['_id'],
            })
          ).unwrap();

          setClientData(clientsData);
        } catch (error) {
          console.error("Error fetching clients:", error);
          notification.error({ message: 'Error fetching client data' });
        }
      }
    };

    fetchClients();
  }, [dispatch, projectTeamClientArr]);

  // Load change orders when modal opens
  useEffect(() => {
    if (isOpen && projectId?.length > 0) {
      setIsLocked(!isNew && !currentCO.isPCO);

      const loadChangeOrders = async () => {
        try {
          setShowProcessing(true);
          const { payload = [] } = await dispatch((viewChangeOrdersAsync as any)(projectId));
          setChangeOrders(payload);

          const coNumbers = payload.map(({ coNumber }) => coNumber);
          form.setValue('existingChangeOrders', coNumbers);

          setNextBestCO(coNumbers.length ? Math.max(...coNumbers) + 1 : 1);

          if (currentChangeOrderId?.length > 0) {
            const existingCO = payload.find((co: any) => co.objectId === currentChangeOrderId);
            setCurrentCO(existingCO || {});
          } else {
            // Initialize new change order with default estData
            const localScopeId = allJobScopes.find(
              (scope) => !scope.isSystemMaster && scope.code === 'OTHR'
            )?.value;
            
            const newDefaultValues = _.cloneDeep(defaultValues);
            newDefaultValues.estData = [
              { 
                scopeId: localScopeId, 
                lineItemId: null, 
                unitCost: 0, 
                unitProfit: 0, 
                qty: 1, 
                subTotalProfit: 0, 
                subTotalProfitPers: 0, 
                subTotalCost: 0,
                subTotalCtr: 0, 
                description: '' 
              },
            ];
            
            setCurrentCO({ 
              ...newDefaultValues, 
              coCostAmount: 0, 
              profitDollars: 0, 
              coTotalAmount: 0 
            });
          }
          
          setShowProcessing(false);
        } catch (error) {
          console.error("Error loading change orders:", error);
          notification.error({ message: 'Error loading change orders' });
          setShowProcessing(false);
        }
      };

      loadChangeOrders();
    }
    
    // Reset state when modal closes
    if (!isOpen) {
      setCurrentChangeOrderId('');
      setIsLocked(false);
      form.reset();
    }
  }, [isOpen, projectId, currentChangeOrderId, form, dispatch, allJobScopes, defaultValues, currentCO.isPCO]);

  // Initialize form with change order data or defaults
  useEffect(() => {
    if (_.isEmpty(currentCO)) {
      form.setValue('effectiveDate', formatDateObject());
      form.setValue(
        'existingChangeOrders',
        changeOrders.map(({ coNumber }) => coNumber)
      );
    } else {
      const formValues = {
        effectiveDate: currentCO?.objectId
          ? formatDateObjectOrNull(currentCO.effectiveDate)
          : formatDateObject(),
        existingChangeOrders: changeOrders
          .filter((co) => co.objectId !== currentCO.objectId)
          .map(({ coNumber }) => coNumber),
        coNumber: currentCO.coNumber ?? nextBestCO,
        comment: currentCO.comment || '',
        exclusions: currentCO.exclusions || '',
        changeOrderReason: currentCO.reasonCodeId ?? currentCO.reasonCode?.objectId,
        coCostAmount: currentCO.coCostAmount || 0,
        profitDollars: currentCO.profitDollars || 0,
        coTotalAmount: currentCO.coTotalAmount || 0,
        estTotalContract: currentCO.coTotalAmount || 0,
        estTotalCost: currentCO.coCostAmount || 0,
        estTotalProfit: currentCO.profitDollars || 0,
        estData: formatEstData(currentCO.estData || [], true),
        scheduleImpact: currentCO.scheduleImpact || 0,
        wasAccepted: currentCO.wasAccepted || false,
        acceptedDate: formatDateObjectOrNull(currentCO.acceptedDate),
        acceptedBy: currentCO.acceptedBy || '',
        scopeArr: currentCO.scopeArr ?? [],
        createEstimate: false,
      };
      
      form.reset(formValues, { keepDefaultValues: true });
    }
  }, [currentCO, changeOrders, form, nextBestCO]);

  // Determine if this is a new change order
  const isNew = useMemo(() => !(currentChangeOrderId?.length > 0), [currentChangeOrderId]);
  
  // Set change order title based on status
  const PCOTitle = useMemo(
    () => `${currentCO.isPCO ? 'Potential ' : ''}Change Order #${currentCO.coNumber}`,
    [currentCO]
  );
  
  const title = useMemo(() => isNew ? 'Enter Change Order' : PCOTitle, [isNew, PCOTitle]);

  // Format data for API submission
  const formatPostData = useCallback((data: any, isPCO: boolean, notApproved: boolean) => {
    return {
      objectId: currentCO.objectId,
      projectId: project.objectId ?? currentCO.projectId,
      reasonCode: data.changeOrderReason,
      exclusions: data.exclusions,
      coNumber: +(data.coNumber || 0),
      coCostAmount: data.estTotalCost,
      coTotalAmount: data.estTotalContract,
      profitDollars: data.estTotalProfit,
      projectStatus: {
        ObjectId: project.jobStatusCodes?.objectId || project.jobStatusCodes,
        jobStatusCodesCode: project.jobStatusCodesCode,
        jobStatusCodesName: project.jobStatusCodesName,
      },
      estData: formatEstData(data.estData, false),
      currentContractAmount: project.currentContractAmount + data.estTotalContract,
      currentGrossProfit: project.currentGrossProfit + data.estTotalProfit,
      comment: data.comment,
      effectiveDate: data.effectiveDate,
      acceptedBy: data.acceptedBy || '',
      wasAccepted: data.wasAccepted,
      acceptedDate: data.acceptedDate,
      scheduleImpact: data.scheduleImpact,
      createEstimate: data.createEstimate,
      trash: project.trash,
      changeOrderDate: formatDateUTC(),
      isPCO,
      notApproved,
      qbEstimateId: data.qbEstimateId,
      shouldGeneratePdf: data.shouldGeneratePdf,
      pdfUrl: data.pdfUrl,
    };
  }, [currentCO, project]);

  // Handle PDF generation and email sending
  const handleSendPdf = useCallback(async () => {
    setIsPdfModalOpen(false);

    const emailSubject = `Change Order for ${project.jobName || 'Project'}`;
    const emailBody = `Please find attached the change order for ${project.jobName || 'your project'}.`;

    const sendEmailData = {
      to: clientData
        .filter((client) => client?.primaryEmail?.email?.length > 0)
        .map((client) => ({
          value: client.primaryEmail.email,
          label: client.primaryEmail.email,
          contactId: client.contactId,
          companyId: client.companyId,
          _id: client._id,
        })),
      subject: emailSubject,
      body: emailBody,
      attachments: [{ fileName: pdfFileName, url: pdfUrl }],
    };

    try {
      const result = await dispatch(
        createEstimateEmail({
          projectId: projectId ?? currentCO.projectId,
          pdfUrl: pdfUrl,
          coId: currentCO.objectId,
          emailData: sendEmailData,
          type: 'changeOrder',
        })
      ).unwrap();

      if (result.emailId) {
        const url = '/project-details/' + result.projectId + '/email/edit/' + result.emailId;
        history.push(url);
        form.reset({ ...form.formState.defaultValues });
        toggle();
        onSubmitCb(true);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      notification.error({ message: 'Error sending email' });
    }
  }, [
    clientData, 
    dispatch, 
    form, 
    history, 
    onSubmitCb, 
    pdfFileName, 
    pdfUrl, 
    project.jobName, 
    projectId, 
    toggle, 
    currentCO
  ]);

  // Handle form submission with different actions
  const onFormSubmit = useCallback(async (markAction: string) => {
    let data: any = null;
    setShowProcessing(true);

    try {
      // Validate form first
      await form.handleSubmit(async (result) => {
        data = result;
      }, console.log)();

      if (!data) {
        setShowProcessing(false);
        throw new Error('Invalid form data');
      }

      // Prepare data for submission based on action
      let postData = {} as any;
      switch (markAction) {
        case 'Save':
          postData = formatPostData(data, !wasAccepted, currentCO.notApproved ?? false);
          break;
        case 'Approve':
          data.wasAccepted = true;
          data.notApproved = false;
          data.isPCO = false;
          postData = formatPostData(data, false, false);
          break;
        case 'Decline':
          data.notApproved = true;
          data.isPCO = true;
          data.wasAccepted = false;
          postData = formatPostData(data, true, true);
          break;
        case 'Potential':
          data.notApproved = false;
          data.isPCO = true;
          data.wasAccepted = false;
          postData = formatPostData(data, true, false);
          break;
        case 'CreatePdf':
          postData = formatPostData(data, !wasAccepted, currentCO.notApproved ?? false);
          break;
        default:
          break;
      }

      // Set PDF flag if needed
      const shouldGeneratePdf = markAction === 'CreatePdf';
      if (shouldGeneratePdf) {
        postData.shouldGeneratePdf = true;
      }

      // Submit change order
      const result = await dispatch((createChangeOrderAsync as any)(postData)).unwrap();

      if (!result.objectId) {
        notification.error({ message: 'Error creating/updating change order' });
        setShowProcessing(false);
        return;
      } else {
        setCurrentChangeOrderId(result.objectId);
      }

      // Handle PDF generation
      if (shouldGeneratePdf && result.objectId && result.pdfUrl) {
        setPdfUrl(result.pdfUrl);
        setPdfFileName(result.pdfFileName);
        setCurrentChangeOrderId(result.objectId);

        const clientEmail = clientData[0]?.primaryEmail?.email || '';
        const emailSubject = `Change Order for ${project.projectName || 'Project'}`;
        const emailBody = `Please find attached the change order for ${project.projectName || 'your project'}.`;

        setEmailData({
          to: [{ value: clientEmail, label: clientEmail }],
          subject: emailSubject,
          body: emailBody,
          attachments: [{ fileName: result.pdfFileName, url: result.pdfUrl }],
        });

        setIsPdfModalOpen(true);
      } else if (!shouldGeneratePdf) {
        form.reset({ ...form.formState.defaultValues });
        toggle();
        onSubmitCb();
      }
      
      setShowProcessing(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      notification.error({ message: 'Error submitting change order' });
      setShowProcessing(false);
    }
  }, [
    currentCO, 
    dispatch, 
    form, 
    formatPostData, 
    clientData, 
    onSubmitCb, 
    project.projectName, 
    toggle, 
    wasAccepted
  ]);

  // Create submit handlers with useSubmit hook
  const [onSaveCO] = useSubmit(async () => await onFormSubmit('Save'), [onFormSubmit]);
  const [onApproveCO] = useSubmit(async () => await onFormSubmit('Approve'), [onFormSubmit]);
  const [onSubmitAsPCO] = useSubmit(async () => await onFormSubmit('Save'), [onFormSubmit]);
  const [onDeclinePCO] = useSubmit(async () => await onFormSubmit('Decline'), [onFormSubmit]);
  const [onSavePotential] = useSubmit(async () => await onFormSubmit('Potential'), [onFormSubmit]);

  // Determine main action button based on state
  const mainAction = useMemo(() => {
    let label = 'Save';
    let action = onSaveCO;

    if (isNew) {
      label = `Save ${wasAccepted ? 'Approved' : 'Potential'} C/O`;
      action = wasAccepted ? onApproveCO : onSubmitAsPCO;
    } else {
      if (wasAccepted) {
        label = 'Save Approved C/O';
        action = onApproveCO;
      }
    }

    return (
      <button className='btn btn-primary' disabled={submitting} onClick={action}>
        {submitting ? 'Saving...' : label}
      </button>
    );
  }, [isNew, wasAccepted, submitting, onSaveCO, onApproveCO, onSubmitAsPCO]);

  // Toggle fill template modal
  const toggleFillTemplate = useCallback(() => {
    setFillTemplateOpen(!fillTemplateOpen);
  }, [fillTemplateOpen]);

  interface ModalHeaderProps {
    toggleFn: () => void;
    toggleFillTemplate: () => void;
  }

  // Modal header component
  const ModalHeader = useCallback(({ toggleFn, toggleFillTemplate }: ModalHeaderProps) => {
    return (
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        <div className='ms-auto align-right'>
          {!appConstants.IS_PRODUCTION && (
            <span
              onClick={toggleFillTemplate}
              className='ms-auto px-2 py-1'
            >
              <i className='fa fa-file-text-o' />
            </span>
          )}
          <button className='btn-close' aria-label='Close' onClick={toggleFn} />
        </div>
      </div>
    );
  }, [title]);

  return (
    <>
      <Modal
        className={styles.coForm}
        backdrop='static'
        isOpen={isOpen}
        toggle={toggle}
        size={detailedMode ? 'xl' : 'lg'}
      >
        <ModalHeader toggleFn={toggle} toggleFillTemplate={toggleFillTemplate} />
        <ModalBody style={{ padding: '16px 30px' }}>
          <FormProvider {...form}>
            {showProcessing && (
              <div className='loading_bg'>
                <img className='ajax-loader' src={loaderImage} width='100' height='100' alt="Loading" />
              </div>
            )}
            <ChangeOrderFields
              isDisabled={isLocked}
              project={project}
              currentCO={currentCO}
              setDetailedModeCallback={setDetailedMode}
            />
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <button type='button' className='btn btn-primary' onClick={toggle}>
            {isLocked ? 'Close' : 'Cancel'}
          </button>
          {!isLocked && (
            <div className='btn-group'>
              {mainAction}
              <button
                type='button'
                className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <span className='visually-hidden'>Toggle Dropdown</span>
              </button>
              <ul className='dropdown-menu ml-auto'>
                <li className='dropdown-item'>
                  <a className='statusBtn' onClick={() => onFormSubmit('CreatePdf')}>
                    Save & send to Client
                  </a>
                </li>
                {(currentCO.isPCO || isNew) &&
                  !wasAccepted &&
                  !currentCO.notApproved &&
                  !isLocked && (
                    <li className='dropdown-item'>
                      <a className='statusBtn' onClick={onDeclinePCO}>
                        Save as Declined
                      </a>
                    </li>
                  )}
                {currentCO.notApproved && !wasAccepted && !isLocked && (
                  <li className='dropdown-item'>
                    <a className='statusBtn' onClick={onSavePotential}>
                      Save as Potential Change Order
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          {isLocked && allowedChangeApproved && (
            <button type='button' className='btn btn-primary' onClick={() => setIsLocked(false)}>
              Unlock & Edit
            </button>
          )}
        </ModalFooter>
      </Modal>
      
      {/* Template and PDF modals */}
      {fillTemplateOpen && currentCO.objectId && (
        <FillTemplateModal
          open={fillTemplateOpen}
          toggle={toggleFillTemplate}
          objectId={currentCO.objectId}
          formType={'changeOrder'}
        />
      )}
      
      <PdfPreviewModal
        isOpen={isPdfModalOpen}
        toggle={() => setIsPdfModalOpen(false)}
        pdfUrl={pdfUrl}
        onSend={handleSendPdf}
        title='Change Order PDF Preview'
        onSendEmail={emailData}
      />
    </>
  );
};