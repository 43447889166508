import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '../style.module.scss';
import { currencyToNumber, formatPercent, roundFormatMoney } from '../../../../../utils/number-formatters';
import FormInput from '../../../../../shared/form-controls/form-input';

type TotalProps = {
  showProfitAsPers: boolean;
  initialValues: { currentGrossProfit: number; currentContractAmount: number };
  taxEnabled?: boolean;
};

export const TotalSection = ({ showProfitAsPers, initialValues, taxEnabled=false }: TotalProps) => {
  const form = useFormContext();
  const [taxRate, setTaxRate] = useState(0);

  const estTotalCost = +form.watch('estTotalCost', 0);
  const estTotalProfit = +form.watch('estTotalProfit', 0);
  const estTotalContract = +form.watch('estTotalContract', 0);

  const estData = form.watch('estData', []);

  const sumAllTaxableItems = () => {
    let estTotalTaxable = 0;
    if (estData?.length > 0) {
      estData.forEach((item: any) => {
        if (item.taxable && !(item.items?.length > 0)) {
          const itemCtr = currencyToNumber(item.subTotalCtr);
          if (itemCtr > 0) estTotalTaxable += itemCtr;
        } else if (item?.items?.length > 0) {
          item.items.forEach((subItem: any) => {
            if (subItem.taxable) {
              const subItemCtr = currencyToNumber(subItem.total);
              estTotalTaxable += subItemCtr;
            }
          });
        }
      });
    }

    return estTotalTaxable;
  };

  const totalTaxable = sumAllTaxableItems();
  const taxAmount = (totalTaxable * taxRate/ 100) ;

  const emptyScope = estTotalProfit === 0 && estTotalCost === 0 && estTotalContract === 0;
  const { currentGrossProfit = 0, currentContractAmount = 0 } = initialValues;

  const totalProfit = emptyScope ? currentGrossProfit : estTotalProfit;
  const totalCost = emptyScope ? currentContractAmount - currentGrossProfit : estTotalCost;
  const totalCtr = emptyScope ? totalProfit + totalCost : estTotalContract;
  const grandTotal = totalCtr + taxAmount;

  const costPers =
    totalCost === 0
      ? 0
      : totalCtr === 0
        ? 100
        : parseFloat(((totalCost * 100) / totalCtr).toFixed(1));
  const profitPers =
    totalCtr === 0
      ? 0
      : totalCtr === 0
        ? 100
        : parseFloat(((totalProfit * 100) / totalCtr).toFixed(1));

  return (
    <div data-testid='bidManagementModalTotal'>
      <hr />
      <div className={`row col-md-12 ${!showProfitAsPers ? 'fw-bold' : ''}`}>
        <div className='offset-md-2 col-md-2'>Totals ($):</div>
        <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(totalCost)}</div>
        <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(totalProfit)}</div>
        <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(totalCtr)}</div>
      </div>
      <div className={`row col-md-12 ${showProfitAsPers ? 'fw-bold' : ''}`}>
        <div className='offset-md-2 col-md-2'>Totals (%):</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatPercent(costPers, 1)}%</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatPercent(profitPers, 1)}%</div>
        <div className={`text-end ${styles.inputWidth}`}>100%</div>
      </div>
      {taxEnabled&&totalTaxable > 0 && (
        <>
          <div className='row col-md-12'>
            <div className='offset-md-2 col-md-2'>Taxed Total:</div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(totalTaxable)}</div>
          </div>
          <div className='row col-md-12'>
            <div className='offset-md-2 col-md-2'>Tax Rate (%):</div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}>
              <FormInput
                type='number'
                name='taxRate'
                inputClassName='form-control text-end'
                className='text-end'
                value={taxRate.toString()}
                onChange={(e) => {
                  return setTaxRate(parseFloat(e));
                }}
                placeholder='Enter tax rate'
              />
            </div>
          </div>
          <div className='row col-md-12'>
            <div className='offset-md-2 col-md-2'>Tax Amount:</div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(taxAmount)}</div>
          </div>
          <div className='row col-md-12 fw-bold'>
            <div className='offset-md-2 col-md-2'>Grand Total:</div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}></div>
            <div className={`text-end ${styles.inputWidth}`}>{roundFormatMoney(grandTotal)}</div>
          </div>
        </>
      )}
    </div>
  );
};
