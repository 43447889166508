import React from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import readXlsxFile from 'read-excel-file';
import {
  //createHelpByUploadAction,
  createHelpDetailsAction,
  deleteHelpDetailsAction,
  getHelpDetailsAction,
  updateHelpDetailsAction
} from '../../../actions/Admin/help.actions.js';
import { appConstants } from '../../../_constants';
import CustomTable from '../../Common/CustomTable';
import TitleComponent from '../../Common/TitleComponent';
import { callAlertMessage, UpperCaseName } from '../../../components/Auth/Auth';
//import AdminSidebar from '../Inc/AdminSideBar.jsx';
import './../../../index.css';
import { TextEditorField } from '../../Common/TextEditorField';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  title: 'required',
  helpType: 'required',
  description: 'required',
};

let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);
validation.passes();
validation.fails();

class HelpList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // htmlFor use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      modal: false,
      modalExcel: false,
      saveType: 'POST',
      formData: {},
      excelType: {},
      fetchRequest: true,
      isCreated: false,
      successRedirect: false,
      masterTypeArr: [],
      selectBox: {},
      optionTitle: [],
      uploadFile: false,
      helpEditor: false,
      isDeleted: false,
      ExcelTypeSelect: '',
      description: {},
      selectedRows: [],
    };
    this.toggle = this.toggle.bind(this);
    this.toggleExcelSample = this.toggleExcelSample.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }


  componentDidMount() {
    this.list();

    let optionTitle = [];
    appConstants.OptionHelpType.map((v) => {
      if (v.name !== '' && v.value !== '') {
        optionTitle.push({ value: v.value, label: v.name });
      }
      this.setState({ optionTitle });
      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.helpInfo && nextProps.helpInfo.length > 0) {
      this.setState({ list: nextProps.helpInfo, fetchRequest: false });
      nextProps.helpInfo.map((v, i) => {
        if (v ) {
          this.state.masterTypeArr.push(v.objectId);
        }
        return true;
      });
    }

    if (nextProps.helpInfo && nextProps.helpInfo.length === 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 3000);
    }

    if (nextProps.isCreated !== this.state.isCreated && nextProps.isCreated === true) {
      this.setState({
        isCreated: true,
      });
      let _this = this;
      if (nextProps.success === 1 && this.state.successRedirect && nextProps.message !== '') {
        callAlertMessage('success', nextProps.message);
        if (_this.state.modal) {
          _this.setState({ modal: false });
        }
        this.list();
      }
      if (nextProps.success === 0 && nextProps.message !== '') {
        callAlertMessage('error', nextProps.message);
        if (_this.state.modal) _this.setState({ modal: true });
      }
    }
  }

  list() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    this.props.getHelpDetailsAction(data);
  }

  toggleExcelSample() {
    this.setState({ modalExcel: !this.state.modalExcel });
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {}, description: {}, selectBox: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  selectedRow1 = (row) => {
    let _this = this;
    var selectBox = this.state.selectBox;

    if (row) {
      this.setState({ formData: row });
    }

    if (row && row.helpType) {
      selectBox.helpType = [{ value: row.helpType, label: row.helpType }];
    }

    this.setState({
      selectBox: selectBox,
      description: row.description,
    });

    if (row ) {
      setTimeout(function () {
        _this.toggle('PUT');
      }, 100); // Select row to open edit modal
    }
     else {
      return false;
    }
  };

  addHelpData(event) {
    event.preventDefault();
    if (this.state.uploadFile === false) {
      let formData = this.state.formData;
      formData['userId'] = this.state.userId;
      formData['accountId'] = this.state.accountId;

      formData['isSystemMaster'] = true;
      
      if (this.allValidate(false)) {
        if (this.state.saveType === 'POST') {
          this.props.createHelpDetailsAction(formData);
          this.setState({ uploadFile: false, helpEditor: false });
        } else {
          this.props.updateHelpDetailsAction(formData);
          this.setState({ uploadFile: false, helpEditor: false });
        }
        this.setState({ successRedirect: true, isCreated: false });

        this.setState({ modal: !this.state.modal, formArr: [] });
      }
    } else {
      let sheetData;
      const input = document.getElementById('files');

      readXlsxFile(input.files[0]).then((rows) => {
        sheetData = rows;
        let headers = ['title', 'helpType', 'description'];
        this.importCompany(sheetData, headers);
      });
    }
    
  }

  importCompany(data, headers) {
    let sendData = [];
    for (var i = 0; i < headers.length; i++) {
      if (headers[i] === data[0][i]) {
        for (var a = 0; a < data.length; a++) {
          let obj = {};
          for (var z = 0; z < headers.length; z++) {
            if (a !== 0) {
              obj[headers[z]] = data[a][z];
            }
          }
          if (obj.helpType !== undefined) {
            sendData.push(obj);
          }
        }

        let data1 = {};
        if (localStorage.getItem('loginType') === 'SUPERADMIN') {
          data1 = {
            userId: this.state.userId,
            accountId: this.state.accountId,
            isSystemMaster: true,
            dataArr: sendData,
          };
        } else {
          data1 = {
            userId: this.state.userId,
            accountId: this.state.accountId,
            isSystemMaster: false,
            dataArr: sendData,
          };
        }

        //this.props.createHelpByUploadAction(data1);
        this.setState({
          successRedirect: true,
          isCreated: false,
          uploadFile: false,
          helpEditor: false,
        });
      } else {
        this.setState({ uploadFile: false, helpEditor: false });
        return false;
      }
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  onRenderDes(cell, row, enumObject) {
    if (row) {
      return (
        <div className='helptext-wrapper'>
          <span>
            <strong>Title: </strong>
            {row.title ? row.title : '-'}
          </span>
          <br />
          <span>
            <strong>Type: </strong>
            {row.helpType ? row.helpType : '-'}
          </span>
          <br />
          <span>
            <strong>Description: </strong>
            {renderHTML(row.description ? row.description : '-')}
          </span>
        </div>
      );
    }
  }

  onInputChange(e) {
    this.setState({ uploadFile: true });
  }

  handleChange1(name, value) {
    let field = this.state.formData;
    field[name] = value;
    this.setState({
      formData: field,
      [name]: value,
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;

    formData[e.target.name] = UpperCaseName(e.target.value);

    if (formData[e.target.name] === '') {
      this.setState({ formData, helpEditor: false, uploadFile: true });
    } else {
      this.setState({ formData, helpEditor: true, uploadFile: false });
    }
  };

  handleChangeState = (name, state) => {
    if (name === 'excelHelpType') {
      let excelTypes = this.state.excelType;
      excelTypes['label'] = state.label;
      excelTypes['value'] = state.value;

      this.setState({
        excelType: excelTypes,
      });
    } else {
      let field1 = this.state.selectBox;
      let formData = this.state.formData;
      formData['helpType'] = state.value;
      field1['helpType'] = state;
      this.setState({
        selectBox: field1,
        formData: formData,
        helpEditor: true,
        uploadFile: false,
      });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    const keysToDelete = list
      .filter((f, index) => selectedRows.includes(index.toString()))
      .map((k) => ({
        objectId: k.objectId,
      }));
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm('Are you sure to delete?');
    if (confirm) {
      console.log('success');
      this.props.deleteHelpDetailsAction(data);
      this.setState({ successRedirect: true, isCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, appConstants.RULESMESSAGE);
    validation.passes();
    validation.fails();


    const selectRowProp = {
      mode: 'checkbox',
      bgColor: function (row, isUnSelect) {
        if (row.isSystemMaster === true) {
          return 'disabled';
        }
        return null;
      },
      unselectable: this.state.masterTypeArr, // give rowkeys for unselectable row
    };

    //show option, delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();
    let disabledCheck;
    let ExcelTypeSelects = '';
    if (this.state.excelType.label === undefined) {
      disabledCheck = true;
      ExcelTypeSelects = 'This field is required';
    } else {
      ExcelTypeSelects = '';

      disabledCheck = false;
    }

    const columns = [
      {
        Header: '',
        accessor: 'objectId',
        width: 500,
        disableSortBy: true,
        Cell: (props) => {
          return this.onRenderDes(props.column.id, props.row.original, props.value);
        },
      },
    ];

    return (
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `Help Field Settings` }} />
        {/* <Suspense fallback="loading"><Header props={this.props} /></Suspense> */}
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-12'>

              <h2 className='heading2'>Help</h2>
              {/*    <div className="textdesc">
                                <p><a href="#" onClick={() =>this.toggleExcelSample()} >Download a simple template (Excel)</a></p>
                            </div> */}
            </div>
            <div className='d-flex justify-content-between'>
              <button
                className='btn btn-primary'
                style={{ cursor: 'pointer', padding: '5px' }}
                disabled={this.state.selectedRows?.length < 1}
                onClick={() => this.handleDeleteButtonClick()}
              >
                <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Delete
              </button>
              <button className='btn btn-primary' onClick={() => this.toggle('POST')}>
                Add
              </button>
            </div>
            <div className='row'>
              <CustomTable
                columns={columns}
                list={this.state.list}
                canShowColumnSettings={false}
                canShowDateFilter={false}
                onChangeRowSelection={this.handleRowSelection}
                canShowExport={false}
                onRowClicked={(cell, row) => this.selectedRow1(row.original)}
                pageSizeArray={[10, 25, 30, 50]}
              />

            </div>
          </div>
        </div>
        {/* <Footer /> */}

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.formData.objectId ? 'Edit Help' : 'Add Help'}
          </ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className={this.state.uploadFile ? 'help' : 'show'}></div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='title'>
                      Title<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='title'
                      id='title'
                      value={this.state.formData && this.state.formData.title}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      placeholder='Title'
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.title && validation.errors.first('title')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='helpType'>
                      Type<span className='requiredField'>*</span>
                    </label>
                    <Select
                      name='helpType'
                      components={makeAnimated()}
                      value={this.state.selectBox.helpType}
                      onChange={this.handleChangeState.bind(this, 'helpType')}
                      options={this.state.optionTitle}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.helpType && validation.errors.first('helpType')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='fieldBox'>
                    <label htmlFor='contactRoleDefinition'>
                      Description<span className='requiredField'>*</span>
                    </label>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                         <TextEditorField
                            title='Description'
                            name='description'
                            value={this.state.description}
                            onChange={(e) => this.handleChange1('description', e)}
                          />
                          <span style={styleData} className='error'>
                            {this.state.formArr.description && validation.errors.first('description')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span style={styleData} className='error'>
                      {this.state.formArr.description && validation.errors.first('description')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn' onClick={(e) => this.addHelpData(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    helpInfo: state.help.helpData,
    isCreated: state.help.isCreated,
    message: state.help.message,
    success: state.help.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createHelpDetailsAction,
    //createHelpByUploadAction,
    getHelpDetailsAction,
    updateHelpDetailsAction,
    deleteHelpDetailsAction,
  })(HelpList)
);