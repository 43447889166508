import moment from 'moment';
import { useEffect, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import FormCheckbox from '../../../shared/form-controls/form-checkbox/form-checkbox';
import FormDatePicker from '../../../shared/form-controls/form-date-picker/form-date-picker';
import { useSelector } from 'react-redux';
import { selectChangeOrderReasonOptions } from '../../../selectors/reasonCode';
import styles from '../change-order-modal.module.scss';

import { JobScopeLineItems } from '../../bid-modal/sections/scope-estimates';

type ChangeOrderFieldsProps = {
  isDisabled?: boolean;
  project: any;
  currentCO: any;
  setDetailedModeCallback?: (isDetailedMode: boolean) => void;
}


export const ChangeOrderFields = ({ isDisabled = false, project, currentCO, setDetailedModeCallback }: ChangeOrderFieldsProps) => {

  const changeOrderReasonOptions = useSelector(selectChangeOrderReasonOptions);

  const form = useFormContext();
  const { watch, setValue } = form;
  const wasAccepted = watch('wasAccepted');

  const jobScopesRef = useRef<any>(null);

  useEffect(() => {
    setValue('acceptedDate', wasAccepted ? moment() : null, { shouldValidate: true });
  }, [wasAccepted]);

  const HorizontalLayoutWide = useMemo(() => 
    (props: any) => <HorizontalLayout {...props} labelWidth={3} controlWidth={9} />,
  []);

  const HorizontalLayoutInline = useMemo(() => 
    (props: any) => <HorizontalLayout {...props} inline labelWidth={0} controlWidth={12} />,
  []);

  return (
    <>
      <FormDatePicker
        label='Date'
        name='effectiveDate'
        control={form.control}
        Layout={HorizontalLayout}
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      <FormInput
        required
        type='number'
        label='Change Order Number:'
        placeholder='Change Order Number'
        control={form.control}
        name='coNumber'
        Layout={HorizontalLayout}
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      <FormInput
        required
        textArea
        label='Description / Inclusions:'
        placeholder='Description of the change (scope, schedule impact, cost impact details)'
        control={form.control}
        name='comment'
        Layout={HorizontalLayout}
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      <FormInput
        textArea
        label='Exclusions:'
        placeholder='Work not included in this change order'
        control={form.control}
        name='exclusions'
        Layout={HorizontalLayout}
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      {changeOrderReasonOptions.length > 0 && <>
      <FormSelect
        label='Change Order Reason:'
        control={form.control}
        options={changeOrderReasonOptions}
        name='changeOrderReason'
        Layout={HorizontalLayout}
        placeholder='Change Order Reason'
        isClearable
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      </>}
      {/* {false && <>
        <FormInput
          required
          label='Enter Your Cost:'
          placeholder='How much it will cost you to perform this work'
          control={form.control}
          name='coCostAmount'
          Layout={HorizontalLayout}
          labelWidth={5}
          controlWidth={7}
          CustomInput={CurrencyInput}
          className={styles.inputTextRight}
          disabled={isDisabled}
        />
        <div className='row'>
          <div className='col-md-5'>
            <label htmlFor='profitPercentage'>
              Enter Markup:<span className='requiredField'>*</span>
            </label>
          </div>
          <div className='col-md-7'>
            <div className='form-group'>
              <div className={`d-flex align-items-center ${styles.inputGroupWrap}`}>
                <div className='input-group'>
                  <input
                    type='number'
                    placeholder='Enter %'
                    className='form-control text-end'
                    {...form.register('profitPercentage')}
                    disabled={isDisabled}
                  />
                  <span className='input-group-text'>%</span>
                </div>
                <span className='mx-3'>Or</span>
                <div className='input-group'>
                  <span className='input-group-text'>$</span>
                  <FormInput
                    control={form.control}
                    name='profitDollars'
                    placeholder='Enter $'
                    className={`form-control p-0 ${styles.noError}`}
                    inputClassName='form-control text-end m-0 border-0'
                    CustomInput={CurrencyInput}
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div>
                <span className='error'>{form.formState.errors.profitPercentage?.message?.toString() ?? ''}</span>
                <span className='error'>{form.formState.errors.profitDollars?.message?.toString() ?? ''}</span>
              </div>
            </div>
          </div>
        </div>
        <FormInput
          type='number'
          label='Amount of Change Order To Customer:'
          placeholder='Total amount of this Change Order'
          control={form.control}
          name='coTotalAmount'
          Layout={HorizontalLayout}
          labelWidth={5}
          controlWidth={7}
          CustomInput={CurrencyInput}
          className={styles.inputTextRight}
          disabled={isDisabled}
        />
      </>} */}
      <FormInput
        type='number'
        label='Schedule Impact (days):'
        placeholder='+/- Calendar Days'
        control={form.control}
        name='scheduleImpact'
        Layout={HorizontalLayout}
        labelWidth={5}
        controlWidth={7}
        disabled={isDisabled}
      />
      {/* Approval section */}
      <div className={`${styles.formSection} ${styles.approvalSection}`}>
        {/*<h5 className={styles.sectionTitle}>Approval Information</h5>*/}
        <div className="row align-items-center mb-3">
          <div className="col-md-5">
            <FormCheckbox
              name='wasAccepted'
              label='Was Accepted'
              control={form.control}
              className={styles.acceptedCheckbox}
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-7">
            {wasAccepted && (
              <div className="d-flex align-items-center">
                <div className="me-2">Date Approved:</div>
                <div className="flex-grow-1">
                  <FormDatePicker
                    label=''
                    name='acceptedDate'
                    placeholderText='Accepted Date'
                    control={form.control}
                    Layout={HorizontalLayoutInline}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {wasAccepted && (
          <FormInput
            label='Accepted By'
            placeholder='Name of person who accepted'
            name='acceptedBy'
            control={form.control}
            Layout={HorizontalLayoutWide}
            disabled={isDisabled}
          />
        )}
      </div>

      <JobScopeLineItems isLocked={isDisabled} projectData={project} ref={jobScopesRef} projectBidData={currentCO} isChangeOrder={true} toggleDetailedModeCallback={setDetailedModeCallback} />
      <br />
    </>
  );
};
